import styled from 'styled-components'

export const BaseInput = styled.input`
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  outline: ${(props) => props.error && props.theme.inputBorderColourError};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  padding: 0 ${(props) => props.theme.formControlHorizontalPadding};
  margin-bottom: ${(props) => props.theme.formControlBottomMargin};
  height: ${(props) => props.theme.formControlHeight};
  width: 100%;
  max-width: 360px;
  color: ${(props) => props.theme.primaryTextColour};
  font-size: ${(props) => props.theme.generalFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  font-style: ${(props) => props.theme.fontStyle};
  font-weight: ${(props) => props.theme.fontWeight};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  ::-ms-clear {
    display: none;
  }

  :focus {
    outline: 4px solid #4c90bd;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

