import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  PriceComparisonWrapper,
  TotalPriceWrapper,
  TotalPriceValueWrapper,
  QuoteInfoWrapper,
  QuoteInfoHighlight,
  PriceComparisonContinueButton,
  ImageAndButtonWrapper,
  PaymentBreakdownIconWrapper,
} from './styles'
import PaymentBreakdownIcon from '../../containers/PaymentBreakdownIcon'
import { ButtonSpinner } from '../common/ButtonSpinner'

const PaymentBreakdownIcon_DisplayRightBreakpoint_Min = 550
const PaymentBreakdownIcon_DisplayRightBreakpoint_Max = 1025

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const PriceComparison = ({
  priceData,
  priceData: {
    UnderwriterSchemeInfo: {
      MaxPenaltyPoints,
      MaxPenaltyPointsExpiryYears,
      MaxFaultClaims,
      MaxFaultClaimsExpiryYears,
    },
  },
  selectedUnderwriter,
  affiliate,
  onContinue,
  continueButtonDisabled,
  index,
}) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <PriceComparisonWrapper
      isSelected={selectedUnderwriter === priceData.UnderwriterId}
    >
      <TotalPriceWrapper>
        Total&nbsp;price{' '}
        <TotalPriceValueWrapper>£{priceData.TotalPrice}</TotalPriceValueWrapper>
        <PaymentBreakdownIconWrapper>
          <PaymentBreakdownIcon
            price={priceData}
            affiliate={affiliate}
            index={index}
            showRight={
              windowDimensions.width <
                PaymentBreakdownIcon_DisplayRightBreakpoint_Max &&
              windowDimensions.width >=
                PaymentBreakdownIcon_DisplayRightBreakpoint_Min
            }
          />
        </PaymentBreakdownIconWrapper>
      </TotalPriceWrapper>
      <QuoteInfoWrapper>
        <img
          src={require(`../../images/Underwriters/${priceData.Underwriter}LogoForUnderwriterSelection.png`)}
          alt={priceData.Underwriter}
          className='price-comparison-underwriter-logo mobile'
        />
        <p>
          <QuoteInfoHighlight>£{priceData.CompulsoryExcess}</QuoteInfoHighlight>{' '}
          compulsory excess
        </p>
        <p>
          Up to{' '}
          <QuoteInfoHighlight>
            {MaxPenaltyPoints} licence points
          </QuoteInfoHighlight>{' '}
          in the last{' '}
          <QuoteInfoHighlight>
            {MaxPenaltyPointsExpiryYears === 1
              ? '1 year'
              : `${MaxPenaltyPointsExpiryYears} years`}
          </QuoteInfoHighlight>
        </p>
        <p>
          Up to{' '}
          <QuoteInfoHighlight>
            {MaxFaultClaims === 1
              ? '1 fault claim'
              : `${MaxFaultClaims} fault claims`}{' '}
          </QuoteInfoHighlight>{' '}
          in the last{' '}
          <QuoteInfoHighlight>
            {MaxFaultClaimsExpiryYears === 1
              ? '1 year'
              : `${MaxFaultClaimsExpiryYears} years`}
          </QuoteInfoHighlight>
        </p>
      </QuoteInfoWrapper>
      <ImageAndButtonWrapper>
        <img
          src={require(`../../images/Underwriters/${priceData.Underwriter}LogoForUnderwriterSelection.png`)}
          alt={priceData.Underwriter}
          className='price-comparison-underwriter-logo desktop'
        />
        <PriceComparisonContinueButton
          onClick={onContinue}
          disabled={continueButtonDisabled}
        >
          {continueButtonDisabled ? (
            <>
              Please wait
              <br />
              <ButtonSpinner disabled={continueButtonDisabled} />
              &nbsp;
            </>
          ) : (
            <>Continue with this quote</>
          )}
        </PriceComparisonContinueButton>
      </ImageAndButtonWrapper>
    </PriceComparisonWrapper>
  )
}

export default PriceComparison

PriceComparison.propTypes = {
  priceData: PropTypes.object.isRequired,
  selectedUnderwriter: PropTypes.number.isRequired,
  affiliate: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  continueButtonDisabled: PropTypes.bool.isRequired,
}
