import styled from 'styled-components'

import {P} from '../common/P'

export const SpecialDiscountBadge = styled.div`
  display: grid;
  grid-template-columns: 7% 93%;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    grid-template-columns: 10% 90%;
  }
  @media (max-width: 450px) {
    grid-template-columns: 13% 87%;
  }

  background-color: #1f1f1f;
  color: #fff;
`

export const BannerImageContainer = styled.div`
  padding: 8px 16px 0px 16px;
  @media (max-width: 768px) {
    padding: inherit;
    margin-left: 8px;
  }
`

export const BannerImage = styled.img`
  height: 24px;
  width: 24px;
  @media (max-width: 450px) {
    padding: inherit;

    margin-left: 12px;
  }
`

export const SpecialDiscountText = styled.p`
  color: #fff;
  @media (max-width: 450px) {
    font-size: 12px;
    padding-left: 4px;
  }
`

export const UnderwriterPriceBadgeContainerDiscount = styled.div`
  background-color: #d4d4d4;
  display: grid;
  grid-template-columns: 25% 75%;
  @media (max-width: 768px) {
    grid-template-columns: 45% 55%;
  }
  border: 4px solid #1f1f1f;
  margin-bottom: 24px;
`

export const ImageSectionDiscount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background: #fff;
`

export const PriceSection = styled.div`
  padding-left: 16px;
`

export const UnderwriterPriceBadgePriceSection = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  align-content: end;
`

export const CurrentPriceSectionDiscount = styled.div`
  margin-top: 8px;
`

export const CurrentPrice = styled.div`
  font-weight: 800;
  font-size: 42px;
  @media (max-width: 450px) {
    font-size: 32px;
  }
`

export const PreviousPriceSection = styled.div``

export const PreviousPrice = styled.div`
  text-decoration: line-through;
  font-size: 20px;
`
export const DiscountSection = styled.div`
  max-width: 22%;
  background-color: #fff;
  display: inline-block;
  @media (max-width: 768px) {
    max-width: 50%;
  }
  @media (max-width: 450px) {
    max-width: 72%;
  }
`

export const DiscountSectionText = styled(P)`
  margin-top: inherit;
  margin-left: 4px;
  margin-bottom: inherit;
`

export const DiscountPrice = styled.div`
  display: inline-block;
`

export const DriverDetailsValue = styled.label`
  color: ${(props) => props.theme.labelTextColour};
  font-size: 1.1666666667rem;
  text-align: left;
  display: block;
  font-weight: bold;
  line-height: 1.1em;
  margin-bottom: 24px;
`

export const UnderwriterPriceBadgeContainer = styled.div`
  background-color: #d4d4d4;
  display: grid;
  grid-template-columns: 25% 75%;
  @media (max-width: 768px) {
    width: 100%;
    grid-template-columns: 40% 60%;
  }
  @media (max-width: 450px) {
    grid-template-columns: 50% 50%;
  }
  border-top: 4px solid #58aae0;
  margin-bottom: 24px;
`

export const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 40px 0;
  background: #fff;
  @media (max-width: 450px) {
    padding: 40px 0;
  }
`

export const CurrentPriceSection = styled.div`
  padding-top: 32px;
  font-weight: bold;

  @media (max-width: 450px) {
    padding-top: 32px;
  }
`