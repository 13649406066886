import React from 'react'
import { FieldLabel } from '../../components/common/FieldLabel'
import UseOfVehicle from '../../components/UseOfVehicle/UseOfVehicle.component'
import BodyType from '../../components/BodyType/BodyType.component'
import Occupation from '../Occupation/Occupation.component'
import { IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME } from '../Vehicle/constants'

interface Props {
  errors:{
    BodyType: any,
    UseOfVehicle: any,
    Occupation: any
  },
  errorMessages:{
    BodyType: string,
    UseOfVehicle: string,
    Occupation: string
  },
  onChange: (e: React.ChangeEvent<any>) => void,
  onBlur:  React.FocusEventHandler<HTMLSelectElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  values: {
    BodyType: string,
    UseOfVehicle: string,
    Occupation: string
  },
  scheme: string,
}

const VanDetails: React.FC<Props> = ({
  errors,
  errorMessages,
  onChange,
  onBlur,
  setFieldValue,
  values,
  scheme,
}) => {
  return (
    <>
      <FieldLabel htmlFor='BodyType'>Body type</FieldLabel>
      <BodyType
        id='BodyType'
        name='BodyType'
        onChange={onChange}
        onBlur={onBlur}
        value={values.BodyType}
        error={errors.BodyType}
        errorMessage={errorMessages.BodyType}
      />
      {scheme !== IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME && ( //If NOT impounded, show the following elements
        <>
          <FieldLabel htmlFor='UseOfVehicle'>Use of vehicle</FieldLabel>
          <UseOfVehicle
            id='UseOfVehicle'
            name='UseOfVehicle'
            onChange={onChange}
            onBlur={onBlur}
            value={values.UseOfVehicle}
            error={errors.UseOfVehicle}
            errorMessage={errorMessages.UseOfVehicle}
          />
          <Occupation
            id='Occupation'
            name='Occupation'
            setFieldValue={setFieldValue}
            onBlur={onBlur}
            value={values.Occupation}
            error={errors.Occupation}
            errorMessage={errorMessages.Occupation}
          />
        </>
      )}
    </>
  )
}

export default VanDetails