import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
  user-select: none;
  font-size: 25px;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`

export const PriceBreakdown = styled.div`
  position: absolute;
  top: -11px;
  right: ${(props) => (props.showRight ? 'unset' : '45px')};
  left: ${(props) => (!props.showRight ? 'unset' : '45px')};
  width: 130px;
  background: #fff;
  color: ${(props) => props.theme.primaryTextColour};
  box-shadow: 0px 0px 15px 0px #707070;
  border-radius: 6px;
  padding: 10px;
  z-index: 1;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;

    & li {
      font-family: ${(props) => props.theme.fontFamily};
      font-size: 14px;
      color: #1f1f1f;
      text-align: left;
      border-bottom: 1px solid #707070;
      &:last-child {
        border: none;
      }
      & span {
        font-family: Poppins, Helvetica, Arial, sans-serif;
        display: block;
        color: ${(props) => props.theme.primaryTextColour};
      }
    }
  }

  .discountItem {
    color: green;
  }

  @media (max-width: 767px) {
    top: -16px;
    right: ${(props) => (props.showRight ? 'unset' : '35px')};
    left: ${(props) => (!props.showRight ? 'unset' : '35px')};

    & ul li {
      font-size: 12px;
    }
  }
`

export const Diamond = styled.div`
  position: absolute;
  top: 12px;
  right: ${(props) => (props.showRight ? 'unset' : '-8px')};
  left: ${(props) => (!props.showRight ? 'unset' : '-8px')};
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 6px;
  z-index: -1;
`
