import styled from 'styled-components'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BaseInput } from '../../../components/common/Input/BaseInput'

export const RegWrapper = styled.div`
  background-color: #1f1f1f
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: auto;
  margin-bottom: 24px;
  display: inline-block;
`

// eslint-disable-next-line no-undef
export const GBIcon = styled("div")<{error: boolean}>`
  text-align: center;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 46px;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
  background-color: #efefef;
  color: #1f1f1f;
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  border-right: none;
  box-sizing: border-box;
  -webkit-appearance: none;
`

// eslint-disable-next-line no-undef
export const RegInput = styled(BaseInput)<{error: boolean}>`
  font-size: 24px;
  color: #1f1f1f;
  letter-spacing: 1px;
  padding:0 50px;
  padding-left:60px
  margin: 0 auto;
  font-weight: 500;
  display: block;
  width: 100%;
  background: #fff;
  
  border-radius: 0px;
  height: 50px;
  box-sizing: border-box;
  -webkit-appearance: none;
`

export const VehicleBuilderTextContainer = styled.div`
  margin-bottom: 48px;
`

export const VehicleBuilderText = styled.a`
  color: #4a4a4a;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0;
`