import React from 'react'
import { P } from '../common/P'
import { SectionHeading } from '../common/SectionHeading'
import { LargeSeperator } from '../common/Seperator'

const ConfusedQuickQuoteStatement = () => {
  return (
    <div id='ConfusedQuickQuoteStatement'>
      <SectionHeading>Annual car insurance</SectionHeading>
      <P>
        If you've compared car insurance with Confused.com before, we'll
        automatically create and display an annual insurance quote after you've
        purchased your temporary insurance. We'll also save it to your account
        so you can get the cover you need when your temporary insurance runs
        out.
      </P>
      <LargeSeperator />
    </div>
  )
}

export default ConfusedQuickQuoteStatement
