import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from 'unsemantic'
import {
  initPolicyConfirmationPage,
  requestPolicy,
  updateAnalysisData,
  updatePolicy,
  updatePolicyContactDetails,
  updateReasonForTempcover,
  updateValidation,
} from './actions'

import { updateConfusedExternalAccount, updateStep } from '../App/actions'

import LoadingScreen from '../../components/LoadingScreen'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { H3 } from '../../components/common/H3'
import { SectionHeading } from '../../components/common/SectionHeading'
import { LargeSeperator } from '../../components/common/Seperator'
import config from '../../config'
import AnnualUpsell from '../AnnualUpsell'
import { REASONS_FOR_COVER } from '../HomePage/constants'
import { SummaryBorderMobile } from '../QuoteSummary/SummarySectionStyles'
import {
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_PRIVATE_CAR_SCHEME,
} from '../Vehicle/constants'
import PolicyEndSection from './Sections/PolicyEndSection'
import PolicyRefSection from './Sections/PolicyRefSection'
import PolicyStartSection from './Sections/PolicyStartSection'
import SummaryFooter from './Sections/SummaryFooter'
import VehicleDescriptionSection from './Sections/VehicleDescriptionSection'
import VehicleRegSection from './Sections/VehicleRegSection'
import {
  A,
  DocumentsList,
  DocumentsWrapper,
  I,
  SendReasonForCoverButton,
  SendReasonForCoverButtonWrapper,
  SendReasonForCoverSelect,
  SendReasonWrapper,
  SuccessfulReasonForCover,
} from './styles.js'
import ToggleInput from '../../components/common/ToggleInput'
import { ContinueButton } from '../DriverDetails'
import { SYSTEM_FEATURES } from '../../services/constants'
import { useAuth } from 'react-oidc-context'

const PolicyConfirmationPage = ({
  actions,
  appStartupComplete,
  loading,
  policy,
  price,
  riskData,
  scheme,
  vehicle,
  features,
  confusedExternalAccount
}) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [saveConfusedUserPerson, setSaveConfusedUserPerson] = useState(true)
  const [saveConfusedUserVehicle, setSaveConfusedUserVehicle] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const auth = useAuth()

  const ReasonsForCover = REASONS_FOR_COVER.filter((x) => x.id !== 3)

  const showSaveChangesToAccountSection = () => {
    if (!features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT_SAVE_CHANGES] || policy.confusedExternalAccountUsed !== true){
      return false;
    } 

    if (policy.confusedExternalAccountPersonUsed === true && policy.confusedExternalAccountVehicleUsed === true){
      return false;
    }

    if (policy.confusedExternalAccountPersonUpdated === true || policy.confusedExternalAccountVehileUpdated === true){
      return false;
    }

    return true
  }

  const showChangesSavedSection = () => {
      if (!features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT_SAVE_CHANGES] || (policy.confusedExternalAccountPersonUpdated !== true && policy.confusedExternalAccountVehileUpdated !== true)){
        return false;
      }

      return policy.confusedExternalAccountPersonUpdated === true || policy.confusedExternalAccountVehileUpdated === true
  }

  const saveChanges = (e) => {
      e.preventDefault()
      setIsSubmitting(true)
      actions.updateConfusedExternalAccount({
        policyId: policy.PolicyId.replace('TCV-MOT-', ''),
        savePerson: saveConfusedUserPerson,
        saveVehicle: saveConfusedUserVehicle
      })
      policy.confusedExternalAccountPersonUpdated = saveConfusedUserPerson
      policy.confusedExternalAccountVehileUpdated = saveConfusedUserVehicle
  }

  const checkIfExistingSession = async () => {    
    if (!auth.isLoading)
    {
      if (!auth.isAuthenticated) {
        await auth.signinSilent()
      }
    }

    if (auth.isAuthenticated){
      confusedExternalAccount.AuthToken = `Bearer ${auth.user.access_token}`
    }
  };

  useEffect(() => {
    if (!window.isHoldingPageOn && appStartupComplete) {
      actions.initPolicyConfirmationPage()
      checkIfExistingSession()
    }
  }, [appStartupComplete])

  useEffect(() => {
    if (policy.reasonForCover) {
      window.dataLayer.push({
        event: 'reasonForCover',
        policyConfirmationDataLayerPushEvent: [
          {
            reasonForCover: policy.reasonForCover.description,
          },
        ],
      })
    }
  }, [policy.reasonForCover])

  const handleSelect = (event) => {
    var index = event.nativeEvent.target.selectedIndex
    setSelectedOption({
      value: event.target.value,
      description: event.nativeEvent.target[index].text,
    })
  }

  const updateReasonForTempcover = () => {
    actions.updateReasonForTempcover(selectedOption)
  }

  return (
    <ConfusedWrapper paddingTop>
      {loading ? (
        <LoadingScreen message='Generating your documents' />
      ) : (
        <>
          <SectionHeading>Policy details</SectionHeading>
          <SummaryBorderMobile>
            <VehicleRegSection reg={vehicle.selected.RegistrationNumber} />
            <VehicleDescriptionSection vehicle={vehicle.selected} />
            <PolicyStartSection
              policyStartDate={riskData.PolicyStartDate.value}
            />
            <PolicyEndSection policyEndDate={riskData.PolicyEndDate} />
            <PolicyRefSection policyId={policy.PolicyId} />
            <SummaryFooter
              price={price.TotalPrice.toFixed(2)}
              impounded={
                scheme === IMPOUNDED_PRIVATE_CAR_SCHEME ||
                scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME
              }
            />
          </SummaryBorderMobile>
          <AnnualUpsell />
          <LargeSeperator />
          <SectionHeading>Your documents</SectionHeading>
          <H3>
            Your policy documents have been sent to the email address provided.
          </H3>
          <DocumentsWrapper>
            <Grid>
              <DocumentsList>
                {policy.PolicyDocuments.map((document, index) => (
                  <li key={index}>
                    <A
                      href={
                        config.BASE_API_URL +
                        '/api/motorQuotePolicies/{encryptedPolicyId}/documents/{documentTypeId}'
                          .replace(/{[encryptedPolicyId}]*}/, riskData.QuoteId)
                          .replace(
                            '{documentTypeId}',
                            document.DocumentUnitTypeId
                          )
                      }
                      target='_blank'
                    >
                      {document.DocumentName.toLowerCase() !==
                      'tempcover contract'
                        ? 'Download ' +
                          document.DocumentName.toLowerCase() +
                          ' here'
                        : 'Download brokerage contract here'}
                    </A>
                  </li>
                ))}
              </DocumentsList>
              <H3 bigGap>
                Need your documents posting? No problem, just email us at{' '}
                <A id='ContactUsMailTo' href='mailto:contactus@tempcover.com'>
                  contactus@tempcover.com
                </A>{' '}
                and we’ll get these out to you.
              </H3>
            </Grid>
          </DocumentsWrapper>
          {policy.reasonForCover &&
            !policy.reasonForCover.value &&
            !config.IMPOUNDED_ENABLED && (
              <>
                <LargeSeperator />
                <SendReasonWrapper>
                  <H3>Tell us why you needed temporary insurance</H3>
                  <SendReasonForCoverSelect
                    onChange={handleSelect}
                    placeholder='Please select...'
                    id='ReasonForCover'
                    options={ReasonsForCover}
                    name='ReasonForCover'
                  />

                  <SendReasonForCoverButtonWrapper>
                    <SendReasonForCoverButton
                      id='ReasonForTempcoverSend'
                      disabled={!(selectedOption && selectedOption.description)}
                      onClick={updateReasonForTempcover}
                    >
                      Send
                    </SendReasonForCoverButton>
                  </SendReasonForCoverButtonWrapper>
                </SendReasonWrapper>
              </>
            )}
          <Grid>
            {policy.reasonForCover &&
              policy.reasonForCover.value &&
              !config.IMPOUNDED_ENABLED && (
                <SuccessfulReasonForCover>
                  Thank you for letting us know.
                </SuccessfulReasonForCover>
              )}

            { 
              showSaveChangesToAccountSection() && (<>
                <LargeSeperator />
                <H3>Save details you may use again to your account</H3>
                <p>
                  Adding cars or drivers to your Confused.com profile will make it faster to get cover next time you need temporary insurance or annual car insurance.
                </p>
                {
                  policy.confusedExternalAccountPersonUsed === false && (
                    <ToggleInput
                      id={'saveConfusedUserPerson'}
                      onChange={(e) => setSaveConfusedUserPerson(e)}
                      value={saveConfusedUserPerson}
                      label={`Save ${policy.forename} ${policy.surname} to account?`}
                    />
                  )
                }
                
                {
                  policy.confusedExternalAccountVehicleUsed === false && (
                    <ToggleInput
                      id={'saveConfusedUserVehicle'}
                      onChange={(e) => setSaveConfusedUserVehicle(e)}
                      value={saveConfusedUserVehicle}
                      label={`Save ${policy.registrationNumber} to account?`}                 
                    />
                  )
                }

                {
                  (saveConfusedUserPerson || saveConfusedUserVehicle) && (
                    <ContinueButton
                      type='button'
                      disabled={isSubmitting}
                      id='SaveConfusedChanges'
                      onMouseDown={(e) => {
                        saveChanges((e))
                      }}
                    >
                      Save
                    </ContinueButton>
                  )
                }
              </>)
            }

            {
              showChangesSavedSection() && (<>
                <LargeSeperator />
                <p>
                    <I className='fas fa-thumbs-up fa-sm' color='#FFFFFF' backgroundColor='#000000' padding="5px" />
                    &nbsp;
                  <strong>Account Updated!</strong> Your new details will be saved ready for you to use next time.
                </p>
              </>)
            }
          </Grid>
        </>
      )}
    </ConfusedWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    policy: state.policy,
    riskData: state.riskData,
    address: state.address,
    price: state.price,
    vehicle: state.vehicle,
    affiliate: state.affiliate,
    loading: state.isLoading,
    scheme: state.scheme.selected,
    features: state.features || {},
    appStartupComplete: state.appStartupComplete,
    confusedExternalAccount: state.confusedExternalAccount
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updatePolicy,
        updatePolicyContactDetails,
        updateValidation,
        requestPolicy,
        updateReasonForTempcover,
        initPolicyConfirmationPage,
        updateStep,
        updateAnalysisData,
        updateConfusedExternalAccount
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyConfirmationPage)
