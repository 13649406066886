export const LOAD_THEME_SUCCESS = 'src/App/LOAD_THEME_SUCCESS'

export const REQUEST_ANTI_FORGERY = 'src/App/REQUEST_ANTI_FORGERY'
export const REQUEST_ANTI_FORGERY_SUCCESS =
  'src/App/REQUEST_ANTI_FORGERY_SUCCESS'
export const REQUEST_ANTI_FORGERY_FAILURE =
  'src/App/REQUEST_ANTI_FORGERY_FAILURE'

export const REQUEST_LINKS = 'src/App/REQUEST_LINKS'
export const REQUEST_LINKS_SUCCESS = 'src/App/REQUEST_LINKS_SUCCESS'
export const REQUEST_LINKS_FAILURE = 'src/App/REQUEST_LINKS_FAILURE'

export const REQUEST_FEATURE_TOGGLES = 'src/App/REQUEST_FEATURE_TOGGLES'
export const REQUEST_FEATURE_TOGGLES_SUCCESS =
  'src/App/REQUEST_FEATURE_TOGGLES_SUCCESS'
export const REQUEST_FEATURE_TOGGLES_FAILURE =
  'src/App/REQUEST_FEATURE_TOGGLES_FAILURE'

export const CREATE_QUOTE = 'src/App/CREATE_QUOTE'
export const CREATE_QUOTE_SUCCESS = 'src/App/CREATE_QUOTE_SUCCESS'
export const CREATE_QUOTE_FAILURE = 'src/App/CREATE_QUOTE_FAILURE'

export const REQUEST_PRICE = 'src/App/REQUEST_PRICE'
export const REQUEST_PRICE_SUCCESS = 'src/App/REQUEST_PRICE_SUCCESS'
export const REQUEST_PRICE_FAILURE = 'src/App/REQUEST_PRICE_FAILURE'

export const SELECT_PRICE = 'src/App/SELECT_PRICE'

export const SET_TEMP_UNDERWRITER = 'src/App/SET_TEMP_UNDERWRITER'

export const REQUEST_QUOTE = 'src/App/REQUEST_QUOTE'
export const REQUEST_QUOTE_SUCCESS = 'src/App/REQUEST_QUOTE_SUCCESS'
export const REQUEST_QUOTE_FAILURE = 'src/App/REQUEST_QUOTE_FAILURE'

export const REQUEST_OPEN_SCHEMES = 'src/app/REQUEST_OPEN_SCHEMES'
export const REQUEST_OPEN_SCHEMES_SUCCESS =
  'src/app/REQUEST_OPEN_SCHEMES_SUCCESS'
export const REQUEST_OPEN_SCHEMES_FAILURE =
  'src/app/REQUEST_OPEN_SCHEMES_FAILURE'

export const GET_QUOTE_SUMMARY_SUCCESS = 'src/app/GET_QUOTE_SUMMARY_SUCCESS'

export const SET_UI_ELEMENT_VISIBILITY = 'src/app/SET_UI_ELEMENT_VISIBILITY'
export const SET_UI_ELEMENT_MESSAGE_VISIBILITY =
  'src/app/SET_UI_ELEMENT_MESSAGE_VISIBILITY'
export const UI_ELEMENT_HOUR_DURATION = 'HourDurationType'

export const CREATE_QUOTE_REL = 'CreateQuote'
export const CREATE_QUOTE_WITH_START_DATE_REL = 'CreateQuoteWithStartDateTime'
export const GET_PRICE_REL = 'GetQuotePrice'
export const REQUEST_OPEN_SCHEMES_REL = 'GetOpenSchemes'
export const GET_MOTOR_QUOTE_REL = 'GetMotorQuoteDetails'
export const GET_QUOTE_SUMMARY_REL = 'GetQuoteSummary'

export const AFFILIATE_REF = 'tempcover'

export const SET_UNDERWRITER_CHANGED = 'src/App/SET_UNDERWRITER_CHANGED'

export const UPDATE_STEP = 'src/App/UPDATE_STEP'
export const IS_LOADING = 'src/App/IS_LOADING'
export const SHOW_LIVE_CHAT = 'src/App/SHOW_LIVE_CHAT'

export const REQUEST_AFFILIATE = 'src/App/REQUEST_AFFILIATE'
export const REQUEST_AFFILIATE_SUCCESS = 'src/App/REQUEST_AFFILIATE_SUCCESS'
export const REQUEST_AFFILIATE_FAILURE = 'src/App/REQUEST_AFFILIATE_FAILURE'

export const SET_QUERY_STRING = 'src/App/SET_QUERY_STRING'

export const REQUEST_AFFILIATE_REL = 'GetAffiliateDetails'

export const UPDATE_CAMPAIGN_DATA = 'src/App/UPDATE_CAMPAIGN_DATA'

export const CREATE_CLIENT_SESSION = 'src/App/CREATE_CLIENT_SESSION'
export const UPDATE_CLIENT_SESSION_QUOTE = 'src/App/UPDATE_CLIENT_SESSION_QUOTE'
export const SET_CLIENT_SESSION_GUID = 'src/App/SET_CLIENT_SESSION_GUID'
export const SET_CLIENT_SESSION_QUOTE = 'src/App/SET_CLIENT_SESSION_QUOTE'
export const SET_CLIENT_SESSION_DATA = 'src/App/SET_CLIENT_SESSION_DATA'
export const SET_CLIENT_SESSION_STATE = 'src/App/SET_CLIENT_SESSION_STATE'
export const SET_CLIENT_SESSION_LAST_ACTIVITY =
  'src/App/SET_CLIENT_SESSION_LAST_ACTIVITY'

export const GET_APP_STARTUP = 'src/App/GET_APP_STARTUP'
export const GET_APP_STARTUP_SUCCESS = 'src/App/GET_APP_STARTUP_SUCCESS'
export const GET_APP_STARTUP_FAILURE = 'src/App/GET_APP_STARTUP_FAILURE'
export const SET_QUOTE_JOURNEY_POSITION = 'src/App/SET_QUOTE_JOURNEY_POSITION'

export const RENDER_READY = 'src/App/RENDER_READY'

export const REQUEST_CONFUSED_QUICK_QUOTE_REL = 'RequestConfusedQuickQuote'
export const REQUEST_CONFUSED_QUICK_QUOTE =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE'
export const REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS'
export const REQUEST_CONFUSED_QUICK_QUOTE_FAILURE =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_FAILURE'

export const REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_REL =
  'RequestConfusedQuickQuoteResponse'
export const REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE'
export const REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS'
export const REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE'

export const REQUEST_CONFUSED_EXTERNAL_ACCOUNT_REL = 'RequestConfusedExternalAccountData'
export const REQUEST_CONFUSED_EXTERNAL_ACCOUNT = 'src/App/REQUEST_CONFUSED_EXTERNAL_ACCOUNT'
export const REQUEST_CONFUSED_EXTERNAL_ACCOUNT_SUCCESS = 'src/App/REQUEST_CONFUSED_EXTERNAL_ACCOUNT_SUCCESS'
export const REQUEST_CONFUSED_EXTERNAL_ACCOUNT_FAILURE = 'src/App/REQUEST_CONFUSED_EXTERNAL_ACCOUNT_FAILURE'
export const UPDATE_CONFUSED_EXTERNAL_ACCOUNT_REL = 'UpdateConfusedExternalAccountData'
export const UPDATE_CONFUSED_EXTERNAL_ACCOUNT = 'src/App/UPDATE_CONFUSED_EXTERNAL_ACCOUNT'