import { put, select, takeLatest } from 'redux-saga/effects'
import {
  drivingLicenceCheck,
  passportCheck,
} from '../RiskDataCollectionPage/actions'
import { getValidation } from '../RiskDataCollectionPage/selector'
import { SUBMIT_MORE_DETAILS_FORM } from './constants'

function* submitMoreDetailsForm() {
  const { IdentityCheck } = yield select(getValidation)
  if (IdentityCheck === 'drivingLicence') {
    yield put(drivingLicenceCheck())
  } else {
    yield put(passportCheck())
  }
}

export default function* moreDetailsSaga() {
  yield takeLatest(SUBMIT_MORE_DETAILS_FORM, submitMoreDetailsForm)
}
