import { GET_APP_STARTUP } from '../constants'
import queryString from 'query-string'
import { put, takeLatest } from 'redux-saga/effects'
import {
  getAppStartupSuccess,
  getAppStartupFailure,
  renderReady,
} from '../actions'
import fetchAntiForgeryTokenSaga from './antiForgerySaga'
import fetchLinksSaga from './linksSaga'
import getOpenSchemeSaga from './schemeSaga'
import featureTogglesSaga from './featureTogglesSaga'
import { showExperiment } from '../../../store/experiment/experiment.actions'

export function* startup() {
  try {
    const parsedQueryString = queryString.parse(window.location.search)
    if (parsedQueryString.experimentoverride) {
      const experimentObject = JSON.parse(parsedQueryString.experimentoverride)
      yield put(showExperiment(experimentObject))
    }
    yield put(renderReady())
    yield* fetchAntiForgeryTokenSaga()
    yield* fetchLinksSaga()
    yield* getOpenSchemeSaga()
    yield* featureTogglesSaga()

    yield put(getAppStartupSuccess())
  } catch (e) {
    yield put(getAppStartupFailure())
  }
}

export default function* AppStartupSaga() {
  yield takeLatest(GET_APP_STARTUP, startup)
}
