/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'

import { H1, P, A } from './styles'
import { ConfusedWrapper } from '../common/ConfusedWrapper'

const EmailUnsubscribePage = () => {
  return (
    <ConfusedWrapper>
      <Grid desktop='100' mobile='100' tablet='100' parent>
        <H1>Unsubscribe Successful</H1>
        <P>
          You will no longer receive these emails from us. Should you purchase a
          policy, you will receive an email confirming all the details. You will
          also receive an email notifying you when your policy is about to
          expire.
        </P>
        <P>
          If in the future you get a new quote from us you will receive an email
          confirming that quote. Should you change your mind in future and wish
          to resume receiving information about our latest offers you can opt
          back by emailing us at{' '}
          <A href='mailto://contactus@tempcover.com'>contactus@tempcover.com</A>
        </P>
      </Grid>
    </ConfusedWrapper>
  )
}

export default EmailUnsubscribePage
