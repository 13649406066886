import styled from 'styled-components'
import { Button } from '../common/Button'

export const PriceComparisonWrapper = styled.div`
  border: 2px solid ${(props) => props.theme.primaryColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  margin-bottom: 1em;
  background-image: ${(props) =>
    `linear-gradient(15deg, ${
      props.isSelected ? '#F2F6DF' : '#E8F0FF'
    } 200px, #FFFFFF 200px);`};
  display: flex;
  flex-direction: row;

  box-shadow: ${(props) =>
    props.isSelected ? '#264694 0px 0px 10px 5px' : 'none'};

  @media (max-width: 768px) {
    flex-direction: column;
    background-image: ${(props) =>
      `linear-gradient(15deg, ${
        props.isSelected ? '#F2F6DF' : '#E8F0FF'
      } 150px, #FFFFFF 150px);`};
  }

  .price-comparison-underwriter-logo {
    height: 50px;
    width: 150px;
    margin: auto;
  }

  .price-comparison-underwriter-logo.mobile {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .price-comparison-underwriter-logo.desktop {
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }
`

export const TotalPriceWrapper = styled.div`
  font-size: ${(props) => props.theme.generalFontSize};
  background: ${(props) => props.theme.primaryColour};
  color: #fff;
  box-sizing: border-box;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 5px;
  }
`

export const PaymentBreakdownIconWrapper = styled.div`
  @media (max-width: 768px) {
    padding-top: 3px;
  }
`

export const TotalPriceValueWrapper = styled.span`
  font-weight: bold;
  font-size: 26px;
  font-family: ${(props) => props.theme.headerFontFamily};

  @media (max-width: 768px) {
    padding: 0 5px;
  }
`

export const QuoteInfoWrapper = styled.div`
  flex-grow: 1;
  align-self: center;
  text-align: left;
  padding-left: 20px;

  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    text-align: center;
    padding-left: 0;
  }
`

export const QuoteInfoHighlight = styled.span`
  color: ${(props) => props.theme.primaryColour};
  font-weight: bold;
  font-family: ${(props) => props.theme.headerFontFamily};
`

export const PriceComparisonContinueButton = styled(Button)`
  padding: 10px 20px;
  height: auto;
  font-weight: bold;
  font-family: ${(props) => props.theme.headerFontFamily};
  font-size: 18px;
  width: 200px;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 16px;
    width: auto;
  }
`

export const ImageAndButtonWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 10px 10px;

  img {
    padding-bottom: 10px;
    width: 150px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
`
