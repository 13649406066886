import React from 'react'
import { FieldLabel } from '../common/FieldLabel'
import InputTS from '../common/InputTS'
import { FieldLegend } from '../common/FieldLegend'

interface Props {
    onChange: (e: React.ChangeEvent<any>) => void,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    error: boolean,
    errorMessage: string,
    value: string
}

const Lastname: React.FC<Props> = ({
    onChange,
    onBlur,
    error,
    errorMessage,
    value,
}) => {

    return (
        <>
            <FieldLegend>Surname</FieldLegend>
            <InputTS
            id='Surname'
            name='Surname'
            placeholder=''
            titleCase={true}
            onBlur={onBlur}
            onChange={onChange}
            error={error}
            errorMessage={errorMessage}
            value={value}
            />
        </>
    )
}

export default Lastname