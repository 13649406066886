import styled from 'styled-components'

export const FieldLegend = styled.legend`
    font-size: 16px;
    text-align: left;
    display: block;
    color: #1f1f1f;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    display: block;
    margin-top: 0;
    margin-bottom: 16px;
`
