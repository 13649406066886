import {
  UPDATE_RISK_DATA,
  UPDATE_VALIDATION_RISK_DATA_COLLECTION,
  TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION,
  FORM_MANUALLY_EDITED,
  REQUEST_IDENTITY_CHECK_FAILURE,
  UPDATE_QUOTE_FAILURE,
  UPDATE_QUOTE_SUCCESS,
  REQUEST_IDENTITY_CHECK_MORE_INFO,
  CLEAR_IDENTITY_CHECK,
} from './constants'
import initialState from '../../initialState'
import { CREATE_QUOTE_SUCCESS } from '../App/constants'
import {
  REQUEST_OCR_FRONT_SUCCESS,
  REQUEST_OCR,
} from '../DrivingLicenceCamera/constants'
import { REQUEST_QUOTE_SUCCESS } from '../App/constants'
import { toTitleCase } from '../../services/handlers'
import {
  REQUEST_POLICY_SUCCESS,
  GET_ANALYSIS_DATA_SUCCESS,
} from '../PolicyConfirmationPage/constants'
import { REQUEST_LEGAL_DECLARATION_SUCCESS } from '../LegalDeclarationModal/constants'
import {
  SET_VEHICLE,
  PRIVATE_CAR,
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
} from '../Vehicle/constants'
import { SUBMIT_MORE_DETAILS_FORM } from '../MoreDetails/constants'

function riskDataCollectionReducer(state = initialState.riskData, action) {
  switch (action.type) {
    case UPDATE_RISK_DATA:
      return {
        ...state,
        ...action.riskData,
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        QuoteId: action.quote.QuoteId.Value,
      }
    case REQUEST_OCR:
      return {
        ...state,
        Forename: '',
        Surname: '',
        DateOfBirth: null,
        DrivingLicenceNumber: '',
        LicenceHeldDuration: '',
        LicenceType: 'FullUkLicence',
        AddressKey: '',
      }
    case REQUEST_OCR_FRONT_SUCCESS:
      return {
        ...state,
        Forename: toTitleCase(action.response.Forename.split(' ')[0]),
        Surname: toTitleCase(action.response.Surname),
        DateOfBirth:
          action.response.DateOfBirth !== ''
            ? action.response.DateOfBirth
            : null,
        DrivingLicenceNumber:
          action.response.DrivingLicenceNumber.split(' ')[0],
        LicenceHeldDuration: action.response.LicenceHeldDuration,
        LicenceType: {
          value: 'FullUkLicence',
          description: 'Full UK licence',
        },
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        Forename: action.policy.Forename || '',
        Surname: action.policy.Surname || '',
        PolicyStartDate: {
          value: action.policy.PolicyStart || '',
        },
        PolicyEndDate: action.policy.PolicyEnd || '',
        QuoteId: action.policyId,
      }
    case REQUEST_QUOTE_SUCCESS:
      const address = action.quote.QuoteAddresses.filter(
        (a) => a.QuoteAddressType === 'MainAddress'
      )[0]
      const driver = action.quote.QuoteDrivers.filter((d) => d.Proposer)[0]
      const vehicle = action.quote.Vehicle
      const scheme = action.scheme

      let vanDetails = {}
      if (scheme === COMMERCIAL_VEHICLE_SCHEME) {
        vanDetails = {
          BodyType: vehicle.BodyType || '',
          UseOfVehicle: vehicle.UseOfVehicle || '',
          Occupation: action.quote.Occupation,
        }
      }

      if (scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME) {
        vanDetails = {
          BodyType: vehicle.BodyType || '',
        }
      }

      return {
        ...state,
        ...vanDetails,
        Duration: action.quote.PolicyDuration,
        DurationType: action.quote.PolicyDurationType.toLowerCase(),
        PolicyStartDate: {
          value: action.quote.PolicyStartDateTime,
          manuallyEdited: action.quote.UserSelectedPolicyStartDate,
        },
        QuoteStatus: action.quote.QuoteStatus,
        Forename: driver.Forename || '',
        Surname: driver.Surname || '',
        DateOfBirth: driver.DateOfBirth,
        AddressKey: address.AddressKey,
        Title: driver.Title,
        LicenceType:
          driver.LicenceType === 'NotSet'
            ? 'FullUkLicence'
            : driver.LicenceType,
        LicenceHeldDuration: driver.LicenceHeldDuration,
        EmailAddress: action.quote.EmailAddress || '',
        Mobile: action.quote.TelephoneNumber || '',
        Impounded: action.quote.Vehicle.Impounded,
        ReasonForCover: action.quote.ReasonForCover,
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        Title: action.response.Title,
        LicenceHeldDuration: action.response.LicenceHeldDuration,
        Duration: action.response.Duration,
        DurationType: action.response.DurationType,
        DateOfBirth: action.response.DateOfBirth,
      }
    case SET_VEHICLE:
      const vehicleType = action.vehicle.VehicleType
      switch (vehicleType) {
        case PRIVATE_CAR:
          return {
            ...state,
            BodyType: '',
            UseOfVehicle: '',
            Occupation: {
              Id: 0,
              OccupationCode: '',
              OccupationName: '',
            },
          }
        default:
          return state
      }
    case SUBMIT_MORE_DETAILS_FORM:
      const { DrivingLicenceNumber, PassportLineOne, PassportLineTwo } =
        action.formValues

      const PassportExpiryDate = action.formValues.PassportExpiryDate.date

      return {
        ...state,
        DrivingLicenceNumber,
        PassportLineOne,
        PassportLineTwo,
        PassportExpiryDate,
      }
    default:
      return state
  }
}

export function validationReducer(
  state = initialState.validation.riskDataCollectionPage,
  action
) {
  switch (action.type) {
    case UPDATE_VALIDATION_RISK_DATA_COLLECTION:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.fields,
        },
      }
    case UPDATE_QUOTE_SUCCESS:
      return {
        ...state,
        AllFieldsValid: true,
      }
    case UPDATE_QUOTE_FAILURE:
      return {
        ...state,
        AllFieldsValid: false,
      }
    case TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION:
      return {
        ...state,
        ...(state.ValidForm = action.form.valid),
      }
    case REQUEST_OCR:
    case REQUEST_OCR_FRONT_SUCCESS:
      return {
        ...state,
        IdentityCheck: null,
      }
    case FORM_MANUALLY_EDITED:
      return {
        ...state,
        ...(state.ManuallyEdited = true),
      }
    case REQUEST_LEGAL_DECLARATION_SUCCESS:
      return {
        ...state,
        IdentityCheck: null,
      }
    case REQUEST_IDENTITY_CHECK_FAILURE:
      return {
        ...state,
        ...(state.ValidIdentity = false),
      }
    case REQUEST_IDENTITY_CHECK_MORE_INFO:
      return {
        ...state,
        IdentityCheck: action.check,
      }

    case CLEAR_IDENTITY_CHECK:
      return {
        ...state,
        IdentityCheck: null,
        fields: {
          ...state.fields,
          DrivingLicenceNumberError: '',
          PassportLineOneError: '',
          PassportLineTwoError: '',
          PassportExpiryDateError: '',
        },
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
        },
      }
    case REQUEST_QUOTE_SUCCESS:
      const driver = action.quote.QuoteDrivers.filter((d) => d.Proposer)[0]
      const address = action.quote.QuoteAddresses.filter(
        (a) => a.QuoteAddressType === 'MainAddress'
      )[0]
      const vehicle = action.quote.Vehicle
      const scheme = action.scheme

      let vanValidation = {}
      if (scheme === COMMERCIAL_VEHICLE_SCHEME) {
        vanValidation = {
          BodyTypeError: vehicle.BodyType === null ? null : '',
          UseOfVehicleError: vehicle.UseOfVehicle === null ? null : '',
          OccupationError: !action.quote.Occupation ? null : '',
        }
      }
      if (scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME) {
        vanValidation = {
          BodyTypeError: vehicle.BodyType === null ? null : '',
          UseOfVehicleError: '',
          OccupationError: '',
        }
      }

      return {
        ...state,
        fields: {
          ...state.fields,
          ...vanValidation,
          TitleError: driver.Title === null ? null : '',
          ForenameError: driver.Forename === null ? null : '',
          SurnameError: driver.Surname === null ? null : '',
          DateOfBirthError: driver.DateOfBirth === null ? null : '',
          LicenceHeldDurationError:
            driver.LicenceHeldDuration === 'Uninitialised' ? null : '',
          PostcodeError: address.Postcode === null ? null : '',
          AddressError: address.AddressKey === null ? null : '',
          EmailAddressError: action.quote.EmailAddress === null ? null : '',
          MobileError: action.quote.TelephoneNumber === null ? null : '',
        },
      }
    default:
      return state
  }
}

export default riskDataCollectionReducer
