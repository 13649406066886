import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import { history } from '../..'
import LicenceHeldDuration from '../../components/LicenceHeldDuration/LicenceHeldDuration.component'
import ConfusedQuickQuoteStatement from '../../components/ConfusedQuickQuoteStatement/ConfusedQuickQuoteStatement.component'
import LicenceType from '../../components/LicenceType'
import MarketingPreferences from '../../components/MarketingPreferences'
import { Message } from '../../components/Message'
import { MiniSummary } from '../../components/MiniSummary/MiniSummary.component'
import PassportExpiryDate from '../../components/PassportExpiryDate/PassportExpiryDate.component'
import { POLICY_DURATION_HOURS } from '../../components/PolicyDurationModal/constants'
import SplitPolicyStartTimePicker from '../../components/SplitPolicyStartTimePicker'
import Title from '../../components/Title'
import ValidationSummary from '../../components/ValidationSummary/ValidationSummary.component'
import { VehicleValue } from '../../components/VehicleValue'
import BasicDate from '../../components/common/BasicDate'
import { Button } from '../../components/common/Button'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { ConfusedBackButton } from '../../components/common/ConfusedBackButton/ConfusedBackButton.component'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import Email from '../../components/common/Email'
import { FieldLabel } from '../../components/common/FieldLabel'
import MobileNumber from '../../components/common/MobileNumber'
import { UppercaseInput } from '../../components/common/Input/UppercaseInput'
import { SectionHeading } from '../../components/common/SectionHeading'
import { LargeSeperator } from '../../components/common/Seperator'
import config from '../../config'
import useABExperiment from '../../hooks/useABExperiment/useABExperiment'
import { ROUTES, SYSTEM_FEATURES } from '../../services/constants'
import {
  ADDRESS_VALIDATION,
  BODY_TYPE_VALIDATION,
  DATE_OF_BIRTH_VALIDATION,
  DRIVING_LICENCE_VALIDATION,
  EMAIL_VALIDATION,
  FORENAME_VALIDATION,
  LICENCE_HELD_DURATION_VALIDATION,
  LICENCE_TYPE_VALIDATION,
  MOBILE_NUMBER_VALIDATION,
  OCCUPATION_VALIDATION,
  PASSPORT_EXPIRY_DATE_VALIDATION,
  PASSPORT_LINE_ONE_VALIDATION,
  PASSPORT_LINE_TWO_VALIDATION,
  SPLIT_POLICY_START_DATE_VALIDATION,
  SURNAME_VALIDATION,
  TITLE_VALIDATION,
  USE_OF_VEHICLE_VALIDATION,
  VEHICLE_VALUE_VALIDATION,
} from '../../services/validation'
import Address from '../Address'
import { updateStep } from '../App/actions'
import { UI_ELEMENT_HOUR_DURATION } from '../App/constants'
import {
  checkMarketingOptInState,
  checkMarketingOptInStateAndForward,
  setMarketingOptInState,
} from '../MarketingPreferencesContainer/actions'
import { updateRiskData } from '../RiskDataCollectionPage/actions'
import RiskDataDiscountBannerContainer from '../RiskDataCollectionPage/components/RiskDataDiscountBannerContainer.component'
import VanDetails from '../VanDetails'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
} from '../Vehicle/constants'
import { submitDriverDetailsForm } from './actions'
import FirstName from '../../components/Firstname/Firstname.component'
import Lastname from '../../components/Lastname'
import DrivingLicence from '../../components/DrivingLicence'
import { FieldLegend } from '../../components/common/FieldLegend'
import { FieldSet } from '../../components/common/FieldSet'

export const ContinueButton = styled(Button)`
  width: 100%;
`
export const QuestionInfo = styled.div`
  margin: 0 0 16px 0;
`

const createSplitPolicyStartDateObject = ({ value, manuallyEdited }) => {
  if (value && manuallyEdited) {
    return {
      date: moment(value).format('YYYY-MM-DD'),
      hour: moment(value).format('HH'),
      minute: moment(value).format('mm'),
      manuallyEdited,
    }
  } else {
    return {
      date: '',
      hour: '',
      minute: '',
      manuallyEdited,
    }
  }
}

const DriverDetails = ({
  actions,
  address,
  hourDurationTypeElement,
  marketingPreferences: {
    loaded,
    PreviousCustomer,
    EmailAddressOptIn,
    TelephoneNumberOptIn,
  },
  myLicence,
  riskData,
  scheme,
  validation: { AllFieldsValid, IdentityCheck },
  vehicle,
  features,
  confusedQuickQuote,
}) => {
  const [updatingMarketingPreferences, setUpdatingMarketingPreferences] =
    useState(false)

  useEffect(() => {
    actions.updateStep(1)
  }, [])

  useEffect(() => {
    setUpdatingMarketingPreferences(true)
  }, [EmailAddressOptIn, TelephoneNumberOptIn])

  const homeDetails = () => {
    history.push(ROUTES.HOME_PAGE)
  }

  const checkMarketingOptInState = (payload) => {
    actions.checkMarketingOptInStateAndForward(payload)
  }

  const { variant } = useABExperiment(config.CONFUSED_CRO_EXPERIMENT_ID)
  const splitDate = (riskData.DateOfBirth || '')
    .replace(/[ :T]/g, '-')
    .split('-')
  const splitDatePassportExpiry = (riskData.PassportExpiryDate || '')
    .replace(/[ :T]/g, '-')
    .split('-')
  const isHours = riskData.DurationType === POLICY_DURATION_HOURS

  return (
    <ConfusedWrapper>
      {!isHours && (
        //we don't have access to whether the price has a discount at this point
        <RiskDataDiscountBannerContainer></RiskDataDiscountBannerContainer>
      )}
      {myLicence.CheckFailed &&
      myLicence.FailReasons.indexOf('LicenceNumber') >= 0 ? (
        <Message
          heading='We have been unable to confirm your information'
          content='The driving licence number you have entered cannot be validated. Check and try again.'
          type='error'
        />
      ) : myLicence.CheckFailed &&
        myLicence.FailReasons.indexOf('LicenceCategory') >= 0 ? (
        <Message
          heading='Check your licence type'
          content='We have been unable to match your driving licence type with the information you have provided. Check and try again.'
          type='error'
        />
      ) : (
        (IdentityCheck === 'passport' ||
          IdentityCheck === 'drivingLicence' ||
          !AllFieldsValid) && (
          <Message
            heading='Sorry, we have been unable to confirm your information.'
            content='Double check your information and your policy start
              time is correct then try again.'
            type='error'
          />
        )
      )}

      {hourDurationTypeElement.messageIsVisible && (
        <Message
          heading='We’re sorry, hourly van insurance is not quite ready.'
          content='In the meantime, we have updated your length of cover to 1 day.'
          type='info'
        />
      )}

      <SectionHeading>Vehicle and cover</SectionHeading>
      <MiniSummary
        vehicle={vehicle.selected}
        duration={{
          durationAmount: riskData.Duration,
          durationType: riskData.DurationType,
        }}
      />
      <ConfusedBackButton onClick={homeDetails}>
        Change vehicle and duration details
      </ConfusedBackButton>
      <Formik
        initialValues={{
          PolicyStartDate: createSplitPolicyStartDateObject(
            riskData.PolicyStartDate,
          ),
          VehicleValue: vehicle.selected.vehicleValue.value,
          BodyType: riskData.BodyType,
          UseOfVehicle: riskData.UseOfVehicle,
          Occupation: riskData.Occupation,
          Title: riskData.Title,
          Forename: riskData.Forename,
          Surname: riskData.Surname,
          DateOfBirth: {
            day: splitDate[2],
            month: splitDate[1],
            year: splitDate[0],
            date: riskData.DateOfBirth,
          },
          Address: {
            Postcode: address.Postcode,
            PostcodeSearched: Boolean(riskData.AddressKey),
            AddressKey: riskData.AddressKey,
          },
          LicenceType: riskData.LicenceType,
          LicenceHeldDuration: riskData.LicenceHeldDuration,
          Email: riskData.EmailAddress,
          MobileNumber: riskData.Mobile,
          DrivingLicenceNumber: riskData.DrivingLicenceNumber,
          PassportLineOne: riskData.PassportLineOne,
          PassportLineTwo: riskData.PassportLineTwo,
          PassportExpiryDate: {
            day: splitDatePassportExpiry[2],
            month: splitDatePassportExpiry[1],
            year: splitDatePassportExpiry[0],
            date: riskData.PassportExpiryDate,
          },
          EmailAddressOptIn: EmailAddressOptIn,
          TelephoneNumberOptIn: TelephoneNumberOptIn
        }}
        validationSchema={Yup.object().shape({
          PolicyStartDate: SPLIT_POLICY_START_DATE_VALIDATION,
          VehicleValue: VEHICLE_VALUE_VALIDATION,
          BodyType: BODY_TYPE_VALIDATION(scheme),
          UseOfVehicle: USE_OF_VEHICLE_VALIDATION(scheme),
          Occupation: OCCUPATION_VALIDATION(scheme),
          Title: TITLE_VALIDATION,
          Forename: FORENAME_VALIDATION,
          Surname: SURNAME_VALIDATION,
          DateOfBirth: DATE_OF_BIRTH_VALIDATION,
          Address: ADDRESS_VALIDATION,
          LicenceType: LICENCE_TYPE_VALIDATION,
          LicenceHeldDuration: LICENCE_HELD_DURATION_VALIDATION,
          Email: EMAIL_VALIDATION,
          MobileNumber: MOBILE_NUMBER_VALIDATION,
          DrivingLicenceNumber: DRIVING_LICENCE_VALIDATION(IdentityCheck),
          PassportLineOne: PASSPORT_LINE_ONE_VALIDATION(IdentityCheck),
          PassportLineTwo: PASSPORT_LINE_TWO_VALIDATION(IdentityCheck),
          PassportExpiryDate: PASSPORT_EXPIRY_DATE_VALIDATION(IdentityCheck),
        })}
        onSubmit={(values, formActions) => {
          if (!loaded) {
            checkMarketingOptInState({
              email: values.Email,
              mobileNumber: values.MobileNumber,
            })
            formActions.setSubmitting(false)
          } else {
            actions.submitDriverDetailsForm(values)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
          submitCount,
          touched,
          values,
        }) => {
          if (updatingMarketingPreferences) {
            if (EmailAddressOptIn !== values.EmailAddressOptIn) {
              setFieldValue('EmailAddressOptIn', EmailAddressOptIn)
            }
            if (TelephoneNumberOptIn !== values.TelephoneNumberOptIn) {
              setFieldValue('TelephoneNumberOptIn', TelephoneNumberOptIn)
            }
            setUpdatingMarketingPreferences(false)
          }
          let errorCount = Object.keys(errors || {}).length
          if (
            errorCount >= 2 &&
            errors.DateOfBirth &&
            errors.LicenceHeldDuration
          ) {
            errorCount -= 1
          }
          return (
            <Form>
              <ValidationSummary
                showSummary={errorCount > 0 && submitCount > 0}
                errorCount={errorCount}
                submitCount={submitCount}
              />
              <FieldSet>
              <FieldLegend>
                When do you need the cover to start?
              </FieldLegend>
              <SplitPolicyStartTimePicker
                id='PolicyStartDate'
                name='PolicyStartDate'
                value={values.PolicyStartDate}
                errors={errors.PolicyStartDate || {}}
                touched={touched.PolicyStartDate || {}}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
              </FieldSet>
              <FieldLabel htmlFor='VehicleValue'>Vehicle value</FieldLabel>
              <QuestionInfo>
                We've based this estimate on the vehicle details you've
                provided. If this doesn't look right, enter the correct value
                below.
              </QuestionInfo>
              <VehicleValue
                name='VehicleValue'
                onChange={handleChange}
                value={values.VehicleValue}
              />
              {(scheme === COMMERCIAL_VEHICLE_SCHEME ||
                scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME) && (
                <>
                  <VanDetails
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    errors={{
                      BodyType: Boolean(touched.BodyType && errors.BodyType),
                      UseOfVehicle: Boolean(
                        touched.UseOfVehicle && errors.UseOfVehicle,
                      ),
                      Occupation: Boolean(
                        touched.Occupation && errors.Occupation,
                      ),
                    }}
                    errorMessages={{
                      BodyType: touched.BodyType && errors.BodyType,
                      UseOfVehicle: touched.UseOfVehicle && errors.UseOfVehicle,
                      Occupation: touched.Occupation && errors.Occupation,
                    }}
                    values={{
                      BodyType: values.BodyType,
                      UseOfVehicle: values.UseOfVehicle,
                      Occupation: values.Occupation,
                    }}
                    scheme={scheme}
                  />
                </>
              )}
              <LargeSeperator />
              <SectionHeading>Tell us about the driver</SectionHeading>
              <FieldSet>
              <FieldLegend>Title</FieldLegend>
              <Title
                id='Title'
                name='Title'
                onChange={handleChange}
                error={Boolean(touched.Title && errors.Title)}
                errorMessage={touched.Title && errors.Title}
                value={values.Title}
                />
                </FieldSet>
              <FirstName
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.Forename && errors.Forename)}
                errorMessage={touched.Forename && errors.Forename}
                value={values.Forename}
              />
              {/* <FieldLabel htmlFor='Forename'>First name</FieldLabel>
              <Input
                id='Forename'
                name='Forename'
                placeholder=''
                titleCase={true}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.Forename && errors.Forename)}
                errorMessage={touched.Forename && errors.Forename}
                value={values.Forename}
              /> */}
              <Lastname
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.Surname && errors.Surname)}
                errorMessage={touched.Surname && errors.Surname}
                value={values.Surname}
              />

              {/* <FieldLabel htmlFor='Surname'>Surname</FieldLabel>
              <Input
                id='Surname'
                name='Surname'
                placeholder=''
                titleCase={true}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.Surname && errors.Surname)}
                errorMessage={touched.Surname && errors.Surname}
                value={values.Surname}
              />
            */}
              <FieldSet>
              <FieldLegend>Date of birth</FieldLegend>
              <BasicDate
                id='DateOfBirth'
                name='DateOfBirth'
                onBlur={handleBlur}
                onChange={setFieldValue}
                value={values.DateOfBirth}
                touched={touched.DateOfBirth}
                error={errors.DateOfBirth}
              />
              </FieldSet>
              <Address
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                values={values.Address}
                touched={touched.Address || {}}
                errors={errors.Address || {}}
              />
              <FieldSet>
              <LicenceType
                id='LicenceType'
                name='LicenceType'
                onChange={handleChange}
                value={values.LicenceType}
                error={Boolean(touched.LicenceType && errors.LicenceType)}
                errorMessage={touched.LicenceType && errors.LicenceType}
              />
              </FieldSet>
              {values.DateOfBirth &&
                values.DateOfBirth.date &&
                !(errors && errors.DateOfBirth && errors.DateOfBirth.date) && (
                  <>
                    <FieldLabel htmlFor='LicenceHeldDuration'>
                      How long has the driver had the licence for?
                    </FieldLabel>
                    <LicenceHeldDuration
                      id='LicenceHeldDuration'
                      name='LicenceHeldDuration'
                      dateOfBirth={values.DateOfBirth.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.LicenceHeldDuration}
                      error={Boolean(
                        touched.LicenceHeldDuration &&
                          errors.LicenceHeldDuration,
                      )}
                      errorMessage={
                        touched.LicenceHeldDuration &&
                        errors.LicenceHeldDuration
                      }
                    />
                  </>
                )}
              <LargeSeperator />
              {variant === '1' &&
                confusedQuickQuote.OptIn &&
                features[SYSTEM_FEATURES.UPSELL_CONFUSED_ENABLED] && (
                  <ConfusedQuickQuoteStatement />
                )}
              <SectionHeading>
                Tell us where to send your documents
              </SectionHeading>
              <FieldLabel htmlFor='Email'>Email</FieldLabel>
              <Email
                id='Email'
                name='Email'
                onBlur={handleBlur}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                error={Boolean(touched.Email && errors.Email)}
                errorMessage={touched.Email && errors.Email}
                value={values.Email}
              />
              <FieldLabel htmlFor='MobileNumber'>Mobile number</FieldLabel>
              <MobileNumber
                id='MobileNumber'
                name='MobileNumber'
                type='tel'
                pattern='\d*'
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.MobileNumber && errors.MobileNumber)}
                errorMessage={touched.MobileNumber && errors.MobileNumber}
                value={values.MobileNumber}
              />
              {IdentityCheck === 'drivingLicence' && (
                <>
                  <DrivingLicence
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.DrivingLicenceNumber &&
                        errors.DrivingLicenceNumber,
                    )}
                    errorMessage={
                      touched.DrivingLicenceNumber &&
                      errors.DrivingLicenceNumber
                    }
                    value={values.DrivingLicenceNumber}
                  />

                  {/* <FieldLabel htmlFor='DrivingLicenceNumber'>
                    Driving licence number
                  </FieldLabel>
                  <UppercaseInput
                    id='DrivingLicenceNumber'
                    name='DrivingLicenceNumber'
                    placeholder='My driving licence number is...'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.DrivingLicenceNumber &&
                        errors.DrivingLicenceNumber,
                    )}
                    errorMessage={
                      touched.DrivingLicenceNumber &&
                      errors.DrivingLicenceNumber
                    }
                    value={values.DrivingLicenceNumber}
                  /> */}
                  <QuestionInfo>
                    We will share this with the DVLA in order to confirm your licence status, entitlement and restriction information together with endorsement/conviction data.
                  </QuestionInfo>
                </>
              )}
              {IdentityCheck === 'passport' && (
                <>
                  <FieldLabel htmlFor='PassportLineOne'>
                    Passport line one
                  </FieldLabel>
                  <UppercaseInput
                    id='PassportLineOne'
                    name='PassportLineOne'
                    placeholder='The first line of my passport says...'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.PassportLineOne && errors.PassportLineOne,
                    )}
                    errorMessage={
                      touched.PassportLineOne && errors.PassportLineOne
                    }
                    value={values.PassportLineOne}
                  />
                  <FieldLabel htmlFor='PassportLineTwo'>
                    Passport line two
                  </FieldLabel>
                  <UppercaseInput
                    id='PassportLineTwo'
                    name='PassportLineTwo'
                    placeholder='and the second line of my passport says...'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.PassportLineTwo && errors.PassportLineTwo,
                    )}
                    errorMessage={
                      touched.PassportLineTwo && errors.PassportLineTwo
                    }
                    value={values.PassportLineTwo}
                  />

                  <PassportExpiryDate
                    id='PassportExpiryDate'
                    name='PassportExpiryDate'
                    onChange={setFieldValue}
                    handleBlurFunction={handleBlur}
                    value={values.PassportExpiryDate}
                    touched={touched.PassportExpiryDate}
                    error={errors.PassportExpiryDate}
                  />
                </>
              )}
              <MarketingPreferences
                loaded={loaded}
                previousCustomer={PreviousCustomer}
                emailAddressOptIn={values.EmailAddressOptIn}
                telephoneNumberOptIn={values.TelephoneNumberOptIn}
                setFieldValue={setFieldValue}
              />

              <LargeSeperator style={{ marginTop: '0' }} />
              <ContinueButton
                type='submit'
                disabled={isSubmitting}
                id='TeslaSubmitManualDetailsNextButton'
                onMouseDown={(e) => {
                  e.preventDefault()
                }}
              >
                {isSubmitting ? 'Please wait' : 'Continue'}
                <ButtonSpinner disabled={isSubmitting} />
              </ContinueButton>
            </Form>
          )
        }}
      </Formik>
    </ConfusedWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    address: state.address,
    hourDurationTypeElement: state.ui[UI_ELEMENT_HOUR_DURATION],
    location: state.router.location,
    marketingPreferences: state.marketingPreferences,
    myLicence: state.myLicence,
    riskData: state.riskData,
    scheme: state.scheme.selected,
    validation: state.validation.riskDataCollectionPage,
    vehicle: state.vehicle,
    features: state.features || {},
    confusedQuickQuote: state.confusedQuickQuote,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        checkMarketingOptInState,
        checkMarketingOptInStateAndForward,
        setMarketingOptInState,
        updateRiskData,
        updateStep,
        submitDriverDetailsForm,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetails)
