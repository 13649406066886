import React from 'react'
import { Grid, GridContainer, GridClear } from 'unsemantic'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import { H4 } from '../common/H4'
import { H2 } from '../common/H2'
import { ProgressionOption } from '../common/ProgressionOption'
import { history } from '../..'
import { ProgressionHeader } from '../common/ProgressionOption/ProgressionHeader'
import { ProgressionHeaderImage } from '../common/ProgressionOption/ProgressionHeaderImage'
import { ProgressionContent } from '../common/ProgressionOption/ProgressionContent'
import { ProgressionFlexGrid } from '../common/ProgressionOption/ProgressionFlexGrid'
import { H4PrimaryTextColour } from './H4'
import { H5 } from '../common/H5'
import { ProgressionSubContent } from '../common/ProgressionOption/ProgressionSubContent'
import { I, TextWrapStyle } from './Styles'

export class DrivingLicenceScan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCamera: false,
      cameraEnabled: true,
    }
    this.startScanClick = this.startScanClick.bind(this)
  }

  startScanClick = () => {
    history.push(`/quote/scan${this.props.queryString}`)
  }

  render() {
    const { theme } = this.props
    return (
      <>
        {this.state.cameraEnabled && (
          <>
            <ProgressionOption
              id='TeslaStartScanLicenceNextButton'
              onClick={this.startScanClick}
            >
              <GridContainer>
                <Grid desktop='100' mobile='100' tablet='100' parent>
                  <ProgressionHeader>
                    <H2>Scan your driving licence</H2>
                  </ProgressionHeader>
                </Grid>
                <GridClear />
                <ProgressionFlexGrid>
                  <div>
                    <ProgressionHeaderImage>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='76'
                        height='61'
                        viewBox='0 0 76 61'
                      >
                        <path
                          id='Path_187'
                          data-name='Path 187'
                          d='M-903,6388v-15h15'
                          transform='translate(903.5 -6372.5)'
                          fill='none'
                          stroke={theme.primaryColour}
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='1'
                        />
                        <path
                          id='Path_188'
                          data-name='Path 188'
                          d='M-903,6388v-15h15'
                          transform='translate(-827.5 6433.5) rotate(180)'
                          fill='none'
                          stroke={theme.primaryColour}
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='1'
                        />
                        <path
                          id='Path_189'
                          data-name='Path 189'
                          d='M-903,6388v-15h15'
                          transform='translate(-6372.5 -842.5) rotate(-90)'
                          fill='none'
                          stroke={theme.primaryColour}
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='1'
                        />
                        <path
                          id='Path_190'
                          data-name='Path 190'
                          d='M-903,6388v-15h15'
                          transform='translate(6448.5 903.5) rotate(90)'
                          fill='none'
                          stroke={theme.primaryColour}
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='1'
                        />
                        <g
                          id='Group_116'
                          data-name='Group 116'
                          transform='translate(2147.843 -197.599)'
                        >
                          <path
                            id='Path_191'
                            data-name='Path 191'
                            d='M-2087.253,210.1h-45.179a5.243,5.243,0,0,0-4.91,4.91v25.817a5.243,5.243,0,0,0,4.91,4.91h45.179a5.244,5.244,0,0,0,4.91-4.91V215.009A5.243,5.243,0,0,0-2087.253,210.1Zm0,34.423h-45.179a4,4,0,0,1-3.7-3.7V217.16h52.572v23.666A4,4,0,0,1-2087.253,244.522Z'
                            transform='translate(0 0)'
                            fill={theme.primaryColour}
                          />
                          <path
                            id='Path_192'
                            data-name='Path 192'
                            d='M-2102.5,274.585a3.827,3.827,0,0,0,1.552-3.074,3.839,3.839,0,0,0-3.834-3.834,3.838,3.838,0,0,0-3.834,3.834,3.828,3.828,0,0,0,1.551,3.074,6,6,0,0,0-3.7,5.532.607.607,0,0,0,.607.607h10.757a.607.607,0,0,0,.607-.607A5.994,5.994,0,0,0-2102.5,274.585Zm-4.9-3.074a2.623,2.623,0,0,1,2.62-2.62,2.623,2.623,0,0,1,2.621,2.62,2.623,2.623,0,0,1-2.621,2.62A2.623,2.623,0,0,1-2107.4,271.511Zm-2.112,8a4.778,4.778,0,0,1,4.732-4.164,4.778,4.778,0,0,1,4.733,4.164Z'
                            transform='translate(-20.121 -43.594)'
                            fill={theme.primaryColour}
                          />
                          <path
                            id='Path_193'
                            data-name='Path 193'
                            d='M-2015.628,289.823h-23.666a.607.607,0,0,0-.607.607.607.607,0,0,0,.607.607h23.666a.607.607,0,0,0,.607-.607A.607.607,0,0,0-2015.628,289.823Z'
                            transform='translate(-73.776 -60.361)'
                            fill={theme.primaryColour}
                          />
                          <path
                            id='Path_194'
                            data-name='Path 194'
                            d='M-2015.628,263.248h-23.666a.607.607,0,0,0-.607.607v2.151a.607.607,0,0,0,.607.607h23.666a.607.607,0,0,0,.607-.607v-2.151A.607.607,0,0,0-2015.628,263.248Zm-.607,2.151h-22.451v-.937h22.451Z'
                            transform='translate(-73.776 -40.241)'
                            fill={theme.primaryColour}
                          />
                          <path
                            id='Path_195'
                            data-name='Path 195'
                            d='M-2015.628,307.54h-23.666a.607.607,0,0,0-.607.607.607.607,0,0,0,.607.607h23.666a.607.607,0,0,0,.607-.607A.607.607,0,0,0-2015.628,307.54Z'
                            transform='translate(-73.776 -73.775)'
                            fill={theme.primaryColour}
                          />
                          <path
                            id='Path_196'
                            data-name='Path 196'
                            d='M-2015.628,325.256h-23.666a.607.607,0,0,0-.607.607.607.607,0,0,0,.607.607h23.666a.607.607,0,0,0,.607-.607A.607.607,0,0,0-2015.628,325.256Z'
                            transform='translate(-73.776 -87.189)'
                            fill={theme.primaryColour}
                          />
                        </g>
                      </svg>
                    </ProgressionHeaderImage>
                  </div>
                  <div>
                    <ProgressionContent id='TeslaSubmitManualDetailsNextButton'>
                      <H4>(UK licence holders only)</H4>
                      <TextWrapStyle>
                        <H4PrimaryTextColour>
                          A quick and easy way to add your info
                        </H4PrimaryTextColour>
                      </TextWrapStyle>
                      <ProgressionSubContent>
                        <H5>
                          <I className='fas fa-lock fa-xs' />
                          It's secure - we won't share this photo
                        </H5>
                      </ProgressionSubContent>
                    </ProgressionContent>
                  </div>
                </ProgressionFlexGrid>
              </GridContainer>
            </ProgressionOption>
          </>
        )}
        {!this.state.cameraEnabled && (
          <>
            <p>
              Please refresh the page and ensure you allow the camera to enable
              scanning.
            </p>
          </>
        )}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    queryString: state.queryString,
  }
}

export default withRouter(
  withTheme(connect(mapStateToProps)(DrivingLicenceScan))
)
