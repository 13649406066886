/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'
import { ConfusedWrapper } from '../common/ConfusedWrapper'
import useWordpressContent from '../../hooks/useWordpressContent/useWordpressContent'

const CampaignTermsPage = () => {
  const { content } = useWordpressContent()
  return (
    <ConfusedWrapper>
      <Grid desktop='100' mobile='100' tablet='100'>
        <div
          dangerouslySetInnerHTML={{
            __html: (content && content['Confused Terms']) || '',
          }}
        ></div>
      </Grid>
    </ConfusedWrapper>
  )
}

export default CampaignTermsPage
