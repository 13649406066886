import React from 'react'
import PropTypes from 'prop-types'
import { P, Span } from './styles.js'
import { Grid, GridContainer } from 'unsemantic'

const PolicyRefSection = ({ policyId }) => {
  return (
    <GridContainer>
      <Grid desktop='40' tablet='30' mobile='30'>
        <P>Policy reference number</P>
      </Grid>
      <Grid desktop='60' tablet='70' mobile='70'>
        <P>
          <Span>{policyId}</Span>
        </P>
      </Grid>
    </GridContainer>
  )
}

PolicyRefSection.propTypes = {
  policyId: PropTypes.string,
}

export default PolicyRefSection
