import styled from 'styled-components'

const HEADER_MARGIN = 4
const MODAL_PADDING = 8

export const ModalWrapperStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  pointer-events: ${({ isVisible }) => (isVisible ? 'visible' : 'none')};
  z-index: 1000000;
`

export const ModalContentStyled = styled.div`
  background-color: #fff;
  max-width: ${(props) => (props.isLarge ? 1200 : 500)}px;
  width: 100%;
  height: ${({ fullscreen }) => (fullscreen ? '100%' : '500px')};
  box-sizing: border-box;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

export const ModalHeaderStyled = styled.div`
  border-bottom: ${(props) => (props.hasHeaderBorder ? 1 : 0)}px solid
    ${(props) => (props.headerColour ? props.headerColour : '#000')};
  position: relative;
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: ${MODAL_PADDING}px ${HEADER_MARGIN + MODAL_PADDING}px;
`

export const CloseButtonStyled = styled.button`
  position: absolute;
  top: ${HEADER_MARGIN + 12}px;
  right: ${HEADER_MARGIN + MODAL_PADDING}px;
  background: transparent;
  border: none;
  color: #000;
  opacity: 0.5;
  :hover {
    cursor: pointer;
    opacity: 0.75;
  }
`
export const CloseButtonDivStyled = styled.div`
  background: #cccccc;
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  cursor: pointer;

  svg {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
  }
`

export const ModalHeaderText = styled.h3`
  display: block;
  margin: ${HEADER_MARGIN}px 0;
  text-align: ${(props) => props.alignment};
  color: ${(props) => props.headerColour};
`

export const ModalInner = styled.div`
  padding: ${MODAL_PADDING}px ${HEADER_MARGIN + MODAL_PADDING}px;
  overflow-y: auto;
  max-height: 100%;
  box-sizing: border-box;
  flex: 1;
`

export const ModalHeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ModalLogoWrapper = styled.div`
  margin-top: ${HEADER_MARGIN}px;
  margin-left: 8px;
`
