import { call, put, select, takeLatest } from 'redux-saga/effects'
import { UPDATE_QUOTE } from '../constants'

import quoteApi from '../../../api/quoteApi'

import {
  clearIdentityCheck,
  confirmRiskCollectionPage,
  enableAllButtons,
  updateQuoteFailure,
  updateQuoteSuccess,
  updateRiskData,
} from '../actions'

import { history } from '../../..'
import { requestPrice } from '../../App/sagas/priceSaga'
import { IMPOUNDED_VALUE } from '../../HomePage/constants'
import { requestMyLicenceStatus } from '../../MyLicence/actions'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
} from '../../Vehicle/constants'
import {
  getConfusedQuickQuoteOptIn,
  getQueryString,
  getRiskData,
  getSelectedScheme,
  getVehicle,
  updateQuoteLinkSelector,
} from '../selector'
import { underwriterSaga } from './underwriter'

export function* updateRiskDataSaga(action) {
  let riskData
  if (action.payload.riskData) {
    riskData = action.payload.riskData
    const { value, date, hour, minute, manuallyEdited } =
      riskData.PolicyStartDate
    riskData.PolicyStartDate = {
      manuallyEdited: manuallyEdited,
      value: value || `${date}T${hour}:${minute}`,
    }
    yield put(updateRiskData(riskData))
  } else {
    riskData = yield select(getRiskData)
  }
  let impounded = riskData.ReasonForCover === IMPOUNDED_VALUE
  const queryString = yield select(getQueryString)
  try {
    const updateQuoteLink = yield select(updateQuoteLinkSelector)
    const vehicleInfo = yield select(getVehicle)
    const scheme = yield select(getSelectedScheme)
    const confusedQuickQuoteOptIn = yield select(getConfusedQuickQuoteOptIn)

    let vanDetails = {}
    if (scheme === COMMERCIAL_VEHICLE_SCHEME) {
      vanDetails = {
        BodyType: riskData.BodyType,
        UseOfVehicle: riskData.UseOfVehicle,
        Occupation: riskData.Occupation,
      }
    }
    if (scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME) {
      vanDetails = {
        BodyType: riskData.BodyType,
      }
    }
    const quoteData = {
      ...vanDetails,
      Title: riskData.Title,
      Forename: riskData.Forename,
      Surname: riskData.Surname,
      AddressKey: riskData.AddressKey,
      VehicleAbi: vehicleInfo.selected.Abi,
      Duration: riskData.Duration,
      Telephone: riskData.Mobile,
      Email: riskData.EmailAddress,
      DateOfBirth: riskData.DateOfBirth,
      LicenceHeldDuration: riskData.LicenceHeldDuration,
      VehicleId: vehicleInfo.selected.VehicleId,
      DurationType: riskData.DurationType,
      LicenceType: riskData.LicenceType,
      ResidencyType: 'PermanentUkResident',
      QuoteId: {
        Value: riskData.QuoteId,
      },
      VehicleValueBandId:
        riskData.VehicleValueBandId || vehicleInfo.selected.vehicleValue.value,
      Impounded: impounded,
      LicenceNumber: riskData.DrivingLicenceNumber,
    }
    if (riskData.PolicyStartDate.manuallyEdited) {
      quoteData.PolicyStartDate = riskData.PolicyStartDate.value
      quoteData.UserSelectedPolicyStartDate = true
    }

    if (confusedQuickQuoteOptIn !== null) {
      quoteData.ConfusedQuickQuoteOptIn = confusedQuickQuoteOptIn
    }

    const quoteEndpoint = updateQuoteLink.Href
    const partialValidation = action ? action.payload.partialValidation : true
    const quote = yield call(
      quoteApi.updateQuote,
      quoteEndpoint,
      quoteData,
      partialValidation,
      scheme
    )
    yield put(updateQuoteSuccess(quote.data))
    yield put(requestMyLicenceStatus())
    if (action) {
      if (action.payload.getPrice) {
        yield* requestPrice()
        yield* underwriterSaga()
        yield put(requestMyLicenceStatus())
      }
      if (action.payload.confirmRiskData) {
        yield put(confirmRiskCollectionPage(action.payload.callback))
      }
      if (action.payload.clearValidation) {
        yield put(clearIdentityCheck())
      }
      if (action.payload.callback) {
        action.payload.callback()
        yield put(enableAllButtons())
      }
    }
  } catch (e) {
    yield put(updateQuoteFailure(e.message))
    history.push(`/quote/driver-details${queryString}`)
    yield put(enableAllButtons())
  }
}

export default function* fetchRiskDataSaga() {
  yield takeLatest(UPDATE_QUOTE, updateRiskDataSaga)
}
