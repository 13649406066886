import { REQUEST_OCR, REQUEST_OCR_ADDRESS_SUCCESS } from './constants'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import moment from 'moment'
import uuid from 'uuid/v4'
import validator from 'validator'

import {
  requestOcrFrontSuccess,
  requestOcrAddressFailure,
  requestOcrAddressSuccess,
} from './actions'

import ocrApi from '../../api/ocrApi'
import {
  updateRiskData,
  updateQuote,
  updateValidation,
} from '../RiskDataCollectionPage/actions'
import { getAddress, getRiskDataValidation } from './selector'
import { handleLicenceDurations, toTitleCase } from '../../services/handlers'
import { history } from '../..'
import { ROUTES } from '../../services/constants'
import {
  getQuoteId,
  getQueryString,
  getVehicle,
} from '../RiskDataCollectionPage/selector'
import addressApi from '../../api/addressApi'
import { searchAddressRelSelector, getPostcode } from '../Address/selector'
import { selectAddress } from '../Address/actions'

function* callOCR({ images, orientation }) {
  const quoteId = yield select(getQuoteId)
  const queryString = yield select(getQueryString)
  try {
    const data = {
      Images: images,
      EncryptedQuoteId: {
        value: quoteId,
      },
    }

    const generatedId = uuid()
    const link = `/api/drivingLicences/${generatedId}/${orientation}`
    const response = yield call(ocrApi.getOcr, link, data)
    const responseData = response.data

    if (responseData.DateOfBirth) {
      responseData.DateOfBirth = moment(
        responseData.DateOfBirth,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD')
      let filteredOptions = handleLicenceDurations(responseData.DateOfBirth)
      filteredOptions.forEach((option, index) => {
        if (index === filteredOptions.length - 1) {
          responseData.LicenceHeldDuration = option
        }
      })
    } else {
      responseData.LicenceHeldDuration = {
        value: null,
        description: null,
      }
    }
    yield put(requestOcrFrontSuccess(responseData))
    yield put(
      updateValidation({
        ForenameError:
          validator.matches(
            toTitleCase(responseData.Forename.split(' ')[0]),
            /^[a-zA-Z]+((['\-\s][a-zA-Z])?[a-zA-Z]+)$/
          ) && validator.isLength(responseData.Forename, { min: 2, max: 50 })
            ? ''
            : 'Enter a valid first name',
        SurnameError:
          validator.matches(
            responseData.Surname,
            /^[a-zA-Z]+((['\-\s][a-zA-Z])?[a-zA-Z]+)?((['\-\s][a-zA-Z])?[a-zA-Z]+)$/
          ) && validator.isLength(responseData.Surname, { min: 2, max: 50 })
            ? ''
            : 'Enter a valid surname',
        DateOfBirthError:
          responseData.DateOfBirth !== '' ? '' : 'Enter your date of birth',
        LicenceHeldDurationError:
          responseData.DateOfBirth !== ''
            ? ''
            : 'Enter how long you have held your licence',
        PostcodeError: validator.matches(
          responseData.LicenceAddress.PostCode.replace(/\s/g, ''),
          /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i
        )
          ? ''
          : 'Enter a valid postcode',
        AddressError: 'Select your address',
        DrivingLicenceNumberError: '',
        PassportLineOneError: '',
        PassportLineTwoError: '',
        PassportExpiryDateError: '',
        LicenceTypeError: '',
      })
    )

    const riskDataValidation = yield select(getRiskDataValidation)
    if (riskDataValidation.fields.PostcodeError === '') {
      yield* fetchOcrAddresses()
    } else {
      history.push({
        pathname: ROUTES.QUOTE_DRIVER_DETAILS,
        search: queryString,
        state: { scanned: true },
      })
    }
  } catch (e) {
    history.push({
      pathname: ROUTES.QUOTE_DRIVER_DETAILS,
      search: queryString,
      state: { scanned: true },
    })
  }
}

function* addressSuccess() {
  const addressSelect = yield select(getAddress)
  const searchedAddresses = addressSelect.SearchResults
  const queryString = yield select(getQueryString)
  const vehicle = yield select(getVehicle)

  if (addressSelect.AddressLineOne) {
    const scannedAddressLineOne = searchedAddresses.find(
      (searchedAddress) =>
        searchedAddress.Address.indexOf(addressSelect.AddressLineOne) === 0
    )
    const scannedAddressLineTwo = searchedAddresses.find(
      (searchedAddress) =>
        searchedAddress.Address.indexOf(addressSelect.AddressLineTwo) === 0
    )
    const scannedAddress = scannedAddressLineOne || scannedAddressLineTwo
    if (scannedAddress) {
      yield put(
        updateValidation({
          AddressError: '',
          BodyTypeError: '',
          UseOfVehicleError: '',
          OccupationError: '',
        })
      )
      yield put(updateRiskData({ AddressKey: scannedAddress.AddressKey }))
      yield put(selectAddress(scannedAddress.Address))
      const riskDataValidation = yield select(getRiskDataValidation)
      if (riskDataValidation.ValidForm) {
        if (vehicle.selected.VehicleType === 'CommercialVehicle') {
          yield put(
            updateValidation({
              BodyTypeError: null,
              UseOfVehicleError: null,
              OccupationError: null,
            })
          )
          yield put(
            updateQuote({
              partialValidation: true,
              callback: () => {
                history.push(`${ROUTES.QUOTE_MORE_INFO_VAN}${queryString}`)
              },
            })
          )
        } else {
          yield put(
            updateQuote({
              partialValidation: true,
              getPrice: true,
              callback: () => {
                history.push(`${ROUTES.QUOTE_DRIVING_LICENCE}${queryString}`)
              },
            })
          )
        }
      } else {
        history.push({
          pathname: ROUTES.QUOTE_DRIVER_DETAILS,
          search: queryString,
          state: { scanned: true },
        })
      }
    } else {
      yield put(
        updateValidation({
          AddressError: 'Select your address',
        })
      )
      history.push({
        pathname: ROUTES.QUOTE_DRIVER_DETAILS,
        search: queryString,
        state: { scanned: true },
      })
    }
  }
}

export default function* ocrSaga() {
  yield takeLatest(REQUEST_OCR, callOCR)
  yield takeLatest(REQUEST_OCR_ADDRESS_SUCCESS, addressSuccess)
}

function* fetchOcrAddresses() {
  const queryString = yield select(getQueryString)

  try {
    const searchAddressPostcodeLink = yield select(searchAddressRelSelector)
    const postcode = yield select(getPostcode)
    const addressEndpoint = searchAddressPostcodeLink.Href.replace(
      /(\{.+\})/g,
      postcode
    )
    const addresses = yield call(addressApi.getAddresses, addressEndpoint)
    if (addresses.data.AddressSummaries.length === 0) {
      yield put(requestOcrAddressFailure())
      history.push({
        pathname: ROUTES.QUOTE_DRIVER_DETAILS,
        search: queryString,
        state: { scanned: true },
      })
    } else {
      yield put(requestOcrAddressSuccess(addresses.data.AddressSummaries))
    }
  } catch (e) {
    history.push({
      pathname: ROUTES.QUOTE_DRIVER_DETAILS,
      search: queryString,
      state: { scanned: true },
    })
  }
}
