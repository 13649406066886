import styled from 'styled-components'

export const StripePaymentWrapper = styled.div``

export const AcceptedCardsWrapper = styled.div`
  margin-top: -10px;
  margin-bottom: 48px;
`

export const StripeLogo = styled.img`
  height: 50px;
`
export const PaymentHeader = styled.h2`
color: ${(props) => props.theme.labelTextColour};
font-size: 24px;
font-weight: 600;
line-height: 28px;
text-align: left;
display: block;
margin-top: 0;
margin-bottom: 16px;
`
