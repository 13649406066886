import React, { useEffect, useState } from 'react'
import {
  IconWrapper,
  TopIconWrapper,
  SmallDurationIcon,
  ShowMoreButton,
  AllDurationsWrapper,
} from '../HomePage/styles'
import { DURATIONS, ELEMENTS, DURATION_OPTIONS } from './constants'
import { RadioButton } from '../../components/common/RadioButton'
import { IndentedLine } from '../../components/common/IndentedLine'
import { ConfusedPageEnd } from '../../components/common/ConfusedPageEnd'
import { FieldLabel } from '../../components/common/FieldLabel'
import { ErrorMessage } from '../../components/common/ErrorMessage'

const PolicyDurations = ({ setFieldValue, value, error, errorMessage }) => {
  const [selectedElement, setSelectedElement] = useState(null)
  const [selectedSmallElement, setSelectedSmallElement] = useState(null)
  const [showMoreDays, setShowMoreDaysState] = useState(false)
  const [otherDurationVisible, setOtherDurationVisible] = useState(false)

  useEffect(() => {
    const durationData = value.split('+')
    if (!durationData || durationData.length < 2) {
      setSelectedElement(null)
      setSelectedSmallElement(null)
      setShowMoreDaysState(false)
      setOtherDurationVisible(false)
      return
    }
    switch (durationData[1].toLowerCase()) {
      case 'hours':
        setSelectedElement(ELEMENTS.HOURS)
        break
      case 'days':
        if (selectedElement === ELEMENTS.WEEKS) {
          switch (durationData[0]) {
            case '7':
              setSelectedElement(ELEMENTS.WEEKS)
              break
            case '14':
              setSelectedElement(ELEMENTS.WEEKS)
              break
            case '21':
              setSelectedElement(ELEMENTS.WEEKS)
              break
            case '28':
              setSelectedElement(ELEMENTS.WEEKS)
              break
            default:
              break
          }
        } else {
          setSelectedElement(ELEMENTS.DAYS)
          if (parseInt(durationData[0]) > 7) {
            setShowMoreDaysState(true)
          }
        }
        break
      case 'weeks':
        setSelectedElement(ELEMENTS.WEEKS)
        setFieldValue('PolicyDuration', `${durationData[0] * 7}+days`)
      // eslint-disable-next-line no-fallthrough
      default:
        return
    }
    if (durationData[1]) {
      setSelectedSmallElement(value.toLowerCase())
      setOtherDurationVisible(true)
      if (parseInt(durationData[0]) > 7) {
        setShowMoreDaysState(true)
      }
    }
  }, [value])

  const setShowMoreDays = (value) => {
    setShowMoreDaysState(!showMoreDays)
  }

  const setDuration = (value) => {
    setSelectedSmallElement(value)
    setFieldValue('PolicyDuration', value)
  }

  return (
    <div className='select-duration'>
      <div id='CommonDurations'>
        <FieldLabel>How long do you need cover for?</FieldLabel>
        {error && (
          <ErrorMessage id='PolicyDurationError'>{errorMessage}</ErrorMessage>
        )}
        <TopIconWrapper>
          {DURATION_OPTIONS.map((option) => (
            <RadioButton
              id={`radio-duration-${option.value}`}
              onChange={() => {
                setSelectedElement(option.element)
                setOtherDurationVisible(true)
              }}
              error={Boolean(error && !selectedElement)}
              value={option.value}
              name='Duration'
              checked={selectedElement === option.element}
              key={`radio-duration-${option.value}`}
            />
          ))}
        </TopIconWrapper>
      </div>
      {otherDurationVisible ? (
        <AllDurationsWrapper>
          <IndentedLine noGap>
            <div id='AllDurations'>
              <FieldLabel noTopMargin>
                How many{' '}
                {(() => {
                  switch (selectedElement) {
                    case 1:
                      return 'hours'
                    case 2:
                      return 'days'
                    case 3:
                      return 'weeks'
                    default:
                      return 'hours'
                  }
                })()}{' '}
                do you need cover for?
              </FieldLabel>
              {selectedElement === ELEMENTS.HOURS && (
                <IconWrapper className='width-6'>
                  {Object.keys(DURATIONS.HOURS).map((hour) => (
                    <SmallDurationIcon
                      className={
                        selectedSmallElement === DURATIONS.HOURS[parseInt(hour)]
                          ? 'Active'
                          : ''
                      }
                      onClick={() => {
                        setDuration(DURATIONS.HOURS[parseInt(hour)])
                      }}
                      key={`hours-duration-${hour}`}
                      error={Boolean(error)}
                    >
                      <div>
                        <span>{hour}</span>
                      </div>
                    </SmallDurationIcon>
                  ))}
                </IconWrapper>
              )}
              {selectedElement === ELEMENTS.DAYS && (
                <>
                  <IconWrapper className='width-8'>
                    {
                      <>
                        {Object.keys(DURATIONS.DAYS)
                          .filter(
                            (day) => parseInt(day) <= (showMoreDays ? 28 : 7)
                          )
                          .map((day) => (
                            <SmallDurationIcon
                              className={
                                selectedSmallElement ===
                                DURATIONS.DAYS[parseInt(day)]
                                  ? 'Active'
                                  : ''
                              }
                              onClick={() => {
                                setDuration(DURATIONS.DAYS[parseInt(day)])
                              }}
                              key={`days-duration-${day}`}
                              error={Boolean(error)}
                            >
                              <div>
                                <span>{day}</span>
                              </div>
                            </SmallDurationIcon>
                          ))}
                      </>
                    }
                  </IconWrapper>
                  <ShowMoreButton
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      setShowMoreDays()
                    }}
                    more={showMoreDays}
                  >
                    <span>See {showMoreDays ? 'less' : 'more'} options</span>
                  </ShowMoreButton>
                </>
              )}
              {selectedElement === ELEMENTS.WEEKS && (
                <IconWrapper className='width-4'>
                  {Object.keys(DURATIONS.WEEKS).map((week) => (
                    <SmallDurationIcon
                      className={
                        selectedSmallElement === DURATIONS.WEEKS[parseInt(week)]
                          ? 'Active'
                          : ''
                      }
                      onClick={() => {
                        setDuration(DURATIONS.WEEKS[parseInt(week)])
                      }}
                      key={`weeks-duration-${week}`}
                      error={Boolean(error)}
                    >
                      <div>
                        <span>{week}</span>
                      </div>
                    </SmallDurationIcon>
                  ))}
                </IconWrapper>
              )}
            </div>
          </IndentedLine>
        </AllDurationsWrapper>
      ) : (
        <></>
      )}
      <ConfusedPageEnd paddingSize={'36px'} />
    </div>
  )
}

export default PolicyDurations
