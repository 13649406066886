import initialState from '../../initialState'
import { SHOW_EXPERIMENT } from './experiment.actions'

const experimentReducer = (state = initialState.experiments, action) => {
  switch (action.type) {
    case SHOW_EXPERIMENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default experimentReducer
