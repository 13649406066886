import {
  REQUEST_PRICE_SUCCESS,
  REQUEST_PRICE_FAILURE,
  UPDATE_CAMPAIGN_DATA,
  SELECT_PRICE,
  REQUEST_QUOTE_SUCCESS,
  SET_TEMP_UNDERWRITER,
  SET_UNDERWRITER_CHANGED,
} from '../constants'
import {
  REQUEST_POLICY_SUCCESS,
  GET_ANALYSIS_DATA_SUCCESS,
} from '../../PolicyConfirmationPage/constants'
import {
  UPDATE_QUOTE,
  UNDERWRITER_CHANGED_BY_CUE,
} from '../../RiskDataCollectionPage/constants'
import { QUOTE_SUMMARY_MODAL_CLOSE } from '../../QuoteSummary/constants'

import initialState from '../../../initialState'

function priceReducer(state = initialState.price, action) {
  switch (action.type) {
    case UPDATE_QUOTE:
    case QUOTE_SUMMARY_MODAL_CLOSE:
      const { getPrice } = action.payload
      return {
        ...state,
        isUpdating: !!getPrice === getPrice ? getPrice : true,
      }
    case REQUEST_PRICE_SUCCESS:
      let listOfPrices = action.price.Prices
      if (listOfPrices && listOfPrices.length > 0) {
        listOfPrices.sort((a, b) => a.TotalPrice - b.TotalPrice)
      }
      return {
        ...state,
        InsurancePremium: action.price.InsurancePremium,
        Ipt: action.price.Ipt,
        AdminFee: action.price.AdminFee,
        Underwriter: action.price.Underwriter,
        UnderwriterDisplayName: action.price.UnderwriterDisplayName,
        UnderwriterId: action.price.UnderwriterId,
        TotalPrice: action.price.TotalPrice,
        EncryptedString: action.price.ValidationData
          ? action.price.ValidationData.Value
          : null,
        CompulsoryExcess: action.price.CompulsoryExcess,
        AffiliateAdminFee: action.price.AffiliateAdminFee,
        UnderwriterChanged: action.price.underwriterChanged,
        isUpdating: false,
        Prices: listOfPrices,
      }
    case REQUEST_PRICE_FAILURE:
      return {
        ...state,
        isUpdating: false,
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        TotalPrice: action.policy.Premium,
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        TotalPrice: action.response.Premium,
        Ipt: action.response.Ipt,
        Underwriter: action.response.Underwriter,
        commission: action.response.Commission,
      }
    case SET_UNDERWRITER_CHANGED:
      return {
        ...state,
        UnderwriterChanged: true,
      }
    case UPDATE_CAMPAIGN_DATA:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          Ref: action.campaignData.CampaignRef || state.campaign.Ref,
          Name: action.campaignData.CampaignName,
          Discount: action.campaignData.CampaignDiscount,
        },
      }
    case SELECT_PRICE:
      return {
        ...state,
        ...action.price,
        EncryptedString: action.price.ValidationData
          ? action.price.ValidationData.Value
          : null,
      }
    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        Underwriter: action.quote.Underwriter,
      }
    case SET_TEMP_UNDERWRITER:
      return {
        ...state,
        TempUnderwriter: action.underwriter,
      }
    case UNDERWRITER_CHANGED_BY_CUE:
      return {
        ...state,
        UnderwriterChangedByCue: true,
        UnderwriterId: action.underwriterId,
      }
    default:
      return state
  }
}

export default priceReducer
