import React from 'react'
import PropTypes from 'prop-types'
import { FormControlWrapper } from '../../FormControlWrapper'
import { ErrorMessage } from '../ErrorMessage'
import { toTitleCase } from '../../../services/handlers'
import { BaseInputTS } from '../Input/BaseInputTS'

interface Props {
    id: string,
    placeholder: string,
    errorMessage: string,
    error: boolean,
    onChange: (e: React.ChangeEvent<any>) => void,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    name: string,
    value: string,
    type?: string,
    pattern?: string,
    titleCase?: boolean,
    tabIndex?: number,
}

const InputTS: React.FC<Props> = ({
  id,
  placeholder,
  errorMessage,
  error,
  onChange,
  onBlur,
  onFocus,
  name,
  value,
  type,
  pattern,
  titleCase,
  tabIndex,
}) => {
  const onInput = (e) => {
    if (titleCase) {
      e.target.value = toTitleCase('' + e.target.value)
    }
  }

  return (
    <FormControlWrapper>
      {error && errorMessage && (
        <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>
      )}
      <BaseInputTS
        placeholder={placeholder}
        error={error}
        onInput={onInput}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        id={id}
        value={value}
        type={type}
        autoComplete='off'
        autoCorrect='off'
        pattern={pattern}
        tabIndex={tabIndex}
      />
    </FormControlWrapper>
  )
}

export default InputTS