import styled from 'styled-components'

export const PolicyStartTimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 90px 20px 90px;
  max-width: 333px;
  align-items: end;
`

export const TimeSeparator = styled.div`
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 22px;
  font-weight: bold;
`

export const SplitDateContainer = styled.div`
  margin-bottom: 48px;
`
