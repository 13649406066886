import React, { useEffect } from 'react'
import { Grid } from 'unsemantic'
import styled from 'styled-components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { updateQuote, isButtonDisabled, enableAllButtons } from './actions'
import { RiskCollectionPathSelection } from '../../components/RiskCollectionPathSelection'
import PaymentPage from '../PaymentPage'
import CoverDetails from '../CoverDetailsPage'
import DriverDetails from '../DriverDetails'
import DriverDetailsRedesigned from '../DriverDetails - Redesigned'
import DrivingLicence from '../DrivingLicence'
import { initRiskDataCollectionPage } from '../RiskDataCollectionPage/actions'
import MoreDetails from '../MoreDetails'
import LegalDeclarationPage from '../LegalDeclarationPage'
import { Secure } from '../JudoPay/Secure'
import DrivingLicenceCamera from '../DrivingLicenceCamera'
import LoadingScreen from '../../components/LoadingScreen'
import MoreInfoVan from '../VanDetails/MoreInfoVan'
import { saveMarketingPreferences } from '../MarketingPreferencesContainer/actions'
import { isLoading } from '../App/actions'

const MainContainer = styled.div`
  text-align: left;
`

const RiskDataCollectionPage = ({
  actions,
  history,
  loading,
  queryString,
  appStartupComplete,
  ownProps
}) => {
  useEffect(() => {
    if (ownProps.location.pathname === "/quote/cover-details") {
      actions.isLoading(false)
    } else if (!window.isHoldingPageOn && appStartupComplete) {
      actions.initRiskDataCollectionPage()
    }
  }, [appStartupComplete])

  const handleContinueClick = () => {
    actions.isButtonDisabled({
      buttonName: 'driverDetails',
      disabled: true,
    })
    actions.saveMarketingPreferences()
    actions.updateQuote({
      partialValidation: true,
      getPrice: true,
      callback: () => {
        actions.enableAllButtons()
        history.push(`/quote/driving-licence${queryString}`)
      },
    })
  }

  const isUsingExperment = window.location.search.indexOf('experiment') > 0

  return (
    <Grid desktop='100' parent>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <MainContainer>
            <Grid desktop='100' tablet='100' mobile='100' parent>
              <Switch>
                <Route path='/quote/scan' component={DrivingLicenceCamera} />
                <Route
                  path='/quote/driver-details/van-info'
                  component={MoreInfoVan}
                />
                <Route
                  path='/quote/driver-details/more-info'
                  component={MoreDetails}
                />
                <Route
                  path='/quote/driver-details'
                  render={() => {
                    return isUsingExperment ?
                    <DriverDetailsRedesigned onContinue={handleContinueClick} /> :
                    <DriverDetails onContinue={handleContinueClick} />
                  }}
                />
                <Route
                  path='/quote/cover-details'
                  component={CoverDetails}
                />
                <Route path='/quote/payment/3d-secure' component={Secure} />
                <Route path='/quote/payment' component={PaymentPage} />
                <Route
                  path='/quote/driving-licence'
                  component={DrivingLicence}
                />
                <Route
                  path='/quote/legal-declaration'
                  component={LegalDeclarationPage}
                />
                <Route
                  path='/quote/select-path'
                  render={() => {
                    return (
                      <RiskCollectionPathSelection queryString={queryString} />
                    )
                  }}
                />
              </Switch>
            </Grid>
          </MainContainer>
        </>
      )}
    </Grid>
  )
}

export const mapStateToProps = (state, ownProps) => {
  return {
    history: ownProps.history,
    loading: state.isLoading,
    queryString: state.queryString,
    appStartupComplete: state.appStartupComplete,
    ownProps: ownProps
  }
}

export const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        initRiskDataCollectionPage,
        updateQuote,
        isButtonDisabled,
        enableAllButtons,
        saveMarketingPreferences,
        isLoading
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(RiskDataCollectionPage)

RiskDataCollectionPage.propTypes = {
  validForm: PropTypes.bool,
  legalDeclaration: PropTypes.shape({
    ShortText: PropTypes.string,
    FullText: PropTypes.string,
  }),
}
