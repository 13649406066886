import React from 'react'
import Arrow from '../Arrow'
import { ConfusedBackButtonWrapper } from './ConfusedBackButton.styles'


export const ConfusedBackButton = ({ children, onClick }) => {
  return (
    <ConfusedBackButtonWrapper onClick={onClick}>
      <Arrow left />
      <span>{children}</span>
    </ConfusedBackButtonWrapper>
  )
}
