import React from 'react'
import { BaseSelectTS } from './BaseSelectTS'
import { SelectWrapperTS } from './SelectWrapperTS'

interface Option {
  id: string | number
  value: string
  description: string
}

interface SelectProps {
  id: string
  value: string
  options: Option[]
  error?: boolean
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void
  placeholder?: string
  name?: string
  style?: React.CSSProperties
  myRef?: React.RefObject<HTMLSelectElement>
  tinyGap?: boolean
}

const SelectTS: React.FC<SelectProps> = ({
  id,
  value,
  options,
  error,
  onChange,
  onBlur,
  placeholder,
  name,
  style,
  myRef,
  tinyGap,
}) => {
  const className =
    value === '' || value === 'Uninitialised' || value === null
      ? 'placeholder'
      : ''

  let actualError = false
  if (error !== undefined) {
    actualError = error
  }

  return (
    <SelectWrapperTS error={actualError} tinyGap={tinyGap} style={style}>
      <BaseSelectTS
        ref={myRef}
        id={id}
        error={actualError}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        style={style}
        className={className}
      >
        {placeholder && <option value=''>{placeholder}</option>}
        {options.map((option) => (
          <option
            key={option.id}
            value={option.value}
            selected={option.value === value}
          >
            {option.description}
          </option>
        ))}
      </BaseSelectTS>
    </SelectWrapperTS>
  )
}

export default SelectTS
