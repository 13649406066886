import 'babel-polyfill'
import 'raf/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from './containers/App'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import '../node_modules/unsemantic/assets/stylesheets/unsemantic-grid-responsive-tablet.css'
import './index.css'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import { ConnectedRouter } from 'connected-react-router'
import { createHistory } from './history'

export const history = createHistory()

const store = configureStore()
const MOUNT_NODE = document.getElementById('app')
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
