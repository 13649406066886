import { call, put, select, takeLatest } from 'redux-saga/effects'

import quoteApi from '../../../api/quoteApi'

import {
  getPriceLink,
  getRiskData,
  getAffiliateFromState,
  getQuoteState,
  getConfusedQuickQuote,
} from '../selector'
import {
  requestConfusedQuickQuote,
  requestPrice as getPrice,
  setTempUnderwriter,
  updateCampaignData,
} from '../actions'
import { requestPriceSuccess, requestPriceFailure } from '../actions'
import { sendEmailQuote } from '../../EmailQuoteModal/saga'
import getAffiliate from './affiliateSaga'
import { SELECT_PRICE } from '../constants'
import { updateQuote } from '../../RiskDataCollectionPage/actions'
import { getPriceData } from '../../LegalDeclarationModal/selector'

export function* requestPrice() {
  try {
    yield put(getPrice())
    yield* select(getAffiliate)

    const { PriceComparison } = yield select(getAffiliateFromState)
    const quoteState = yield select(getQuoteState)
    let { Href: priceLink } = yield select(getPriceLink)

    if (PriceComparison) {
      priceLink = `${priceLink}/all`
    }

    const { QuoteId } = yield select(getRiskData)
    const { Underwriter, TempUnderwriter } = yield select(getPriceData)

    const header = {
      Accept: PriceComparison
        ? 'application/tempcover.PricingAllRepresentation+json'
        : 'application/tempcover.PricingRepresentation+json',
    }

    const price = yield call(
      quoteApi.getPrice,
      priceLink,
      {
        value: QuoteId,
        QuoteJourneyPosition: quoteState.QuoteJourneyPosition,
      },
      header
    )

    if (PriceComparison && Underwriter) {
      const currentSelectedPrice = price.data.Prices.filter((p) =>
        TempUnderwriter
          ? parseInt(TempUnderwriter) === p.UnderwriterId
          : p.Underwriter === Underwriter.replace(' ', '')
      )[0]
      Object.assign(price.data, currentSelectedPrice)
      yield put(setTempUnderwriter(null))
    }

    yield put(requestPriceSuccess(price.data))
    yield put(updateCampaignData(price.data))
    yield call(sendEmailQuote)
  } catch (e) {
    yield put(requestPriceFailure(e))
  }
}

export function* selectPriceSaga() {
  var { OptIn } = yield select(getConfusedQuickQuote)
  if (OptIn) {
    yield put(requestConfusedQuickQuote())
  }

  yield put(
    updateQuote({
      partialValidation: true,
      confirmRiskData: true,
      getPrice: false,
    })
  )
}

export default function* priceSaga() {
  yield takeLatest(SELECT_PRICE, selectPriceSaga)
}
