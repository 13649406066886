import styled from 'styled-components'
import { Grid } from 'unsemantic'

export const SummaryWrapper = styled(Grid)`
  background-color: #58aae0;
  padding-left: 1.5%;
  margin-top: 20px;
  margin-bottom: 24px;

  max-width: ${(props) =>
    props.maxWidth
      ? props.maxWidth
      : '100%'};

  display: ${(props) =>
    props.display
      ? props.display
      : 'block'};

  padding: ${(props) =>
    props.padding
      ? props.padding
      : '0%'};
  
  margin-right: ${(props) =>
    props.marginRight
      ? props.marginRight
      : '0%'};

  @media (max-width: 768px) {
    padding-left: 15px;
    font-size: 14px;
  }

  table {
    padding-top: 15px;
    padding-bottom: 15px;

    tr {
      td {
        color: #1f1f1f;
        padding: 6px 10px 6px 0;
      }

      td.vehicle-summary-value {
        font-weight: bold;
      }
    }
  }
`

export const SummaryLabel = styled.div`
  font-weight: 400;
`

export const SummaryValue = styled.div`
  font-weight: 600;
`