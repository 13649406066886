import { call, put, select } from 'redux-saga/effects'
import {
  requestIdentityCheckSuccess,
  updateValidation,
  requestIdentityCheckMoreInfo,
  enableAllButtons,
  underwriterChangedByCue,
} from '../actions'
import { requestPriceSuccess } from '../../App/actions'
import { updateMyLicenceData } from '../../MyLicence/actions'
import {
  getRiskData,
  identityCheckLinkSelector,
  drivingLicenceCheckLink,
  passportCheckLink,
  getQueryString,
} from '../selector'
import identityApi from '../../../api/IdentityApi'
import { getMyLicenceStatus } from '../../MyLicence/selector'
import { history } from '../../../'
import { getPriceLink } from '../../App/selector'
import { getPriceData } from '../../LegalDeclarationModal/selector'
import quoteApi from '../../../api/quoteApi'
import PriceComparison from '../../../components/PriceComparison'
import { QUOTE_JOURNEY_POSITION } from '../../../constants'

export function* identitySaga() {
  const identityCheckLink = yield select(identityCheckLinkSelector)

  const riskData = yield select(getRiskData)

  const queryString = yield select(getQueryString)

  try {
    const identityLink = identityCheckLink.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId
    )
    const identityCheck = yield call(identityApi.identityCheck, identityLink)
    switch (identityCheck.data.FraudWorkFlow) {
      case 'DriverLicenceCheck':
        yield put(
          requestIdentityCheckMoreInfo(
            identityCheck.data.Links,
            'drivingLicence'
          )
        )
        if (riskData.DrivingLicenceNumber) {
          yield* drivingLicenceCheckSaga()
        } else {
          yield put(
            updateValidation({
              DrivingLicenceNumberError: 'Enter your driving licence number',
            })
          )
          yield put(
            requestIdentityCheckMoreInfo(
              identityCheck.data.Links,
              'drivingLicence'
            )
          )
          yield put(enableAllButtons())
          history.push(`/quote/driver-details/more-info${queryString}`)
        }
        break
      case 'PassportCheck':
        yield put(
          requestIdentityCheckMoreInfo(identityCheck.data.Links, 'passport')
        )
        if (
          riskData.PassportLineOne &&
          riskData.PassportLineTwo &&
          riskData.PassportExpiryDate
        ) {
          yield* passportCheckSaga()
        } else {
          yield put(
            updateValidation({
              PassportLineOneError: riskData.PassportLineOne
                ? ''
                : 'Enter passport line one',
              PassportLineTwoError: riskData.PassportLineTwo
                ? ''
                : 'Enter passport line two',
              PassportExpiryDateError: riskData.PassportExpiryDate
                ? ''
                : 'Enter your passport expiry date',
            })
          )

          history.push(`/quote/driver-details/more-info${queryString}`)
          yield put(enableAllButtons())
        }
        break
      case 'Prepayment':
        const { UnderwriterId } = yield select(getPriceData)
        if (identityCheck.data.UnderwriterId !== UnderwriterId) {
          let { Href: priceLink } = yield select(getPriceLink)

          if (PriceComparison) {
            priceLink = `${priceLink}`
          }

          const { QuoteId } = yield select(getRiskData)

          const header = {
            Accept: 'application/tempcover.PricingRepresentation+json',
          }

          const price = yield call(
            quoteApi.getPrice,
            priceLink,
            {
              value: QuoteId,
              QuoteJourneyPosition:
                QUOTE_JOURNEY_POSITION.SystemDocumentCheckSubmit,
            },
            header
          )

          yield put(requestPriceSuccess({ ...price.data }))
          yield put(underwriterChangedByCue(identityCheck.data.UnderwriterId))
          yield put(enableAllButtons())
        } else {
          yield put(requestIdentityCheckSuccess(identityCheck.data))
        }
        break
      case 'Price':
        //We will hit this if the underwriter has changed during identity checks
        yield put(drivingLicenceCheckSaga())
        yield put(enableAllButtons())
        break
      default:
        break
    }
  } catch (e) {
    rg4js('send', e) // eslint-disable-line no-undef
    yield put(requestPriceSuccess({ TotalPrice: 0 }))
    yield put(requestIdentityCheckMoreInfo([], 'passport'))
    history.push(`/quote/driving-licence${queryString}`)
    yield put(enableAllButtons())
  }
}

export function* drivingLicenceCheckSaga() {
  const licenceCheck = yield select(drivingLicenceCheckLink)
  const riskData = yield select(getRiskData)
  const queryString = yield select(getQueryString)
  try {
    const licenceCheckLink = licenceCheck.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId
    )
    const data = {
      LicenceNumber: riskData.DrivingLicenceNumber,
    }
    const response = yield call(
      identityApi.drivingLicenceCheck,
      licenceCheckLink,
      data
    )
    const myLicenceData = yield select(getMyLicenceStatus)
    const actionResults = myLicenceData.Actioned

    const myLicenceCheckFailed = response.data.CheckFailed
    const myLicenceFailReasons = response.data.FailReasons
    if (actionResults) {
      //We have done the checks and relevant DB Logging, but we don't want to act on the results

      yield put(
        updateMyLicenceData({
          CheckFailed: myLicenceCheckFailed,
          Actioned: actionResults,
          FailReasons: myLicenceFailReasons,
        })
      )

      if (
        myLicenceCheckFailed &&
        (myLicenceFailReasons.indexOf('LicenceNumber') >= 0 ||
          myLicenceFailReasons.indexOf('LicenceCategory') >= 0)
      ) {
        yield put(enableAllButtons())
        history.push(`/quote/driver-details${queryString}`)
        return
      } else {
        let { Href: priceLink } = yield select(getPriceLink)

        if (PriceComparison) {
          priceLink = `${priceLink}`
        }

        const { QuoteId } = yield select(getRiskData)
        const { Underwriter } = yield select(getPriceData)

        const header = {
          Accept: 'application/tempcover.PricingRepresentation+json',
        }

        const price = yield call(
          quoteApi.getPrice,
          priceLink,
          {
            value: QuoteId,
            QuoteJourneyPosition:
              QUOTE_JOURNEY_POSITION.SystemDocumentCheckSubmit,
          },
          header
        )

        if (price.data.Underwriter !== Underwriter) {
          yield put(
            requestPriceSuccess({ ...price.data, underwriterChanged: true })
          )

          yield put(enableAllButtons())
          history.push(`/quote/driving-licence${queryString}`)
          return
        } else {
          yield call(identitySaga)
        }
      }
    } else {
      yield call(identitySaga)
    }
  } catch (e) {
    yield put(enableAllButtons())
    history.push(`/quote/driver-details${queryString}`)
  }
}

export function* passportCheckSaga() {
  const passportCheck = yield select(passportCheckLink)
  const riskData = yield select(getRiskData)
  const queryString = yield select(getQueryString)
  try {
    const passportCheckLink = passportCheck.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId
    )
    const data = {
      UkPassportLineOne: riskData.PassportLineOne,
      UkPassportLineTwo: riskData.PassportLineTwo,
      UkPassportExpiryDate: riskData.PassportExpiryDate,
    }
    yield call(identityApi.passportCheck, passportCheckLink, data)
    yield call(identitySaga)
  } catch (e) {
    yield put(enableAllButtons())
    history.push(`/quote/driver-details${queryString}`)
  }
}
