import { Form, Formik } from 'formik'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { history } from '../..'
import ValidationSummary from '../../components/ValidationSummary'
import { ConfusedPageEnd } from '../../components/common/ConfusedPageEnd'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { ConfusedBackButton } from '../../components/common/ConfusedBackButton'
import { MiniSummary } from '../../components/MiniSummary'
import { FieldLabel } from '../../components/common/FieldLabel'
import { SectionHeading } from '../../components/common/SectionHeading'
import config from '../../config'
import { HOME_PAGE_VALIDATION } from '../../services/validation'
import { isLoading, updateStep } from '../App/actions'
import { initRiskDataCollectionPage } from '../RiskDataCollectionPage/actions'
import { createUnkownVehicle, requestVehicle } from '../Vehicle/actions'
import PolicyDurations from './PolicyDurations'
import ReasonForCover from './ReasonForCover'
import { IMPOUNDED_VALUE } from './constants'
import {
  GetQuoteWrapper,
  GreyBackground,
  P3,  
  TeslaQuoteNextButton,
} from '../HomePage/styles'
import { LargeSeperator } from '../../components/common/Seperator'
import { VehicleValue } from '../../components/VehicleValue'
import SplitPolicyStartTimePicker from '../../components/SplitPolicyStartTimePicker'
import styled from 'styled-components'
import { ROUTES } from '../../services/constants'
import { RiskDataDiscountBannerContainer } from '../RiskDataCollectionPage/components'
import { addMissingUtmTags } from '../../history'

export const QuestionInfo = styled.div`
  margin: 0 0 16px 0;
`

export const formatDate = (startDate, dateEdited) => {
  if (dateEdited) {
    return moment(startDate).format('ddd DD/MM/YYYY HH:mm')
  } else {
    return 'Policy starts immediately'
  }
}

const createSplitPolicyStartDateObject = ({ value, manuallyEdited }) => {
  if (value && manuallyEdited) {
    return {
      date: moment(value).format('YYYY-MM-DD'),
      hour: moment(value).format('HH'),
      minute: moment(value).format('mm'),
      manuallyEdited,
    }
  } else {
    return {
      date: '',
      hour: '',
      minute: '',
      manuallyEdited,
    }
  }
}


const CoverDetailsPage = ({ riskData, appStartupComplete, actions, confusedProfileVehicleSelected, vehicle}) => {
  useEffect(() => {
    actions.updateStep(1)
  }, [])

  const { duration } = queryString.parse(window.location.search)

  const handleStartQuote = (values) => {
    actions.isLoading(true)
    let url = `/quote?${addMissingUtmTags({})}&${getVehicleFromUrl()}&duration=${values.PolicyDuration}`
    if (config.IMPOUNDED_ENABLED) {
      url += `&reason=${values.ReasonForCover}`
    }

    const policyStartDate = values.PolicyStartDate;
    if (policyStartDate.manuallyEdited) {
      url += `&start=${policyStartDate.date}T${policyStartDate.hour}_${policyStartDate.minute}`
    } else {
      url += '&start=Immediately'
    }

    const parsedQueryString = queryString.parse(window.location.search)
    if (parsedQueryString.confusedVehicleId){
      url += `&confusedVehicleId=${parsedQueryString.confusedVehicleId}`
    }
    
    actions.initRiskDataCollectionPage(values)
    history.push(url)
  }


  const getVehicleFromUrl = () => {        
    const registration = new URLSearchParams(document.location.search).get("registration")
    if (registration != null) {
      return `registration=${registration}`
    }

    // TODO: Add Manually built vehicle quote creation
    const make = new URLSearchParams(document.location.search).get("make");
    const model = new URLSearchParams(document.location.search).get("model");
    const variant = new URLSearchParams(document.location.search).get("variant");

    if (make != null && model != null && variant != null) {
      return `make=${make}&model=${model}&variant=${variant}`
    }

    if (vehicle.searched.RegistrationNumber != null) {
      return `registration=${vehicle.searched.RegistrationNumber}`
    }

    history.push(`vehicle/invalid-reg?${window.location.search}`)
  }

  const homeDetails = () => {
    history.push(ROUTES.HOME_PAGE)
  }

  useEffect(() => {
    const parsedString = queryString.parse(window.location.search)
    if (parsedString.registration != null && vehicle.searched.VehicleId == null)
    {
      actions.requestVehicle(parsedString.registration)
    }
  }, [window.location, vehicle])

  return (
    <ConfusedWrapper>
      <RiskDataDiscountBannerContainer></RiskDataDiscountBannerContainer>
      {
        vehicle.searched.VehicleId != null && (
          <>
            <SectionHeading>Vehicle and cover</SectionHeading>
            <MiniSummary
              vehicle={vehicle.searched}
            />
            <ConfusedBackButton onClick={homeDetails}>
              Change vehicle details
            </ConfusedBackButton>
          </>
        )
      }

      <SectionHeading>Your Cover</SectionHeading>
      <section id='MainCTA' className=''>
        <div className='grid-100 grid-parent'>
          <section id='Tesla' className=''>
            <Formik
              initialValues={{
                PolicyStartDate: createSplitPolicyStartDateObject(
                  riskData.PolicyStartDate
                ),
                PolicyDuration:
                  riskData.Duration && riskData.DurationType
                    ? `${
                        riskData.Duration
                      }+${riskData.DurationType.toLowerCase()}`
                    : (duration || '').replace(/\s/g, '+'),
                ReasonForCover: riskData.ReasonForCover,
                ImpoundedEnabled: config.IMPOUNDED_ENABLED,
                VehicleValue: vehicle.searched.vehicleValue.value
              }}
              validationSchema={HOME_PAGE_VALIDATION}
              onSubmit={(values) => handleStartQuote(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                setFieldTouched,
                setFieldValue,
                submitCount,
                touched,
                values
              }) => {
                const errorCount = Object.keys(errors || {}).length
                /* eslint-disable react-hooks/rules-of-hooks */
                const [globalSubmitCount, setGlobalSubmitCount] = useState(0)
                if (
                  errorCount > 0 &&
                  submitCount > 0 &&
                  globalSubmitCount !== submitCount
                ) {
                  setGlobalSubmitCount(submitCount)
                }
                return (
                  <Form id='TeslaForm'>
                     <ValidationSummary
                        showSummary={errorCount > 0 && submitCount > 0}
                        errorCount={errorCount}
                        submitCount={submitCount}
                      />
                      <FieldLabel htmlFor='PolicyStartDate'>
                        When do you need the cover to start?
                      </FieldLabel>
                      <SplitPolicyStartTimePicker
                        id='PolicyStartDate'
                        name='PolicyStartDate'
                        value={values.PolicyStartDate}
                        errors={errors.PolicyStartDate || {}}
                        touched={touched.PolicyStartDate || {}}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <FieldLabel htmlFor='VehicleValue'>Vehicle value</FieldLabel>
                      <QuestionInfo>
                        We've based this estimate on the vehicle details you've
                        provided. <br />
                        If this doesn't look right, select the correct 
                        price range from the options listed
                      </QuestionInfo>
                      <VehicleValue
                        name='VehicleValue'
                        onChange={handleChange}
                        value={values.VehicleValue}
                      />
                      <LargeSeperator />

                    {config.IMPOUNDED_ENABLED && (
                      <div className='select-reason-for-cover'>
                        <ReasonForCover
                          id='ReasonForCover'
                          name='ReasonForCover'
                          value={values.ReasonForCover}
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          error={Boolean(
                            touched.ReasonForCover && errors.ReasonForCover
                          )}
                          errorMessage={errors.ReasonForCover}
                        />
                      </div>
                    )}
                    {values.ReasonForCover !== IMPOUNDED_VALUE ? (
                      <PolicyDurations
                        id='PolicyDuration'
                        name='PolicyDuration'
                        value={values.PolicyDuration}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        error={Boolean(
                          touched.PolicyDuration && errors.PolicyDuration
                        )}
                        errorMessage={errors.PolicyDuration}
                      />
                    ) : (
                      <>
                        <GreyBackground>
                          Impound Insurance is only available for a duration of
                          30 days
                        </GreyBackground>

                        <ConfusedPageEnd bigPadding></ConfusedPageEnd>
                      </>
                    )}
                    <GetQuoteWrapper>
                      <P3>
                        To process your application we will verify your identity
                        and may check your details with credit reference and
                        fraud prevention agencies. Entering incorrect data risks
                        invalidating your policy. I have read and agree to the{' '}
                        <a href='/terms-conditions' target='_blank'>
                          customer terms of business
                        </a>{' '}
                        and{' '}
                        <a href='/privacy-cookies' target='_blank'>
                          privacy policy
                        </a>
                        .
                      </P3>
                      <TeslaQuoteNextButton
                        id='TeslaQuoteNextButton'
                        type='submit'
                        tabIndex={3}
                        disabled={!appStartupComplete}
                        onMouseDown={(e) => {
                          e.preventDefault()
                        }}
                      >
                        Agree and continue
                      </TeslaQuoteNextButton>
                    </GetQuoteWrapper>
                  </Form>
                )
              }}
            </Formik>
          </section>
        </div>
      </section>
    </ConfusedWrapper>
  )
}

function mapStateToProps(state) {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    appStartupComplete: state.appStartupComplete,
    confusedExternalAccount: state.confusedExternalAccount,
    confusedProfileVehicleSelected: state.confusedProfileVehicleSelected
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        isLoading,
        updateStep,
        createUnkownVehicle,
        initRiskDataCollectionPage,
        requestVehicle
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverDetailsPage)
