import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { showBreakdownIcon } from './actions'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Wrapper, PriceBreakdown, Diamond } from './PaymentBreakdownStyles'

library.add(faInfoCircle)

const PaymentBreakdownIcon = ({
  price,
  affiliate,
  index,
  showRight,
  visibleBreakdown,
  actions: { showBreakdownIcon },
}) => {
  const IconKey = `BreakdownIcon_${index}`
  return (
    <Wrapper>
      <FontAwesomeIcon
        icon='info-circle'
        onClick={() => showBreakdownIcon(IconKey)}
      />
      {visibleBreakdown === IconKey && (
        <PriceBreakdown showRight={showRight}>
          <ul>
            <li>
              Insurance premium:{' '}
              <span>
                &pound;
                {price.InsurancePremium && price.InsurancePremium.toFixed(2)}
              </span>
            </li>
            <li>
              Insurance premium tax:{' '}
              <span>&pound;{price.Ipt && price.Ipt.toFixed(2)}</span>
            </li>
            <li>
              Insurance provider: <span>{price.UnderwriterDisplayName}</span>
            </li>
            {price.AdminFee > 0 && (
              <li>
                {' '}
                {price.AffiliateAdminFee > 0
                  ? 'Broker admin '
                  : 'Broker '}fee:{' '}
                <span>&pound;{price.AdminFee.toFixed(2)}</span>
              </li>
            )}
            {price.AffiliateAdminFee > 0 && (
              <li>
                {affiliate} broker fee:{' '}
                <span>&pound;{price.AffiliateAdminFee.toFixed(2)}</span>
              </li>
            )}
            {price.campaign &&
            price.campaign.Discount &&
            Math.abs(price.campaign.Discount) > 0 ? (
              <li>
                Discount applied:{' '}
                <span className='discountItem'>
                  - &pound;{Math.abs(price.campaign.Discount).toFixed(2)}
                </span>
              </li>
            ) : null}
          </ul>
          <Diamond showRight={showRight} />
        </PriceBreakdown>
      )}
    </Wrapper>
  )
}

PaymentBreakdownIcon.propTypes = {
  price: PropTypes.shape({
    InsurancePremium: PropTypes.number.isRequired,
    Ipt: PropTypes.number.isRequired,
    AdminFee: PropTypes.number.isRequired,
  }).isRequired,
  affiliate: PropTypes.string,
  showRight: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ ui }) => {
  return {
    visibleBreakdown: ui.VisiblePriceBreakdown,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        showBreakdownIcon,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentBreakdownIcon)
