import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from '../../components/common/Button'
import { H1 } from '../../components/common/H1'
import { P } from '../../components/common/P'
import MobileBannerImage from '../../images/banner-car-handover.jpg'
import { BaseInput } from '../../components/common/Input/BaseInput'

export const Headline = styled(H1)`
  font-family: 'adobe-clean', Arial, san-serif;
  font-weight: bold;
  font-size: 32px;
  max-width: none;
  color: ${(props) => props.theme.headlineColour};
  padding: 0;
  font-weight: 700;
  margin: 0;

  @media (max-width: 320px) {
    font-size: 18px;
  }
`

export const MobileBanner = styled.div`
  background-image: url(${MobileBannerImage});
  background-size: cover;
  background-position: bottom;
  display: none;
  padding-bottom: 40%;
  height: 0;

  @media (max-width: 1024px) {
    display: block;
  }
`

export const P2 = styled(P)`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  color: #1f1f1f;
  margin-top: 30px;
  margin-bottom: 17px;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
`

export const P3 = styled(P)`
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #1f1f1f;

  @media (max-width: 1024px) {
    padding: 0;
  }

  a {
    color: #1f1f1f;
  }
`

export const HomePageContainer = styled.div`
  text-align: left;
  padding: 0 48px 24px 0;

  @media (max-width: 768px) {
    padding: 0 8px 24px 8px;
  }
`

export const RegWrapper = styled.div`
  background-color: #1f1f1f
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: auto;
  margin-bottom: 24px;
  display: inline-block;
`

export const RegInput = styled(BaseInput)`
  font-size: 24px;
  color: #1f1f1f;
  letter-spacing: 1px;
  padding:0 50px;
  padding-left:60px
  margin: 0 auto;
  font-weight: 500;
  display: block;
  width: 100%;
  background: #fff;
  
  border-radius: 0px;
  height: 50px;
  box-sizing: border-box;
  -webkit-appearance: none;
`

export const GBIcon = styled.div`
  text-align: center;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 46px;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
  background-color: #efefef;
  color: #1f1f1f;
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  border-right: none;
  box-sizing: border-box;
  -webkit-appearance: none;
`

export const VehicleBuilderText = styled.a`
  color: #4a4a4a;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0;
`

export const VehicleBuilderTextContainer = styled.div`
  margin-bottom: 48px;
`

export const TeslaQuoteNextButton = styled(Button)`
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 22px !important;
  font-weight: 400;
  width: 100%;
  max-width: 395px;
  margin: 15px 0 0;

  &.disabled {
    background: #777;
    cursor: not-allowed;
    pointer-events: none;
  }

  :hover {
    background-color: ${(props) => props.theme.button.hoverColour};
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`

export const RegValidation = styled.p`
  position: absolute;
  top: 97%;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 14px;
  color: #ff0508;
  font-family: 'freight-sans-pro', sans-serif !important;
  font-weight: 600;
`

export const IconWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direaction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: auto;
  margin-top: 20px;
`

export const TopIconWrapper = styled.div`
  display: flex;
  max-width: 360px;
  flex-direction: column;

  & > div:last-child {
    margin-bottom: 0;
  }
`

export const DurationIcon = styled.div`
  margin: 4px;
  flex: 1;
  display: inline-block;
  border: 2px solid #1f1f1f;
  border-radius: 0;
  padding: 10px 10px 10px 54px;
  cursor: pointer;
  font-weight: 500;
  background-color: #fff;
  &:hover {
    background-color: #58aae0;
    color: #ffffff;
  }

  &.Active {
    background-color: #58aae0;
    color: #ffffff;

    .check {
      display: inline-block;
    }
  }
`
export const SmallDurationIcon = styled.div`
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  flex: 1 0 50px;
  color: #1f1f1f;
  background-color: #fff;
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: bold;
  &.Active {
    background-color: #58aae0;
    color: #1f1f1f;
    border: 4px solid #1f1f1f;
  }

  @media (max-width: 440px) {
    margin: 8px;
    flex: 1 0 28px;
  }
`

export const GreyBackground = styled.div`
  background-color: #d4d4d4;
  padding: 24px;
`
export const MoreDaysOverview = styled.div`
  max-width: 340px;
  height: 40px;
  flex: 1 0 340px;
  background-color: #58aae0;
  color: #ffffff;
  border: 4px solid #1f1f1f;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 440px) {
    width: 220px;
    height: 28px;
    flex: 0 0 220px;
    margin: 2px;
  }
`

export const DayDropdown = styled.select`
  width: 100%;
  margin: 5px;
  border: 2px solid #1f1f1f;
  border-radius: 0px;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #e2e2e2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #58aae0;
  }

  &::-webkit-scrollbar-track {
    background-color: #e2e2e2;
  }

  &:focus {
    outline: none;
  }
`

export const DayDropdownOption = styled.option`
  background: #ffffff;
  color: #808080;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #808080;
  height: 21px;
  padding: 3px 0 0 0;

  &:hover {
    background: #efefef;
    color: #1f1f1f;
    font-weight: 600;
  }
`

export const Chevron = styled(FontAwesomeIcon)`
  float: right;
  margin-top: 3px;
  transform: rotateZ(0deg);
  transition: transform 0.8s;

  &.Active {
    transform: rotateZ(90deg);
  }
`

export const Check = styled(FontAwesomeIcon)`
  display: none;
  margin-right: 5px;
`

export const ShowMoreButton = styled.a`
  font-weight: 600;
  cursor: pointer;
  color: #1f1f1f;

  span {
    text-decoration: underline;
    margin-right: 8px;
    display: inline-block;
  }

  &::after {
    content: '';
    content: ${(props) => (props.more ? '"-"' : '"+"')};
    display: inline-block;
    font-size: 1.3rem;
    position: relative;
    top: 2px;
  }

  &:hover,
  &:active,
  &:visited {
    color: #1f1f1f;
  }
`

export const AllDurationsWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  margin-top: 16px;
`

export const ButtonSpacer = styled.div`
  padding-top: 24px;
`
export const GetQuoteWrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;

  p {
    margin-top: 0px;
    margin-bottom: 48px;
  }
`
