import React from 'react'
import PropTypes from 'prop-types'
import { BaseSelect } from './BaseSelect'
import { SelectWrapper } from './SelectWrapper'

export const Select = ({
  id,
  value,
  options,
  error,
  onChange,
  onBlur,
  placeholder,
  name,
  style,
  myRef,
  tinyGap,
}) => {
  const className =
    value === '' || value === 'Uninitialised' || value === null
      ? 'placeholder'
      : ''
  return (
    <SelectWrapper error={error} tinyGap={tinyGap} style={style}>
      <BaseSelect
        ref={myRef}
        id={id}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        style={style}
        className={className}
      >
        {placeholder && <option value=''>{placeholder}</option>}
        {options.map((option) => (
          <option
            key={option.id}
            value={option.value}
            selected={option.selected}
          >
            {option.description}
          </option>
        ))}
      </BaseSelect>
    </SelectWrapper>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // Using any as we have instances where we are getting numbers / strings for value and ID
      value: PropTypes.any,
      id: PropTypes.any,
      description: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
}
