export const INIT_POLICY_CONFIRMATION =
  'src/PolicyConfirmationPage/INIT_POLICY_CONFIRMATION'
export const REQUEST_POLICY = 'app/PolicyConfirmationPage/REQUEST_POLICY'
export const REQUEST_POLICY_SUCCESS =
  'app/PolicyConfirmationPage/REQUEST_POLICY_SUCCESS'
export const REQUEST_POLICY_FAILURE =
  'app/PolicyConfirmationPage/REQUEST_POLICY_FAILURE'
export const UPDATE_POLICY = 'app/PolicyConfirmationPage/UPDATE_POLICY'
export const UPDATE_POLICY_SUCCESS =
  'app/PolicyConfirmationPage/UPDATE_POLICY_SUCCESS'
export const UPDATE_POLICY_FAILURE =
  'app/PolicyConfirmationPage/UPDATE_POLICY_FAILURE'
export const UPDATE_POLICY_LINK_REL = 'TODO'
export const UPDATE_POLICY_CONTACT_DETAILS =
  'app/PolicyConfirmationPage/UPDATE_POLICY_CONTACT_DETAILS'
export const UPDATE_VALIDATION_POLICY_CONFIRMATION =
  'app/PolicyConfirmationPage/UPDATE_VALIDATION_POLICY_CONFIRMATION'
export const TOGGLE_FORM_IS_VALID_POLICY_CONFIRMATION =
  'app/PolicyConfirmationPage/TOGGLE_FORM_IS_VALID_POLICY_CONFIRMATION'
export const GET_POLICY_LINK_REL = 'TODO'
export const ENABLE_POLICY_HAS_EMAIL_ADDRESS =
  'app/PolicyConfirmationPage/ENABLE_POLICY_HAS_EMAIL_ADDRESS'

export const GET_DOCUMENTS_SUCCESS =
  'app/PolicyConfirmationPage/GET_DOCUMENTS_SUCCESS'

export const GET_ANALYSIS_DATA = 'app/PolicyConfirmationPage/GET_ANALYSIS_DATA'
export const GET_ANALYSIS_DATA_SUCCESS =
  'app/PolicyConfirmationPage/GET_ANALYSIS_DATA_SUCCESS'
export const GET_ANALYSIS_DATA_FAILURE =
  'app/PolicyConfirmationPage/GET_ANALYSIS_DATA_FAILURE'

export const UPDATE_ANALYSIS_DATA =
  'app/PolicyConfirmationPage/UPDATE_ANALYSIS_DATA'
export const UPDATE_ANALYSIS_DATA_SUCCESS =
  'app/PolicyConfirmationPage/UPDATE_ANALYSIS_DATA_SUCCESS'
export const UPDATE_ANALYSIS_DATA_FAILURE =
  'app/PolicyConfirmationPage/UPDATE_ANALYSIS_DATA_FAILURE'

export const UPDATE_REASON_FOR_TEMPCOVER =
  'src/PolicyConfirmationPage/UPDATE_REASON_FOR_TEMPCOVER'
export const UPDATE_REASON_FOR_TEMPCOVER_SUCCESS =
  'src/PolicyConfirmationPage/UPDATE_REASON_FOR_TEMPCOVER_SUCCESS'
export const UPDATE_REASON_FOR_TEMPCOVER_FAILURE =
  'src/PolicyConfirmationPage/UPDATE_REASON_FOR_TEMPCOVER_FAILURE'
export const UPDATE_REASON_FOR_TEMPCOVER_REL =
  'UpdateReasonForTempcoverPurchase'

export const REASON_FOR_TEMPCOVER_OPTIONS_CAR = [
  {
    id: 1,
    value: '28',
    description: 'Buying or selling a car',
  },
  {
    id: 2,
    value: '2',
    description: 'Test driving a car',
  },
  {
    id: 3,
    value: '5',
    description: 'Shared driving on holiday',
  },

  {
    id: 4,
    value: '15',
    description: 'Vehicle in for MOT/repair/service',
  },
  {
    id: 5,
    value: '6',
    description: 'Temporary business use',
  },
  {
    id: 6,
    value: '4',
    description: "Borrowing someone's vehicle",
  },
]

export const REASON_FOR_TEMPCOVER_OPTIONS_VAN = [
  {
    id: 1,
    value: '28',
    description: 'Buying or selling a van',
  },
  {
    id: 2,
    value: '2',
    description: 'Test driving a van',
  },
  {
    id: 3,
    value: '4',
    description: "Borrowing somebody else's van",
  },

  {
    id: 4,
    value: '15',
    description: 'Van in for MOT/repair/service',
  },
  {
    id: 5,
    value: '29',
    description: "Working on site using the contractor's van/vehicles",
  },
]

export const hideReasonForCoverAffiliates = ['GU513QH42']
