import config from '../../config'

export const REQUEST_CALLBACK = 'src/AnnualUpsell/REQUEST_CALLBACK'
export const REQUEST_CALLBACK_SUCCESS =
  'src/AnnualUpsell/REQUEST_CALLBACK_SUCCESS'
export const REQUEST_CALLBACK_FAILURE =
  'src/AnnualUpsell/REQUEST_CALLBACK_FAILURE'
export const REQUEST_CALLBACK_REL = 'RequestAnnualCallback'

export const MINIMUM_DURATION_CONFIRMATION_PAGE = 3000

export const CALLBACK_SOURCE = {
  Website: 'Website',
  Email: 'Email',
}

export const REQUEST_CALLBACK_STATUS = {
  WAITING: 0,
  REQUESTING: 1,
  SUCCESS: 2,
  ERROR: 3,
}

export const ANNUAL_CALLBACK_PROVIDER = {
  Confused: {
    Id: 6,
    RedirectUrl: 'https://www.confused.com/car-insurance',
  },
  ConfusedRTP: {
    Id: 7,
    RedirectUrl: config.CONFUSED_QUICK_QUOTE_REDIRECT_URL,
  },
}
