import styled from 'styled-components'
import React from 'react'
import { FormControlWrapperTS } from '../../FormControlWrapperTS'
import { ErrorMessage } from '../ErrorMessage'
import { BaseInputTS } from '../Input/BaseInputTS'

export const UppercaseInputStyled = styled(BaseInputTS)`
  text-transform: uppercase;
  ::placeholder {
    text-transform: none;
  }
`

interface Props {
    disallowSpaces?: boolean,
    error: boolean,
    errorMessage: string,
    id: string,
    name: string,
    onChange: (e: React.ChangeEvent<any>) => void,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    pattern?: string,
    placeholder: string,
    value: string,
}

const UppercaseInput: React.FC<Props> = ({
  disallowSpaces,
  error,
  errorMessage,
  id,
  name,
  onBlur,
  onChange,
  pattern,
  placeholder,
  value,
}) => {
  const onInput = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase()
    if (disallowSpaces) {
      e.target.value = e.target.value.replace(/\s/g, '')
    }
  }

  return (
    <FormControlWrapperTS smallGap>
        {error && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
      <BaseInputTS
        placeholder={placeholder}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        onInput={onInput}
        name={name}
        id={id}
        value={value}
        autoComplete='off'
        autoCorrect='off'
        pattern={pattern}
        />
    </FormControlWrapperTS>
  )
}

export default UppercaseInput
