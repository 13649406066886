import React from 'react'
import PropTypes from 'prop-types'

import { Select } from '../common/Select'
import { useOfVehicleOptions } from './constants'
import { ErrorMessage } from '../common/ErrorMessage'

interface Props {
  onChange :(e: React.ChangeEvent<any>) => void,
  onBlur: React.FocusEventHandler<HTMLSelectElement>,
  value: string,
  error: any,
  errorMessage: string,
  id: string,
  name: string,
}

const UseOfVehicle: React.FC<Props>  = ({
  onChange,
  onBlur,
  value,
  error,
  errorMessage,
  id,
  name,
}) => {
  return (
    <>
      {error && <ErrorMessage id={`${id}Error`}>{errorMessage}</ErrorMessage>}
      <Select
        placeholder='I am using my van for...'
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        options={useOfVehicleOptions}
        name={name}
        value={value}
        error={error}
        style={{}}
        myRef={undefined}
        tinyGap={''}
      />
    </>
  )
}

export default UseOfVehicle

UseOfVehicle.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  error: PropTypes.bool,
}
