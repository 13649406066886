import initialState from '../../../initialState'
import { REQUEST_PRICE_SUCCESS, SET_QUOTE_JOURNEY_POSITION } from '../constants'

function quoteReducer(state = initialState.quote, action) {
  switch (action.type) {
    case REQUEST_PRICE_SUCCESS:
      return {
        ...state,
        Underwriter:
          action.price.UnderwriterTotalPrice &&
          action.price.UnderwriterTotalPrice.Underwriter,
        TotalPrice:
          action.price.UnderwriterTotalPrice &&
          action.price.UnderwriterTotalPrice.TotalPrice,
      }
    case SET_QUOTE_JOURNEY_POSITION:
      return {
        ...state,
        QuoteJourneyPosition: action.journeyPosition,
      }
    default:
      return state
  }
}

export default quoteReducer
