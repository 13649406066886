import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { requestOccupations } from './actions'
import { bindActionCreators } from 'redux'
import { Input } from '../../components/common/Input'
import { ErrorMessage } from '../../components/common/ErrorMessage'
import { FieldLabel } from '../../components/common/FieldLabel'
import { AutoComplete } from '../../components/common/AutoComplete'
import InputTS from '../../components/common/InputTS'



interface Props {
  setFieldValue:(field: string, value: any, shouldValidate?: boolean) => void,
  onChange :(e: React.ChangeEvent<any>) => void,
  onBlur: React.FocusEventHandler<HTMLInputElement>,
  value: {
    Id: number,
    OccupationCode: string,
    OccupationName: string
  },
  error: any,
  errorMessage: string,
  actions:any,
  occupations:any,
  id: string,
  name: string,
}


const Occupation: React.FC<Props> = ({
  setFieldValue,
  onBlur,
  value,
  error,
  actions,
  occupations,
  id,
  name,
}) => {
  const [search, setSearch] = useState(value.OccupationName || '')
  const [editing, setEditing] = useState(false)
  const [selectedOccupation, setSelectedOccupation] = useState(value)

  useEffect(() => {
    if (search && search.length >= 3) {
      actions.requestOccupations(search)
    }
  }, [search])

  useEffect(() => {
    setFieldValue('Occupation', selectedOccupation)
  }, [selectedOccupation])

  const onFocus = () => {
    setEditing(true)
    setSelectedOccupation({
      Id: 0,
      OccupationCode: '',
      OccupationName: '',
    })
  }

  const handleBlur = (e) => {
    setEditing(false)

    if (occupations && occupations.length === 1) {
      handleOccupationSelection(occupations[0])
    }
    //onBlur(e)
  }

  const onOccupationSelect = (event) => {
    setEditing(false)
    const occupation = occupations.find(
      (o) => o.Id === parseInt(event.nativeEvent.target.id)
    )
    
    handleOccupationSelection(occupation)
  }

  const handleOccupationSelection = (occupation) => {
    setSearch(occupation.OccupationName)
    setSelectedOccupation(occupation)
  }

  return (
    <>
      <FieldLabel htmlFor={id}>Occupation</FieldLabel>
      {error && (
        <ErrorMessage id={`${id}Error`}>
          We need to know your occupation
        </ErrorMessage>
      )}
      <InputTS
        placeholder='I am a...'
        type='text'
        id={id}
        name={name}
        value={search}
        onFocus={onFocus}
        onBlur={handleBlur}
        onChange={(e) => setSearch(e.target.value)}
        error={error}
        errorMessage={''}
      />
      {editing && occupations.length > 0 && (
        <>
        
          <AutoComplete
            searchTerm={search}
            onClick={onOccupationSelect}
            id={`${id}List`}
            options={occupations}
            properties={{ id: 'Id', value: 'OccupationName' }}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ occupation }) => {
  return {
    occupations: occupation.SearchResults,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestOccupations,
      },
      dispatch
    ),
  }
}

Occupation.propTypes = {
  occupations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    })
  ),
  //requestOccupations: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Occupation)
