import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Vehicle from '../Vehicle'
import { history } from '../..'
import { isLoading } from '../App/actions'
import { initInvalidRegPage } from './actions'
import LoadingScreen from '../../components/LoadingScreen'
import { Message } from '../../components/Message'
import { setVehicleListener, createUnkownVehicle } from '../Vehicle/actions'
import { handleQueryString } from '../../services/handlers'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { IMPOUNDED_VALUE } from '../HomePage/constants'
import config from '../../config'

export class InvalidRegPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  componentDidMount = () => {
    if (!window.isHoldingPageOn) {
      this.props.actions.initInvalidRegPage()
    }
  }

  handleConfirm() {
    const { Duration, DurationType, StartTime, ReasonForCover } =
      handleQueryString()
    let url = `/quote?registration=${this.props.vehicle.searched.RegistrationNumber}&start=${StartTime}&duration=${Duration}+${DurationType}`
    if (config.IMPOUNDED_ENABLED) {
      url += `&reason=${ReasonForCover}`
    }
    this.props.actions.isLoading(true)
    if (this.props.vehicle.searched.VehicleFound) {
      this.props.actions.setVehicleListener(this.props.vehicle.searched)
      history.replace(url)
    } else {
      if (this.props.vehicle.searched.Make !== null)
        url = url.concat(`&make=${this.props.vehicle.searched.Make}`)
      if (this.props.vehicle.searched.Model !== null)
        url = url.concat(`&model=${this.props.vehicle.searched.Model}`)
      if (this.props.vehicle.searched.Abi !== null)
        url = url.concat(`&variant=${this.props.vehicle.searched.Abi}`)
      if (this.props.vehicle.searched.GrossWeight !== null)
        url = url.concat(`&weight=${this.props.vehicle.searched.GrossWeight}`)
      if (this.props.vehicle.searched.YearOfManufacture !== null)
        url = url.concat(
          `&year=${this.props.vehicle.searched.YearOfManufacture}`
        )
      if (ReasonForCover === IMPOUNDED_VALUE) {
        this.props.vehicle.Impounded = true
        this.props.actions.setVehicleListener(this.props.vehicle)
      }
      this.props.actions.createUnkownVehicle(url)
    }
  }
  render() {
    return (
      <>
        {this.props.loading ? (
          <LoadingScreen />
        ) : (
          <ConfusedWrapper>
            <br />
            <Message
              heading='Sorry!'
              content='There was an issue with your registration number. Check below and try again.'
              type='info'
            />
            <Vehicle handleConfirm={this.handleConfirm} />
          </ConfusedWrapper>
        )}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    loading: state.isLoading,
  }
}
export const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        isLoading,
        initInvalidRegPage,
        setVehicleListener,
        createUnkownVehicle,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchtoProps)(InvalidRegPage)
