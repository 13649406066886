import styled from 'styled-components'

export const H4 = styled.h4`
  font-size: ${(props) => props.theme.generalFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  color: #1f1f1f;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 48px;
`


