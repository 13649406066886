import React from 'react'
import Arrow from '../../../../components/common/Arrow'
import {
  ANNUAL_CALLBACK_PROVIDER,
  CALLBACK_SOURCE,
  REQUEST_CALLBACK_STATUS,
} from '../../constants'
import {
  RTPBanner,
  BannerButton,
  BannerH2Secondary,
  BannerTextTertiary,
  ButtonTextPrimary,
  ConfusedLogo,
  PricingContainer,
  PriceContainer,
  ImageContainer,
  Image,
  PriceResult,
  PriceText,
} from '../../styles'

const ConfusedRTPBanner = ({
  callbackRequest,
  requestCallback,
  pricing,
  registrationNumber,
}) => {
  return (
    <RTPBanner id='ConfusedUpsellRTPBanner'>
      <BannerH2Secondary>
        Top 3 annual car insurance prices for {registrationNumber} from{' '}
        <ConfusedLogo alt='Confused.com' />
      </BannerH2Secondary>
      <PricingContainer>
        {pricing.Results.map((p) => (
          <PriceContainer key={p.BrandId}>
            <ImageContainer>
              <div>
                <Image
                  src={`https://partnerimages.confused.com/${p.BrandId}`}
                  alt={p.BrandName}
                />
              </div>
            </ImageContainer>
            <PriceResult>
              <PriceText>
                Insurer: <strong>{p.BrandName}</strong>
                <br />
                Annual: <strong>£{p.AnnualPremium}</strong>
              </PriceText>
            </PriceResult>
          </PriceContainer>
        ))}
      </PricingContainer>
      <BannerTextTertiary>
        We've created a personalised Quick Quote for you for the car you're
        insuring to make it easier for you to stay insured. It'll be saved to
        your Confused.com account so you can come back to it later.
      </BannerTextTertiary>
      <BannerButton
        id='ConfusedUpsellRTPBannerButton'
        onClick={() =>
          requestCallback({
            linkType: CALLBACK_SOURCE.Website,
            provider: ANNUAL_CALLBACK_PROVIDER.ConfusedRTP,
            urlReplacement: [{ key: 'ActivityId', value: pricing.ActivityID }],
          })
        }
        disabled={callbackRequest.status === REQUEST_CALLBACK_STATUS.REQUESTING}
      >
        <div>
          <ButtonTextPrimary>See all prices</ButtonTextPrimary>
        </div>
        <Arrow right />
      </BannerButton>
    </RTPBanner>
  )
}
export default ConfusedRTPBanner
