export const getCookie = (name) => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    // eslint-disable-next-line
    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

export const setCookie = (name, value, days) => {
  const date = new Date()
  date.setDate(date.getDate() + days)
  const expires = '; expires=' + date.toGMTString()

  document.cookie = name + '=' + value + expires + '; path=/'
}
