import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { requestVehicle } from '../Vehicle/actions'
import { Button } from '../../components/common/Button'
import {
  VehicleButtonWrapper,
  VehicleParagraph,
  VehicleBlockWrapper,
} from './styles'
import { RegWrapper, RegInput, GBIcon, P2 } from '../HomePage/styles'
import VehicleBuilder from '../../components/VehicleBuilder'
import { ErrorMessage } from '../../components/common/ErrorMessage'
import {
  ALLOWED_VEHICLE_TYPES,
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  PRIVATE_CAR,
} from './constants'
import { VEHICLE_OPTIONS } from '../../services/constants'
import {
  VEHICLE_REG_VALIDATION,
  INVALID_REG_VEHICLE_VALIDATION,
} from '../../services/validation'
import ValidationSummary from '../../components/ValidationSummary/ValidationSummary.component'

const createValidationSchema = (vehicleFound) => {
  if (vehicleFound) {
    Yup.object().shape({
      Registration: VEHICLE_REG_VALIDATION,
    })
  } else {
    return INVALID_REG_VEHICLE_VALIDATION
  }
}

const Vehicle = ({ vehicle, actions, handleConfirm }) => {
  const [validationScheme, setValidationSchema] = useState(() =>
    createValidationSchema(vehicle.searched.VehicleFound)
  )

  useEffect(() => {
    setValidationSchema(createValidationSchema(vehicle.searched.VehicleFound))
  }, [vehicle])

  const handleSearch = (e, registration) => {
    e.preventDefault()
    actions.requestVehicle(registration)
  }

  const onRegInput = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase()
    e.target.value = e.target.value.replace(/\s/g, '')
  }

  const {
    RegistrationNumber,
    VehicleType,
    Make,
    Model,
    Abi,
    GrossWeight,
    YearOfManufacture,
  } = vehicle.searched

  return (
    <Formik
      initialValues={{
        Registration: RegistrationNumber,
        VehicleType: VehicleType,
        VehicleMake: Make,
        VehicleModel: Model,
        VehicleVariant: Abi,
        VehicleWeight: GrossWeight,
        VehicleDateOfManufacture: YearOfManufacture,
      }}
      validationSchema={validationScheme}
      onSubmit={handleConfirm}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldTouched,
        setFieldValue,
        setValues,
        submitCount,
        touched,
        values,
      }) => {
        const errorCount = Object.keys(errors || {}).length
        /* eslint-disable react-hooks/rules-of-hooks */
        const [globalSubmitCount, setGlobalSubmitCount] = useState(0)
        if (
          errorCount > 0 &&
          submitCount > 0 &&
          globalSubmitCount !== submitCount
        ) {
          setFieldTouched('VehicleType', !!values.Registration)
          setFieldTouched(
            'VehicleMake',
            (values.VehicleType === COMMERCIAL_VEHICLE &&
              values.VehicleWeight &&
              values.VehicleWeight <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
              values.VehicleType === PRIVATE_CAR
          )
          setFieldTouched('VehicleModel', !!values.VehicleMake)
          setFieldTouched('VehicleVariant', !!values.VehicleModel)
          setFieldTouched(
            'VehicleWeight',
            values.VehicleType === COMMERCIAL_VEHICLE
          )
          setFieldTouched(
            'VehicleDateOfManufacture',
            values.VehicleType === COMMERCIAL_VEHICLE
          )
          setGlobalSubmitCount(submitCount)
        }
        return (
          <Form>
            <ValidationSummary
              showSummary={errorCount > 0 && submitCount > 0}
              submitCount={submitCount}
            />
            <p>
              To get started, tell us the reg of the vehicle you want cover for
            </p>
            <VehicleBlockWrapper>
              {touched.Registration && errors.Registration && (
                <ErrorMessage id='RegistrationError'>
                  {errors.Registration}
                </ErrorMessage>
              )}
              <RegWrapper className='reg-wrapper'>
                <GBIcon className='gb-icon'>GB</GBIcon>
                <RegInput
                  id='Registration'
                  autoComplete='off'
                  name='Registration'
                  type='text'
                  autoCorrect='off'
                  value={values.Registration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={onRegInput}
                  tabIndex={1}
                />
              </RegWrapper>
              <Button
                id='SearchVehicle'
                onClick={(e) => handleSearch(e, values.Registration)}
                disabled={Boolean(errors.Registration)}
              >
                Search
              </Button>
            </VehicleBlockWrapper>
            {vehicle.searched.VehicleFound &&
              ALLOWED_VEHICLE_TYPES.includes(vehicle.searched.VehicleType) && (
                <>
                  <VehicleParagraph>
                    Vehicle:&nbsp;
                    {vehicle.searched.Make} {vehicle.searched.Model}
                  </VehicleParagraph>
                </>
              )}
            {vehicle.error === 'NotFound' && (
              <>
                <P2>
                  Sorry we've not found that vehicle, select from the options
                  below
                </P2>
                <VehicleBuilder
                  searchedVehicle={vehicle.searched}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  values={values}
                  errors={errors}
                  touched={touched}
                  vehicleOptions={{
                    vehicleType: VEHICLE_OPTIONS,
                    makes: vehicle.makes,
                    models: vehicle.models,
                    variants: vehicle.variants,
                  }}
                  handleSelect={handleChange}
                  onBlur={handleBlur}
                />
              </>
            )}

            <VehicleButtonWrapper>
              <Button
                id='SetVehicle'
                type='submit'
                onMouseDown={(e) => {
                  e.preventDefault()
                }}
              >
                Set vehicle
              </Button>
            </VehicleButtonWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}

export const mapStateToProps = (state) => {
  return {
    vehicle: state.vehicle,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestVehicle,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle)
