import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { QuestionInfo } from '../../containers/DriverDetails'
import { ErrorMessage } from '../common/ErrorMessage'
import { FieldLabel } from '../common/FieldLabel'
import { IndentedLine } from '../common/IndentedLine'
import { RadioButton } from '../common/RadioButton'
import { Select } from '../common/Select'
import { HOURS, MINUTES, NAMED_DAYS } from './constants'
import {
  PolicyStartTimeWrapper,
  TimeSeparator,
  SplitDateContainer,
} from './SplitTimePolicyStartTimePicker.styles'


interface Dates {
  id:number,
  value:any,
  description:string
}

const createDates = (date, days) => {
  let dates : Dates[] = []
  for (let index = 0; index <= days; index++) {
    const newDate = date.clone().add(index, 'days')
    const desc = newDate.format('ddd, Do MMMM YYYY')
    const addition = NAMED_DAYS[index]

    dates.push({
      id: index,
      value: newDate.format('YYYY-MM-DD'),
      description: addition ? `${desc} (${addition})` : desc,
    })
  }
  return dates
}

const SplitPolicyStartTimePicker = ({
  id,
  name,
  value,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}) => {
  const [state, setState] = useState(value)

  useEffect(() => {
    const { date, hour, minute } = state

    const manuallyEdited = Boolean(date || hour || minute)

    setFieldValue('PolicyStartDate', {
      date: manuallyEdited ? date : '',
      hour: manuallyEdited ? hour : '',
      minute: manuallyEdited ? minute : '',
      manuallyEdited: manuallyEdited,
    })
  }, [state])

  const dates = createDates(moment(), 30)
  const dateInPastError =
    Boolean(errors) && (typeof errors === 'string' || errors instanceof String)

  const updateDate = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleBlur = (e) => {
    setFieldTouched(`PolicyStartDate.${e.target.name}`)
  }

  return (
    <SplitDateContainer>
      {(Boolean(errors.date && touched.date) ||
        Boolean(errors.hour && touched.hour) ||
        Boolean(errors.minute && touched.minute)) && (
        <ErrorMessage>
          {errors.date || errors.hour || errors.minute}
        </ErrorMessage>
      )}

      {dateInPastError && <ErrorMessage>{errors}</ErrorMessage>}
      <RadioButton
        id={`${id}_Immediateley`}
        name={name}
        label='Immediately'
        value='1'
        checked={!Boolean(value.manuallyEdited)}
        error={Boolean(errors.date && touched.date)}
        onChange={(e) => {
          if (e.target.checked) {
            setState({
              date: '',
              hour: '',
              minute: '',
            })
          }
        }}
      />
      <Select
        id={`${id}_Date`}
        placeholder='Select day...'
        name='date'
        value={state.date}
        options={dates}
        onChange={updateDate}
        onBlur={handleBlur}
        error={Boolean(errors.date && touched.date)}
        style={{}}
        tinyGap={0} 
        myRef={undefined} />
        
      {state.date && (
        <IndentedLine>
          <FieldLabel>What time do you need the cover to start?</FieldLabel>
          <PolicyStartTimeWrapper>
            <div>
              <QuestionInfo>Hour</QuestionInfo>
              <Select
                id={`${id}_Hour`}
                placeholder=' '
                name='hour'
                value={state.hour}
                options={HOURS}
                onChange={updateDate}
                onBlur={handleBlur}
                error={Boolean(touched.hour && errors.hour) || dateInPastError}
                style={{ marginBottom: '0px' }}
                tinyGap={0} 
                myRef={undefined}              />
            </div>
            <div>
              <TimeSeparator>:</TimeSeparator>
            </div>
            <div>
              <QuestionInfo>Minute</QuestionInfo>
              <Select
                id={`${id}_Minute`}
                placeholder=' '
                name='minute'
                value={state.minute}
                options={MINUTES}
                onChange={updateDate}
                onBlur={handleBlur}
                error={Boolean(touched.minute && errors.minute) || dateInPastError}
                style={{ marginBottom: '0px' }}
                tinyGap={0} 
                myRef={undefined}              />
            </div>
          </PolicyStartTimeWrapper>
        </IndentedLine>
      )}
    </SplitDateContainer>
  )
}

export default SplitPolicyStartTimePicker
