import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Grid } from 'unsemantic'
import { ErrorContainer } from '../../components/ErrorContainer'
import { Message } from '../../components/Message'
import { ErrorIcon } from '../../components/Message/ErrorIcon'
import { MessageContent } from '../../components/Message/MessageContent.style'
import { MessageHeader } from '../../components/Message/MessageHeader.style'
import { Button } from '../../components/common/Button'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { ClearFix } from '../../components/common/ClearFix'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { H2 } from '../../components/common/H2'
import { ROUTES } from '../../services/constants'
import { selectPrice, updateStep } from '../App/actions'
import { sendEmailQuote } from '../EmailQuoteModal/actions'
import { showBreakdownIcon } from '../PaymentBreakdownIcon/actions'
import {
  isButtonDisabled,
  updateQuote,
  updateRiskData,
  updateValidation,
} from '../RiskDataCollectionPage/actions'
import PolicyDetails from '../../components/PolicyDetails'
import DriverCovered from '../../components/DriverCovered'

import './driving-licence.css'

import ConfusedQuickQuoteStatement from '../../components/ConfusedQuickQuoteStatement/ConfusedQuickQuoteStatement.component'
import { ConfusedBackButton } from '../../components/common/ConfusedBackButton'
import { SectionHeading } from '../../components/common/SectionHeading'
import { LargeSeperator } from '../../components/common/Seperator'
import { ButtonSpacer } from '../HomePage/styles'

import { NewPayableSection } from './PayableSection'
import  UnderwriterPriceBadge from '../../components/UnderwriterPriceBadge'


export const AntiFraudSmallPrint = styled.p`
  color: #1f1f1f;
  font-size: 16px;
  margin-top: 0px;
`

export const ContinueButton = styled(Button)`
  width: 100%;
`

export const SmallPrintWrapper = styled.div`
  margin-top: 48px;
`

const DrivingLicence = ({
  actions,
  address,
  confusedQuickQuote,
  driverLicenceButtonDisabled,
  history,
  location,
  myLicence,
  price,
  queryString,
  quote,
  riskData,
  vehicle,
}) => {
  const [underwriterChanged, setUnderwriterChanged] = useState(false)
  const [hasQuotes, setHasQuotes] = useState(true)

  useEffect(() => {
    actions.updateStep(2)
    actions.showBreakdownIcon(null)
    if (
      (quote.Underwriter &&
        quote.Underwriter !== 'Uninitialised' &&
        price.Underwriter &&
        quote.Underwriter !== price.Underwriter) ||
      price.UnderwriterChanged
    ) {
      setUnderwriterChanged(true)
    }
    if (window.fomo && hasQuotes) {
      window.fomo.clearLocalStorage()
      window.fomo.trigger('start', true)
    }
  }, [])

  useEffect(() => {
    setHasQuotes(
      price.TotalPrice > 0 ||
        (price.Prices &&
          price.Prices.filter((p) => p.TotalPrice > 0).length > 0)
    )
  }, [price])

  const driverDetails = () => {
    history.push(`${ROUTES.QUOTE_DRIVER_DETAILS}${queryString}`)
  }

  const handleContinueClick = (price = null) => {
    actions.isButtonDisabled({
      buttonName: 'driverLicence',
      disabled: true,
    })
    if (!price) {
      actions.updateQuote({
        partialValidation: true,
        confirmRiskData: true,
        getPrice: false,
      })
    } else {
      actions.selectPrice(price)
    }
  }

  let fraudFailure
  if (location.state) {
    fraudFailure = location.state.fraudFailure
  }

  return (
    <>
      <ConfusedWrapper>
        <ConfusedBackButton onClick={driverDetails}>
          Driver details
        </ConfusedBackButton>
        {myLicence.CheckFailed &&
          myLicence.FailReasons.indexOf('LicenceHeld') >= 0 && (
            <Message
              heading='Your licence held duration has been updated'
              content='Based on the information from your driving licence, we have updated the duration you have held your licence to match the details held by the DVLA. By proceeding with this quote you acknowledge and agree that this information is correct.'
              type='info'
            />
          )}

        {underwriterChanged && (
          <Message
            heading='The quote has been updated'
            content='Please continue and ensure you meet the criteria outlined on the next page'
            type='info'
          />
        )}
        {price.UnderwriterChangedByCue && (
          <Message
            heading='The quote has been updated'
            content='Based on the information you have provided and/or information we have found, a new insurer has been selected, and your price has been updated. Please ensure you have checked the price and are happy to proceed before continuing.'
            type='info'
          />
        )}
        {fraudFailure && (
          <ErrorContainer>
            <Grid desktop='20' tablet='20' mobile='20'>
              <ErrorIcon className='fa fa-exclamation-triangle'></ErrorIcon>
            </Grid>
            <Grid desktop='80' tablet='80' mobile='80'>
              <MessageHeader>
                There has been an issue with your quote.
              </MessageHeader>
              <MessageContent>
                Please ensure all data is valid and try again.
              </MessageContent>
            </Grid>
            <ClearFix />
          </ErrorContainer>
        )}
        {!vehicle.selected.RegistrationNumber && (
          <ErrorContainer>
            <Grid desktop='80' tablet='80' mobile='80'>
              <MessageContent>
                We are unable to proceed any further without a registration.
                Please enter a valid reg to continue.
              </MessageContent>
            </Grid>
            <ClearFix />
          </ErrorContainer>
        )}
        {!hasQuotes && (
          <ErrorContainer>
            <Grid desktop='80' tablet='80' mobile='80'>
              <MessageHeader>
                We have been unable to offer you a quote at this time.
              </MessageHeader>
              <MessageContent>
                We are regularly reviewing our products so we may be able to
                cover you in the future.
              </MessageContent>
            </Grid>
            <ClearFix />
          </ErrorContainer>
        )}

        <SectionHeading>Your Quote</SectionHeading>
        <UnderwriterPriceBadge price={price}></UnderwriterPriceBadge>
        {hasQuotes && <NewPayableSection price={price} />}
        <H2 gap='medium'>Policy details:</H2>
        <PolicyDetails 
          make={vehicle.selected.Make}
          model={vehicle.selected.Model}
          grossWeight={vehicle.selected.GrossWeight}
          yearOfManufacture={vehicle.selected.YearOfManufacture}
          registrationNumber={vehicle.selected.RegistrationNumber}
          vehicleValueDescription={vehicle.selected.vehicleValue.description.replace('to', '-')}
          duration={riskData.Duration}
          durationType={riskData.DurationType}
          manuallyEdited={riskData.PolicyStartDate.manuallyEdited}
          policyStartDate={riskData.PolicyStartDate.value} />
        {/* <PolicyDetails vehicle={vehicle} riskData={riskData} /> */}
        <H2 gap='medium'>Driver covered:</H2>
        <DriverCovered 
        addressLineOne={address.AddressLineOne}
        addressLineTwo={address.AddressLineTwo}
        addressLineThree={address.AddressLineThree}
        addressLineFour={address.AddressLineFour}
        postcode={address.Postcode}
        selectedAddress={address.SelectedAddress}
        licenceHeldDuration={riskData.LicenceHeldDuration}
        forename={riskData.Forename}
        surname={riskData.Surname}
        dateOfBirth={riskData.DateOfBirth} />

        {/* <DriverCovered riskData={riskData} address={address} /> */}

        <LargeSeperator />
        {confusedQuickQuote.OptIn && <ConfusedQuickQuoteStatement />}
        {price.Underwriter && vehicle.selected.RegistrationNumber && (
          <SmallPrintWrapper>
            <>
              <AntiFraudSmallPrint>
                To assess your risk data, verify your identity &amp; to help
                prevent &amp; detect fraud, we may check your details with &amp;
                pass your details to, credit reference &amp; fraud prevention
                agencies &amp; databases. If false or inaccurate information is
                provided &amp; fraud is identified, your details may be passed
                to these agencies to aid legal investigation &amp; to prevent
                future fraud. By proceeding, you agree you have taken care not
                to make any misrepresentation of the information provided.
              </AntiFraudSmallPrint>
              <ButtonSpacer></ButtonSpacer>
            </>
            <ContinueButton
              id='TeslaSubmitPricePageNextButton'
              onClick={handleContinueClick}
              disabled={driverLicenceButtonDisabled}
              data-email={riskData.EmailAddress}
              className={
                myLicence.DisabledBySetting
                  ? `uw_${price.Underwriter}`
                  : myLicence.Enabled
                  ? `ab_mylicence_on uw_${price.Underwriter}`
                  : `ab_mylicence_off uw_${price.Underwriter}`
              }
            >
              {driverLicenceButtonDisabled ? 'Please wait' : 'Continue'}
              <ButtonSpinner disabled={driverLicenceButtonDisabled} />
            </ContinueButton>
          </SmallPrintWrapper>
        )}
        <ConfusedBackButton onClick={driverDetails}>
          Driver details
        </ConfusedBackButton>
      </ConfusedWrapper>
    </>
  )
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    validation: state.validation.riskDataCollectionPage,
    address: state.address,
    price: state.price,
    vehicle: state.vehicle,
    location: state.router.location,
    quoteId: state.riskData.QuoteId,
    queryString: state.queryString,
    affiliate: state.affiliate,
    driverLicenceButtonDisabled:
      state.formButtons.driverLicence.disabled || state.price.isUpdating,
    quote: state.quote,
    myLicence: state.myLicence,
    confusedQuickQuote: state.confusedQuickQuote,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateQuote,
        updateRiskData,
        updateValidation,
        updateStep,
        sendEmailQuote,
        isButtonDisabled,
        selectPrice,
        showBreakdownIcon,
      },
      dispatch
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrivingLicence)
)
