import styled from 'styled-components'

export const FormControlWrapper = styled.div`
  position: relative;
  max-width: 360px;
  margin-bottom: ${(props) => (props.smallGap ? '24px;' : '48px;')}   

  input:before {
    content: '\f00c';
  }
`
