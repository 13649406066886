const config = {
  BASE_API_URL: process.env.REACT_APP_API_URL,
  CONFUSED_API_URL: process.env.REACT_APP_CONFUSED_API_URL,
  SITE_URL: process.env.REACT_APP_SITE_URL,
  WEB_URL: process.env.REACT_APP_WEB_URL,
  PAYPAL_CLIENT_KEY: process.env.REACT_APP_PAYPAL_CLIENT_KEY,
  TEMPCOVER_SITE: process.env.REACT_APP_TEMPCOVER_SITE,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  GOOGLE_EXPERIMENT_ID: process.env.REACT_APP_GOOGLE_EXPERIMENT_ID,
  ReCAPTCHA_KEY: process.env.REACT_APP_ReCAPTCHA_KEY,
  APPLICATION_ID: 1,
  SESSION_EXPIRY_MINUTES:
    process.env.REACT_APP_SESSION_COOKIE_EXPIRY_MINUTES || 30,
  IMPOUNDED_ENABLED: `${process.env.REACT_APP_IMPOUNDED_ENABLED}` === 'true',
  WORDPRESS_CONTENT_URL: process.env.REACT_APP_WORDPRESS_CONTENT_URL,
  CONFUSED_GA_SESSION_COOKIE_NAME:
    process.env.REACT_APP_CONFUSED_GA_SESSION_COOKIE_NAME,
  CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION,
  CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS,
  CONFUSED_QUICK_QUOTE_RETRY_DELAY:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_RETRY_DELAY,
  CONFUSED_QUICK_QUOTE_REDIRECT_URL:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_REDIRECT_URL,
  CONFUSED_CRO_EXPERIMENT_ID: process.env.REACT_APP_CONFUSED_CRO_EXPERIMENT_ID,
  MOCK_HEADER_CONFUSED_EXTERNAL_ACCOUNT: `${process.env.REACT_APP_MOCK_HEADER_CONFUSED_EXTERNAL_ACCOUNT}` === 'true',
  CONFUSED_ACCOUNT_AUTHORITY: process.env.REACT_APP_CONFUSED_ACCOUNT_AUTHORITY,
  CONFUSED_ACCOUNT_CLIENT_ID: process.env.REACT_APP_CONFUSED_ACCOUNT_CLIENT_ID,
  CONFUSED_ACCONT_REDIRECT_URI: process.env.REACT_APP_CONFUSED_ACCOUNT_REDIRECT_URI,
  CONFUSED_ACCOUNT_CLIENT_SECRET: process.env.REACT_APP_CONFUSED_ACCOUNT_CLIENT_SECRET,
}

export default config
