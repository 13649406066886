import styled from 'styled-components'

export const BaseButton = styled.button`
  background: ${(props) =>
    props.disabled
      ? props.theme.button.disabledBackground
      : props.theme.button.backgroundColour};
  color: ${(props) =>
    props.disabled
      ? props.theme.button.disabledFont
      : props.theme.button.textColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-family: 'Poppins', sans-serif;
  font-size: ${(props) => props.theme.button.fontSize};
  text-transform: ${(props) => props.theme.button.textTransform};
  text-align: ${(props) => props.theme.button.textAlign};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  :hover {
    filter: ${(props) => (props.disabled ? null : 'brightness(80%)')};
  }
  padding: 8px 24px;
  margin-bottom: 24px;
  font-weight: 600;
  text-decoration: none;
  text-align: left;
  display: flex;
  align-items: center;
`
