import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  REQUEST_ADDRESS,
  REQUEST_ADDRESS_SUCCESS,
  REQUEST_ADDRESS_FAILED,
  // SELECT_ADDRESS,
} from './constants'
import addressApi from '../../api/addressApi'
import {
  requestAddressSuccess,
  requestAddressFailed,
  requestAddressByKeySuccess,
} from './actions'
import {
  searchAddressByKeyRelSelector,
  searchAddressRelSelector,
} from './selector'
import { enableAllButtons } from '../RiskDataCollectionPage/actions'

export function* fetchAddresses(action) {
  try {
    const searchAddressPostcodeLink = yield select(searchAddressRelSelector)
    const { postcode } = action
    const addressEndpoint = searchAddressPostcodeLink.Href.replace(
      /(\{.+\})/g,
      postcode
    )
    const addresses = yield call(addressApi.getAddresses, addressEndpoint)
    if (addresses.data.AddressSummaries.length === 0) {
      yield put(requestAddressFailed())
    } else {
      yield put(requestAddressSuccess(addresses.data.AddressSummaries))
    }
  } catch (e) {
    yield put(requestAddressFailed(e.message))
  }
}

export function* selectAddress(action) {
  try {
    const searchAddressByKeyLink = yield select(searchAddressByKeyRelSelector)
    const addressEndpoint = searchAddressByKeyLink.Href.replace(
      /(\{.+\})/g,
      action.addressKey
    )
    const address = yield call(addressApi.getAddressByKey, addressEndpoint)
    if (address.data) {
      yield put(requestAddressByKeySuccess(address.data))
    }
  } catch (e) {
    yield put(requestAddressFailed(e.message))
  }
}

export function* handleAddressResult() {
  yield put(enableAllButtons())
}
export default function* addressSaga() {
  yield takeLatest(REQUEST_ADDRESS, fetchAddresses)
  // yield takeLatest(SELECT_ADDRESS, selectAddress)
  yield takeLatest(REQUEST_ADDRESS_SUCCESS, handleAddressResult)
  yield takeLatest(REQUEST_ADDRESS_FAILED, handleAddressResult)
}
