import React, { useEffect, useRef } from 'react'
import { usePrevious } from '../../services/usePrevious'
import { Message } from '../Message'
import { useFormikContext } from 'formik'
import { SkipToError } from './ValidationSummary.styles'
import { smoothScroll } from '../../services/handlers'

const ValidationSummary = ({ errorCount, showSummary, submitCount }) => {
  const ValidationSummaryRef = useRef(null)
  const previousSubmitCount = usePrevious(submitCount)
  const objectValidationWithParentLabel = ['DateOfBirth', 'Occupation']
  const scrollToError = (errors, prefix = '') => {
    const keys = Object.keys(errors)
    if (keys.length > 0) {
      let key = keys[0]
      if (
        errors[key] &&
        typeof errors[key] === 'object' &&
        !objectValidationWithParentLabel.includes(key)
      ) {
        scrollToError(errors[key], `${prefix}${key}.`)
        return
      }
      const selector = `label[for="${prefix}${key}"]`
      const errorElement = document.querySelector(selector)
      if (errorElement) {
        smoothScroll(errorElement)
      }
    }
  }

  useEffect(() => {
    if (
      showSummary &&
      ValidationSummaryRef.current &&
      previousSubmitCount !== submitCount
    ) {
      // ValidationSummaryRef.current.scrollIntoView({
      //   behavior: 'smooth',
      // })
    }
  }, [showSummary, submitCount, ValidationSummaryRef])
  const { errors } = useFormikContext()
  return (
    <>
      {showSummary && (
        <div ref={ValidationSummaryRef}>
          {errorCount && errorCount > 0 && (
            <>
              <Message
                heading={`There are ${errorCount} errors on this page that require your attention`}
                type='error'
                content={
                  <SkipToError onClick={() => scrollToError(errors)}>
                    Skip to error
                  </SkipToError>
                }
              />
            </>
          )}
        </div>
      )}
    </>
  )
}

export default ValidationSummary
