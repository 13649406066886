const useChristmasCampaign = (campaignId) => {
  var currentDate = Date.now()
  var expiryDate = new Date('01/01/2024')
  if (
    campaignId === '8jBnWVLkGTwt-jsLqHGTr-Z-F9jJ8rDQ89v' &&
    currentDate < expiryDate
    //dirty fix to expire banner instead of changing the way campaigns are called if we run out of time over christmas
  ) {
    return true
  }
  return false
}

export default useChristmasCampaign
