import React from 'react'
import queryString from 'query-string'
import config from '../../config'
import BrandLogoImage from '../../images/brand-logo.svg'

import { BrandLogo, BrandLogoAlign, LogoImage, StyledLink } from './Logo.styles'
import { addMissingUtmTags } from '../../history'
import styled from 'styled-components'

const Logo = () => {
  const queries = addMissingUtmTags({})
  
  return (
    <BrandLogo>
      <BrandLogoAlign>
        <StyledLink
          href={queryString.stringifyUrl( {
            url: config.SITE_URL!,
            query: { ...queries },
          })}
        >
          <LogoImage>
            <img src={BrandLogoImage} alt='Confused Logo' />
          </LogoImage>
        </StyledLink>
      </BrandLogoAlign>
    </BrandLogo>
  )
}
export default Logo
