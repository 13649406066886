import React, { useRef, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import {
  ContactUsPageContainer,
  Headline,
  SubHeadline,
  ByPostHeadline,
  P2,
  A,
  TextArea,
  SubmitButton,
  Red,
  PaddingReset,
  BottomSpacingContainer,
  GBIcon,
  RegInput,
  RegWrapper,
  ContactUsForm,
} from './styles'
import { Grid } from 'unsemantic'
import { submitContactUsRequest } from './actions'
import { EnquiryType } from './EnquiryType'
import config from '../../config'
import { Input } from '../../components/common/Input'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { Message } from '../../components/Message'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { FieldLabel } from '../../components/common/FieldLabel'
import Email from '../../components/common/Email'
import {
  CONTACT_US_POLICY_NUMBER_VALIDATION,
  CONTACT_US_QUESTION_VALIDATION,
  EMAIL_VALIDATION,
  ENQUIRY_TYPE_VALIDATION,
  FULLNAME_VALIDATION,
  MOBILE_NUMBER_VALIDATION,
  VEHICLE_REG_VALIDATION,
  COINTACT_US_RECAPTCHA_VALIDATION,
} from '../../services/validation'
import { ErrorMessage } from '../../components/common/ErrorMessage'

const ContactUsPage = ({ actions, formData }) => {
  const recaptcha = useRef()

  useEffect(() => {
    recaptcha.current.reset()
  }, [formData.error])

  const onRegInput = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase()
    e.target.value = e.target.value.replace(/\s/g, '')
  }

  return (
    <ConfusedWrapper>
      <ContactUsPageContainer>
        <Grid desktop='100' mobile='100' tablet='100'>
          <Headline>Contact us</Headline>
          <P2>
            Need to get in touch? Our helpful Tempcover staff will try their
            best to contact you as soon as they can to ensure that your question
            is answered as quickly as possible.
          </P2>
          <SubHeadline>Contact Us</SubHeadline>
          <P2>To contact us, please complete the form below.</P2>
          <P2>
            The team are available Monday to Friday, 9am to 5.30pm, excluding
            Bank Holidays. During these times, the team will endeavour to
            contact you within one hour of receiving your query. If a request is
            received outside of these hours, the team will read and action it
            the next working day.
          </P2>
          <Formik
            initialValues={{
              FullName: formData.FullName,
              RegistrationNumber: formData.RegistrationNumber,
              PolicyNumber: formData.PolicyNumber,
              MobileNumber: formData.MobileNumber,
              EmailAddress: formData.EmailAddress,
              EnquiryType: formData.EnquiryType,
              CommentBox: formData.CommentBox,
              ReCAPTCHA_Token: formData.ReCAPTCHA_Token,
            }}
            validationSchema={Yup.object().shape({
              FullName: FULLNAME_VALIDATION,
              RegistrationNumber: VEHICLE_REG_VALIDATION,
              PolicyNumber: CONTACT_US_POLICY_NUMBER_VALIDATION,
              MobileNumber: MOBILE_NUMBER_VALIDATION,
              EmailAddress: EMAIL_VALIDATION,
              EnquiryType: ENQUIRY_TYPE_VALIDATION,
              CommentBox: CONTACT_US_QUESTION_VALIDATION,
              ReCAPTCHA_Token: COINTACT_US_RECAPTCHA_VALIDATION,
            })}
            onSubmit={(values) => {
              actions.submitContactUsRequest(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => {
              return (
                <Form>
                  <ContactUsForm>
                    <div>
                      <FieldLabel htmlFor='FullName'>
                        Full name <Red>*</Red>
                      </FieldLabel>
                      <Input
                        id='FullName'
                        name='FullName'
                        placeholder=''
                        titleCase={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.FullName && errors.FullName)}
                        errorMessage={touched.FullName && errors.FullName}
                        value={values.FullName}
                        tabIndex={1}
                      />
                    </div>
                    <div>
                      <FieldLabel htmlFor='RegistrationNumber'>
                        Registration number <Red>*</Red>
                      </FieldLabel>
                      {Boolean(
                        touched.RegistrationNumber && errors.RegistrationNumber
                      ) && (
                        <ErrorMessage id={'RegistrationNumberError'}>
                          {touched.RegistrationNumber &&
                            errors.RegistrationNumber}
                        </ErrorMessage>
                      )}
                      <RegWrapper className='reg-wrapper'>
                        <GBIcon
                          className='gb-icon'
                          error={Boolean(
                            touched.RegistrationNumber &&
                              errors.RegistrationNumber
                          )}
                        >
                          GB
                        </GBIcon>
                        <RegInput
                          id='RegistrationNumber'
                          autoComplete='off'
                          name='RegistrationNumber'
                          type='text'
                          autoCorrect='off'
                          value={values.RegistrationNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onInput={onRegInput}
                          error={Boolean(
                            touched.RegistrationNumber &&
                              errors.RegistrationNumber
                          )}
                        />
                      </RegWrapper>
                    </div>
                    <div>
                      <FieldLabel htmlFor='MobileNumber'>
                        Mobile number <Red>*</Red>
                      </FieldLabel>
                      <Input
                        id='MobileNumber'
                        name='MobileNumber'
                        type='tel'
                        pattern='\d*'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.MobileNumber && errors.MobileNumber
                        )}
                        errorMessage={
                          touched.MobileNumber && errors.MobileNumber
                        }
                        value={values.MobileNumber}
                      />
                    </div>
                    <div>
                      <FieldLabel htmlFor='EmailAddress'>
                        Email address <Red>*</Red>
                      </FieldLabel>
                      <Email
                        id='EmailAddress'
                        name='EmailAddress'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        error={Boolean(
                          touched.EmailAddress && errors.EmailAddress
                        )}
                        errorMessage={
                          touched.EmailAddress && errors.EmailAddress
                        }
                        value={values.EmailAddress}
                      />
                    </div>
                    <div>
                      <BottomSpacingContainer>
                        <FieldLabel htmlFor='EnquiryType'>
                          Enquiry type <Red>*</Red>
                        </FieldLabel>
                        <EnquiryType
                          id='EnquiryType'
                          name='EnquiryType'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.EnquiryType}
                          error={Boolean(
                            touched.EnquiryType && errors.EnquiryType
                          )}
                          errorMessage={
                            touched.EnquiryType && errors.EnquiryType
                          }
                        />
                      </BottomSpacingContainer>
                    </div>
                    <div>
                      <FieldLabel htmlFor='PolicyNumber'>
                        Policy number
                      </FieldLabel>
                      <Input
                        id='PolicyNumber'
                        name='PolicyNumber'
                        placeholder=''
                        titleCase={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.PolicyNumber && errors.PolicyNumber
                        )}
                        errorMessage={
                          touched.PolicyNumber && errors.PolicyNumber
                        }
                        value={values.PolicyNumber}
                      />
                    </div>
                    <div className='span-2'>
                      <FieldLabel htmlFor='CommentBox'>
                        Comment/Question <Red>*</Red>
                      </FieldLabel>
                      {Boolean(touched.CommentBox && errors.CommentBox) && (
                        <ErrorMessage id={'CommentBoxError'}>
                          {touched.CommentBox && errors.CommentBox}
                        </ErrorMessage>
                      )}
                      <TextArea
                        rows='9'
                        cols='50'
                        placeholder=''
                        name='CommentBox'
                        id='CommentBox'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.CommentBox}
                        error={Boolean(touched.CommentBox && errors.CommentBox)}
                        errorMessage={touched.CommentBox && errors.CommentBox}
                      />
                    </div>
                    <div className='span-2'>
                      <BottomSpacingContainer>
                        <ReCAPTCHA
                          sitekey={config.ReCAPTCHA_KEY}
                          action='contact_us'
                          onChange={(token) =>
                            setFieldValue('ReCAPTCHA_Token', token)
                          }
                          ref={recaptcha}
                        />
                      </BottomSpacingContainer>
                    </div>
                    <div className='span-2'>
                      <SubmitButton
                        type='submit'
                        id='SubmitContactInformationButton'
                        disabled={isSubmitting}
                        onMouseDown={(e) => {
                          e.preventDefault()
                        }}
                      >
                        {isSubmitting ? 'Please wait' : 'Submit'}
                        <ButtonSpinner disabled={isSubmitting} />
                      </SubmitButton>
                    </div>
                  </ContactUsForm>
                </Form>
              )
            }}
          </Formik>
          {formData.error && (
            <PaddingReset>
              <Message
                heading={`We're sorry.`}
                content='There was an error when submitting your message, please try again'
                type='error'
              />
            </PaddingReset>
          )}

          <P2>
            If you’re a broker, please email&nbsp;
            <A href='mailto:broker.support@tempcover.com'>
              broker.support@tempcover.com
            </A>
          </P2>
          <P2>
            If you wish to make a complaint, please email&nbsp;
            <A href='mailto:complaints@tempcover.com'>
              complaints@tempcover.com
            </A>
          </P2>
          <P2>
            Alternatively you can submit a complaint via the EU Online Dispute
            Resolution portal at:&nbsp;
            <A
              href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'
              target='_blank'
              rel='noopener noreferrer'
            >
              EU Online Dispute Resolution portal.
            </A>
          </P2>
          <P2>
            Please note, some enquiries can only be dealt with during Head
            Office opening hours, which are Monday to Friday, 9am to 5.30pm.
          </P2>
          <ByPostHeadline>By post</ByPostHeadline>
          <P2>You can also write to us at:</P2>
          <P2>
            Tempcover Ltd
            <br />
            Admiral House
            <br />
            Harlington Way
            <br />
            Fleet
            <br />
            Hampshire
            <br />
            GU51 4BB
          </P2>
        </Grid>
      </ContactUsPageContainer>
    </ConfusedWrapper>
  )
}

export const mapStateToProps = (state) => {
  return {
    formData: state.contactUs,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        submitContactUsRequest,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage)
