import styled from 'styled-components'

export const RegInput = styled.p`
  text-align: center;
  position: relative;
  font-family: ${(props) => props.theme.regFont};
  color: #1f1f1f;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 40px;
  line-height: 48px;
  margin: 10px 0 0 0;
  text-align: center;
  border: 1px solid ${(props) => props.theme.primaryColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  height: 50px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    height: 30px;
    padding-left: 28px;
  }
`

export const GBIcon = styled.span`
  text-align: center;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 50px;
  height: 50px;
  z-index: 2;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  line-height: 50px;
  background-color: ${(props) => props.theme.primaryColour};
  color: ${(props) => props.theme.GbIcon};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  box-sizing: border-box;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
  }
`
