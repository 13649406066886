import styled from 'styled-components'

export const PreferenceWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 360px;
  margin-bottom: calc(
    48px - 16px
  ); // remove the existing margin from the radio buttons
  grid-gap: 16px;
  box-sizing: border-box;
`