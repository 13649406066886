import {
  UPDATE_CONTACT_US_DATA,
  UPDATE_VALIDATION_CONTACT_US_PAGE,
  TOGGLE_FORM_IS_VALID_CONTACT_US_PAGE,
  SUBMIT_CONTACT_US_REQUEST,
  SUBMIT_CONTACT_US_REQUEST_SUCCESS,
  SUBMIT_CONTACT_US_REQUEST_FAILURE,
} from './constants'
import initialState from '../../initialState'

function contactUsReducer(state = initialState.contactUs, action) {
  switch (action.type) {
    case UPDATE_CONTACT_US_DATA:
      return {
        ...state,
        ...action.data,
      }
    case SUBMIT_CONTACT_US_REQUEST:
      return {
        ...state,
        isPosting: true,
        error: false,
      }
    case SUBMIT_CONTACT_US_REQUEST_SUCCESS:
      return {
        ...state,
        ReCAPTCHA_Token: '',
        isPosting: false,
        error: false,
      }
    case SUBMIT_CONTACT_US_REQUEST_FAILURE:
      return {
        ...state,
        isPosting: false,
        ReCAPTCHA_Token: '',
        error: true,
      }
    default:
      return state
  }
}

export function validationReducer(
  state = initialState.validation.contactUsPage,
  action
) {
  switch (action.type) {
    case UPDATE_VALIDATION_CONTACT_US_PAGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.fields,
        },
      }
    case TOGGLE_FORM_IS_VALID_CONTACT_US_PAGE:
      return {
        ...state,
        ...(state.ValidForm = action.form.valid),
      }
    default:
      return state
  }
}

export default contactUsReducer
