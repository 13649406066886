import http from './http'
import config from '../config'

export default class confusedExternalAccountApi {
  static getConfusedExternalAccount(endpoint, authToken, internalRvuUserOverride) {
    return http.get(endpoint, {
      validateStatus: () => true,
      headers: {
        Accept:
          'application/tempcover.ConfusedExternalAccountRequestRepresentation+json',
        RetrieveMockData:
          config.MOCK_HEADER_CONFUSED_EXTERNAL_ACCOUNT,
        Authorization:
          authToken,
        IsInternalRvuUser:
          internalRvuUserOverride
      },
    })
  }

  static getConfusedExternalAccountResponse(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept:
          'application/tempcover.ConfusedExternalAccountRequestRepresentation+json'
      },
    })
  }

  static updateConfusedExternalAccount(endpoint, authToken, data) {
    return http.post(endpoint, data, {
      headers: {
        Accept:
          'application/tempcover.ConfusedExternalAccountRequestRepresentation+json',
        Authorization: authToken
      }
    })
  }
}