import styled from 'styled-components'
import { H1 } from '../H1'

export const SectionHeading = styled(H1)`
  font-size: 42px;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.headerFontColour};
  text-align: left;
  line-height: 1;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`
