import styled from 'styled-components'

export const MessageContent = styled.p`
  font-size: 26px;
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 36px;
  padding: 24px;
  color: #1f1f1f;
  text-align: left;
  margin: 0px;
  padding-left: 14px;
`
