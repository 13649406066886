import styled from 'styled-components'

export const DriverDetailsLabel = styled.label`
  color: ${(props) => props.theme.labelTextColour};
  font-size: 1.1666666667rem;
  text-align: left;
  display: block;
  font-weight: ${(props) => props.bold && 'bold'};
  line-height: 1.1em;
  margin-bottom: 24px;
`
