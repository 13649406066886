import styled from 'styled-components'

const BodyWrapper = styled.div`
  flex: 1 0 auto;
  padding-left: 58px;
  padding-right: 58px;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export default BodyWrapper
