import React, { useEffect, useContext, useLayoutEffect } from 'react'
import { ReactComponent as ModalCloseButton } from '../../../images/modal-close-button.svg'
import {
  CloseButtonStyled,
  ModalContentStyled,
  ModalHeaderStyled,
  ModalWrapperStyled,
  ModalHeaderText,
  ModalInner,
  ModalHeaderTextWrapper,
  ModalLogoWrapper,
} from './Modal.styles'
import { ThemeContext } from 'styled-components'

const Modal = ({
  id,
  isVisible,
  isLarge = false,
  onClose,
  header,
  hasCloseButton = true,
  allowOutsideClose = true,
  fullscreen = false,
  headerAlignment = 'left',
  hasHeaderBorder = false,
  headerColour,
  HeaderLogo,
  shouldModalHandleOverflow = true,
  children,
}) => {
  const theme = useContext(ThemeContext)
  const headerStyle = headerColour || theme.primaryTextColour

  // Unmounting logic. When the page is redirected with modal open we need to set the overflow style.
  useLayoutEffect(() => {
    if (shouldModalHandleOverflow) {
      return () => {
        document.querySelector('body').style.overflow = 'visible'
      }
    }
  }, [shouldModalHandleOverflow])

  useEffect(() => {
    if (shouldModalHandleOverflow) {
      // TODO: Investigate if there's a better way to stop scrolling outside of modal?
      if (isVisible) {
        document.querySelector('body').style.overflow = 'hidden'
      } else {
        document.querySelector('body').style.overflow = 'visible'
      }
    }
  }, [isVisible, shouldModalHandleOverflow])

  const handleClose = () => {
    onClose()
  }

  return (
    <ModalWrapperStyled
      isVisible={isVisible}
      onClick={allowOutsideClose ? handleClose : () => {}}
    >
      <ModalContentStyled
        fullscreen={fullscreen}
        isLarge={isLarge}
        onClick={(e) => e.stopPropagation()}
      >
        {(header || hasCloseButton) && (
          <ModalHeaderStyled
            hasHeaderBorder={hasHeaderBorder}
            headerColour={headerStyle}
          >
            <ModalHeaderTextWrapper>
              {header && (
                <ModalHeaderText
                  alignment={headerAlignment}
                  headerColour={headerStyle}
                >
                  {header}
                </ModalHeaderText>
              )}
              {HeaderLogo && <ModalLogoWrapper>{HeaderLogo}</ModalLogoWrapper>}
            </ModalHeaderTextWrapper>
            {hasCloseButton && (
              <CloseButtonStyled
                type='button'
                onClick={handleClose}
                id={`${id}.CloseButton`}
                aria-label='Close modal'
              >
                <ModalCloseButton />
              </CloseButtonStyled>
            )}
          </ModalHeaderStyled>
        )}
        <ModalInner>{children}</ModalInner>
      </ModalContentStyled>
    </ModalWrapperStyled>
  )
}

export default Modal
