import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Spinner = ({ color }) => {
  return (
    <>
      {
        <FontAwesomeIcon
          icon={faCircleNotch}
          color={color}
          size={'1x'}
          spin
        ></FontAwesomeIcon>
      }
    </>
  )
}

Spinner.propTypes = {
  size: PropTypes.string,
}

export default Spinner
