import React from 'react'
import { ErrorMessage } from '../common/ErrorMessage'

import { Select } from '../common/Select'
import SelectTS from '../common/SelectTS'

interface OptionProps {
    AddressKey: string,
    Address: string
}

interface Props {
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    id: string,
    options: OptionProps[],
    name: string,
    value: string,
    error: boolean,
    errorMessage: string,
}

const AddressSelect: React.FC<Props> = ({
  onChange,
  id,
  options,
  name,
  value,
  error,
  errorMessage,
}) => {
  const addresses = options.map((option) => {
    return {
      id: option.AddressKey,
      value: option.AddressKey,
      description: option.Address,
    }
  })

  return (
    <>
      <SelectTS
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        options={addresses}
        placeholder='and I live at...'
        error={error} />
        {error && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
    </>
  )
}
export default AddressSelect
