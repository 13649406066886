import styled from 'styled-components'
import { H1 } from '../../components/common/H1'
import { H2 } from '../../components/common/H2'
import { H3 } from '../../components/common/H3'
import { P } from '../../components/common/P'
import MobileTelephone from '../../components/MobileTelephone'
import { Button } from '../../components/common/Button'
import { BaseInput } from '../../components/common/Input/BaseInput'

export const ContactUsPageContainer = styled.div`
  text-align: left;
`

export const Headline = styled(H1)`
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  font-size: 32px;
  max-width: none;
  color: #1f1f1f;
  display: block;
  font-weight: 700;
  margin: 0.67em 0;
  text-align: left;
`

export const SubHeadline = styled(H2)`
font-family: Poppins, Helvetica, Arial, sans-serif; !important;
  font-size: 24px;
  max-width: none;
  display:block;
  font-weight: 400;
  margin: .83em 0;
  text-align:left;
`

export const ByPostHeadline = styled(H3)`
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  color: #1f1f1f;
  font-size: 1.17em;
  max-width: none;
  display: block;
  font-weight: 400;
  margin: 1em 0;
  text-align: left;
`

export const P2 = styled(P)`
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #1f1f1f;
  display: block;
  text-size-adjust: 100%;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

export const Telephone = styled(MobileTelephone)`
  text-align: center !important;
`

export const A = styled.a`
  font-family: Poppins, Helvetica, Arial, sans-serif !important;
  text-decoration: none;
  text-size-adjust: 100%;
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.primaryColour};
  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

export const TextArea = styled.textarea`
  height: 160px;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  outline: ${(props) => props.error && props.theme.inputBorderColourError};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  box-sizing: border-box;
  margin-bottom: 48px;

  ::placeholder {
    text-transform: none;
    letter-spacing: 0;
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
  }
`

export const SubmitButton = styled(Button)`
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  width: 100%;
  max-width: 395px;
  margin: 15px 0 0;

  &.disabled {
    background: #777;
    cursor: not-allowed;
    pointer-events: none;
  }

  :hover {
    background-color: #0e2a6e;
  }
`

export const Red = styled.span`
  color: #790000;
`

export const PaddingReset = styled.div`
  margin: 0 -10px;
`

export const BottomSpacingContainer = styled.div`
  margin-bottom: 48px;
`

export const RegWrapper = styled.div`
  background-color: #1f1f1f
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: auto;
  margin-bottom: 48px;
  display: inline-block;
`

export const RegInput = styled(BaseInput)`
  font-size: 24px;
  color: #1f1f1f;
  letter-spacing: 1px;
  padding:0 45px;
  padding-left:60px
  margin: 0 auto;
  font-weight: 500;
  display: block;
  width: 100%;
  background: #fff;
  
  border-radius: 0px;
  height: 45px;
  box-sizing: border-box;
  -webkit-appearance: none;
`

export const GBIcon = styled.div`
  text-align: center;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 40px;
  position: absolute;
  width: 45px;
  height: 45px;
  left: 0px;
  top: 0px;
  background-color: #efefef;
  color: #1f1f1f;
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  border-right: none;
  box-sizing: border-box;
  -webkit-appearance: none;
`

export const ContactUsForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;

  .span-2 {
    grid-column: span 2;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;

    .span-2 {
      grid-column: span 1;
    }
  }
`
