import initialState from '../../../initialState'
import {
  SET_CLIENT_SESSION_GUID,
  SET_CLIENT_SESSION_QUOTE,
  SET_CLIENT_SESSION_DATA,
  SET_CLIENT_SESSION_STATE,
  SET_CLIENT_SESSION_LAST_ACTIVITY,
} from '../constants'

function sessionReducer(state = initialState.sessionData, action) {
  switch (action.type) {
    case SET_CLIENT_SESSION_DATA:
      return { ...state, ...action.sessionBaseData }
    case SET_CLIENT_SESSION_GUID:
      return {
        ...state,
        sessionGuid: action.sessionGuid,
      }
    case SET_CLIENT_SESSION_QUOTE:
      return {
        ...state,
        sessionQuoteId: action.sessionQuoteId,
      }
    case SET_CLIENT_SESSION_STATE:
      return { ...state, sessionState: action.sessionState }
    case SET_CLIENT_SESSION_LAST_ACTIVITY:
      return {
        ...state,
        sessionLastActivityTimestamp: action.lastActivityTimeStamp,
      }
    default:
      return state
  }
}

export default sessionReducer
