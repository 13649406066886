import React from 'react'
import styled from 'styled-components'
import { BaseSelect } from '../common/Select/BaseSelect'
import { SelectWrapper } from '../common/Select/SelectWrapper'
import { FormControlWrapper } from '../FormControlWrapper'
import { FormControlValidIcon } from '../FormControlValidIcon'

const VehicleBuilderSelectStyle = styled(BaseSelect)`
  margin-bottom: 8px;
  &.noMargin {
    margin-bottom: 0px;
  }
`

export const YearOfManufacture = ({ 
  id,
  value,
  error,
  onBlur,
  onChange,
  placeholder,
  name,
  style,
  myRef,
}) => {

const currentYear = new Date().getFullYear();
const options = [];

for (let year = currentYear; year >= 1920; year--) {
  options.push({
    id: year,
    value: year,
    description: year,
    selected: year === value
  });
}

return (
  <FormControlWrapper>
    <FormControlValidIcon
      className='fas fa-check'
      error={error}
    ></FormControlValidIcon>
    <SelectWrapper error={error} style={style} tinyGap>
      <VehicleBuilderSelectStyle
        ref={myRef}
        id={id}
        error={error}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        key={value}
        name={name}
        style={style}
      >
        {placeholder && <option value='' disabled selected >{placeholder}</option>}
        {options.map((option) => (
          <option
            key={option.id}
            value={option.value}
            selected={option.selected}
          >
            {option.description}
          </option>
        ))}
      </VehicleBuilderSelectStyle>
    </SelectWrapper>
  </FormControlWrapper>
)}