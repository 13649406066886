import styled from 'styled-components'
import { SecondaryButton } from '../../components/common/Button'
import { P } from '../../components/common/P'
import { ReactComponent as confusedLogoDark } from '../../images/brand-logo-dark.svg'


export const Container = styled.div`
  margin: 48px 0;
`

const Banner = styled.div`
  background-color: ${(props) => props.theme.confusedDarkColour};
  padding: 32px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`

export const LoadingBanner = styled(Banner)`
  font-size: 64px;
  color: #ffffff;
`

export const RTPBanner = styled(Banner)``

export const StaticBanner = styled(Banner)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 32px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    grid-gap: 16px;
  }
`

export const BannerTextPrimary = styled.h2`
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: #1f1f1f;
  text-align: left;
  margin-top: 0;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`

export const BannerH2Secondary = styled(BannerTextPrimary)`
  margin-bottom: 16px;
`

export const BannerTextSecondary = styled.h3`
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: #1f1f1f;
  text-align: left;
  margin: 0;

  strong {
    font-weight: 600;
  }
`

export const BannerTextTertiary = styled(BannerTextSecondary)`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`

export const StaticBannerTextTertiary = styled(BannerTextTertiary)`
  margin-bottom: 0;
`

export const ConfusedLogo = styled(confusedLogoDark)`
  height: 20px;
`

export const ButtonWrapper = styled.div``

export const BannerButton = styled(SecondaryButton)`
  background-color: #fff;
  padding: 16px 24px;
  margin-bottom: 0;
  :hover {
    filter: ${(props) => (props.disabled ? null : 'brightness(100%)')};
  }
`

export const ButtonTextPrimary = styled.h4`
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: #1f1f1f;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`

export const ButtonTextSecondary = styled.h4`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: #1f1f1f;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`

export const SmallPrint = styled.p`
  font-size: 14px;
`

export const PricingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  margin: 16px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: 78px auto;
  border-top: 4px solid #58aae0;
`

export const ImageContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 83px;

  & > div {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Image = styled.img`
  width: 90%;
`

export const PriceResult = styled.div`
  background-color: #d4d4d4;
  padding: 0 16px;
  display: flex;
  align-items: center;
`

export const PriceText = styled(P)`
  font-size: 14px;
  margin: 0;
`
