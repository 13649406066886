import {
  REQUEST_ADDRESS,
  REQUEST_ADDRESS_SUCCESS,
  REQUEST_ADDRESS_FAILED,
  UPDATE_POSTCODE,
  SELECT_ADDRESS,
  REQUEST_ADDRESS_BY_KEY_SUCCESS,
  RESET_POSTCODE,
  RESET_SELECTED_ADDRESS,
} from './constants'

export function updatePostcode(postcode) {
  return {
    type: UPDATE_POSTCODE,
    postcode,
  }
}

export function requestAddress(postcode) {
  return {
    type: REQUEST_ADDRESS,
    postcode,
  }
}

export function requestAddressSuccess(addresses) {
  return {
    type: REQUEST_ADDRESS_SUCCESS,
    addresses,
  }
}

export function requestAddressFailed(message) {
  return {
    type: REQUEST_ADDRESS_FAILED,
    message,
  }
}

export function selectAddress(address, addressKey) {
  return {
    type: SELECT_ADDRESS,
    address,
    addressKey,
  }
}

export function resetPostcode() {
  return {
    type: RESET_POSTCODE,
  }
}

export function resetSelectedAddress() {
  return {
    type: RESET_SELECTED_ADDRESS,
  }
}

export function requestAddressByKeySuccess(payload) {
  return {
    type: REQUEST_ADDRESS_BY_KEY_SUCCESS,
    payload,
  }
}
