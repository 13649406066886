import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  requestConfusedQuickQuoteSuccess,
  requestConfusedQuickQuoteFailure,
  requestConfusedQuickQuoteResponseSuccess,
  requestConfusedQuickQuoteResponseFailure,
} from '../actions'
import {
  REQUEST_CONFUSED_QUICK_QUOTE,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE,
} from '../constants'
import {
  getRequestConfusedQuickQuoteLink,
  getRequestConfusedQuickQuoteResponseLink,
} from '../selector'
import confusedQuickQuoteApi from '../../../api/confusedQuickQuoteApi'

export function* requestConfusedQuickQuote() {
  try {
    let { Href } = yield select(getRequestConfusedQuickQuoteLink)

    if (Href) {
      const response = yield call(
        confusedQuickQuoteApi.getConfusedQuickQuote,
        Href
      )
      yield put(
        requestConfusedQuickQuoteSuccess(
          response && response.data.RequestAccepted
        )
      )
    }
  } catch (e) {
    yield put(requestConfusedQuickQuoteFailure())
  }
}

export function* requestConfusedQuickQuoteResponse() {
  try {
    let { Href } = yield select(getRequestConfusedQuickQuoteResponseLink)

    if (Href) {
      const response = yield call(
        confusedQuickQuoteApi.getConfusedQuickQuoteResponse,
        Href
      )
      if (response && response.data && response.data.Pricing) {
        yield put(requestConfusedQuickQuoteResponseSuccess(response.data))
      } else {
        yield put(requestConfusedQuickQuoteResponseFailure())
      }
    }
  } catch (e) {
    yield put(requestConfusedQuickQuoteResponseFailure())
  }
}

export default function* confusedQuickQuoteSaga() {
  yield takeLatest(REQUEST_CONFUSED_QUICK_QUOTE, requestConfusedQuickQuote)
  yield takeLatest(
    REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE,
    requestConfusedQuickQuoteResponse
  )
}
