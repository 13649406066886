export const SESSIONSTATES = {
  NEW_SESSION: 'NEW_SESSION', // new session started
  CREATING_SESSION: 'CREATING_SESSION', // api called to get new session guid
  SESSION_CREATED: 'SESSION_CREATED', // session api called, session guid stored
  UPDATING_SESSION_QUOTE: 'UPDATING_SESSION_QUOTE', // quote id new/changed
  SESSION_QUOTE_UPDATED: 'SESSION_QUOTE_UPDATED', // session api called with quoteid
}

export const QUOTE_JOURNEY_POSITION = {
  NotSet: 'NotSet',
  CustomerDetailsSubmit: 'CustomerDetailsSubmit',
  CustomerDirectAccess: 'CustomerDirectAccess',
  SystemUnderwriterUpdate: 'SystemUnderwriterUpdate',
  SystemDocumentCheckSubmit: 'SystemDocumentCheckSubmit',
  SystemPolicyPayment: 'SystemPolicyPayment',
  SystemPriceRefresh: 'SystemPriceRefresh',
}

export const SCHEMES = {
  COMMERCIAL_VEHICLE_SCHEME: 'VehicleCommercialVehicleBaseScheme',
  PRIVATE_CAR_SCHEME: 'VehiclePrivateCarBaseScheme',
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME:
    'VehicleCommercialVehicleImpoundedScheme',
  IMPOUNDED_PRIVATE_CAR_SCHEME: 'VehiclePrivateCarImpoundedScheme',
  VEHICLE_LEARNER_SCHEME: 'VehicleLearnerScheme',
  MOTORCYCLE_VEHICLE_SCHEME: 'VehicleMotorcycleLearnerAndFullLicenceScheme',
}

export const VALID_URL_REFERRER = ['confused', 'confusedsynergy1']

export const VALID_AFFILIATE_REF = ['CF103AE310635']
