import React from 'react'
import Breadcrumb from '../Breadcrumb'
import Logo from '../Logo'

import { HeaderWrapper,  HeaderContainer, TeslaBrand } from './Header.styles'

const Header = ({ step, query, showBreadcrumb }) => {
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <TeslaBrand>
            <Logo />
          </TeslaBrand>
        </HeaderContainer>
        {/* <PoweredByTempcover></PoweredByTempcover> */}
      </HeaderWrapper>
      {showBreadcrumb && <Breadcrumb step={step} query={query} />}
    </>
  )
}

export default Header
