import React from 'react'
import config from '../../config'
import { Button } from '../common/Button'
import { H1 } from '../common/H1'
import styled from 'styled-components'
import { ConfusedWrapper } from '../common/ConfusedWrapper'

const Heading = styled(H1)`
  text-align: left;
`

const ErrorPage = () => {
  return (
    <ConfusedWrapper>
      <Heading>Sorry, there seems to be a problem with your quote.</Heading>
      <Button
        id='HomePageButton'
        onClick={() => {
          window.location.href = `${config.SITE_URL}`
        }}
      >
        New Quote
      </Button>
    </ConfusedWrapper>
  )
}

ErrorPage.propTypes = {}

export default ErrorPage
