import styled from 'styled-components'
import downChevron from '../../../images/down-chevron.png'

export const SelectWrapperTS = styled('div')<{
  error: boolean
  tinyGap?: boolean
}>`
  position: relative;
  width: 100%;
  max-width: 360px;
  outline: none;
  border-radius: 0px;
  margin-bottom: 16px;
  &:after {
    content: '';
    background: url(${downChevron}) white no-repeat 100% !important;
    border: ${(props) => (props.error ? '4px' : '2px')} solid
      ${(props) =>
        props.error
          ? props.theme.inputBorderColourError
          : props.theme.inputBorderColour};
    height: ${(props) => props.theme.formControlHeight};
    border-left: none;
    position: absolute;
    width: 45px;
    right: 0;
    box-sizing: border-box;
    pointer-events: none;
  }
`
