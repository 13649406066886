import styled from 'styled-components'



export const AllDurationsWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  margin-top: 16px;
`

export const TopIconWrapper = styled.div`
  display: flex;
  max-width: 360px;
  flex-direction: column;

  & > div:last-child {
    margin-bottom: 0;
  }
`


export const IconWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direaction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: auto;
  margin-top: 20px;
`

// eslint-disable-next-line no-undef
export const SmallDurationIcon = styled("div")<{error: boolean}>`
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  flex: 1 0 50px;
  color: #1f1f1f;
  background-color: #fff;
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: bold;
  &.Active {
    background-color: #58aae0;
    color: #1f1f1f;
    border: 4px solid #1f1f1f;
  }

  @media (max-width: 440px) {
    margin: 8px;
    flex: 1 0 28px;
  }
`

// eslint-disable-next-line no-undef
export const IndentedLine = styled("div")<{noGap: boolean }>`
  border-left: 3px solid #1f1f1f;
  padding-left: 24px;
  margin-bottom: ${(props) => (props.noGap ? '0' : '48px')};
  margin-top: 0;
`

// eslint-disable-next-line no-undef
export const ConfusedPageEnd = styled("div")<{paddingSize: string}>`
  border-bottom: 3px solid #1f1f1f;
  padding-top: ${(props) => props.paddingSize};
  padding-bottom: 12px;
`


// eslint-disable-next-line no-undef
export const ShowMoreButton = styled("a")<{more: boolean}>`
  font-weight: 600;
  cursor: pointer;
  color: #1f1f1f;

  span {
    text-decoration: underline;
    margin-right: 8px;
    display: inline-block;
  }

  &::after {
    content: '';
    content: ${(props) => (props.more ? '"-"' : '"+"')};
    display: inline-block;
    font-size: 1.3rem;
    position: relative;
    top: 2px;
  }

  &:hover,
  &:active,
  &:visited {
    color: #1f1f1f;
  }
`
