import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
// eslint-disable-next-line
import { Button } from '../../components/common/Button'
import { bindActionCreators } from 'redux'
import { updateStep } from '../App/actions'
import { Grid } from 'unsemantic'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CheckoutForm from '../Stripe/Card'
import {
  updateEmailRiskData,
  handleEmailValidation,
} from '../EmailQuoteModal/actions'
import { ErrorContainer } from '../../components/ErrorContainer'
import { updateRiskData } from '../RiskDataCollectionPage/actions'
import {
  resetPaymentOptions,
  handleMobileValidation,
  clearPaymentError,
  priceMatch,
  priceCheckPaymentPage,
} from './actions'
import queryString from 'query-string'
import { MessageHeader } from '../../components/Message/MessageHeader.style'
import { MessageContent } from '../../components/Message/MessageContent.style'
import { ClearFix } from '../../components/common/ClearFix'
import config from '../../config'
import LoadingScreen from '../../components/LoadingScreen'
import { P } from '../../components/common/P'
import {
  StripePaymentWrapper,
  AcceptedCardsWrapper,
  StripeLogo,
  PaymentHeader,
} from './styles'
import { Message } from '../../components/Message'
import { history } from '../..'
import { addMissingUtmTags } from '../../history'
import { ConfusedBackButton } from '../../components/common/ConfusedBackButton/ConfusedBackButton.component'
import { ROUTES } from '../../services/constants'
import { ConfusedLink } from '../../components/common/ConfusedLink'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import acceptedCards from '../../images/payments-stripe.png'
import poweredByStripe from '../../images/stripe-logo.png'
import { SectionHeading } from '../../components/common/SectionHeading'

export const PaymentPageButton = styled(Button)`
  width: 100%;
`

export const PaymentButton = styled(PaymentPageButton)`
  display: inline-block;
`

const PaymentPage = ({
  payment,
  riskData,
  quote,
  price,
  paymentError,
  paymentInProgress,
  queryStringState,
  actions,
  affiliate,
}) => {
  const policyDetails = () => {
    history.push(`${ROUTES.QUOTE_LEGAL_DECLARATION}${queryStringState}`)
  }

  useEffect(() => {
    actions.updateStep(3)
  }, [])

  useEffect(() => {
    if (!paymentInProgress && paymentError !== null) {
      actions.clearPaymentError()
    }
  }, [paymentError, paymentInProgress])

  useEffect(() => {
    if (!affiliate.PriceComparison) {
      if (quote.Underwriter !== price.Underwriter) {
        // show message on driving licence page & update premium units
        actions.priceCheckPaymentPage()
        history.push({
          pathname: `/quote/driving-licence`,
          search: `${queryStringState}`,
        })
      } else if (quote.TotalPrice !== price.TotalPrice) {
        // show message on payment page & update premium units
        actions.priceMatch(false)
        actions.priceCheckPaymentPage()
      }
    }
  }, [])

  useEffect(() => {
    const handlePopstate = () => {
      if (payment.paymentError) {
        actions.clearPaymentError()
      }
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [payment])

  const parsedQueryString = queryString.parse(window.location.search)
  let error = false
  if (parsedQueryString.message) {
    error = parsedQueryString.message
  }
  const queries = addMissingUtmTags({})
  return payment.paymentInProgress ? (
    <LoadingScreen />
  ) : (
    <div>
      <ConfusedWrapper>
        <StripeProvider apiKey={config.STRIPE_KEY}>
          <>
            <></>
            <SectionHeading>Payment</SectionHeading>

            {!payment.priceMatch && (
              <Message
                heading='We are sorry, your price has now updated'
                content='Please double check before proceeding.'
                type='info'
              />
            )}
            {payment.paymentError && (
              <ErrorContainer>
                <Grid desktop='80' tablet='80' mobile='80'>
                  <MessageHeader>Payment Error</MessageHeader>
                  <MessageContent>{payment.paymentError}</MessageContent>
                </Grid>
                <ClearFix />
              </ErrorContainer>
            )}
            {error && (
              <ErrorContainer>
                <Grid desktop='80' tablet='80' mobile='80'>
                  <MessageHeader>Payment Error</MessageHeader>
                  <MessageContent>{error}</MessageContent>
                </Grid>
                <ClearFix />
              </ErrorContainer>
            )}

            {riskData.Impounded ? (
              <P>
                This policy meets the demands and needs of someone needing to
                insure their vehicle in order to release it from a Police
                Impound. You accept that once a policy has been purchased it is
                not possible to amend any details and is non-refundable.
                <br />
                <br />
                Please review our{' '}
                <ConfusedLink
                  href={queryString.stringifyUrl({
                    url: `${config.SITE_URL}/our-refund-policy`,
                    query: { ...queries },
                  })}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Refund Policy
                </ConfusedLink>
                .
              </P>
            ) : (
              <>
                <P>
                  This policy meets the demands &amp; needs of someone needing
                  to insure their vehicle for a short period. You accept that
                  once a policy has been purchased it is not possible to amend
                  any details and is non-refundable.
                  <br />
                  <br />
                  Please review our{' '}
                  <ConfusedLink
                    href={queryString.stringifyUrl({
                      url: `${config.SITE_URL}/our-refund-policy`,
                      query: { ...queries },
                    })}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Refund Policy
                  </ConfusedLink>
                  .
                </P>
              </>
            )}
            <Grid desktop='100' mobile='100' tablet='100' parent>
              <Grid>
                <PaymentHeader>Card details</PaymentHeader>
                <Elements>
                  <StripePaymentWrapper>
                    <CheckoutForm style={{ base: { fontSize: '18px' } }} />
                  </StripePaymentWrapper>
                </Elements>
                <PaymentHeader>Cards accepted:</PaymentHeader>
                <AcceptedCardsWrapper id='AcceptedCardsImages'>
                  <img src={acceptedCards} alt='accepted cards' />
                  <StripeLogo src={poweredByStripe} alt='powered by Stripe' />
                </AcceptedCardsWrapper>
              </Grid>
            </Grid>
          </>
        </StripeProvider>
        <ConfusedBackButton onClick={policyDetails}>
          Your quote
        </ConfusedBackButton>
      </ConfusedWrapper>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    contactInformation: state.contactInformation,
    riskData: state.riskData,
    vehicle: state.vehicle,
    price: state.price,
    history: ownProps.history,
    location: ownProps.location,
    payment: state.paymentPage,
    queryStringState: state.queryString,
    affiliate: state.affiliate,
    quote: state.quote,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateStep,
        updateEmailRiskData,
        updateRiskData,
        handleEmailValidation,
        handleMobileValidation,
        resetPaymentOptions,
        clearPaymentError,
        priceCheckPaymentPage,
        priceMatch,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage)
