import styled from 'styled-components'
import { H3 } from '../common/H3'

interface Props {
  paddingLeft?: string,
  paddingRight?: string,
  paddingTop?: string,
  paddingBottom?: string,
}

// eslint-disable-next-line no-mixed-operators
export const BannerWrapper = styled.div<Props>`
  margin-top: -24px;
  margin-bottom: '48px';
  display: grid;
  background-color: #fff';
  justify-content: center;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : '12px'};
  padding-right: ${props => props.paddingRight ? props.paddingRight : '0px'};
  padding-top: ${props => props.paddingTop ? props.paddingTop : '0px'};
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : '0px'};
`
export const BannerGrid = styled.div`
  display: grid;
  width: 70%;
  justify-content: center;
  grid-template-columns: auto auto;
  align-items: center;
`

export const BannerText = styled(H3)`
  color: #1f1f1f;
  justify-self: start;
  margin-left: auto;
  margin: 0;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
export const BannerImage = styled.image`
  padding: 8px 16px 0px 16px;
`
