export const ELEMENTS = {
    HOURS: 1,
    DAYS: 2,
    WEEKS: 3,
  }

  export const DURATION_OPTIONS = [
    {
      id: 1,
      description: 'Hours',
      value: 'Hours',
      element: 1,
    },
    {
      id: 2,
      description: 'Days',
      value: 'Days',
      element: 2,
    },
    {
      id: 3,
      description: 'Weeks',
      value: 'Weeks',
      element: 3,
    },
  ]

  export const DURATIONS = {
    HOURS: {
      1: '1+hours',
      2: '2+hours',
      3: '3+hours',
      4: '4+hours',
      5: '5+hours',
      6: '6+hours',
      7: '7+hours',
      8: '8+hours',
      9: '9+hours',
      10: '10+hours',
      11: '11+hours',
      12: '12+hours',
    },
    DAYS: {
      1: '1+days',
      2: '2+days',
      3: '3+days',
      4: '4+days',
      5: '5+days',
      6: '6+days',
      7: '7+days',
      8: '8+days',
      9: '9+days',
      10: '10+days',
      11: '11+days',
      12: '12+days',
      13: '13+days',
      14: '14+days',
      15: '15+days',
      16: '16+days',
      17: '17+days',
      18: '18+days',
      19: '19+days',
      20: '20+days',
      21: '21+days',
      22: '22+days',
      23: '23+days',
      24: '24+days',
      25: '25+days',
      26: '26+days',
      27: '27+days',
      28: '28+days',
    },
    WEEKS: {
      1: '7+days',
      2: '14+days',
      3: '21+days',
      4: '28+days',
    },
  }