import {
  REQUEST_APPLE_PAY_MERCHANT_SESSION,
  CREATE_APPLE_PAYMENT,
} from './constants'

import { call, takeLatest, select, put } from 'redux-saga/effects'

import paymentApi from '../../api/paymentApi'
import { getRiskData } from './selector'
import { getPriceData } from '../JudoPay/selector'
import { createApplePayFailure } from './actions'
import { history } from '../..'
import { getQueryString } from '../RiskDataCollectionPage/selector'

function* applePayMerchantSession({ config, callback }) {
  try {
    const session = yield call(
      paymentApi.getApplePayMerchantSession,
      '/api/applePay/merchantSession',
      { ValidationUrl: config.ValidationUrl }
    )
    yield call(callback(session.data))
  } catch (e) {}
}

function* applePaySession({ token, callback }) {
  const riskData = yield select(getRiskData)
  const queryString = yield select(getQueryString)
  try {
    const priceData = yield select(getPriceData)

    const data = {
      ApplePayToken: {
        PaymentData: token.paymentData,
        PaymentInstrumentName: token.paymentMethod.displayName,
        PaymentNetwork: token.paymentMethod.network,
      },
      DeviceUniqueIdentifier: 'Test',
      QuoteId: {
        value: riskData.QuoteId,
      },
      ValidationData: {
        Value: priceData.EncryptedString,
      },
    }
    const response = yield call(
      paymentApi.createApplePayment,
      '/api/policy/payment/apple',
      data
    )
    if (response.data.JudoPaymentResult) {
      switch (response.data.JudoPaymentResult.Result) {
        case 0:
          yield call(callback())
          break
        case 1:
        case 2:
          yield put(
            createApplePayFailure(
              'Sorry your payment has been unsuccessful. Please try again.'
            )
          )
          break
        default:
          break
      }
    }
  } catch (e) {
    if (e.response.status === 403) {
      history.push({
        pathname: `/quote/driving-licence`,
        search: `${queryString}`,
        state: { fraudFailure: true },
      })
    }
  }
}

export default function* applePaySaga() {
  yield takeLatest(REQUEST_APPLE_PAY_MERCHANT_SESSION, applePayMerchantSession)
  yield takeLatest(CREATE_APPLE_PAYMENT, applePaySession)
}
