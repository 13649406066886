import React, { useEffect } from 'react'
import { TopIconWrapper } from '../HomePage/styles'
import { IMPOUNDED_VALUE, REASONS_FOR_COVER } from './constants'
import { RadioButton } from '../../components/common/RadioButton'
import { FieldLabel } from '../../components/common/FieldLabel'
import { ErrorMessage } from '../../components/common/ErrorMessage'
import { AllReasonsWrapper } from '../ReasonForCover/ReasonForCover.styles'

const ReasonForCover = ({
  setFieldValue,
  error,
  errorMessage,
  id,
  name,
  value,
}) => {
  const setReasonForCover = (selectedValue) => {
    setFieldValue('ReasonForCover', selectedValue)
  }

  useEffect(() => {
    if (value === IMPOUNDED_VALUE) {
      setFieldValue('PolicyDuration', '30+days')
    } else {
      setFieldValue('PolicyDuration', '')
    }
  }, [value])

  return (
    <>
      <AllReasonsWrapper>
        <FieldLabel>What&apos;s your reason for cover?</FieldLabel>
        {error && <ErrorMessage id={`${id}Error`}>{errorMessage}</ErrorMessage>}
        <TopIconWrapper>
          {REASONS_FOR_COVER.map((option) => (
            <RadioButton
              id={`${id}-${option.id}`}
              onChange={() => setReasonForCover(option.value)}
              error={error}
              value={option.description}
              name={name}
              checked={value === option.value}
              key={`radio-reasonforcover-${option.value}`}
            />
          ))}
        </TopIconWrapper>
      </AllReasonsWrapper>
    </>
  )
}

export default ReasonForCover
