import React from 'react'
import { Bold, Wrapper } from './PolicyDetails.style'
import Moment from 'moment'
import { P } from '../common/P'

interface Props {
    make: string,
    model: string,
    grossWeight: string,
    yearOfManufacture: string,
    registrationNumber: string,
    vehicleValueDescription: string,
    duration: number,
    durationType: string,
    manuallyEdited: boolean,
    policyStartDate: Date
}


const PolicyDetails:React.FC<Props> = ({ 
    make,
    model,
    grossWeight,
    yearOfManufacture,
    registrationNumber,
    vehicleValueDescription,
    duration,
    durationType,
    manuallyEdited,
    policyStartDate
 }) => {
    return (
      <Wrapper>
        <P>
          Vehicle to be covered:{' '}
          {Boolean(make && model) && (
            <Bold>
              {make} {model}
            </Bold>
          )}
          {!Boolean(make && model) &&
            Boolean(
              grossWeight && yearOfManufacture
            ) && (
              <Bold>
                {`Weight: ${grossWeight}kg Year: ${yearOfManufacture}`}
              </Bold>
            )}
        </P>
        <P>
          Registration: <Bold>{registrationNumber}</Bold>
        </P>
        <P>
          Vehicle value:{' '}
          <Bold>
            {vehicleValueDescription}
          </Bold>
        </P>
        <P>
          Duration:{' '}
          <Bold>
            {duration} {durationType}
          </Bold>
        </P>
        <P>
          Start date and time:{' '}
          {manuallyEdited && (
            <Bold>
              {Moment(policyStartDate).format('Do')}{' '}
              {Moment(policyStartDate).format('MMMM')}{' '}
              {Moment(policyStartDate).format('YYYY')}{' '}
              {Moment(policyStartDate).format('HH:mm')}
            </Bold>
          )}
          {!manuallyEdited && <Bold>Immediately</Bold>}
        </P>
      </Wrapper>
    )
  }

  export default PolicyDetails