import {
  REQUEST_POLICY_SUCCESS,
  UPDATE_POLICY_CONTACT_DETAILS,
  TOGGLE_FORM_IS_VALID_POLICY_CONFIRMATION,
  UPDATE_VALIDATION_POLICY_CONFIRMATION,
  ENABLE_POLICY_HAS_EMAIL_ADDRESS,
  UPDATE_REASON_FOR_TEMPCOVER,
  GET_DOCUMENTS_SUCCESS,
  GET_ANALYSIS_DATA_FAILURE,
  UPDATE_ANALYSIS_DATA_SUCCESS,
  UPDATE_ANALYSIS_DATA_FAILURE,
} from './constants'

import initialState from '../../initialState'
import {
  REQUEST_CALLBACK,
  REQUEST_CALLBACK_SUCCESS,
  REQUEST_CALLBACK_FAILURE,
  REQUEST_CALLBACK_STATUS,
} from '../AnnualUpsell/constants'
import { HTTP_CODES } from '../../services/constants'

function policyConfirmationPageReducer(state = initialState.policy, action) {
  switch (action.type) {
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        PolicyId: action.policy.ReferenceNumber,
        analysisSent: action.policy.SentToAnalytics,
        confusedExternalAccountUsed: action.policy.ConfusedExternalAccountUsed,
        confusedExternalAccountPersonUsed: action.policy.ConfusedExternalAccountPersonUsed,
        confusedExternalAccountVehicleUsed: action.policy.ConfusedExternalAccountVehicleUsed,
        confusedExternalAccountPersonUpdated: action.policy.ConfusedExternalAccountPersonUpdated,
        confusedExternalAccountVehicleUpdated: action.policy.ConfusedExternalAccountVehicleUpdated,
        forename: action.policy.Forename,
        surname: action.policy.Surname,
        registrationNumber: action.policy.VehicleSummary.Registration,
      }
    case ENABLE_POLICY_HAS_EMAIL_ADDRESS:
      return {
        ...state,
        ContactDetails: {
          ...state.ContactDetails,
          PolicyHasEmailAddress: true,
        },
      }
    case UPDATE_POLICY_CONTACT_DETAILS:
      return {
        ...state,
        ContactDetails: {
          ...state.ContactDetails,
          ...action.contactDetails,
        },
      }
    case UPDATE_REASON_FOR_TEMPCOVER:
      return {
        ...state,
        reasonForCover: action.reasonForTempcover,
      }
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        PolicyDocuments: action.response.PolicyDocuments,
      }
    case GET_ANALYSIS_DATA_FAILURE:
    case UPDATE_ANALYSIS_DATA_SUCCESS:
    case UPDATE_ANALYSIS_DATA_FAILURE:
      return {
        ...state,
        analysisSent: true,
      }
    case REQUEST_CALLBACK:
      return {
        ...state,
        callbackRequest: {
          status: REQUEST_CALLBACK_STATUS.REQUESTING,
          httpCode: 0,
        },
      }
    case REQUEST_CALLBACK_SUCCESS:
      return {
        ...state,
        callbackRequest: {
          status: REQUEST_CALLBACK_STATUS.SUCCESS,
          httpCode: HTTP_CODES.SUCCESS.OK,
        },
      }
    case REQUEST_CALLBACK_FAILURE:
      return {
        ...state,
        callbackRequest: {
          status: REQUEST_CALLBACK_STATUS.ERROR,
          httpCode: action.payload.httpCode,
        },
      }
    default:
      return state
  }
}

export function validationReducer(
  state = initialState.validation.policyConfirmationPage,
  action
) {
  switch (action.type) {
    case UPDATE_VALIDATION_POLICY_CONFIRMATION:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.fields,
        },
      }

    case TOGGLE_FORM_IS_VALID_POLICY_CONFIRMATION:
      return {
        ...state,
        ...(state.ValidForm = action.form.valid),
      }
    default:
      return state
  }
}

export default policyConfirmationPageReducer
