import { REQUEST_CREATE_UNKNOWN_VEHICLE } from './constants'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import vehicleApi from '../../api/mocks/vehicleApi'

import {
  requestCreateUnknownVehicleSuccess,
  requestCreateUnknownVehicleFailed,
} from './actions'

import { createUnknownVehicleLinkSelector } from './selector'
import { createQuote } from '../App/actions'

function* createUnknownVehicle(action) {
  try {
    const createUnknownVehicleLink = yield select(
      createUnknownVehicleLinkSelector
    )

    const response = yield call(
      vehicleApi.createUnknownVehicle,
      createUnknownVehicleLink,
      action.unknownVehicle
    )
    yield put(requestCreateUnknownVehicleSuccess(response.data))
    yield put(createQuote())
  } catch (e) {
    yield put(requestCreateUnknownVehicleFailed(e.message))
  }
}

export default function* unknownRegSaga() {
  yield takeLatest(REQUEST_CREATE_UNKNOWN_VEHICLE, createUnknownVehicle)
}
