export const AFFILIATE_REFS = [
  {
    AffiliateId: 31,
    DisplayName: 'A-Plan Holdings',
    AffiliateType: 3,
    Value: 'aplan',
  },
  {
    AffiliateId: 21,
    DisplayName: 'Brightside',
    AffiliateType: 3,
    Value: 'BS354BL302216BS',
  },
  {
    AffiliateId: 1,
    DisplayName: 'One Answer Insurance Ltd',
    AffiliateType: 3,
    Value: 'BN266JF432453',
  },
  {
    AffiliateId: 16,
    DisplayName: 'Admiral Insurance',
    AffiliateType: 2,
    Value: 'CF103AZ219824',
  },
  {
    AffiliateId: 2,
    DisplayName: 'A J Insurance Service',
    AffiliateType: 4,
    Value: 'RM187BS303916',
  },
]

export const POLICY_DURATION_TYPES = ['hours', 'days', 'weeks']

export const LICENCE_HELD_DURATIONS = [
  {
    id: 8,
    value: 'ZeroToFiveMonths',
    description: '0 to 5 months',
    yearDiff: 0,
    order: 0,
  },
  {
    id: 1,
    value: 'SixToElevenMonths',
    description: '6 to 11 months',
    yearDiff: 0.5,
    order: 1,
  },
  {
    id: 2,
    value: 'OneYear',
    description: '1 year',
    yearDiff: 1,
    order: 2,
  },
  {
    id: 3,
    value: 'TwoYears',
    description: '2 years',
    yearDiff: 2,
    order: 3,
  },
  {
    id: 4,
    value: 'ThreeYears',
    description: '3 years',
    yearDiff: 3,
    order: 4,
  },
  {
    id: 5,
    value: 'FourYears',
    description: '4 years',
    yearDiff: 4,
    order: 5,
  },
  {
    id: 6,
    value: 'FiveToNineYears',
    description: '5-9 years',
    yearDiff: 5,
    order: 6,
  },
  {
    id: 7,
    value: 'TenYearsOrMore',
    description: '10 years +',
    yearDiff: 10,
    order: 7,
  },
]

export const TITLE_OPTIONS = [
  {
    id: 1,
    description: 'Mr',
    value: 'Mr',
  },
  {
    id: 2,
    description: 'Mrs',
    value: 'Mrs',
  },
  {
    id: 3,
    description: 'Miss',
    value: 'Miss',
  },
  {
    id: 4,
    description: 'Ms',
    value: 'Ms',
  },
]

export const ROUTES = {
  HOME_PAGE: '/',
  ELIGIBILITY: '/who-we-cover-eligibility',
  FAQ: '/frequently-asked-questions',
  CLAIMS: '/claims',
  CONTACT_US: '/contact-us',
  CONTACT_US_THANK_YOU: '/contact-us/thank-you',
  COVER_DETAILS: '/quote/cover-details',
  PRIVACY_AND_COOKIES: '/privacy-cookies',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  REFUND_POLICY: '/our-refund-policy',
  ERROR: '/error',
  HOLDING_PAGE: '/holding-page',
  POLICY_BASE: '/policy',
  POLICY_CONFIRMATION: '/policy/confirmation',
  INVALID_REG: '/vehicle/invalid-reg',
  QUOTE_BASE: '/quote',
  QUOTE_DRIVING_LICENCE_SCAN: '/quote/scan',
  QUOTE_MORE_INFO: '/quote/driver-details/more-info',
  QUOTE_MORE_INFO_VAN: '/quote/driver-details/van-info',
  QUOTE_DRIVER_DETAILS: '/quote/driver-details',
  QUOTE_PAYMENT_3D_SECURE: '/quote/payment/3d-secure',
  QUOTE_PAYMENT: '/quote/payment',
  QUOTE_DRIVING_LICENCE: '/quote/driving-licence',
  QUOTE_LEGAL_DECLARATION: '/quote/legal-declaration',
  QUOTE_SELECT_PATH: '/quote/select-path',
  ANONYMISED_QUOTE: '/expired-quote',
  ANNUAL_INSURANCE_EMAIL_CONFIRMATION: '/AnnualInsurance/Confirmation',
  EMAIL_UNSUBSCRIBE_SUCCESSFUL: '/unsubscribe-successful',
  CAMPAIGN_TERMS: '/campaign-terms',
}

export const QUOTE_STATUSES = {
  COMPLETE: 'Complete',
  ANONYMISED: 'Anonymised',
}

export const SYSTEM_FEATURES = {
  SYSTEM_DOWN_FOR_MAINTAINANCE: 'SystemDownForMaintenance',
  UPSELL_CTM_ENABLED: 'UpsellCtmEnabled',
  UPSELL_FLOW_ENABLED: 'UpsellFlowEnabled',
  UPSELL_CONFUSED_ENABLED: 'ConfusedUpsellEnabled',
  CONFUSED_EXTERNAL_ACCOUNT: 'UseConfusedExternalAccount',
  CONFUSED_EXTERNAL_ACCOUNT_PROFILE: 'UseConfusedExternalAccountProfile',
  CONFUSED_EXTERNAL_ACCOUNT_VEHICLE: 'UseConfusedExternalAccountVehicle',
  CONFUSED_EXTERNAL_ACCOUNT_SHOW_LOGIN: 'UseConfusedExternalAccountShowLogin',
  CONFUSED_EXTERNAL_ACCOUNT_SAVE_CHANGES: 'UseConfusedExternalAccountSaveChanges',
}

export const VEHICLE_OPTIONS = [
  {
    id: 1,
    description: 'Car',
    value: 'PrivateCar',
    element: 1,
  },
  {
    id: 2,
    description: 'Commercial Vehicle',
    value: 'CommercialVehicle',
    element: 2,
  },
]

export const HTTP_CODES = {
  SUCCESS: {
    OK: 200,
  },
  ERROR: {
    BadRequest: 400,
    Forbidden: 403,
    TooManyRequests: 429,
    Unauthorized: 401,
    NotFound: 404,
    InternalServerError: 500,
    ServiceUnavailable: 503,
  },
}
