import axios from 'axios'
import config from '../../config'
import { useState } from 'react'

const http = axios.create({
  baseURL: `${config.BASE_API_URL}`,
  withCredentials: false,
  headers: { 'Content-Type': 'application/json' },
})

const useWordpressContent = () => {
  const [content, setContent] = useState(null)
  if (!content) {
    http.get(config.WORDPRESS_CONTENT_URL).then((response) => {
      setContent(response.data)
    })
  }
  return { content }
}

export default useWordpressContent
