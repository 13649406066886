import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { submitMoreDetailsForm } from './actions'
import {
  DRIVING_LICENCE_VALIDATION,
  PASSPORT_EXPIRY_DATE_VALIDATION,
  PASSPORT_LINE_ONE_VALIDATION,
  PASSPORT_LINE_TWO_VALIDATION,
} from '../../services/validation'
import PassportExpiryDate from '../../components/PassportExpiryDate/PassportExpiryDate.component'
import PassportExample from '../../images/passport-example.gif'
import { MoreDetailsHelperImage, MoreDetailsLabel } from './styles'
import { Message } from '../../components/Message'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { ConfusedBackButton } from '../../components/common/ConfusedBackButton/ConfusedBackButton.component'
import { ROUTES } from '../../services/constants'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { FieldLabel } from '../../components/common/FieldLabel'
import { UppercaseInput } from '../../components/common/Input/UppercaseInput'
import { Button } from '../../components/common/Button'
import ValidationSummary from '../../components/ValidationSummary/ValidationSummary.component'
import DrivingLicence from '../../components/DrivingLicence'

const MoreDetails = ({
  actions,
  history,
  queryString,
  riskData,
  validation: { IdentityCheck },
}) => {
  useEffect(() => {
    if (!IdentityCheck) {
      history.push(`${ROUTES.QUOTE_DRIVING_LICENCE}${queryString}`)
    }
  }, [IdentityCheck])

  const policyDetails = () => {
    history.push(`${ROUTES.QUOTE_DRIVING_LICENCE}${queryString}`)
  }
  let splitDatePassportExpiry = (riskData.PassportExpiryDate || '')
    .replace(/[ :T]/g, '-')
    .split('-')
  if (splitDatePassportExpiry[0] === '') {
    splitDatePassportExpiry[0] = undefined
  }
  return (
    <ConfusedWrapper>
      <ConfusedBackButton onClick={policyDetails}>
        Your quote
      </ConfusedBackButton>

      <Message
        heading='We need more information about you'
        content="Some of your details didn't quite match up. Please can you provide us some more info below."
        type='info'
      />

      <Formik
        initialValues={{
          DrivingLicenceNumber: riskData.DrivingLicenceNumber,
          PassportLineOne: riskData.PassportLineOne,
          PassportLineTwo: riskData.PassportLineTwo,
          PassportExpiryDate: {
            day: splitDatePassportExpiry[2],
            month: splitDatePassportExpiry[1],
            year: splitDatePassportExpiry[0],
            date: riskData.PassportExpiryDate,
          },
        }}
        validationSchema={Yup.object().shape({
          DrivingLicenceNumber: DRIVING_LICENCE_VALIDATION(IdentityCheck),
          PassportLineOne: PASSPORT_LINE_ONE_VALIDATION(IdentityCheck),
          PassportLineTwo: PASSPORT_LINE_TWO_VALIDATION(IdentityCheck),
          PassportExpiryDate: PASSPORT_EXPIRY_DATE_VALIDATION(IdentityCheck),
        })}
        onSubmit={(values) => {
          actions.submitMoreDetailsForm(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          submitCount,
          touched,
          values,
        }) => {
          const errorCount = Object.keys(errors || {}).length
          return (
            <Form>
              <ValidationSummary
                showSummary={errorCount > 0 && submitCount > 0}
                submitCount={submitCount}
              />
              {IdentityCheck === 'drivingLicence' && (
                <>
                <DrivingLicence 
                 onChange={handleChange}
                 onBlur={handleBlur}
                 error={Boolean(
                  touched.DrivingLicenceNumber &&
                    errors.DrivingLicenceNumber
                )}
                 errorMessage={
                  touched.DrivingLicenceNumber &&
                  errors.DrivingLicenceNumber
                }
                 value={values.DrivingLicenceNumber}
                />
                <MoreDetailsLabel>
                  We will share this with the DVLA in order to confirm your licence status, entitlement and restriction information together with endorsement/conviction data.
                </MoreDetailsLabel>
                </>
              )}
              {IdentityCheck === 'passport' && (
                <>
                  <MoreDetailsHelperImage src={PassportExample} />
                  <FieldLabel htmlFor='PassportLineOne'>
                    Passport line one
                  </FieldLabel>
                  <UppercaseInput
                    id='PassportLineOne'
                    name='PassportLineOne'
                    placeholder='The first line of my passport says...'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.PassportLineOne && errors.PassportLineOne
                    )}
                    errorMessage={
                      touched.PassportLineOne && errors.PassportLineOne
                    }
                    value={values.PassportLineOne}
                  />
                  <FieldLabel htmlFor='PassportLineTwo'>
                    Passport line two
                  </FieldLabel>
                  <UppercaseInput
                    id='PassportLineTwo'
                    name='PassportLineTwo'
                    placeholder='and the second line of my passport says...'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.PassportLineTwo && errors.PassportLineTwo
                    )}
                    errorMessage={
                      touched.PassportLineTwo && errors.PassportLineTwo
                    }
                    value={values.PassportLineTwo}
                  />
                  <PassportExpiryDate
                    id='PassportExpiryDate'
                    name='PassportExpiryDate'
                    onChange={setFieldValue}
                    handleBlurFunction={handleBlur}
                    value={values.PassportExpiryDate}
                    touched={touched.PassportExpiryDate}
                    error={errors.PassportExpiryDate}
                  />
                </>
              )}
              <Button
                type='submit'
                disabled={isSubmitting}
                id='TeslaSubmitDrivingLicenceNextButton'
                onMouseDown={(e) => {
                  e.preventDefault()
                }}
              >
                {isSubmitting ? 'Please wait' : 'Continue'}
                <ButtonSpinner disabled={isSubmitting} />
              </Button>
            </Form>
          )
        }}
      </Formik>

      <ConfusedBackButton onClick={policyDetails}>
        Your quote
      </ConfusedBackButton>
    </ConfusedWrapper>
  )
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    validation: state.validation.riskDataCollectionPage,
    queryString: state.queryString,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        submitMoreDetailsForm,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreDetails)
