import { REQUEST_QUOTE } from '../constants'

import { call, put, select, takeLatest } from 'redux-saga/effects'
import quoteApi from '../../../api/quoteApi'
import {
  createQuote,
  createQuoteFailure,
  createQuoteSuccess,
  getQuoteSummarySuccess,
  requestQuoteFailure,
  requestQuoteSuccess,
} from '../actions'
import {
  createQuoteLink,
  createQuoteWithStartDateLink,
  getCampaignData,
  getConfusedExternalAccountState,
  getQuoteLink,
  getRiskData,
  getSelectedScheme,
  getVehicleInfo,
} from '../selector'

import config from '../../../config'
import { isValidScheme } from '../../../services/handlers'
import { IMPOUNDED_VALUE } from '../../HomePage/constants'
import { getAffiliate } from '../../PolicyConfirmationPage/selector'
import { updateRiskData } from '../../RiskDataCollectionPage/actions'
import { getQuoteSummaryLink } from '../selector'

export function* createQuoteSaga() {
  try {
    yield put(createQuote())
    const parsedQueryString = window.location.search
    const parameters = new URLSearchParams(parsedQueryString)

    const quoteLink = yield select(parameters.get('experiment') != null ? createQuoteWithStartDateLink  : createQuoteLink)
    const vehicleInfo = yield select(getVehicleInfo)
    let {AuthToken} = yield select(getConfusedExternalAccountState)
    const riskData = yield select(getRiskData)
    const affiliate = yield select(getAffiliate)
    const campaignData = yield select(getCampaignData)
    let impounded = riskData.ReasonForCover === IMPOUNDED_VALUE

    const data = {
      VehicleAbi: vehicleInfo.selected.Abi,
      VehicleId: vehicleInfo.selected.VehicleId,
      Duration: parseInt(riskData.Duration),
      DurationType: riskData.DurationType,
      CampaignRef: campaignData.Ref,
      Impounded: impounded,
      ReasonForCover: riskData.ReasonForCover,
      UsingNewConfusedUi: parameters.get('experiment') != null,
      CreatedWithLoggedInConfusedAccount: AuthToken !== '',
      CreatedWithExistingConfusedVehicle: riskData.confusedProfileVehicleSelected
    }
    const referrerValue = parameters.get('referrer')
    if (referrerValue) {
      data.Referrer = referrerValue
    }

    let quoteEndpoint = quoteLink.Href.replace(
      /{[affiliateRef}]*}/,
      affiliate.Ref || 'tempcover'
    ).replace(
      /{[startDateTime}]*}/,
      parameters.get('start'))

    const response = yield call(quoteApi.createQuote, quoteEndpoint, data)
    yield put(createQuoteSuccess(response.data))
  } catch (e) {
    yield put(createQuoteFailure(e))
  }
}

export function* fetchQuote() {
  try {
    const quoteLink = yield select(getQuoteLink)
    const response = yield call(quoteApi.getMotorQuote, quoteLink.Href)
    const scheme = yield select(getSelectedScheme)
    yield put(requestQuoteSuccess(response.data, scheme))
  } catch (e) {
    yield put(requestQuoteFailure(e))
  }
}

export function* fetchQuoteSummary(quoteId) {
  yield put(
    updateRiskData({
      QuoteId: quoteId,
    })
  )

  let quoteSummaryLink = yield select(getQuoteSummaryLink)
  const quoteSummary = yield call(
    quoteApi.getQuoteSummary,
    quoteSummaryLink.Href.replace('{encryptedQuoteId}', quoteId)
  )
  const { data } = quoteSummary
  if (isValidScheme(data.Scheme)) {
    yield put(getQuoteSummarySuccess(data))
  } else {
    window.location.href = config.SITE_URL
  }
}

export default function* fetchQuoteSaga() {
  yield takeLatest(REQUEST_QUOTE, fetchQuote)
}
