import React from 'react'
import { SummaryWrapper } from './MiniSummary.styles'
import moment from 'moment'

interface Props {
  vehicle: {
    RegistrationNumber: string,
    Make: string,
    Model: string,
    GrossWeight: string,
    YearOfManufacture: number
  },
  duration: {
    durationAmount: any,
    durationType: any
  },
  selectedPerson: {
    FirstName: string,
    LastName: string,
    DateOfBirth: string,
    DrivingLicence: {
      Type: string
    }
  }
}

export const MiniSummary: React.FC<Props> = ({ vehicle, duration, selectedPerson }) => {

  if (selectedPerson != null)
    {
      return (
      <SummaryWrapper>
        <table>
          <tbody>
            <tr>
                <td>Name:</td>
                <td className='vehicle-summary-value'>
                  {selectedPerson.FirstName} {selectedPerson.LastName}
                </td>
              </tr>
              <tr>
                <td>Date Of Birth:</td>
                <td className='vehicle-summary-value'>
                  {moment(selectedPerson.DateOfBirth).format("DD/MM/YYYY")}
                </td>
              </tr>
              {
                selectedPerson.DrivingLicence.Type != null && (<tr>
                  <td>Licence:</td>
                  <td className='vehicle-summary-value'>
                    {selectedPerson.DrivingLicence.Type}
                  </td>
                </tr>)
              }
          </tbody>
        </table>
      </SummaryWrapper>)
    }

  return (
    <SummaryWrapper>
      <table>
        <tbody>
          {vehicle.RegistrationNumber ? (
            <tr>
              <td>Vehicle registration:</td>
              <td className='vehicle-summary-value'>
                {vehicle.RegistrationNumber}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {Boolean(vehicle.Make && vehicle.Model) && (
            <tr>
              <td>Vehicle covered:</td>
              <td className='vehicle-summary-value'>{`${vehicle.Make} ${vehicle.Model}`}</td>
            </tr>
          )}
          {!Boolean(vehicle.Make && vehicle.Model) &&
            Boolean(vehicle.GrossWeight && vehicle.YearOfManufacture) && (
              <tr>
                <td>Vehicle covered:</td>
                <td className='vehicle-summary-value'>{`Weight: ${vehicle.GrossWeight}kg Year: ${vehicle.YearOfManufacture}`}</td>
              </tr>
            )}
            {duration && (
              <tr>
                <td>Duration:</td>
                <td className='vehicle-summary-value'>{`${duration.durationAmount} ${duration.durationType}`}</td>
              </tr>
            )}
        </tbody>
      </table>
    </SummaryWrapper>
  )
}
