import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'
import { BaseButton } from './BaseButton'
import Arrow from '../Arrow'

export const SecondaryButton = styled(BaseButton)`
  background: ${(props) =>
    props.disabled
      ? props.theme.button.disabledBackground
      : props.theme.button.secondaryBackgroundColour};
  color: ${(props) =>
    props.disabled
      ? props.theme.button.disabledFont
      : props.theme.button.secondaryTextColour};

  svg > path {
    fill: ${(props) =>
      props.disabled
        ? props.theme.button.disabledFont
        : props.theme.button.secondaryTextColour};
  }
  margin-bottom: ${(props) =>
    props.marginBottom
      ? props.marginBottom
      : '48px'};
  :hover:not([disabled]) {
    svg {
      transition: transform 0.3s ease;
      transform: rotate(45deg);
    }
  }
`

export const TertiaryButton = styled(BaseButton)`
  background: ${(props) =>
    props.disabled
      ? props.theme.button.disabledBackground
      : props.theme.button.tertiaryBackgroundColour};
  color: ${(props) =>
    props.disabled
      ? props.theme.button.disabledFont
      : props.theme.button.tertiaryTextColour};
  
  border: 2px solid #000;

  svg > path {
    fill: ${(props) =>
      props.disabled
        ? props.theme.button.disabledFont
        : props.theme.button.tertiaryTextColour};
  }
  margin-bottom: ${(props) =>
    props.marginBottom
      ? props.marginBottom
      : '48px'};
  
  padding: 14px 27.5px;

  :hover:not([disabled]) {
    svg {
      transition: transform 0.3s ease;
      transform: rotate(45deg);
    }
  }
`

export const StyledButton = styled.button`
  background: ${(props) =>
    props.disabled
      ? props.theme.button.disabledBackground
      : props.theme.button.backgroundColour};
  color: ${(props) =>
    props.disabled
      ? props.theme.button.disabledFont
      : props.theme.button.textColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-family: 'Poppins', sans-serif;
  font-size: ${(props) => props.theme.button.fontSize};
  text-transform: ${(props) => props.theme.button.textTransform};
  text-align: ${(props) => props.theme.button.textAlign};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: ${(props) =>
    props.marginBottom
      ? props.marginBottom
      : '48px'};
  margin-top: ${(props) =>
    props.marginTop
      ? props.marginTop
      : '0px'};
  display: flex;
  align-items: center;

  font-size: ${(props) => props.fontSize ? props.fontSize : '24px'}

  @media (max-width: 650px) {
    font-size: 18px;
  }

  padding: ${(props) => (props.padding ? props.padding : '16px 24px')};
  font-weight: 600;
  text-decoration: none;
  text-align: left;

  svg > path {
    fill: ${(props) =>
      props.disabled
        ? props.theme.button.disabledFont
        : props.theme.button.textColour};
  }

  :hover:not([disabled]) {
    background: #3c3c3c;
    svg {
      transition: transform 0.3s ease;
      transform: rotate(45deg);
    }
  }
`

export const Button = ({
  children,
  onClick,
  onMouseDown,
  secondary,
  tertiary,
  disabled,
  id,
  includeArrow,
  type,
  marginBottom,
  marginTop,
  fontSize
}) => {
  if (secondary)
    return (
      <SecondaryButton
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        id={id}
        className='secondary'
        type={type != null ? type : "submit"}
        marginBottom={marginBottom}
      >
        {children}
        {
          (includeArrow == null || includeArrow) && (
            <Arrow right className='button-arrow' />
          )
        }
      </SecondaryButton>
    )
  
    if (tertiary)
      return (
        <TertiaryButton
          disabled={disabled}
          onClick={onClick}
          onMouseDown={onMouseDown}
          id={id}
          className='tertiary'
          type={type != null ? type : "submit"}
          marginBottom={marginBottom}
        >
          {children}
          {
            (includeArrow == null || includeArrow) && (
              <Arrow right className='button-arrow' />
            )
          }
        </TertiaryButton>
      )

    return (
      <StyledButton
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        id={id}
        className='primary'
        type={type != null ? type : "submit"}
        marginBottom={marginBottom}
        marginTop={marginTop}
        fontSize={fontSize}
      >
        {children}
        {
          (includeArrow == null || includeArrow) && (
            <Arrow right className='button-arrow' />
          )
        }
      </StyledButton>
    )
}

Button.propTypes = {
  onClick: PropTypes.func,
}