import styled from "styled-components";

export const ConfusedBackButtonWrapper = styled.a`
  color: ${(props) => props.theme.primaryTextColour};
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 48px;
  display: flex;
  align-items: center;

  span {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
`