import React from 'react'
import styled from 'styled-components'
import { BaseInput } from '../common/Input/BaseInput'
import PropTypes from 'prop-types'
import validator from 'validator'
import { withTheme } from 'styled-components'
import { ErrorMessage } from '../common/ErrorMessage'
import { FormControlWrapper } from '../FormControlWrapper'

export const TelephoneWrapper = styled(BaseInput)`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export class MobileTelephone extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (event) => {
    this.handleValidation(event)
    this.props.onChange(event)
  }

  handleValidation = (event) => {
    let isValid = false

    if (event.target.value !== '') {
      isValid =
        validator.matches(
          event.target.value,
          /^((07)[12345789]{1}\d{8})|((07624)\d{6})$/
        ) && validator.isLength(event.target.value, { min: 10, max: 11 })
    }

    let message = isValid ? '' : 'Enter a valid Mobile Number'

    this.props.handleValidation(event.target.name, message)
  }

  render() {
    return (
      <div>
        <ErrorMessage>{this.props.error}</ErrorMessage>
        <FormControlWrapper>
          <TelephoneWrapper
            id={this.props.id || 'MobileNumber'}
            type='tel'
            placeholder={this.props.placeholder || ''}
            onBlur={this.props.onBlur}
            onChange={this.handleChange}
            error={this.props.error}
            name={this.props.name || 'Mobile'}
            value={this.props.value}
            pattern='\d*'
          />
        </FormControlWrapper>
      </div>
    )
  }
}

export default withTheme(MobileTelephone)

MobileTelephone.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
}
