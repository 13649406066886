import logo from '../images/TempcoverBrandLogo.png'
var primaryColour = '#1f1f1f'
const defaultTheme = {
  logo: logo,
  primaryColour: primaryColour,
  primaryTextColour: primaryColour,
  headlineColour: primaryColour,
  backgroundColour: '#E8F0FF',
  racColour: '#1f1f1f',
  formControlBorderRadius: '0',
  formControlHorizontalPadding: '10px',
  formControlBottomMargin: '10px',
  generalFontSize: '18px',
  smallerFontSize: '18px',
  formControlHeight: '45px',
  formControlValidIcon: {
    colour: '#fff',
    backgroundColour: primaryColour,
  },
  fontFamily: 'Poppins, Helvetica, sans-serif',
  fontStyle: 'normal',
  fontWeight: '400',
  labelFontSize: '16px',
  mobileLabelFontSize: '10px',
  labelTextColour: '#1f1f1f',
  headerFontFamily: 'Poppins, Helvetica, Arial, sans-serif',
  headerFontColour: primaryColour,
  labelFontFamily: 'Poppins, Helvetica, Arial, sans-serif',
  disabledButtonColour: '#d2d2d2',
  buttonFontSize: '14px',
  buttonTextTransform: 'uppercase',
  buttonTextColour: '#FFFFFF',
  buttonTextAlign: 'center',
  linkFontSize: '18px',
  errorBackgroundColour: '#FFEDE8',
  infoBackgroundColour: '#5bc0de',
  errorBorderProperties: '2px solid #EF5831',
  infoBorderProperties: '2px solid #1f1f1f',
  errorIconColour: '#EF5831',
  infoIconColour: primaryColour,
  messageBorderRadius: '6px',
  regFont: 'Charles-Wright',
  validIcon: '\f00c',
  secondaryColour: '#A2BC1E',
  successColour: '#A2BC1E',
  toggleActiveColour: '#080',
  inputBorderColour: primaryColour,
  inputBorderColourError: '#FF0000',
  addressButton: {
    backgroundColour: '#58AAE0',
  },
  button: {
    backgroundColour: '#1f1f1f',
    secondaryBackgroundColour: '#58AAE0',
    disabledBackground: '#d2d2d2',
    disabledFont: '#fff',
    fontSize: '22px',
    textColour: '#fff',
    secondaryTextColour: '#1f1f1f',
    textAlign: 'left',
    fontWeight: 'bold',
    hover: 'none',
    hoverColour: '#1f1f1f',
  },
  secondaryButton: {
    hoverColour: primaryColour,
  },
  progressionStep: {
    titleDesktopFontSize: '20px',
    titleFontSize: '18px',
    contentDesktopFontSize: '14px',
    contentFontSize: '12px',
    subContentFontSize: '10px',
    subContentExtraSmallFontSize: '8px',
    subContentIconDesktopSize: '15px',
    subContentIconSize: '15px',
  },
  GbIcon: '#fff',
  icon: {
    color: primaryColour,
    backgroundColor: '#E9F0FF',
  },
  staticHeaderColour: '#1F1F1F',
  callUsNumberColour: '#a4c733',
  breadcrumb: {
    activeColor: '#ffffff',
    activeBorderColor: '#264694',
    defaultColor: '#ffffff',
    defaultBorderColor: '#CCCCCC',
    defaultBarColor: '#CCCCCC',
    completeColor: '#264694',
    completeBorderColor: '#264694',
    completeBarColor: '#264694',
    circleSize: 20,
    circleMarginTop: 10,
  },
  spinner: {
    primaryColour: primaryColour,
    textColour: primaryColour,
  },
  drivingLicence: {
    primaryColour: primaryColour,
  },
  durationOptions: {
    primaryColour: primaryColour,
    secondaryColour: '#A2BC1E',
  },
  header: {
    background: '#1f1f1f',
    borderBottom: 'none',
    breadcrumb: {
      background: '#58AAE0',
      borderColor: '#1F1F1F',
      borderColorDisabled: '#8AC4E9',
      color: '#1F1F1F',
      colorDisabled: '#1F1F1F66',
    },
  },
  iconBackground: '#E9F0FF',
  footerInfoBackground: '#1F1F1F',
  mobileHamburgerMenu: '#0e2a6e',
  headerWrapperColour: primaryColour,
  footerWrapperBackground: '#264694',
  footerTextColour: '#a2bc1e',
  documentWrapperBackground: '#ccdcff',
  cookieTextColour: primaryColour,
  headerLinkStyled: {
    padding: '0px',
    hover: {
      textDecoration: 'underline',
      color: '#fff',
      backgroundColour: primaryColour,
      padding: '0px',
    },
  },
  gradient: {
    background: '#E8F0FF',
  },
}

export default defaultTheme
