import React from 'react'
import PropTypes from 'prop-types'
import { BaseRadioButton } from './BaseRadioButton'
import styled from 'styled-components'

const Label = styled.label`
  display: inline-block;
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  border-radius: 0;
  padding: 10px 10px 10px 54px;
  cursor: pointer;
  font-weight: 500;
  background-color: #fff;
  width: 100%;
  min-width: 145px;
  line-height: 1.5rem;
  text-align: left;
  box-sizing: border-box;
  font-size: 18px;
  color: #1f1f1f;

  ::before {
    content: '';
    border: 2px solid #9f9f9f;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    width: 26px;
    height: 26px;
    top: calc(50% - 15px);
    left: 18px;
  }

  ::after {
    content: '';
    border: 9px solid #58aae0;
    background-color: #58aae0;
    width: 0;
    height: 0;
    position: absolute;
    border-radius: 50%;
    zoom: 1;
    top: calc(50% - 9px);
    left: 24px;
    opacity: 0;
    box-sizing: border-box;
  }

  @media (max-width: 768px) {
    min-width: 120px;
  }
`

const RadioWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  max-width: 360px;
`

export const RadioButton = ({
  id,
  name,
  value,
  onChange,
  label,
  checked,
  error,
}) => {
  return (
    <RadioWrapper className='radio-wrapper'>
      <BaseRadioButton
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        type='radio'
        checked={checked}
      ></BaseRadioButton>
      <Label htmlFor={id} error={error}>
        {label ? label : value}
      </Label>
    </RadioWrapper>
  )
}

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}
