import React from 'react'
import { Grid } from 'unsemantic'
import { Container } from './ErrorIcon'
import { MessageHeader } from './MessageHeader.style'
import { MessageContent } from './MessageContent.style'

interface Props {
  heading:string,
  content:any,
  type:string
}

export const Message: React.FC<Props> = ({ heading, content }) => {
  return (
    <>
      <Container>
        <Grid desktop='80' tablet='80' mobile='80'>
          <MessageHeader className='message-header'>{heading}</MessageHeader>
          {content && <MessageContent>{content}</MessageContent>}
        </Grid>
      </Container>
    </>
  )
}
