import { UPDATE_VALIDATION_RISK_DATA_COLLECTION } from '../constants'
import { put, takeLatest, select } from 'redux-saga/effects'

import { toggleFormIsValid } from '../actions'

import { getValidation } from '../selector'

export function* formValidation() {
  const validation = yield select(getValidation)
  const fields = validation.fields
  const validForm = Object.keys(fields).every((key) => {
    return fields[key] === ''
  })
  yield put(
    toggleFormIsValid({
      valid: validForm,
    })
  )
}

export function* formValidationSaga() {
  yield takeLatest(UPDATE_VALIDATION_RISK_DATA_COLLECTION, formValidation)
}
