import React from 'react'
import Modal from '../../components/common/NewModal'
import { H1 } from '../../components/common/H1'
import styled from 'styled-components'

const ModalContent = styled.div`
  color: #1f1f1f;
  font-family: Poppins, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  & li {
    list-style-type: disc;
  }
`

export const DeclarationModal = ({
  myRef,
  text,
  title,
  hideFullDeclaration,
  isVisible,
  id,
}) => {
  return (
    <Modal
      myRef={myRef}
      display='center'
      theme='auto'
      buttons={[]}
      onClose={hideFullDeclaration}
      isVisible={isVisible}
      fullscreen
    >
      <H1>{title}</H1>
      <ModalContent
        id={id}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Modal>
  )
}
