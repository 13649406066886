import styled from 'styled-components'

export const LegalDeclarationAgreeAndContinueText = styled.p`
  text-align: left;
  font-weight: 700;
  margin: 0;
  margin-bottom: 48px;
  color: #1f1f1f;
`

export const PolicyConfirmationButtonWrapper = styled.div`
  padding-top: 48px;
`

export const DocumentLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: ${(props) => (props.reducedMargin ? '12px' : '24px')};
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`

export const ShortDeclarationsText = styled.div`
  ul > li > ul > li {
    color: #1f1f1f;
  }
  ul > li {
    color: #1f1f1f;
  }
  & > ul {
    margin: 24px 0 48px;
    padding: ${(props) => (props.noPadding ? 0 : '')};
    @media (max-width: 768px) {
      padding: 0;
    }
  }
`
