import styled from 'styled-components'

export const ErrorContainer = styled.div`
  background: #fff;
  color: #e61414;
  border: 4px solid #e61414;
  border-radius: 0;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  padding: 24px 0;
`
