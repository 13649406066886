import { Form, Formik } from 'formik'
import Moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { history } from '../..'
import ValidationSummary from '../../components/ValidationSummary/ValidationSummary.component'
import VehicleBuilder from '../../components/VehicleBuilder'
import { ConfusedPageEnd } from '../../components/common/ConfusedPageEnd'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { FieldLabel } from '../../components/common/FieldLabel'
import { SectionHeading } from '../../components/common/SectionHeading'
import config from '../../config'
import { VEHICLE_OPTIONS } from '../../services/constants'
import { HOME_PAGE_VALIDATION } from '../../services/validation'
import { isLoading, updateStep } from '../App/actions'
import RiskDataDiscountBannerContainer from '../RiskDataCollectionPage/components/RiskDataDiscountBannerContainer.component'
import { createUnkownVehicle } from '../Vehicle/actions'
import VehicleRegistration from './VehicleRegistration' 
import {
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  PRIVATE_CAR,
} from '../Vehicle/constants'
import PolicyDurations from '../PolicyDuration'
import ReasonForCover from '../ReasonForCover'
import { IMPOUNDED_VALUE } from './constants'
import {
  GetQuoteWrapper,
  GreyBackground,
  P3,
  TeslaQuoteNextButton,
  VehicleBuilderText,
  VehicleBuilderTextContainer,
} from './styles'

export const formatDate = (startDate, dateEdited) => {
  if (dateEdited) {
    return Moment(startDate).format('ddd DD/MM/YYYY HH:mm')
  } else {
    return 'Policy starts immediately'
  }
}

const HomePage = ({ riskData, vehicle, appStartupComplete, actions }) => {
  useEffect(() => {
    actions.updateStep(0)
  }, [])

  const { duration } = queryString.parse(window.location.search)

  const handleConfirm = (values) => {
    let url = `/quote?duration=${values.PolicyDuration}`

    if (config.IMPOUNDED_ENABLED) {
      url += `&reason=${values.ReasonForCover}`
    }

    actions.isLoading(true)
    if (vehicle.searched.Make !== null)
      url = url.concat(`&make=${vehicle.searched.Make}`)
    if (vehicle.searched.Model !== null)
      url = url.concat(`&model=${vehicle.searched.Model}`)
    if (vehicle.searched.Abi !== null)
      url = url.concat(`&variant=${vehicle.searched.Abi}`)
    if (vehicle.searched.GrossWeight !== null)
      url = url.concat(`&weight=${vehicle.searched.GrossWeight}`)
    if (vehicle.searched.YearOfManufacture !== null)
      url = url.concat(`&year=${vehicle.searched.YearOfManufacture}`)

    actions.createUnkownVehicle(url)
  }

  const handleStartQuote = (values) => {
    if (values.ShowVehicleBuilder) {
      handleConfirm(values)
    } else {
      let url = `/quote?registration=${values.Registration}&duration=${values.PolicyDuration}&start=Immediately`
      if (config.IMPOUNDED_ENABLED) {
        url += `&reason=${values.ReasonForCover}`
      }
      history.push(url)
    }
  }

  return (
    <ConfusedWrapper>
      <RiskDataDiscountBannerContainer></RiskDataDiscountBannerContainer>
      <SectionHeading>Temporary vehicle insurance</SectionHeading>
      <section id='MainCTA' className=''>
        <div className='grid-100 grid-parent'>
          <section id='Tesla' className=''>
            <Formik
              initialValues={{
                Registration: vehicle.selected.RegistrationNumber,
                ShowVehicleBuilder: false,
                PolicyDuration:
                  riskData.Duration && riskData.DurationType
                    ? `${
                        riskData.Duration
                      }+${riskData.DurationType.toLowerCase()}`
                    : (duration || '').replace(/\s/g, '+'),
                VehicleType: vehicle.selected.VehicleType,
                VehicleMake: vehicle.selected.Make,
                VehicleModel: vehicle.selected.Model,
                VehicleVariant: vehicle.selected.Abi,
                VehicleWeight: vehicle.selected.GrossWeight,
                VehicleDateOfManufacture: vehicle.selected.YearOfManufacture,
                ReasonForCover: riskData.ReasonForCover,
                ImpoundedEnabled: config.IMPOUNDED_ENABLED,
              }}
              validationSchema={HOME_PAGE_VALIDATION}
              onSubmit={(values) => handleStartQuote(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                setFieldTouched,
                setFieldValue,
                setValues,
                submitCount,
                touched,
                values,
              }) => {
                const errorCount = Object.keys(errors || {}).length
                /* eslint-disable react-hooks/rules-of-hooks */
                const [globalSubmitCount, setGlobalSubmitCount] = useState(0)
                if (
                  errorCount > 0 &&
                  submitCount > 0 &&
                  globalSubmitCount !== submitCount
                ) {
                  setFieldTouched('Registration', !values.ShowVehicleBuilder)
                  setFieldTouched('VehicleType', !!values.ShowVehicleBuilder)
                  setFieldTouched(
                    'VehicleMake',
                    (values.VehicleType === COMMERCIAL_VEHICLE &&
                      values.VehicleWeight &&
                      values.VehicleWeight <=
                        COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
                      values.VehicleType === PRIVATE_CAR
                  )
                  setFieldTouched('VehicleModel', !!values.VehicleMake)
                  setFieldTouched('VehicleVariant', !!values.VehicleModel)
                  setFieldTouched(
                    'VehicleWeight',
                    values.VehicleType === COMMERCIAL_VEHICLE
                  )
                  setFieldTouched(
                    'VehicleDateOfManufacture',
                    values.VehicleType === COMMERCIAL_VEHICLE
                  )
                  setGlobalSubmitCount(submitCount)
                }
                return (
                  <Form id='TeslaForm'>
                    <ValidationSummary
                      showSummary={errorCount > 0 && submitCount > 0}
                      submitCount={submitCount}
                    />
                    <div className='select-vehicle'>
                      <section id='FindByReg'>
                        {!values.ShowVehicleBuilder && (
                          <>
                            <VehicleRegistration 
                              handleChange={handleChange} 
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              value={values.Registration} 
                              errorMessage={errors.Registration}
                              hasError={Boolean(
                                  touched.Registration && errors.Registration
                                )} 
                              />
                          </>
                        )}
                        {values.ShowVehicleBuilder && (
                          <>
                            <FieldLabel>
                              Find the vehicle from the options below
                            </FieldLabel>
                            <VehicleBuilder
                              searchedVehicle={vehicle.searched}
                              setFieldValue={setFieldValue}
                              setValues={setValues}
                              values={values}
                              errors={errors}
                              touched={touched}
                              vehicleOptions={{
                                vehicleType: VEHICLE_OPTIONS,
                                makes: vehicle.makes,
                                models: vehicle.models,
                                variants: vehicle.variants,
                              }}
                              handleSelect={handleChange}
                              onBlur={handleBlur}
                            />
                          </>
                        )}
                        {values.ShowVehicleBuilder && (
                          <VehicleBuilderTextContainer>
                            <VehicleBuilderText
                              onClick={() => {
                                setFieldValue('ShowVehicleBuilder', false)
                              }}
                            >
                              I can enter the reg
                            </VehicleBuilderText>
                          </VehicleBuilderTextContainer>
                        )}
                      </section>
                    </div>

                    <div className='select-reason-for-cover'>

                    <ReasonForCover 
                    id='ReasonForCover'
                    name='ReasonForCover'
                    value={values.ReasonForCover}
                    error={Boolean(
                      touched.ReasonForCover && errors.ReasonForCover
                    )} 
                    errorMessage={errors.ReasonForCover} 
                    setFieldValue={setFieldValue}/>
                  </div>
                  
                    {values.ReasonForCover !== IMPOUNDED_VALUE ? (
                      <PolicyDurations
                        value={values.PolicyDuration}
                        setFieldValue={setFieldValue}
                        error={Boolean(
                          touched.PolicyDuration && errors.PolicyDuration
                        )}
                        errorMessage={errors.PolicyDuration}
                      />
                    ) : (
                      <>
                        <GreyBackground>
                          Impound Insurance is only available for a duration of
                          30 days
                        </GreyBackground>

                        <ConfusedPageEnd bigPadding></ConfusedPageEnd>
                      </>
                    )}
                    <GetQuoteWrapper>
                      <P3>
                        To process your application we will verify your identity
                        and may check your details with credit reference and
                        fraud prevention agencies. Entering incorrect data risks
                        invalidating your policy. I have read and agree to the{' '}
                        <a href='/terms-conditions' target='_blank'>
                          customer terms of business
                        </a>{' '}
                        and{' '}
                        <a href='/privacy-cookies' target='_blank'>
                          privacy policy
                        </a>
                        .
                      </P3>
                      <TeslaQuoteNextButton
                        id='TeslaQuoteNextButton'
                        type='submit'
                        tabIndex={3}
                        disabled={!appStartupComplete}
                        onMouseDown={(e) => {
                          e.preventDefault()
                        }}
                      >
                        Agree and continue
                      </TeslaQuoteNextButton>
                    </GetQuoteWrapper>
                  </Form>
                )
              }}
            </Formik>
          </section>
        </div>
      </section>
    </ConfusedWrapper>
  )
}

function mapStateToProps(state) {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    appStartupComplete: state.appStartupComplete,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        isLoading,
        updateStep,
        createUnkownVehicle,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
