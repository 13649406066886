import styled from 'styled-components'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

export const ButtonSpinnerWrapper = styled.span`
  // Super mega hack to make the spinner become fullscreen
  //    Yes, we did do this on purpose.
  //    Yes... I know...
  position: fixed;
  left: 0;
  top: 0;
  display: ${(props) => (props.disabled ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  color: #1f1f1f;
  background: rgba(0, 0, 0, 0.2);
  font-size: 150px;
  z-index: 9999999;
  & * {
    fill: #1f1f1f !important;
  }

  -webkit-transition: opacity 0.25s, max-width 0.45s;
  -moz-transition: opacity 0.25s, max-width 0.45s;
  -o-transition: opacity 0.25s, max-width 0.45s;
  transition: opacity 0.25s, max-width 0.45s;
`

export const ButtonSpinner = ({ disabled }) => {
  return (
    <ButtonSpinnerWrapper disabled={disabled}>
      <FontAwesomeIcon icon={faCircleNotch} size={'1x'} spin></FontAwesomeIcon>
    </ButtonSpinnerWrapper>
  )
}
