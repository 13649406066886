export const extraDayOptions = [
  {
    id: 1,
    value: 8,
    description: '8 days',
  },
  {
    id: 2,
    value: 9,
    description: '9 days',
  },
  {
    id: 3,
    value: 10,
    description: '10 days',
  },
  {
    id: 4,
    value: 11,
    description: '11 days',
  },
  {
    id: 5,
    value: 12,
    description: '12 days',
  },
  {
    id: 6,
    value: 13,
    description: '13 days',
  },
  {
    id: 7,
    value: 14,
    description: '14 days',
  },
  {
    id: 8,
    value: 15,
    description: '15 days',
  },
  {
    id: 9,
    value: 16,
    description: '16 days',
  },
  {
    id: 10,
    value: 17,
    description: '17 days',
  },
  {
    id: 11,
    value: 18,
    description: '18 days',
  },
  {
    id: 12,
    value: 19,
    description: '19 days',
  },
  {
    id: 13,
    value: 20,
    description: '20 days',
  },
  {
    id: 14,
    value: 21,
    description: '21 days',
  },
  {
    id: 15,
    value: 22,
    description: '22 days',
  },
  {
    id: 16,
    value: 23,
    description: '23 days',
  },
  {
    id: 17,
    value: 24,
    description: '24 days',
  },
  {
    id: 18,
    value: 25,
    description: '25 days',
  },
  {
    id: 19,
    value: 26,
    description: '26 days',
  },
  {
    id: 20,
    value: 27,
    description: '27 days',
  },
  {
    id: 21,
    value: 28,
    description: '28 days',
  },
]

export const POLICY_DURATION_HOURS = 'hours'
export const POLICY_DURATION_DAYS = 'days'
export const POLICY_DURATION_WEEKS = 'weeks'
