import React from 'react'
import Moment from 'moment'
import {P} from '../common/P'
import { Bold, Wrapper } from './DriverCovered.style'
import { LICENCE_HELD_DURATIONS } from '../../services/constants'

interface Props {
    addressLineOne: string,
    addressLineTwo: string,
    addressLineThree: string,
    addressLineFour: string,
    postcode: string,
    selectedAddress: string,
    licenceHeldDuration: string,
    forename: string,
    surname: string,
    dateOfBirth: Date
}


const DriverCovered: React.FC<Props> = ({ 
    addressLineOne,
    addressLineTwo,
    addressLineThree,
    addressLineFour,
    postcode,
    selectedAddress,
    licenceHeldDuration,
    forename,
    surname,
    dateOfBirth
 }) => {
    const filteredAddress = [
      addressLineOne,
      addressLineTwo,
      addressLineThree,
      addressLineFour,
      postcode,
    ].filter((a) => !!a)
    const AddressInfo = selectedAddress
      ? selectedAddress
      : filteredAddress.join(', ')
    const licenceHeldDurationObj = LICENCE_HELD_DURATIONS.find(
      (l) => l.value === licenceHeldDuration
    )
    return (
      <Wrapper>
        <P>
          Name: <Bold>{`${forename} ${surname}`}</Bold>
        </P>
        <P>
          Date of birth:{' '}
          <Bold>{Moment(dateOfBirth).format('DD/MM/YYYY')}</Bold>
        </P>
        <P>
          Address: <Bold>{AddressInfo}</Bold>
        </P>
        <P>
          Period licence held:{' '}
          <Bold>{licenceHeldDurationObj && licenceHeldDurationObj.description}</Bold>
        </P>
      </Wrapper>
    )
  }

  export default DriverCovered