import styled from 'styled-components'

export const P = styled.p`  
   font-family: ${(props) => props.theme.labelFontFamily}
   font-size:${(props) => props.theme.labelFontSize}    
   @media (max-width: 768px) {
      font-size:${(props) => props.theme.mobileLabelFontSize}   
   }
   margin-bottom: ${(props) => (props.noGap ? '0' : '24px')}
   margin-top: 0;
   color: ${(props) => props.theme.primaryTextColour};
`
