import { createBrowserHistory } from 'history'
import queryString from 'query-string'
import { VALID_AFFILIATE_REF, VALID_URL_REFERRER } from './constants'

const baseHistory = createBrowserHistory()

export const createHistory = () => {
  return {
    ...baseHistory,
    push: (location, state) => {
      let parsed
      if (typeof location === 'string') {
        parsed = queryString.parseUrl(location)
        parsed.query = addMissingUtmTags(parsed.query)
        const url = queryString.stringifyUrl(parsed)
        baseHistory.push(url, state)
      } else {
        const { search } = location
        parsed = queryString.parse(search)
        parsed = addMissingUtmTags(parsed)
        const updatedSearch = queryString.stringify(parsed)
        baseHistory.push({ ...location, search: updatedSearch })
      }
    },
    replace: (location, state) => {
      let parsed
      if (typeof location === 'string') {
        parsed = queryString.parseUrl(location)
        parsed.query = addMissingUtmTags(parsed.query)
        const url = queryString.stringifyUrl(parsed)
        baseHistory.replace(url, state)
      } else {
        const { search } = location
        parsed = queryString.parse(search)
        parsed = addMissingUtmTags(parsed)
        const updatedSearch = queryString.stringify(parsed)
        baseHistory.replace({ ...location, search: updatedSearch })
      }
    },
  }
}

export const addMissingUtmTags = (tags) => {
  const { search } = window.location
  let currentTags = queryString.parse(search)

  Object.keys(currentTags).forEach((key) => {
    key = key.toLowerCase()
    if (
      (key.startsWith('utm_') ||
        key.startsWith('referrer') ||
        key.startsWith('affiliate') ||
        key.startsWith('experiment') || 
        key.startsWith('InternalRvuUserOverride')) &&
      !Object.prototype.hasOwnProperty.call(tags, key)
    ) {
      tags[key] = currentTags[key]
    }
  })

  let newTags = tags

  newTags = setReferrer(newTags)
  newTags = setAffiliateRef(newTags)

  return newTags
}

export const setReferrer = (tags) => {
  let newTags = tags
  let referrer = (newTags['referrer'] || '').toLowerCase()
  if (!VALID_URL_REFERRER.includes(referrer)) {
    referrer = 'confused'
  }

  newTags['referrer'] = referrer

  return newTags
}
export const setAffiliateRef = (tags) => {
  let newTags = tags
  let ref = (newTags['ref'] || '').toLowerCase()
  if (!VALID_AFFILIATE_REF.includes(ref)) {
    ref = 'CF103AE310635'
  }

  newTags['ref'] = ref

  return newTags
}
