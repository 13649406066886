import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { AutoCompleteOption } from './AutoCompleteOption'

const BaseAutoComplete = styled.ul`
  margin-top: -48px;
  box-sizing: border-box;
  width: 100%;
  max-width: 360px;
  list-style: none;
  border: 2px solid #7a9cd3;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
  background-color: #fff;
`

export const AutoComplete = ({
  options,
  searchTerm,
  onClick,
  properties,
  id,
}) => {
  const defaultedProperties = {
    id: properties.id || 'id',
    value: properties.value || 'value',
  }
  return (
    <BaseAutoComplete id={id}>
      {options.map((option, index) => (
        <AutoCompleteOption
          key={index}
          option={option}
          searchTerm={searchTerm}
          onClick={onClick}
          properties={defaultedProperties}
        />
      ))}
    </BaseAutoComplete>
  )
}

AutoComplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchTerm: PropTypes.string,
  properties: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
}
