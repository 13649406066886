import PropTypes from 'prop-types'
import queryStringHelper from 'query-string'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ThemeProvider } from 'styled-components'
import { Grid } from 'unsemantic'
import { AnonymisedQuotePage } from '../../components/AnonymisedQuotePage'
import ErrorPage from '../../components/ErrorPage'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import HoldingPage from '../../components/HoldingPage'
import { PageNotFound } from '../../components/PageNotFound'
import CampaignTermsPage from '../../components/StaticPages/CampaignTermsPage'
import ClaimsPage from '../../components/StaticPages/ClaimsPage'
import EligibilityPage from '../../components/StaticPages/EligibilityPage'
import EmailUnsubscribePage from '../../components/StaticPages/EmailUnsubscribePage'
import FaqsPage from '../../components/StaticPages/FaqsPage'
import PrivacyPage from '../../components/StaticPages/PrivacyPage'
import RefundPolicyPage from '../../components/StaticPages/RefundPolicyPage'
import TermsAndConditionsPage from '../../components/StaticPages/TermsAndConditionsPage'
import { VALID_AFFILIATE_REF, VALID_URL_REFERRER } from '../../constants'
import { addMissingUtmTags } from '../../history'
import useSession from '../../hooks/useSession/useSession'
import { ROUTES } from '../../services/constants'
import { usePrevious } from '../../services/usePrevious'
import defaultTheme from '../../themes/default'
import ContactUsPage from '../ContactUsPage'
import ThankYouPage from '../ContactUsPage/ThankYouPage'
import HomePage from '../HomePage'
import HomepageRedesigned from '../HomePage - Redesigned'
import InvalidRegPage from '../InvalidRegPage'
import PolicyConfirmationPage from '../PolicyConfirmationPage'
import RiskDataCollectionPage from '../RiskDataCollectionPage'
import AppWrapper from './AppWrapper'
import BodyWrapper from './BodyWrapper'
import {
  createClientSession,
  getAppStartup,
  setClientSessionLastActivity,
  updateClientSessionQuote,
} from './actions'
import { init as initFullStory } from '@fullstory/browser';
import { getCookie } from '../../services/cookieService'
import confusedOidcConfig from '../../security/confusedOidcConfig'
import { AuthProvider } from 'react-oidc-context';
import { history } from '../..'

const fullstoryCookie = getCookie('fs_uid')

const App = ({
  actions,
  history,
  location,
  step,
  queryString,
  renderReady,
}) => {
  const prevPath = usePrevious(location.pathname)
  const { manageSession } = useSession()

  useEffect(() => {
    if (window.isHoldingPageOn) {
      history.push(`/holding-page`)
    }
    if(fullstoryCookie){
      initFullStory({orgId: 'M1D0M'})
    }
    actions.getAppStartup()
  }, [])
  useEffect(() => {
    if (location.pathname !== prevPath) {
      manageSession(location)
      let currentTags = queryStringHelper.parse(location.search)
      if (
        !VALID_URL_REFERRER.includes(
          (currentTags['referrer'] || '').toLowerCase())
         || !VALID_AFFILIATE_REF.includes((currentTags['ref'] || '').toLowerCase())) {
        let updatedTags = addMissingUtmTags(currentTags)
        history.replace({
          ...location,
          search: queryStringHelper.stringify(updatedTags),
        })
      }
      window.scrollTo(0, 0)
    }
  }, [location.pathname])

  const showBreadcrumb =
    location.pathname.toLowerCase() === ROUTES.HOME_PAGE.toLowerCase() ||
    location.pathname.toLowerCase().includes(ROUTES.QUOTE_BASE.toLowerCase())

  const isUsingExperment = window.location.search.indexOf('experiment=') > 0

  if (renderReady) {
    return (
      <AuthProvider {...confusedOidcConfig} onSigninCallback={onSigninCallback}>
        <ThemeProvider theme={defaultTheme}>
          <AppWrapper>
            <Header
              step={step}
              query={queryString}
              showBreadcrumb={showBreadcrumb}
            />
            <BodyWrapper className='body'>
              <Grid desktop='100' tablet='100' parent>
                <Switch>
                  <Route
                    path={ROUTES.EMAIL_UNSUBSCRIBE_SUCCESSFUL}
                    component={EmailUnsubscribePage}
                  />
                  <Route
                    path={ROUTES.CONTACT_US_THANK_YOU}
                    component={ThankYouPage}
                  />
                  <Route path={ROUTES.CONTACT_US} component={ContactUsPage} />
                  <Route path={ROUTES.ELIGIBILITY} component={EligibilityPage} />
                  <Route path={ROUTES.FAQ} render={FaqsPage} />
                  <Route path={ROUTES.CLAIMS} component={ClaimsPage} />
                  <Route
                    path={ROUTES.PRIVACY_AND_COOKIES}
                    component={PrivacyPage}
                  />
                  <Route
                    path={ROUTES.REFUND_POLICY}
                    component={RefundPolicyPage}
                  />
                  <Route
                    path={ROUTES.TERMS_AND_CONDITIONS}
                    component={TermsAndConditionsPage}
                  />
                  <Route path={ROUTES.HOLDING_PAGE} component={HoldingPage} />
                  <Route path={ROUTES.ERROR} component={ErrorPage} />
                  <Route
                    path={ROUTES.POLICY_CONFIRMATION}
                    component={PolicyConfirmationPage}
                  />
                  <Route path={ROUTES.INVALID_REG} component={InvalidRegPage} />
                  <Route
                    path={ROUTES.ANONYMISED_QUOTE}
                    component={AnonymisedQuotePage}
                  />
                  <Route
                    path={ROUTES.CAMPAIGN_TERMS}
                    component={CampaignTermsPage}
                  />
                  <Route
                    path={ROUTES.QUOTE_BASE}
                    component={RiskDataCollectionPage}
                  />  
                  <Route exact path={ROUTES.HOME_PAGE} component={isUsingExperment ? HomepageRedesigned : HomePage} />
                  <Route component={PageNotFound} />
                </Switch>
                {/* <IntercomComponent /> */}
              </Grid>
            </BodyWrapper>
            <Footer />
          </AppWrapper>
        </ThemeProvider>
      </AuthProvider>
    )
  }
  return <></>
}

function onSigninCallback(_user) {
  if (_user != null)
  {
    history.replace("/?experiment=de64efbb-1135-4367-9f6e-27a8c802b885&ref=CF103AE310635&referrer=confused") 
  }
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    step: state.step,
    queryString: state.queryString,
    quoteId: state.riskData.QuoteId,
    sessionData: state.session,
    renderReady: state.renderReady,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getAppStartup,
        createClientSession,
        updateClientSessionQuote,
        setClientSessionLastActivity,
      },
      dispatch
    ),
  }
}

App.propTypes = {
  theme: PropTypes.shape({
    logo: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
