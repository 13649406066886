import {
  GET_QUOTE_SUMMARY_SUCCESS,
  REQUEST_OPEN_SCHEMES_SUCCESS,
} from '../constants'
import initialState from '../../../initialState'
import {
  PRIVATE_CAR,
  PRIVATE_CAR_SCHEME,
  IMPOUNDED_PRIVATE_CAR_SCHEME,
  COMMERCIAL_VEHICLE,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  COMMERCIAL_VEHICLE_SCHEME,
  SET_VEHICLE,
} from '../../Vehicle/constants'

function schemeReducer(state = initialState.scheme, action) {
  switch (action.type) {
    case GET_QUOTE_SUMMARY_SUCCESS:
      return {
        ...state,
        selected: action.quoteSummary.Scheme,
      }
    case REQUEST_OPEN_SCHEMES_SUCCESS:
      return {
        ...state,
        open: action.openSchemes.Schemes,
      }
    case SET_VEHICLE:
      let scheme = ''
      switch (action.vehicle.VehicleType) {
        case PRIVATE_CAR:
          scheme = action.vehicle.Impounded
            ? IMPOUNDED_PRIVATE_CAR_SCHEME
            : PRIVATE_CAR_SCHEME
          break
        case COMMERCIAL_VEHICLE:
          scheme = action.vehicle.Impounded
            ? IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME
            : COMMERCIAL_VEHICLE_SCHEME
          break
        default:
          scheme = PRIVATE_CAR_SCHEME
      }
      return {
        ...state,
        selected: scheme,
      }
    default:
      return state
  }
}

export default schemeReducer
