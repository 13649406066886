import {
    REQUEST_CONFUSED_EXTERNAL_ACCOUNT_FAILURE,
    REQUEST_CONFUSED_EXTERNAL_ACCOUNT_SUCCESS
  } from '../constants'
  
  import initialState from '../../../initialState'
  
  function confusedExternalAccountReducer(
    state = initialState.confusedExternalAccount,
    action
  ) {
    switch (action.type) {
      case REQUEST_CONFUSED_EXTERNAL_ACCOUNT_SUCCESS:
        return {
          ...state,
          Account: action.data
        }
      case REQUEST_CONFUSED_EXTERNAL_ACCOUNT_FAILURE:
        return {
          ...state,
          confusedExternalAccount: null,
        }
      default:
        return state
    }
  }
  
  export default confusedExternalAccountReducer  