import React, { Component } from 'react'
import { ErrorMessage } from '../common/ErrorMessage'
import { FormControlWrapper } from '../FormControlWrapper'
import { TITLE_OPTIONS } from '../../services/constants'
import RadioButtonTS from '../common/RadioButtonTS'

interface Option {
  id: number,
  value: string,
  description: string
}

interface Props {
  id?: number,
  error?: boolean,
  errorMessage?: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string,
  value?: string,
  style?: React.CSSProperties
}

const Title: React.FC<Props> = ({
  id,
  error,
  errorMessage,
  onChange,
  name,
  value,
  style,
}) => {
  return (
    <FormControlWrapper>
      {error && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
      {TITLE_OPTIONS.map((option: Option, index: number) => (
        <RadioButtonTS
          id={`${id}_${option.id}`}
          onChange={onChange}
          value={option.value}
          name={name}
          label={option.description}
          style={style}
          checked={value === option.value}
          key={`${id}_${index}`}
          error={error}
        />
      ))}
    </FormControlWrapper>
  )
}

export default Title
