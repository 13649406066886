/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'
import { ConfusedWrapper } from '../common/ConfusedWrapper'

import { H1, H2, H3, P, A } from './styles'

const DriveawayFaqsPage = () => {
  return (
    <ConfusedWrapper>
      <Grid desktop='100' mobile='100' tablet='100'>
        <H1>FAQs</H1>
        <H2>
          Looking for some help? Take a look at our frequently asked questions
          below.
        </H2>
        <H3>How do I contact you? </H3>
        <P>
          Do you need to ask us a question about our policies? Do you wish to
          make a data request? Are you needing to complain about something?
          Simply drop us an email at{' '}
          <A href='mailto://contactus@tempcover.com?subject=Confused.com:%20Contact%20us%20request'>
            contactus@tempcover.com
          </A>{' '}
          or get in touch via our live chat service located in the corner of the
          web page. We look forward to hearing from you.
        </P>
        <H3>Promotional Terms &amp; Conditions</H3>
        <P>
          If you have received a promotional email or SMS from Tempcover, you
          can get a discount for the amount stated within the message until the
          date stated within the message. This discount is only available for
          temporary car or van insurance policies for 1-28 days. The current
          offer began on Friday 13th November and ends online on Thursday 3rd
          December 2020. The discount is taken from the administration fee and
          is not, therefore subject to IPT.
        </P>
        <H3>How do I know if my discount has been applied?</H3>
        <P>
          If you have received a promotional email or SMS from Tempcover, you
          can get a discount for the amount stated within the message until the
          date stated within the message.
        </P>
        <P>
          The link in your email/text will take you to our website; this
          automatically applies the discount for you and if you click the
          information ‘i‘ icon it will show as an itemised discount. If the link
          does not open our website, please get in touch with us and we will
          take a look into it for you.
        </P>
        <H3>How do I unsubscribe from Tempcover marketing communications?</H3>
        <P>
          To opt out from email marketing messages please follow the
          ‘unsubscribe’ link at the foot of the email.
        </P>
        <P>
          To opt-out from SMS marketing messages, please text the code PKLT to
          88802. Texting the code ‘PKLT’ to 88802 will unsubscribe you from all
          future marketing messages via SMS, however, there are some occasions
          where you may be unable to send a message to a short code number. This
          could be because replying to short code numbers is blocked by your
          phone provider. This is usually because there is a small fee attached
          to sending an opt-out. This fee is set by the phone provider and does
          not go to Tempcover.
        </P>
        <P>
          If you are unable to send the code ‘PKLT’ to 88802, please get in
          contact via email to{' '}
          <A href='mailto://contactus@tempcover.com?subject=Confused.com:%20Unsubscribe%20request'>
            contactus@tempcover.com
          </A>{' '}
          and we can unsubscribe you from marketing SMS messages.
        </P>
        <P>
          Alternatively, we can action this for you. Simply get in touch with us
          at{' '}
          <A href='mailto://contactus@tempcover.com?subject=Confused.com:%20Unsubscribe%20request'>
            contactus@tempcover.com
          </A>{' '}
          and we will unsubscribe you from our marketing communications.
        </P>
        <H3>When will my vehicle show on AskMID?</H3>
        <P>
          The details of the Motor Insurers Database (MID) will be shown on your
          Policy Confirmation email.
        </P>
        <P>
          Once you’ve purchased a temporary insurance policy, your insurance
          details will shortly be passed to the MID. We have a time to supply to
          the Database of 7 days and so due to the short-term nature of your
          policy, it is possible your policy may have expired before the details
          appear on askMID.
        </P>
        <P>
          We recommend that you print your Insurance Certificate and have this
          with you as this document is your valid proof of your insurance and
          legal entitlement to drive the vehicle.
        </P>
        <H3>How do I change the details on my policy?</H3>
        <P>
          Due to the short period nature of the insurance we are not able to
          make any changes to the policy once it has been purchased. This
          includes the vehicle insured on the policy, the start date and time of
          the policy or anything else you’ve provided on the application. If you
          have made a mistake when applying for the policy please get in touch
          with us at
          <A href='mailto://contactus@tempcover.com?subject=Confused.com:%20Policy%20update%20request'>
            contactus@tempcover.com
          </A>{' '}
          or via our live chat service and we’ll see what we can do to help you.
        </P>
        <H3>How do I cancel my policy?</H3>
        <P>
          You can cancel your policy at any time by contacting us at{' '}
          <A href='mailto://contactus@tempcover.com?subject=Confused.com:%20Policy%20cancellation%20request'>
            contactus@tempcover.com
          </A>{' '}
          or via our live chat service. Due to the short period nature of the
          insurance refunds are not available once a policy has been purchased.
        </P>
        <P>
          If you’ve made a mistake with your policy please get in touch with us
          and where possible we may be able to assist you with this.
        </P>
        <H3>
          I haven’t received my Policy Confirmation email, what should I do?
        </H3>
        <P>
          It’s possible that the email has made its way into your Junk or Spam
          folder so the first thing to do is check there. If it’s not there
          please get in touch with us at{' '}
          <A href='mailto://contactus@tempcover.com?subject=Confused.com:%20Resend%20emails%20request'>
            contactus@tempcover.com
          </A>{' '}
          or via our live chat service and we’ll look into it for you.
        </P>
        <H3>Why can’t I get a quote?</H3>
        <P>
          If after submitting your application none of our insurers have come
          forward to offer you a quote then we will be unable to assist you with
          short term insurance at this time. This may be due to a combination of
          factors such as your age, the duration that you’ve held your licence
          and the vehicle you’re looking to insure. Whilst we cannot change the
          outcome of this for you, do pop back again in the future; we regularly
          review our acceptance criteria and work hard with our insurers to
          provide quotes for our customers.
        </P>
      </Grid>
    </ConfusedWrapper>
  )
}

export default DriveawayFaqsPage
