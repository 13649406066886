import styled from 'styled-components'
import { H2 } from '../../components/common/H2'

export const LicenceHeader = styled(H2)`
  text-transform: uppercase;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  color: ${(props) => props.theme.drivingLicence.primaryColour};
`
