import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const BreadcrumbWrapper = styled.div`
  background-color: ${(props) => props.theme.header.breadcrumb.background};
  padding: 0 58px;
  margin-bottom: 48px;
  min-height: 50px;

  @media (max-width: 768px) {
    padding: 0;
    min-height: 46px;
  }
`

export const BreadcrumbContainer = styled.div`
  width: 100%;
  max-width: 842px;
  display: flex;
  flex-direction: row;
  min-height: 50px;

  @media (max-width: 768px) {
    padding: 0;
    min-height: 46px;

    a {
      text-align: start;
      padding-left: 32px;
    }

    a:last-child {
      text-align: end;
      padding-right: 32px;
    }
  }
`
export const BreadcrumbNumericTextContainer = styled.span`
  font-weight: normal;
`

export const StepWrapper = styled(Link)`
  flex: 1 1 150px;
  height: 42px;
  line-height: 44px;
  border-bottom: 8px solid
    ${(props) => props.theme.header.breadcrumb.borderColor};
  color: ${(props) => props.theme.header.breadcrumb.color};
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  max-width: 900px;

  @media (max-width: 768px) {
    font-size: 16px;
    height: 38px;
    display: none;
    &.mobile-visible,
    &.mobile-visible.desktop-hidden {
      display: block;
    }
  }

  @media (max-width: 450px) {
    height: 38px;
  }

  &.desktop-hidden {
    display: none;
  }

  &.number-display {
    pointer-events: none;
    border-bottom-color: ${(props) =>
      props.theme.header.breadcrumb.borderColorDisabled};
  }

  &.disabled-link {
    pointer-events: none;
    border-bottom-color: ${(props) =>
      props.theme.header.breadcrumb.borderColorDisabled};
    color: ${(props) => props.theme.header.breadcrumb.colorDisabled};
    font-weight: normal;
  }

  &.previous-step {
    text-decoration: underline;
    font-weight: 400;
  }
`
