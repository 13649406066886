export const UPDATE_POSTCODE = 'src/Address/UPDATE_POSTCODE'

export const REQUEST_ADDRESS = 'src/Address/REQUEST_ADDRESS'
export const REQUEST_ADDRESS_SUCCESS = 'src/Address/REQUEST_ADDRESS_SUCCESS'
export const REQUEST_ADDRESS_FAILED = 'src/Address/REQUEST_ADDRESS_FAILED'
export const RESET_POSTCODE = 'src/Address/RESET_POSTCODE'
export const RESET_SELECTED_ADDRESS = 'src/Address/RESET_SELECTED_ADDRESS'
export const REQUEST_ADDRESS_BY_KEY_SUCCESS =
  'src/Address/REQUEST_ADDRESS_BY_KEY_SUCCESS'

export const SELECT_ADDRESS = 'src/Address/SELECT_ADDRESS'

export const REQUEST_ADDRESS_REL = 'SearchAddressByPostCode'
export const REQUEST_ADDRESS_BY_KEY_REL = 'SearchAddressByAddressKey'
