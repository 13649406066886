import React from 'react'
import { FieldLabel } from '../common/FieldLabel'
import UppercaseInput from '../common/UppercaseInput'
import { MoreDetailsHelperImage } from '../../containers/MoreDetails/styles'
import DrivingLicenceExample from '../../images/driving-licence-example.png'



interface Props {
    onChange: (e: React.ChangeEvent<any>) => void,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    error: boolean,
    errorMessage: string,
    value: string
}

const DrivingLicence:React.FC<Props> = ({
    onChange,
    onBlur,
    error,
    errorMessage,
    value
}) => {

    return (
        <>
            <FieldLabel htmlFor='DrivingLicenceNumber'>
            Driving licence number
            </FieldLabel>
            <UppercaseInput
                id='DrivingLicenceNumber'
                name='DrivingLicenceNumber'
                placeholder='My driving licence number is...'
                onChange={onChange}
                onBlur={onBlur}
                error={error}
                errorMessage={errorMessage}
                value={value} 
                />
            <MoreDetailsHelperImage src={DrivingLicenceExample!} alt='Driving licence Example' />

        </>
    )
}

export default DrivingLicence