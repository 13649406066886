import React from 'react'
import styled from 'styled-components'
import MailCheck from 'react-mailcheck'
import { ErrorMessage } from '../ErrorMessage'
import { FormControlWrapper } from '../../FormControlWrapper'
import { BaseInputTS } from '../Input/BaseInputTS'
import { P } from '../P'

export const SuggestionText = styled.span`
  display: inline;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
`

interface Props {
    id: string,
    name: string,
    placeholder?: string,
    value: string,
    error: boolean,
    errorMessage: string,
    onChange: (e: React.ChangeEvent<any>) => void,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
}

const Email: React.FC<Props> = ({
  id,
  name,
  placeholder,
  onBlur,
  onChange,
  setFieldValue,
  error,
  errorMessage,
  value,
}) => {
  return (
    <>
      <MailCheck email={value} style={{ marginBottom: '24px' }}>
        {(suggestion) => (
          <>
            <FormControlWrapper>
              {error && (
                <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>
              )}
              <BaseInputTS
                id={id}
                name={name}
                placeholder={placeholder}
                type='email'
                onBlur={onBlur}
                onChange={onChange}
                error={error}
                value={value}
              />
            </FormControlWrapper>
            {suggestion && (
              <P>
                Did you mean{' '}
                <SuggestionText
                  onClick={() => setFieldValue(name, suggestion.full)}
                >
                  {suggestion.full}?
                </SuggestionText>
              </P>
            )}
          </>
        )}
      </MailCheck>
    </>
  )
}

export default Email

// Email.propTypes = {
//   onBlur: PropTypes.func,
//   onChange: PropTypes.func,
//   valid: PropTypes.bool,
// }
