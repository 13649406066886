import { call, put, select, takeLatest } from 'redux-saga/effects'
import quoteApi from '../../../api/quoteApi'

import { updateUnderwriterSuccess } from '../actions'

import { getRiskData, updateUnderwriterLinkSelector } from '../selector'
import { getPriceData } from '../../LegalDeclarationModal/selector'
import { UPDATE_UNDERWRITER } from '../constants'
import { QUOTE_JOURNEY_POSITION } from '../../../constants'

export function* underwriterSaga() {
  try {
    const priceData = yield select(getPriceData)

    if (priceData.Underwriter) {
      const updateUnderwriterLink = yield select(updateUnderwriterLinkSelector)
      const riskData = yield select(getRiskData)
      const data = {
        QuoteId: {
          value: riskData.QuoteId,
        },
        Underwriter: priceData.UnderwriterId,
        QuoteJourneyPosition: QUOTE_JOURNEY_POSITION.SystemUnderwriterUpdate,
      }

      const response = yield call(
        quoteApi.updateUnderwriter,
        updateUnderwriterLink.Href,
        data
      )
      yield put(updateUnderwriterSuccess(response.data))
    }
  } catch (e) {}
}

export default function* underwriterListenerSaga() {
  yield takeLatest(UPDATE_UNDERWRITER, underwriterSaga)
}
