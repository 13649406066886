import http from './http'

export default class addressApi {
  static getAddresses(endpoint) {
    return http.get(endpoint)
  }

  static getAddressByKey(endpoint) {
    return http.get(endpoint)
  }
}
