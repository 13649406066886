import React from 'react'
import Discountbanner from '../../../components/Discountbanner'

const RiskDataDiscountBanner = () => (
  <Discountbanner>
    Complete your quote to receive your exclusive discount
  </Discountbanner>
)

export default RiskDataDiscountBanner
