import queryString from 'query-string'
import moment from 'moment'
import { LICENCE_HELD_DURATIONS } from './constants'
import { POLICY_DURATION_DAYS } from '../components/PolicyDurationModal/constants'
import {
  PRIVATE_CAR,
  COMMERCIAL_VEHICLE,
  PRIVATE_CAR_SCHEME,
  IMPOUNDED_PRIVATE_CAR_SCHEME,
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
} from '../containers/Vehicle/constants'

export const handleQueryString = () => {
  const parsedQueryString = queryString.parse(window.location.search)

  let policyDuration, policyDurationType
  // set default
  policyDuration = 1
  policyDurationType = POLICY_DURATION_DAYS

  if (parsedQueryString.duration) {
    var durationArray = parsedQueryString.duration.split(' ')

    if (durationArray.length > 1) {
      policyDuration = durationArray[0]
      policyDurationType = durationArray[1].toLowerCase()
    }
  }
  let vehicleType = null
  switch (parsedQueryString.type) {
    case PRIVATE_CAR:
    case PRIVATE_CAR.toLowerCase():
      vehicleType = PRIVATE_CAR
      break
    case COMMERCIAL_VEHICLE:
    case COMMERCIAL_VEHICLE.toLowerCase():
      vehicleType = COMMERCIAL_VEHICLE
      break
    default:
      break
  }

  let preQuoteInformation = {
    RegistrationNumber: parsedQueryString.registration,
    Duration: policyDuration, //2+week,
    DurationType: policyDurationType,
    UnknownVehicle: {
      Make: parsedQueryString.make,
      Model: parsedQueryString.model,
      Variant: parsedQueryString.variant,
      Abi: parsedQueryString.variant,
      GrossWeight: parsedQueryString.weight,
      YearOfManufacture: parsedQueryString.year,
      VehicleType: vehicleType,
      RegistrationNumber: parsedQueryString.registration,
    },
    QuoteId: parsedQueryString.q,
    Email: parsedQueryString.EmailAddress,
    Ref: parsedQueryString.ref,
    SelectedUnderwriter: parsedQueryString.u,
    CampaignRef: parsedQueryString.utm_campaignref,
    ReasonForCover: parseInt(parsedQueryString.reason),
    PolicyStartDateTime: parsedQueryString.start,
    ConfusedProfileVehicleSelected: parsedQueryString.confusedVehicleId
  }
  return preQuoteInformation
}

export const handleLicenceDurations = (dateOfBirth) => {
  const assumedPassedDate = moment().subtract(17, 'years')
  const fullOptions = LICENCE_HELD_DURATIONS
  let filteredOptions = []
  let yearDiff = assumedPassedDate.diff(dateOfBirth, 'years', true)

  fullOptions.forEach((option) => {
    if (yearDiff >= option.yearDiff) {
      filteredOptions.push(option)
    }
  })

  return filteredOptions
}

export const toTitleCase = (str) => {
  return str.replace(/\b(\w)/g, (txt) => txt.toUpperCase())
}

export const humanize = (value) => {
  return value.split(/(?=[A-Z])/).join(' ')
}

export const loadScript = (url, callback) => {
  let script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        if (callback) callback()
      }
    }
  } else {
    script.onload = function () {
      if (callback) callback()
    }
  }

  script.src = url
  document.getElementsByTagName('body')[0].appendChild(script)
}

export const appendToHead = function (html) {
  document
    .getElementsByTagName('head')[0]
    .appendChild(document.createRange().createContextualFragment(html))
}

export const appendToBody = function (html) {
  document
    .getElementsByTagName('body')[0]
    .appendChild(document.createRange().createContextualFragment(html))
}

export const smoothScroll = (el) => {
  const pageYOffset = window.pageYOffset
  const halfViewportHeight =
    ((window.visualViewport && window.visualViewport.height) ||
      window.innerHeight) / 32
  const elementYOffset = el.getBoundingClientRect().top
  const relativeScrollDistance =
    pageYOffset + elementYOffset - halfViewportHeight - pageYOffset
  if (Math.abs(relativeScrollDistance) > 50) {
    const duration = 200
    const steps = 50
    const step = relativeScrollDistance / steps
    let yScrollPos = pageYOffset
    let currentStep = 0
    const loop = setInterval(() => {
      if (currentStep < steps) {
        yScrollPos += step
        currentStep++
        window.scrollTo(0, yScrollPos)
      } else {
        clearInterval(loop)
      }
    }, duration / steps)
  }
}

export const isValidScheme = (selectedScheme) => {
  // Needs to be built up as we introduce new schemes
  const schemeList = [
    PRIVATE_CAR_SCHEME,
    IMPOUNDED_PRIVATE_CAR_SCHEME,
    COMMERCIAL_VEHICLE_SCHEME,
    IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  ]
  return schemeList.indexOf(selectedScheme) >= 0
}
