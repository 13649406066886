import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { requestAddress, selectAddress } from './actions'
import { InputWrapper } from '../../components/common/Input/InputWrapper'
import { bindActionCreators } from 'redux'
import { Button } from '../../components/common/Button'
import { FieldLabel } from '../../components/common/FieldLabel'
// import { UppercaseInput } from '../../components/common/Input/UppercaseInput'
import  UppercaseInput  from '../../components/common/UppercaseInput'

import AddressSelect from '../../components/AddressSelect'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { isButtonDisabled } from '../RiskDataCollectionPage/actions'

export const AddressInputWrapper = styled(InputWrapper)`
  margin-bottom: 0;
`
const Address = ({
  actions,
  onBlur,
  onChange,
  setFieldTouched,
  setFieldValue,
  values,
  touched,
  errors,
  address,
  addressButtonSearchDisabled,
}) => {
  const [postcodeEdited, setPostcodeEdited] = useState(false)

  const onAddressSearch = (e, errors) => {
    e.preventDefault()
    setPostcodeEdited(false)
    setFieldTouched('Address.Postcode', true)
    setFieldTouched('Address.PostcodeSearched', true)
    setFieldValue('Address.PostcodeSearched', true)
    if (!errors.Postcode) {
      actions.requestAddress(values.Postcode)
      actions.isButtonDisabled({
        buttonName: 'addressSearch',
        disabled: true,
      })
    }
  }

  const handlePostcodeChange = (e) => {
    setPostcodeEdited(true)
    setFieldValue('Address.PostcodeSearched', false)
    setFieldValue('Address.AddressKey', '')
    onChange(e)
  }

  const handleAddressSelect = (e) => {
    const index = e.nativeEvent.target.selectedIndex
    actions.selectAddress(e.nativeEvent.target[index].text)
    onChange(e)
  }

  return (
    <>
      <FieldLabel htmlFor='Address.Postcode'>Enter postcode</FieldLabel>
      <UppercaseInput
        id='Address.Postcode'
        name='Address.Postcode'
        placeholder=''
        onChange={handlePostcodeChange}
        onBlur={onBlur}
        error={Boolean(
          touched.Postcode &&
            (errors.Postcode ||
              (touched.PostcodeSearched && errors.PostcodeSearched))
        )}
        errorMessage={
          touched.Postcode &&
          (errors.Postcode ||
            (touched.PostcodeSearched && errors.PostcodeSearched))
        }
        value={values.Postcode}
        disallowSpaces={true}
      />
      <Button
        secondary
        id='PostcodeSearch'
        onClick={(e) => onAddressSearch(e, errors)}
        disabled={addressButtonSearchDisabled}
      >
        Look up address
        <ButtonSpinner disabled={addressButtonSearchDisabled} />
      </Button>

      {(address.SearchResults || []).length > 0 &&
        !postcodeEdited &&
        !Boolean(errors.Postcode) &&
        !addressButtonSearchDisabled && (
          <>
            <FieldLabel htmlFor='Address.AddressKey'>Address</FieldLabel>
            <AddressSelect
              onChange={handleAddressSelect}
              id='Address.AddressKey'
              options={address.SearchResults}
              name='Address.AddressKey'
              value={values.AddressKey}
              error={Boolean(touched.AddressKey && errors.AddressKey)}
              errorMessage={touched.AddressKey && errors.AddressKey}
            />
          </>
        )}
    </>
  )
}

const mapStateToProps = ({ address, formButtons }) => {
  return {
    address: address,
    addressButtonSearchDisabled: formButtons.addressSearch.disabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestAddress,
        selectAddress,
        isButtonDisabled,
      },
      dispatch
    ),
  }
}

Address.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    })
  ),
  requestAddress: PropTypes.func,
  updateValidation: PropTypes.func,
  updatePostcode: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Address)
