import {
  UPDATE_CONTACT_US_DATA,
  UPDATE_VALIDATION_CONTACT_US_PAGE,
  TOGGLE_FORM_IS_VALID_CONTACT_US_PAGE,
  SUBMIT_CONTACT_US_REQUEST,
  SUBMIT_CONTACT_US_REQUEST_SUCCESS,
  SUBMIT_CONTACT_US_REQUEST_FAILURE,
} from './constants'

export function updateContactUsData(data) {
  return {
    type: UPDATE_CONTACT_US_DATA,
    data,
  }
}

export function updateContactUsValidation(fields) {
  return {
    type: UPDATE_VALIDATION_CONTACT_US_PAGE,
    fields,
  }
}

export function toggleContactUsFormIsValid(form) {
  return {
    type: TOGGLE_FORM_IS_VALID_CONTACT_US_PAGE,
    form,
  }
}

export function submitContactUsRequest(formValues) {
  return {
    type: SUBMIT_CONTACT_US_REQUEST,
    formValues,
  }
}

export function submitContactUsDataSuccess() {
  return {
    type: SUBMIT_CONTACT_US_REQUEST_SUCCESS,
  }
}

export function submitContactUsDataFailure() {
  return {
    type: SUBMIT_CONTACT_US_REQUEST_FAILURE,
  }
}
