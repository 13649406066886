import styled from 'styled-components'
import { Label } from '../Label'

export const SmallFieldLabel = styled(Label)`
  color: ${(props) => props.theme.labelTextColour};
  font-size: 18px;
  text-align: left;
  display: inline-block;
  margin-bottom: 16px !important;
`
