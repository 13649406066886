import React from 'react'
import { FieldLabel } from "../../../components/common/FieldLabel"

import { RegWrapper,  GBIcon, RegInput, VehicleBuilderTextContainer, VehicleBuilderText } from './VehicleRegistration.styles'
import { ErrorMessage } from '../../../components/common/ErrorMessage'


export interface Props {
    handleChange: (e: React.ChangeEvent<any>) => void,
    handleBlur: React.FocusEventHandler<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    hasError: boolean,
    errorMessage: string,
    value: string
}

const VehicleRegistration: React.FC<Props> = ({
    handleChange,
    handleBlur,
    setFieldValue,
    hasError,
    errorMessage,
    value
}) => {

const onRegInput = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase()
    e.target.value = e.target.value.replace(/\s/g, '')
  }

return (
    <>
        <FieldLabel>
            What is the reg of the vehicle you need cover for?
        </FieldLabel>

        {hasError && errorMessage && (
            <ErrorMessage id='RegistrationError'>
            {errorMessage}
          </ErrorMessage>
        )}

        <RegWrapper className='reg-wrapper'>
            <GBIcon
            className='gb-icon'
            error={hasError}
            >
            GB
            </GBIcon>
            <RegInput
                id='Registration'
                autoComplete='off'
                name='Registration'
                type='text'
                autoCorrect='off'
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={onRegInput}
                tabIndex={0}
                error={hasError}
            />
        </RegWrapper>
        <VehicleBuilderTextContainer>
                              <VehicleBuilderText
                                onClick={() => {
                                  setFieldValue('Registration', null)
                                  setFieldValue('ShowVehicleBuilder', true)
                                }}
                              >
                                I don't know the reg yet
                              </VehicleBuilderText>
                            </VehicleBuilderTextContainer>
    </>
)
}

export default VehicleRegistration