import { put, select, takeLatest } from 'redux-saga/effects'
import { history } from '../..'
import { VEHICLE_VALUES } from '../../components/VehicleValue/constants'
import { QUOTE_JOURNEY_POSITION } from '../../constants'
import { saveMarketingPreferences } from '../MarketingPreferencesContainer/saga'
import { enableAllButtons } from '../RiskDataCollectionPage/actions'
import { updateRiskDataSaga } from '../RiskDataCollectionPage/sagas/riskData'
import { getQueryString, getRiskData } from '../RiskDataCollectionPage/selector'
import { updateVehicleValue } from '../Vehicle/actions'
import { SUBMIT_DRIVER_DETAILS_FORM } from './constants'
import { setQuoteJourneyPosition } from '../App/actions'

function* submitDriverDetailsForm(action) {
  const { formValues } = action

  yield put(
    setQuoteJourneyPosition(QUOTE_JOURNEY_POSITION.CustomerDetailsSubmit)
  )

  const marketingPreferences = {
    mobile: formValues.MobileNumber,
    mobileOptIn: formValues.TelephoneNumberOptIn,
    email: formValues.Email,
    emailOptIn: formValues.EmailAddressOptIn,
  }
  yield* saveMarketingPreferences(marketingPreferences)

  const vehicleValueBand = parseInt(formValues.VehicleValue)

  const vehicleValueDesctiption = VEHICLE_VALUES.find(
    (v) => v.value === vehicleValueBand
  )
  const vehicleValue = {
    value: vehicleValueBand,
    description: vehicleValueDesctiption && vehicleValueDesctiption.description,
  }
  yield put(updateVehicleValue(vehicleValue))

  const stateRiskData = yield select(getRiskData)

  const riskData = {
    ...stateRiskData,
    PolicyStartDate: formValues.PolicyStartDate,
    VehicleValueBandId: vehicleValueBand,
    BodyType: formValues.BodyType,
    UseOfVehicle: formValues.UseOfVehicle,
    Occupation: formValues.Occupation,
    Title: formValues.Title,
    Forename: formValues.Forename,
    Surname: formValues.Surname,
    DateOfBirth: formValues.DateOfBirth.date,
    AddressKey: formValues.Address.AddressKey,
    LicenceType: formValues.LicenceType,
    LicenceHeldDuration: formValues.LicenceHeldDuration,
    EmailAddress: formValues.Email,
    Mobile: formValues.MobileNumber,
    DrivingLicenceNumber: formValues.DrivingLicenceNumber,
    PassportLineOne: formValues.PassportLineOne,
    PassportLineTwo: formValues.PassportLineTwo,
    PassportExpiryDate: formValues.PassportExpiryDate.date,
    confusedProfilePersonSelected: formValues.confusedProfilePersonSelected
  }

  const queryString = yield select(getQueryString)
  yield* updateRiskDataSaga({
    payload: {
      riskData,
      getPrice: true,
      callback: () => {
        enableAllButtons()
        history.push(`/quote/driving-licence${queryString}`)
      },
    },
  })
}

export default function* driverDetailsSaga() {
  yield takeLatest(SUBMIT_DRIVER_DETAILS_FORM, submitDriverDetailsForm)
}
