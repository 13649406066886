import styled from 'styled-components'

export const I = styled.i`
  font-size: 30px;
  vertical-align: 50%;
  text-align: center;
  border-radius: 100%;
  padding: 5px;
  padding-bottom: 10px;
`

export const ErrorIcon = styled(I)`
  color: ${(props) => props.theme.errorIconColour};
  border: ${(props) => props.theme.errorBorderProperties};
`

// eslint-disable-next-line no-undef, no-mixed-operators
export const InfoIcon = styled(I)<{type:string }>`
  color: ${(props) => props.type === 'info' && props.theme.infoIconColour};
  font-size: 50px;
  margin-top: 10px;
`

export const Container = styled.div`
  background: #fff;
  color: #e61414;
  border: 4px solid #e61414;
  border-radius: 0;
  display: flex;
  align-items: left;
  margin-bottom: 48px;
  padding: 24px 0;
`
