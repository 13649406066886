import React from 'react'
import { Select } from '../common/Select'
import { VEHICLE_VALUES } from './constants'

interface Props {
  name:string,
  value:string,
  onChange: (e: React.ChangeEvent<any>) => void,
}

const VehicleValue:  React.FC<Props> = ({
name,
value,
onChange,
}) => {
    return (
      <Select
        id='VehicleValue'
        options={VEHICLE_VALUES}
        name={name}
        onChange={onChange}
        value={value}
        myRef={undefined}
        style={{}}
        tinyGap={(0)}
        onBlur={undefined}
      />
    )
}

export default VehicleValue