/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import { H1, H2, P, Italic, LI, UL_N, Icon } from './styles'
import { ConfusedWrapper } from '../common/ConfusedWrapper'

const EligibilityPage = () => {
  return (
    <ConfusedWrapper>
      <Grid desktop='100' mobile='100' tablet='100'>
        <H1>Eligibility</H1>
        <H2>Who we cover</H2>
        <P>
          We cover drivers who wish to be insured to drive a vehicle for a short
          period of time for reasons such as:
        </P>
        <UL_N>
          <LI>
            <Icon icon={faCheck} />
            You wish to share a long drive
          </LI>
          <LI>
            <Icon icon={faCheck} />
            You need to borrow someone else’s car
          </LI>
          <LI>
            <Icon icon={faCheck} />
            You want to drive your mum/dad’s car whilst home from university
          </LI>
          <LI>
            <Icon icon={faCheck} />
            Your car is being fixed so you need to borrow someone else’s car
          </LI>
          <LI>
            <Icon icon={faCheck} />
            You’re buying a new vehicle and want to collect it and get it home
            before arranging an annual policy
          </LI>
          <LI>
            <Icon icon={faCheck} />
            You need to drive a different vehicle e.g. a van to move house
          </LI>
          <LI>
            <Icon icon={faCheck} />
            You need to collect an impounded vehicle
          </LI>
          <LI>
            <Icon icon={faCheck} />
            You are learning to drive
          </LI>
        </UL_N>
        <H2>Eligibility criteria</H2>
        <ul>
          <LI>You must be aged between 17 and 78 years old</LI>
          <LI>
            You must hold a UK driving licence, an EU or a UK provisional
            driving licence
          </LI>
          <LI>
            You must have been a permanent UK resident for the last 12 months or
            a British expatriate who has previously been a permanent resident of
            the UK
          </LI>
          <LI>
            You must have no more than 7 licence points in the last 3 years
          </LI>
          <LI>
            You must not have been disqualified from driving for the last 2
            years
          </LI>
          <LI>You must have no more than 2 fault claims in the last 3 years</LI>
          <LI>
            You must have no criminal convictions, a current Anti-Social
            Behaviour Order (ASBO) or Criminal Behaviour Order (CBO)
          </LI>
          <LI>
            You must not have had a previous policy of insurance declared void
            by an insurer
          </LI>
          <LI>
            The minimum vehicle value is £500 and the maximum claim for a
            vehicle is capped at £65,000 depending on the insurer chosen
          </LI>
          <LI>
            The vehicle must be in a roadworthy condition with a current and
            valid MOT{' '}
            <em>
              (unless you are using the insurance policy to take the vehicle to
              a pre-booked MOT)
            </em>
          </LI>
          <LI>
            The vehicle must be taxed{' '}
            <em>
              (unless you are using the insurance policy to obtain tax for the
              vehicle immediately after purchase)
            </em>
          </LI>
        </ul>
        <Italic>
          Please be aware that each insurer on our panel has their own
          eligibility criteria and so you should pay close attention to their
          Proposer’s Declaration.
        </Italic>
        <H2>What we don’t cover:</H2>
        <UL_N>
          <LI>
            <Icon icon={faTimes} />
            No vehicle modifications allowed (except for those designed to aid
            disabled drivers)
          </LI>
          <LI>
            <Icon icon={faTimes} />
            Left hand drive cars (we do insure left hand drive motorhomes)
          </LI>
          <LI>
            <Icon icon={faTimes} />
            Cars with more than 8 seats in total
          </LI>
          <LI>
            <Icon icon={faTimes} />
            Commercial vehicles with more than 6 seats in total
          </LI>
          <LI>
            <Icon icon={faTimes} />
            Use for hire or loan vehicles (i.e. vehicle rentals, vehicle
            salvage/recovery agents, credit hire vehicle/companies and accident
            management companies)
          </LI>
          <LI>
            <Icon icon={faTimes} />
            Use for hire and reward, racing, pace-making, speed testing,
            commercial travelling or use for any purpose in relation to the
            motor trade
          </LI>
          <LI>
            <Icon icon={faTimes} />
            Use for carrying hazardous goods or use at a hazardous location
          </LI>
          <LI>
            <Icon icon={faTimes} />
            Vehicles declared as SORN
          </LI>
          <LI>
            <Icon icon={faTimes} />
            International driving licences
          </LI>
        </UL_N>
      </Grid>
    </ConfusedWrapper>
  )
}

export default EligibilityPage
