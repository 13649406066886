import React from 'react'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import useChristmasCampaign from '../../hooks/useChristmasCampaign/useChristmasCampaign'
import confusedPoundLight from '../../images/confused-pound-light.svg'
import { useImage } from '../../hooks/useImage/useImage'
import {P} from '../common/P'
import { BannerImage, BannerImageContainer, CurrentPrice, CurrentPriceSection, CurrentPriceSectionDiscount, DiscountPrice, DiscountSection, DiscountSectionText, DriverDetailsValue, ImageSection, ImageSectionDiscount, PreviousPrice, PreviousPriceSection, PriceSection, SpecialDiscountBadge, SpecialDiscountText, UnderwriterPriceBadgeContainer, UnderwriterPriceBadgeContainerDiscount, UnderwriterPriceBadgePriceSection } from './UnderwriterPriceBadge.style'

interface CampaignPros {
    Ref: string,
       Name: string,
       Discount: number,
}

interface PricingProps {
    InsurancePremium: number,
    Ipt: number,
    AdminFee: number,
    TotalPrice: number,
    CompulsoryExcess: number,
    commission: number,
    Underwriter: string,
    UnderwriterDisplayName: string,
    UnderwriterId: number,
    EncryptedString: string,
    AffiliateAdminFee: number,
    isUpdating: boolean,
    UnderwriterChanged: boolean,
    UnderwriterChangedByCue: boolean,
    campaign: CampaignPros
}

interface Props {
    price: PricingProps
}


  const UnderwriterPriceBadge: React.FC<Props> = ({
    price
  }) => {
    let discount = useSelector((state) => state.price.campaign.Discount)
    let underwriterName = useSelector((state) => state.price.Underwriter)
    const Image = ({ fileName, alt, className, ...rest }) => {
      const { loading, error, image } = useImage(fileName)
      if (error) return <P>{alt}</P>
      return (
        <>
          {loading || image === null ? (
            <P>loading</P>
          ) : (
            <img
              className={`Image${
                className ? className.padStart(className.length + 1) : ''
              }`}
              src={image}
              alt={alt}
              {...rest}
            />
          )}
        </>
      )
    }
    const hasDiscount = !!(discount < 0)
    const parsedQueryString = queryString.parse(window.location.search)
  
    let specialDiscount = useChristmasCampaign(parsedQueryString.utm_campaignref)
  
    let previousPrice
    if (hasDiscount) {
      previousPrice = Math.abs(discount) + price.TotalPrice
    }
    return (
      <>
        {hasDiscount ? (
          <>
            <SpecialDiscountBadge>
              <BannerImageContainer>
                <BannerImage src={confusedPoundLight} alt='Logo'></BannerImage>
              </BannerImageContainer>
              <SpecialDiscountText>
                Special Offer Applied.
                {specialDiscount && (
                  <> 50% off admin fee discount is available until 01/01/24</>
                )}{' '}
              </SpecialDiscountText>
            </SpecialDiscountBadge>
            <UnderwriterPriceBadgeContainerDiscount id='UnderwriterPriceBadge'>
              <ImageSectionDiscount>
                <Image
                  fileName={`${underwriterName}LogoForUnderwriterSelection.png`}
                  alt={price.Underwriter}
                  className='price-comparison-underwriter-logo'
                />
              </ImageSectionDiscount>
              <PriceSection>
                <UnderwriterPriceBadgePriceSection>
                  <CurrentPriceSectionDiscount>
                    Total price now
                    <CurrentPrice>£{price.TotalPrice.toFixed(2)}</CurrentPrice>
                  </CurrentPriceSectionDiscount>
                  <PreviousPriceSection>
                    Was
                    <PreviousPrice>£{previousPrice.toFixed(2)}</PreviousPrice>
                  </PreviousPriceSection>
                  <DiscountSection>
                    <DiscountSectionText>
                      You save&nbsp;
                      <DiscountPrice>
                        £{Math.abs(discount).toFixed(2)}
                      </DiscountPrice>
                    </DiscountSectionText>
                  </DiscountSection>
                </UnderwriterPriceBadgePriceSection>
                <DriverDetailsValue></DriverDetailsValue>
              </PriceSection>
            </UnderwriterPriceBadgeContainerDiscount>
          </>
        ) : (
          <>
            {price.TotalPrice > 0 && (
              <UnderwriterPriceBadgeContainer id='UnderwriterPriceBadge'>
                <ImageSection>
                  <Image
                    fileName={`${underwriterName}LogoForUnderwriterSelection.png`}
                    alt={price.Underwriter}
                    className='price-comparison-underwriter-logo'
                  />
                </ImageSection>
                <PriceSection>
                  <UnderwriterPriceBadgePriceSection>
                    <CurrentPriceSection>
                      Total price
                      <CurrentPrice>£{price.TotalPrice.toFixed(2)}</CurrentPrice>
                    </CurrentPriceSection>
                  </UnderwriterPriceBadgePriceSection>
                  <DriverDetailsValue></DriverDetailsValue>
                </PriceSection>
              </UnderwriterPriceBadgeContainer>
            )}
          </>
        )}
      </>
    )


  }

  export default UnderwriterPriceBadge
