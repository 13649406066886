import styled from 'styled-components'

export const BrandLogo = styled.div`
  float: left;
  max-width: 361px;
  width: 100%;
  height: 70px;

  @media (max-width: 768px) {
    height: 50px;
  }
`

export const BrandLogoAlign = styled.div`
  display: flex;
  max-width: 395px;
  width: 100%;
  height: 48px;
  align-items: center;
`

export const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-decoration: none;
  transition: opacity 0.2s;
`

export const Image = styled.img`
  max-width: 395px;
  max-height: 100%;
  vertical-align: top;

  @media (max-width: 1170px) {
    max-width: 250px;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`

export const LogoImage = styled.div`
color: #fff;
margin: 0;
width: 203px;
height: 36px;
position: absolute;
top: 12px;
left: 58px;

@media (max-width: 768px) {
  width: 152px;
  height: 27px;
  left: 18px;
}
`
