import { put, select } from 'redux-saga/effects'
import { history } from '../..'
import { enableAllButtons } from '../../containers/RiskDataCollectionPage/actions'
import { getQueryString } from '../RiskDataCollectionPage/selector'

export default function* legalDeclarationSaga() {
  const queryString = yield select(getQueryString)
  history.push(`/quote/legal-declaration${queryString}`) //TODO Need to fix up declartion routing, too many things going on prior to getting here
  yield put(enableAllButtons())
}
