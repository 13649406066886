import { SESSIONSTATES, QUOTE_JOURNEY_POSITION } from './constants'

export default {
  appStartupComplete: false,
  renderReady: false,
  tokenData: {},
  links: [],
  features: {},
  step: 0,
  queryString: '',

  sessionData: {
    sessionGuid: '',
    sessionQuoteId: '',
    sessionState: SESSIONSTATES.NEW_SESSION,
    sessionLastActivityTimestamp: '',
  },
  affiliate: {
    AffiliateId: null,
    DisplayName: '',
    AffiliateType: null,
    Name: '',
    Ref: '',
    CustomerPresent: null,
    PriceComparison: false,
  },
  correlationId: null,
  cameraEnabled: true,
  vehicle: {
    searched: {
      VehicleId: null,
      RegistrationNumber: null,
      Make: '',
      Model: '',
      VehicleType: null,
      Abi: null,
      MakeAndModel: null,
      VehicleFound: null,
      VehicleBuilt: null,
      GrossWeight: '',
      YearOfManufacture: null,
      vehicleValue: {
        description: '',
        value: null,
      },
      Impounded: false,
    },
    selected: {
      VehicleId: null,
      RegistrationNumber: null,
      Make: '',
      Model: '',
      VehicleType: null,
      Abi: null,
      MakeAndModel: null,
      vehicleValue: {
        description: '',
        value: null,
      },
    },
    validation: {
      grossWeightError: '',
      yearOfManufactureError: '',
    },
    error: false,
    scheme: null,
    makes: [],
    models: [],
    variants: [],
  },
  price: {
    InsurancePremium: 0,
    Ipt: 0,
    AdminFee: 0,
    TotalPrice: 0,
    CompulsoryExcess: 0,
    commission: 0,
    Underwriter: null,
    UnderwriterDisplayName: '',
    UnderwriterId: null,
    EncryptedString: null,
    AffiliateAdminFee: 0,
    isUpdating: false,
    UnderwriterChanged: false,
    UnderwriterChangedByCue: false,
    campaign: {
      Ref: '',
      Name: '',
      Discount: 0,
    },
    Prices: [],
  },
  quote: {
    TotalPrice: 0,
    Underwriter: null,
    QuoteJourneyPosition: QUOTE_JOURNEY_POSITION.NotSet,
  },
  riskData: {
    Forename: '',
    Surname: '',
    AddressKey: '',
    DateOfBirth: null,
    Duration: 1,
    DurationType: '',
    EmailAddress: '',
    Mobile: '',
    LicenceType: '',
    ReasonForCover: null,
    PolicyStartDate: {
      value: null,
      manuallyEdited: false,
    },
    PolicyEndDate: null,
    QuoteId: '',
    LicenceHeldDuration: '',
    DrivingLicenceNumber: '',
    PassportLineOne: '',
    PassportLineTwo: '',
    PassportExpiryDate: null,
    Title: '',
    BodyType: '',
    UseOfVehicle: '',
    Occupation: {
      Id: 0,
      OccupationCode: '',
      OccupationName: '',
    },
    confusedProfilePersonSelected: null,
  },
  address: {
    Postcode: '',
    SearchResults: [],
    FailedSearch: false,
    AddressLineOne: null,
    AddressLineTwo: null,
    AddressLineThree: null,
    AddressLineFour: null,
    SelectedAddress: null,
    AddressKey: null,
  },
  confusedExternalAccount: {
    Account: null,
    AuthToken: '',
  },
  confusedProfileVehicleSelected: null,
  confusedQuickQuote: {
    OptIn: null,
    RequestAccepted: false,
    RequestedDateTime: null,
    Pricing: {},
    PricingLoaded: false,
    PricingLoadAttempts: 0,
  },
  contactUs: {
    FullName: '',
    RegistrationNumber: '',
    PolicyNumber: '',
    MobileNumber: '',
    EmailAddress: '',
    EnquiryType: '',
    CommentBox: '',
    ReCAPTCHA_Token: '',
    isPosting: false,
    error: false,
  },
  occupation: {
    OccupationSearch: '',
    SearchResults: [],
    SelectedOccupation: null,
  },
  validation: {
    riskDataCollectionPage: {
      fields: {
        ForenameError: null,
        SurnameError: null,
        DateOfBirthError: null,
        PostcodeError: null,
        AddressError: null,
        DatePassedTestError: '',
        LicenceHeldDurationError: null,
        DrivingLicenceNumberError: '',
        LicenceTypeError: '',
        PassportLineOneError: '',
        PassportLineTwoError: '',
        PassportExpiryDateError: '',
        BodyTypeError: '',
        UseOfVehicleError: '',
        OccupationError: '',
        EmailAddressError: null,
        MobileError: null,
        TitleError: null,
      },
      ValidForm: false,
      ManuallyEdited: false,
      IdentityCheck: '',
      AllFieldsValid: true,
      MarketingPreferencesChecked: false,
    },
    policyConfirmationPage: {
      ValidForm: false,
    },
    contactUsPage: {
      fields: {
        FullNameError: null,
        RegistrationNumberError: null,
        PolicyNumberError: '',
        MobileNumberError: '',
        EmailAddressError: null,
        EnquiryTypeError: null,
        CommentBoxError: null,
        ReCAPTCHAError: null,
      },
      ValidForm: false,
    },
  },
  legalDeclaration: {
    ShortText: '',
    FullText: '',
    DisallowedOccupations: '',
    PolicyWordingUrl: '',
    InsuranceProductInformationUrl: '',
    TempcoverContractUrl: '',
  },
  policy: {
    PolicyId: '',
    annualQuote: 0,
    annualLink: '',
    reasonForCover: {
      value: null,
      description: '',
    },
    PolicyDocuments: [],
    analysisSent: false,
    callbackRequest: '',
    confusedExternalAccountUsed: null,
    confusedExternalAccountPersonUsed: null,
    confusedExternalAccountVehicleUsed: null,
    forename: '',
    surname: '',
  },
  payment: {
    Secure: {},
    optionsVisible: null,
    paymentError: '',
    paymentButtonDisabled: false,
    paymentInProgress: false,
    priceMatch: true,
  },
  isLoading: true,
  showLiveChat: true,
  formButtons: {
    driverDetails: {
      disabled: false,
    },
    driverLicence: {
      disabled: false,
    },
    legalDeclaration: {
      disabled: false,
    },
    vanInfo: {
      disabled: false,
    },
    paymentButton: {
      disabled: false,
    },
    addressSearch: {
      disabled: false,
    },
    addressSelect: {
      disabled: false,
    },
    moreDetails: {
      disabled: false,
    },
    marketingPreferences: {
      disabled: false,
    },
  },
  scheme: {
    open: [],
    selected: '',
  },
  ui: {
    VisiblePriceBreakdown: null,
    HourDurationType: {
      isVisible: true,
      messageIsVisible: false,
    },
  },
  myLicence: {
    Enabled: false,
    Actioned: false,
    DisabledBySetting: false,
    CheckFailed: false,
    FailReasons: [],
  },
  marketingPreferences: {
    loaded: false,
    error: false,
    PreviousCustomer: false,
    EmailAddressOptIn: true,
    TelephoneNumberOptIn: true,
  },
  experiments: {},
}
