import React from 'react'
import {
  BreadcrumbWrapper,
  BreadcrumbContainer,
  BreadcrumbNumericTextContainer,
  StepWrapper,
} from './Breadcrumb.styles'
import Steps from './constants'

interface StepProps {
    displayName: React.ReactNode,
    route: string | undefined,
    isDisabled?: boolean,
    isPreviousStep?: boolean,
    isNumberDisplay?: boolean,
    showOnMobile: boolean,
    isDesktopHidden?: boolean,
}


const Step:React.FC<StepProps> = ({
    displayName,
    route,
    isDisabled,
    isPreviousStep,
    isNumberDisplay,
    showOnMobile,
    isDesktopHidden,
  }) => {
    return (
      <StepWrapper
        className={`${isDisabled ? 'disabled-link ' : ''}${
          isPreviousStep ? 'previous-step ' : ''
        }${isNumberDisplay ? 'number-display ' : ''}${
          showOnMobile ? 'mobile-visible ' : ''
        }${isDesktopHidden ? 'desktop-hidden ' : ''}
        `}
        to={route || '/'}      
        >
        {displayName}
      </StepWrapper>
    )
  }


interface Props {
    step: number,
    query: string
}

const Breadcrumb:React.FC<Props> = ({ step, query }) => {
  return (
    <BreadcrumbWrapper id='Breadcrumb'>
      <BreadcrumbContainer>
        {Object.entries(Steps).map(([key, value]) => {
          return (
            <Step
                key={`Step_${key}`}
                displayName={value.Display}
                route={`${value.Route}${query}`}
                isDisabled={parseInt(key) > step}
                isPreviousStep={parseInt(key) < step}
                showOnMobile={parseInt(key) === step} 
            />
          )
        })}
        <Step
            displayName={<BreadcrumbNumericTextContainer>
                <b>{step + 1}</b> of {Object.entries(Steps).length}
            </BreadcrumbNumericTextContainer>}
            isNumberDisplay
            showOnMobile
            isDesktopHidden
            route=''
            />
      </BreadcrumbContainer>
    </BreadcrumbWrapper>
  )
}

export default Breadcrumb
