import styled from 'styled-components'

const gapSize = {
  big: '48px',
  medium: '24px',
}

export const H2 = styled.h2`
  font-size: 22px;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.headerFontColour};
  text-align: left;
  margin-top: 0;
  margin-bottom: ${(props) => gapSize[props.gap] || '0'};
`
export const ThinH2 = styled.h2`
  font-size: ${(props) => props.theme.generalFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 400;
  color: #1f1f1f;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 24px;
`
