import styled from 'styled-components'

export const Seperator = styled.hr`
  border-top: 4px solid #1f1f1f;
  margin-top: 35px;
  margin-bottom: 24px;
`

export const LargeSeperator = styled.hr`
  border-top: 4px solid #1f1f1f;
  margin-top: 48px;
  margin-bottom: 48px;
`
