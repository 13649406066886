import React from 'react'
import { Select } from '../../components/common/Select'
import { ENQUIRYTYPE_OPTIONS } from './constants'
import { ErrorMessage } from '../../components/common/ErrorMessage'

export const EnquiryType = ({
  id,
  name,
  value,
  error,
  errorMessage,
  onChange,
  onBlur,
}) => {
  return (
    <>
      {error && <ErrorMessage id={`${id}Error`}>{errorMessage}</ErrorMessage>}
      <Select
        placeholder='Please select'
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        options={ENQUIRYTYPE_OPTIONS}
        name={name}
        value={value}
        error={error}
      />
    </>
  )
}
