import React from 'react'
import PropTypes from 'prop-types'
import { bodyTypeOptions } from './constants'
import { ErrorMessage } from '../common/ErrorMessage'
import SelectTS from '../common/SelectTS'

interface Props {
  onChange :(e: React.ChangeEvent<any>) => void,
  onBlur: React.FocusEventHandler<HTMLSelectElement>,
  value: string,
  error: any,
  errorMessage: string,
  id: string,
  name: string,
}

const BodyType: React.FC<Props> = ({
  onChange,
  onBlur,
  value,
  error,
  errorMessage,
  id,
  name,
}) => {
  return (
    <>
      {error && <ErrorMessage id={`${id}Error`}>{errorMessage}</ErrorMessage>}
      <SelectTS
        placeholder="My van's body type is..."
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        options={bodyTypeOptions}
        name={name}
        value={value}
        error={error}
      />
    </>
  )
}

export default BodyType