import { TOGGLE_FORM_IS_VALID, SUBMIT_DRIVER_DETAILS_FORM } from './constants'

export function toggleFormIsValid(valid) {
  return {
    type: TOGGLE_FORM_IS_VALID,
    valid,
  }
}

export function submitDriverDetailsForm(formValues) {
  return {
    type: SUBMIT_DRIVER_DETAILS_FORM,
    formValues,
  }
}
