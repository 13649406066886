import { WebStorageStateStore } from 'oidc-client-ts';
import config from '../config'

const confusedOidcConfig = {
    authority: config.CONFUSED_ACCOUNT_AUTHORITY,
    client_id: config.CONFUSED_ACCOUNT_CLIENT_ID,
    client_secret: config.CONFUSED_ACCOUNT_CLIENT_SECRET,
    redirect_uri: config.CONFUSED_ACCONT_REDIRECT_URI,
    response_type: 'code',
    query_status_response_type: 'code',
    scope: 'openid',
    includeIdTokenInSilentRenew: false,
    loadUserInfo: false,
    monitorSession: true,
    disablePKCE: false,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };

  export default confusedOidcConfig;