import React from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import { bindActionCreators } from 'redux'
import pluralize from 'pluralize'
import { Grid, GridClear } from 'unsemantic'
import './style.css'
import { updateRiskData, updateQuote } from '../RiskDataCollectionPage/actions'
import { setVehicleListener, createUnkownVehicle } from '../Vehicle/actions'
import {
  SummarySection,
  SummaryBorderMobile,
  SummaryBorderLeftMobile,
  SummaryBorderBottomMobile,
} from './SummarySectionStyles'
import VehicleReg from '../../components/VehicleReg'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  PRIVATE_CAR_SCHEME,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
} from '../Vehicle/constants'
import { UI_ELEMENT_HOUR_DURATION } from '../App/constants'

export const formatDate = (startDate, dateEdited) => {
  if (dateEdited) {
    return Moment(startDate).format('ddd DD/MM/YYYY HH:mm')
  } else {
    return 'Policy starts immediately'
  }
}

export class QuoteSummary extends React.Component {
  constructor(props) {
    super(props)
    this.policyDurationModalRef = React.createRef()
    this.vehicleModalRef = React.createRef()
    this.policyStartDateRef = React.createRef()

    this.updateData = this.updateData.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.handleDateTimeSet = this.handleDateTimeSet.bind(this)
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.updateQuote &&
      (prevProps.vehicle.selected !== this.props.vehicle.selected ||
        prevProps.riskData !== this.props.riskData)
    ) {
      if (
        this.props.scheme === COMMERCIAL_VEHICLE_SCHEME &&
        !this.props.riskData.UseOfVehicle.value
      ) {
        // Todo: Need to sort this out, as currently we are just pushing them back to the driver details screen in vehicle/saga
        // history.push(`/quote/driver-details/van-info${this.props.queryString}`)
      } else {
        if (
          prevProps.vehicle.selected.VehicleType ===
          this.props.vehicle.selected.VehicleType
        ) {
          this.props.actions.updateQuote({
            partialValidation: true,
            getPrice: true,
          })
        }
      }
    }
  }

  updateData(payload) {
    this.hideModal(payload.modalRef)
    if (payload.durationDetails) {
      this.props.actions.updateRiskData({
        Duration: payload.durationDetails.duration,
        DurationType: payload.durationDetails.durationType,
      })
    }
    if (payload.vehicle) {
      if (this.props.vehicle.searched.VehicleFound) {
        this.props.actions.setVehicleListener(this.props.vehicle.searched)
      } else {
        this.props.actions.createUnkownVehicle()
      }
    }
  }

  handleDateTimeSet = (event, instance) => {
    this.props.actions.updateRiskData({
      PolicyStartDate: { value: instance.getVal(), manuallyEdited: true },
    })
  }

  showModal(ref) {
    ref.instance.show()
  }

  hideModal(ref) {
    ref.instance.hide()
  }
  render() {
    let vehicleSummary
    let registrationNumber =
      this.props.vehicle.selected.RegistrationNumber === null
        ? ''
        : this.props.vehicle.selected.RegistrationNumber

    switch (this.props.scheme) {
      case PRIVATE_CAR_SCHEME:
        if (this.props.vehicle.selected.Variant) {
          vehicleSummary = `${this.props.vehicle.selected.Variant}`
        } else {
          vehicleSummary = `${this.props.vehicle.selected.Make} ${this.props.vehicle.selected.Model}`
        }
        break
      case COMMERCIAL_VEHICLE_SCHEME:
        if (
          this.props.vehicle.selected.GrossWeight >
          COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT
        ) {
          vehicleSummary = `Weight: ${this.props.vehicle.selected.GrossWeight}kg Year: ${this.props.vehicle.selected.YearOfManufacture}`
        } else if (this.props.vehicle.selected.Variant) {
          vehicleSummary = `${this.props.vehicle.selected.Variant}`
        } else {
          vehicleSummary = `${this.props.vehicle.selected.Make} ${this.props.vehicle.selected.Model}`
        }
        break
      default:
        break
    }

    return (
      <Grid parent>
        <Grid desktop-hide tablet-hide mobile='100'>
          <SummaryBorderMobile>
            <Grid parent mobile='60'>
              <SummarySection
                id='VehicleSummaryMobile'
                onClick={() => this.showModal(this.vehicleModalRef)}
              >
                <p className='quote-summary-title'>Vehicle to be covered</p>
                <p className='quote-summary-text'>{vehicleSummary}</p>
                <VehicleReg reg={registrationNumber} />
                <i className='fa fa-pencil-alt'></i>
              </SummarySection>
            </Grid>
            <Grid parent mobile='40'>
              <SummaryBorderLeftMobile>
                <Grid parent mobile='100'>
                  <SummaryBorderBottomMobile>
                    <SummarySection
                      onClick={() =>
                        this.showModal(this.policyDurationModalRef)
                      }
                    >
                      <p className='quote-summary-title'>Duration</p>
                      <p className='quote-summary-text'>
                        {this.props.riskData.Duration}{' '}
                        {parseInt(this.props.riskData.Duration) === 1
                          ? pluralize.singular(this.props.riskData.DurationType)
                          : this.props.riskData.DurationType}
                      </p>
                      <i className='fa fa-pencil-alt'></i>
                    </SummarySection>
                  </SummaryBorderBottomMobile>
                </Grid>
                <Grid parent mobile='100'>
                  <SummarySection
                    onClick={() => this.showModal(this.policyStartDateRef)}
                  >
                    <p className='quote-summary-title'>Start date &amp; time</p>
                    <p className='quote-summary-text'>
                      {formatDate(
                        this.props.riskData.PolicyStartDate.value,
                        this.props.riskData.PolicyStartDate.manuallyEdited
                      )}
                    </p>
                    <i className='fa fa-pencil-alt'></i>
                  </SummarySection>
                </Grid>
              </SummaryBorderLeftMobile>
            </Grid>
            <GridClear />
          </SummaryBorderMobile>
        </Grid>

        <Grid parent desktop='100' tablet='100' mobile-hide>
          <Grid desktop='100' tablet='100'>
            <SummarySection
              id='VehicleSummaryDesktop'
              onClick={() => this.showModal(this.vehicleModalRef)}
            >
              <p className='quote-summary-title vehicle'>
                Vehicle to be covered
              </p>

              <p className='quote-summary-text vehicle'>{vehicleSummary}</p>
              <VehicleReg reg={registrationNumber} />
              <i className='fa fa-pencil-alt'></i>
            </SummarySection>
          </Grid>
          {this.props.riskData.Impounded ? (
            <Grid desktop='33' tablet='33'>
              <SummarySection cursor='auto'>
                <p className='quote-summary-title'>Duration</p>
                <p className='quote-summary-text'>30 Days</p>
              </SummarySection>
            </Grid>
          ) : (
            <Grid desktop='33' tablet='33'>
              <SummarySection
                onClick={() => this.showModal(this.policyDurationModalRef)}
              >
                <p className='quote-summary-title'>Duration</p>
                <p className='quote-summary-text'>
                  {this.props.riskData.Duration}{' '}
                  {parseInt(this.props.riskData.Duration) === 1
                    ? pluralize.singular(this.props.riskData.DurationType)
                    : this.props.riskData.DurationType}
                </p>
                <i className='fa fa-pencil-alt'></i>
              </SummarySection>
            </Grid>
          )}
          <Grid desktop='66' tablet='66'>
            <SummarySection
              onClick={() => this.showModal(this.policyStartDateRef)}
            >
              <p className='quote-summary-title'>Start date &amp; time</p>
              <p className='quote-summary-text'>
                {formatDate(
                  this.props.riskData.PolicyStartDate.value,
                  this.props.riskData.PolicyStartDate.manuallyEdited
                )}
              </p>
              <i className='fa fa-pencil-alt'></i>
            </SummarySection>
          </Grid>

          <Grid desktop='100' tablet='100' parent></Grid>
        </Grid>
      </Grid>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    price: state.price,
    step: state.step,
    scheme: state.scheme.selected,
    showHourDurationType: state.ui[UI_ELEMENT_HOUR_DURATION].isVisible,
    queryString: state.queryString,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateRiskData,
        updateQuote,
        setVehicleListener,
        createUnkownVehicle,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSummary)
