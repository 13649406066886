import http from '../api/http'

export default class contactUsApi {
  static createContactUsRequest(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.CreateContactUsRepresentation+json',
      },
    })
  }
}
