export const VEHICLE_VALUES = [
  {
    id: 1,
    description: '£1,500 to £5,000',
    value: 5,
  },
  {
    id: 2,
    description: '£5,001 to £10,000',
    value: 6,
  },
  {
    id: 3,
    description: '£10,001 to £20,000',
    value: 7,
  },
  {
    id: 4,
    description: '£20,001 to £30,000',
    value: 8,
  },
  {
    id: 5,
    description: '£30,001 to £40,000',
    value: 9,
  },
  {
    id: 6,
    description: '£40,001 to £50,000',
    value: 10,
  },
  {
    id: 7,
    description: '£50,001 to £65,000',
    value: 11,
  },
  {
    id: 8,
    description: '£65,001 and over',
    value: 12,
  },
]
