import React from 'react'
import { Grid } from 'unsemantic'
import { Headline, P2 } from './styles'

const ThankYouPage = () => {
  return (
    <Grid desktop='100' mobile='100' tablet='100'>
      <Headline>Thank you</Headline>
      <P2>
        Thank you for your enquiry. Someone will be in touch shortly with you.
      </P2>
    </Grid>
  )
}

export default ThankYouPage
