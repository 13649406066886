import styled from 'styled-components'

export const AllReasonsWrapper = styled.div`
  margin-bottom: 48px;
`

export const TopIconWrapper = styled.div`
  display: flex;
  max-width: 360px;
  flex-direction: column;

  & > div:last-child {
    margin-bottom: 0;
  }
`