import { takeLatest, call, put, select } from 'redux-saga/effects'
import {
  CREATE_CLIENT_SESSION,
  UPDATE_CLIENT_SESSION_QUOTE,
} from '../constants'
import { SESSIONSTATES } from '../../../constants'
import sessionApi from '../../../api/sessionApi'
import {
  setClientSessionGuid,
  setSessionBaseData,
  setClientSessionQuote,
  setClientSessionState,
} from '../actions'
import { getSessionData } from '../selector'

function* createClientSession(payload) {
  yield put(setClientSessionState(SESSIONSTATES.CREATING_SESSION))
  let requestData = {
    SessionGuid: payload.sessionData.sessionGuid,
    UTMSource: payload.sessionData.utmSource,
    UTMMedium: payload.sessionData.utmMedium,
    Campaign: payload.sessionData.utmCampaign,
    Referrer: payload.sessionData.referrer,
    UserAgent: payload.sessionData.userAgent,
    DeviceType: payload.sessionData.deviceType,
    Affiliate: payload.sessionData.affiliate,
    QuoteId: { Value: payload.sessionData.sessionQuoteId },
    ConfusedGASessionId: payload.sessionData.confusedGASessionId,
  }
  yield put(setSessionBaseData(payload.sessionData))
  let response = yield call(
    sessionApi.createClientSession,
    'api/clientSessions',
    requestData
  )
  if (response.data && response.data.SessionGuid) {
    yield put(setClientSessionGuid(response.data.SessionGuid))
    yield put(setClientSessionState(SESSIONSTATES.SESSION_CREATED))
  }
}

function* updateClientSessionQuote(payload) {
  let data = yield select(getSessionData)
  let requestData = {
    SessionGuid: data.sessionGuid,
    QuoteId: { Value: payload.sessionQuoteId },
  }
  yield put(setClientSessionState(SESSIONSTATES.UPDATING_SESSION_QUOTE))
  if (data.sessionQuoteId !== payload.sessionQuoteId && data.sessionGuid) {
    let response = yield call(
      sessionApi.updateClientSessionQuote,
      'api/clientSessions/updateSessionQuote',
      requestData
    )
    if (response.data && response.data.SessionGuid) {
      yield put(setClientSessionQuote(payload.sessionQuoteId))
      yield put(setClientSessionState(SESSIONSTATES.SESSION_QUOTE_UPDATED))
    } else {
      // update quote failed
      yield put(setClientSessionState(SESSIONSTATES.SESSION_CREATED))
    }
  }
}

export default function* sessionSaga() {
  yield takeLatest(CREATE_CLIENT_SESSION, createClientSession)
  yield takeLatest(UPDATE_CLIENT_SESSION_QUOTE, updateClientSessionQuote)
}
