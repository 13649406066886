import React from 'react'
import { LargeSeperator } from '../common/Seperator'
import { SectionHeading } from '../common/SectionHeading'


import { H4 } from '../common/H4'
import ToggleInput from '../common/ToggleInput'
import { ThinH2 } from '../common/H2'

interface Props {
    loaded: boolean,
    previousCustomer: boolean,
    emailAddressOptIn: boolean,
    telephoneNumberOptIn: boolean,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
}

const MarketingPreferences:React.FC<Props> = ({
    loaded,
    previousCustomer,
    emailAddressOptIn,
    telephoneNumberOptIn,
    setFieldValue
}) => {
const a = 1

    return (
    <>
        <LargeSeperator />
        <SectionHeading id='keepingInTouch_hdr'>
        Keeping in touch
        </SectionHeading>
        <ThinH2 id='keepingInTouch_desc'>
        You will shortly receive a summary of your quote by email.
        </ThinH2>
        {loaded && (
            <>
                {previousCustomer ? (
                <H4>
                    We'd also like to keep you up to date with our latest
                    customer discounts and promotions. Here are your current
                    marketing preferences. If you'd like to change them,
                    simply update below.
                </H4>
                ) : (
                <H4>
                    We'd also like to keep you up to date with our latest
                    customer discounts and promotions. You can change your
                    mind at anytime. Just tell us how you wish to be
                    contacted:
                </H4>
                )}

                <ToggleInput
                    id={'mktpref-2optin'}
                    onChange={(e) => setFieldValue('EmailAddressOptIn', e)}
                    value={!!emailAddressOptIn}
                    name='mktpref-2'
                    label='Send via email'                    
                />

                <ToggleInput
                    id={'mktpref-1optin'}
                    onChange={(e) => setFieldValue('TelephoneNumberOptIn', e)}
                    value={!!telephoneNumberOptIn}
                    name='mktpref-1'
                    label='Send via message'                    
                />
                <H4>
                Please note, we will never pass your details to third
                parties for marketing purposes without your permission
                </H4>
            </>
        )}
    </>
    )
}

export default MarketingPreferences