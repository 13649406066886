import styled from 'styled-components'

export const BaseSelectTS = styled('select')<{ error: boolean }>`
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  outline: none;
  border-radius: 0px;
  padding: 0 ${(props) => props.theme.formControlHorizontalPadding};
  color: ${(props) => props.theme.primaryTextColour};
  font-size: ${(props) => props.theme.generalFontSize};
  height: ${(props) => props.theme.formControlHeight};
  width: 100%;
  max-width: 360px;
  font-family: ${(props) => props.theme.fontFamily};
  font-style: ${(props) => props.theme.fontStyle};
  font-weight: ${(props) => props.theme.fontWeight};
  appearance: none;
  position: relative;
  background-color: #fff;

  &.placeholder,
  option[value=''] {
    color: #1f1f1f;
  }

  :focus {
    outline: 4px solid #4c90bd;
  }

  option:not([value='']) {
    color: ${(props) => props.theme.primaryTextColour};
  }
`
