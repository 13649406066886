import React from 'react'
import Proptypes from 'prop-types'
import { LICENCE_TYPES } from './constants'
import { ErrorMessage } from '../common/ErrorMessage'
import { RadioButton } from '../common/RadioButton'
import { FormControlWrapper } from '../FormControlWrapper'
import { FieldLabel } from '../../components/common/FieldLabel'
import { FieldLegend } from '../common/FieldLegend'


interface Props {
    id: string,
    error: boolean,
    errorMessage: string,
    onChange: (e: React.ChangeEvent<any>) => void,
    name: string,
    value: string,
}


const LicenceType: React.FC<Props> = ({
  id,
  error,
  errorMessage,
  onChange,
  name,
  value,
}) => {
  return (
    <>
    <FieldLegend>
        What type of licence does the driver currently hold?
    </FieldLegend>
    <FormControlWrapper>
      {error && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
      {LICENCE_TYPES.map((option, index) => (
        <RadioButton
          id={`${id}_${option.id}`}
          onChange={onChange}
          value={option.value}
          name={name}
          label={option.description}
          checked={value === option.value}
          key={`${id}_${index}`}
          error={error}
          />
          ))}
    </FormControlWrapper>
          </>
  )
}

// LicenceType.propTypes = {
//   onChange: Proptypes.func.isRequired,
// }

export default LicenceType
