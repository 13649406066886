import styled from 'styled-components'

export const IndentedLine = styled.div`
  border-left: 3px solid #1f1f1f;
  padding-left: 24px;
  margin-bottom: ${(props) => (props.noGap ? '0' : '48px')};
  margin-top: ${(props) => (props.indentTop ? '-24px' : '0')};

  @media (max-width: 440px) {
    ${(props) =>
      props.hideOnMobile ? 'border-left: none; padding-left: 0;' : ''}
  }
`
