import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IndentedLine } from '../common/IndentedLine'
import VehicleBuilderSelect from './VehicleBuilderSelect'
import styled from 'styled-components'
import { FieldLabel } from '../common/FieldLabel'
import { YearOfManufacture } from '../YearOfManufacture'
import { ErrorMessage } from '../common/ErrorMessage'
import {
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  PRIVATE_CAR,
} from '../../containers/Vehicle/constants'
import {
  updateSearchedVehicle,
  getModels,
  getVariants,
  getMakes,
  setVehicleType,
  updateGrossWeight,
  updateYearOfManufacture,
} from '../../containers/Vehicle/actions'
import { BaseInput } from '../common/Input/BaseInput'

const VehicleBuilderWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 24px;
  div:last-child {
    margin-bottom: 0px;
  }
  select.last {
    margin-bottom: 0px;
  }
`
const KGWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: auto;
  display: inline-block;
`
const WeightInput = styled(BaseInput)`
  font-size: ${(props) => props.theme.generalFontSize};
  height: ${(props) => props.theme.formControlHeight};
  font-family: ${(props) => props.theme.fontFamily};
  font-style: ${(props) => props.theme.fontStyle};
  font-weight: ${(props) => props.theme.fontWeight};
  color: #1f1f1f;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 50px 0 10px;
  margin: 0 auto;
  margin-bottom: 24px;
  text-align: left;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 0px;
  box-sizing: border-box;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const KgIcon = styled.div`
  text-align: center;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 43px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 45px;
  height: 45px;
  background-color: #efefef;
  color: #1f1f1f;
  border: ${(props) => (props.error ? '4px' : '2px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  border-left: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`
const VehicleBuilder = ({
  vehicleOptions,
  values,
  errors,
  touched,
  setFieldValue,
  setValues,
  onBlur,
  actions,
}) => {
  return (
    <VehicleBuilderWrapper>
      {Boolean(touched.VehicleType && errors.VehicleType) && (
        <ErrorMessage id='VehicleTypeError'>{errors.VehicleType}</ErrorMessage>
      )}
      <VehicleBuilderSelect
        id='VehicleBuilderType'
        className='noMargin'
        name='VehicleType'
        placeholder='Please select...'
        value={values.VehicleType}
        onBlur={onBlur}
        onChange={(e) => {
          actions.setVehicleType(e.target.value)
          actions.getMakes()
          actions.updateSearchedVehicle({
            vehicleType: e.target.value,
            Make: '',
            Model: '',
            Abi: '',
            Variant: '',
          })
          setValues({
            ...values,
            VehicleType: e.target.value,
            VehicleMake: '',
            VehicleModel: '',
            VehicleWeight: '',
            VehicleDateOfManufacture: '',
            VehicleVariant: '',
          })
          actions.getMakes(e.target.value)
        }}
        options={vehicleOptions.vehicleType}
        error={Boolean(touched.VehicleType && errors.VehicleType)}
      />
      {values.VehicleType && (
        <IndentedLine noGap>
          {values.VehicleType === COMMERCIAL_VEHICLE && (
            <>
              <FieldLabel>Weight</FieldLabel>
              {Boolean(touched.VehicleWeight && errors.VehicleWeight) && (
                <ErrorMessage id='GrossWeightError'>
                  {errors.VehicleWeight}
                </ErrorMessage>
              )}
              <KGWrapper className='reg-wrapper'>
                <WeightInput
                  id='VehicleBuilderWeight'
                  type='number'
                  onBlur={onBlur}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      const weightString = Math.round(e.target.value).toString()
                      actions.updateGrossWeight(weightString)
                    }
                    setFieldValue('VehicleWeight', e.target.value)
                  }}
                  value={values.VehicleWeight}
                  name='VehicleWeight'
                  error={Boolean(touched.VehicleWeight && errors.VehicleWeight)}
                />
                <KgIcon
                  error={Boolean(touched.VehicleWeight && errors.VehicleWeight)}
                >
                  <span>KG</span>
                </KgIcon>
              </KGWrapper>
            </>
          )}
          {(values.VehicleType === PRIVATE_CAR ||
            parseInt(values.VehicleWeight) <=
              COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) && (
            <>
              {values.VehicleType !== null && (
                <>
                  <FieldLabel>Make</FieldLabel>
                  {Boolean(touched.VehicleMake && errors.VehicleMake) && (
                    <ErrorMessage id='VehicleMakeError'>
                      {errors.VehicleMake}
                    </ErrorMessage>
                  )}
                  <VehicleBuilderSelect
                    id='VehicleBuilderMake'
                    name='VehicleMake'
                    placeholder='Please select...'
                    onBlur={onBlur}
                    className={
                      (values.VehicleMake &&
                        vehicleOptions.models.length > 0) ||
                      values.VehicleType === COMMERCIAL_VEHICLE
                        ? ''
                        : 'last'
                    }
                    onChange={(e) => {
                      actions.updateSearchedVehicle({
                        Make: e.target.value,
                        Model: '',
                        Abi: '',
                        Variant: '',
                      })
                      actions.getModels()
                      setValues({
                        ...values,
                        VehicleMake: e.target.value,
                        VehicleModel: '',
                        VehicleVariant: '',
                      })
                    }}
                    options={vehicleOptions.makes}
                    value={values.VehicleMake}
                    error={Boolean(touched.VehicleMake && errors.VehicleMake)}
                  />
                </>
              )}
              {values.VehicleMake && vehicleOptions.models.length > 0 && (
                <>
                  <FieldLabel>Model</FieldLabel>
                  {Boolean(touched.VehicleModel && errors.VehicleModel) && (
                    <ErrorMessage id='VehicleModelError'>
                      {errors.VehicleModel}
                    </ErrorMessage>
                  )}
                  <VehicleBuilderSelect
                    id='VehicleBuilderModel'
                    name='VehicleModel'
                    placeholder='Please select...'
                    onBlur={onBlur}
                    className={
                      (values.VehicleMake &&
                        vehicleOptions.models.length > 0) ||
                      values.VehicleType === COMMERCIAL_VEHICLE
                        ? ''
                        : 'last'
                    }
                    onChange={(e) => {
                      actions.updateSearchedVehicle({
                        Model: e.target.value,
                        Abi: '',
                        Variant: '',
                      })
                      actions.getVariants()
                      setValues({
                        ...values,
                        VehicleModel: e.target.value,
                        VehicleVariant: '',
                      })
                    }}
                    options={vehicleOptions.models}
                    value={values.VehicleModel}
                    error={Boolean(touched.VehicleModel && errors.VehicleModel)}
                  />
                </>
              )}
              {values.VehicleModel && vehicleOptions.variants.length > 0 && (
                <>
                  <FieldLabel>Variant</FieldLabel>
                  {Boolean(touched.VehicleVariant && errors.VehicleVariant) && (
                    <ErrorMessage id='VehicleVariantError'>
                      {errors.VehicleVariant}
                    </ErrorMessage>
                  )} 
                  <VehicleBuilderSelect
                    id='VehicleBuilderVariant'
                    name='VehicleVariant'
                    placeholder='Please select...'
                    onBlur={onBlur}
                    className={
                      values.VehicleType === COMMERCIAL_VEHICLE ? '' : 'last'
                    }
                    onChange={(e) => {
                      const index = e.nativeEvent.target.selectedIndex
                      actions.updateSearchedVehicle({
                        Abi: e.target.value,
                        Variant: e.nativeEvent.target[index].text,
                      })
                      setFieldValue('VehicleVariant', e.target.value)
                    }}
                    options={vehicleOptions.variants}
                    value={values.VehicleVariant}
                    error={Boolean(
                      touched.VehicleVariant && errors.VehicleVariant
                    )}
                  />
                </>
              )}
            </>
          )}
          {values.VehicleType === COMMERCIAL_VEHICLE && (
            <>
              <FieldLabel>Date of Manufacture</FieldLabel>
              {Boolean(
                touched.VehicleDateOfManufacture &&
                  errors.VehicleDateOfManufacture
              ) && (
                <ErrorMessage id='VehicleDateOfManufactureError'>
                  {errors.VehicleDateOfManufacture}
                </ErrorMessage>
              )}
              <YearOfManufacture
                id='VehicleBuilderManufactureDate'
                name='VehicleDateOfManufacture'
                placeholder='Please select...'
                onBlur={onBlur}
                onChange={(e) => {
                  actions.updateYearOfManufacture(e.target.value)
                  setFieldValue('VehicleDateOfManufacture', e.target.value)
                }}
                value={values.YearOfManufacture}
                error={Boolean(
                  touched.VehicleDateOfManufacture &&
                    errors.VehicleDateOfManufacture
                )}
              />
            </>
          )}
        </IndentedLine>
      )}
    </VehicleBuilderWrapper>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateSearchedVehicle,
        getMakes,
        getModels,
        getVariants,
        setVehicleType,
        updateGrossWeight,
        updateYearOfManufacture,
      },
      dispatch
    ),
  }
}

export default connect(null, mapDispatchToProps)(VehicleBuilder)

VehicleBuilder.propTypes = {
  searchedVehicle: PropTypes.shape({
    Make: PropTypes.string,
    Models: PropTypes.string,
    Abi: PropTypes.string,
  }),
  vehicleOptions: PropTypes.shape({
    makes: PropTypes.array,
    models: PropTypes.array,
    variants: PropTypes.array,
  }),
  handleSelect: PropTypes.func,
  handleSelectVehicleBuilder: PropTypes.func,
}
