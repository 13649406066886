import React from 'react'
import styled from 'styled-components'
import Proptype from 'prop-types'

export const BaseOption = styled.li`
  color: ${(props) => props.theme.primaryTextColour};
  font-size: ${(props) => props.theme.generalFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  font-style: ${(props) => props.theme.fontStyle};
  font-weight: ${(props) => props.theme.fontWeight};
  padding: 0 10px 2px;

  &:hover {
    background-color: #1e8fff;
    color: #fff;
    cursor: pointer;
  }
`

export const ClickThroughSpan = styled.span`
  pointer-events: none;
`

const highlightText = (id, text, searchTerm) => {
  let parts = text.split(new RegExp(`(${searchTerm})`, 'gi'))
  return (
    <ClickThroughSpan value={text}>
      {parts.map((part, i) => (
        <span
          key={i}
          id={id}
          style={
            part.toLowerCase() === searchTerm.toLowerCase()
              ? { fontWeight: '530', textDecoration: 'underline' }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </ClickThroughSpan>
  )
}

export const AutoCompleteOption = ({
  option,
  searchTerm,
  onClick,
  properties,
}) => {
  const { id, value } = properties
  const formattedText = highlightText(option[id], option[value], searchTerm)
  return (
    <BaseOption
      id={option[id]}
      noMatches={option.noMatches}
      value={option[id]}
      onMouseDown={onClick}
      onTouchStart={onClick}
    >
      {formattedText}
    </BaseOption>
  )
}

AutoCompleteOption.propTypes = {
  option: Proptype.object,
  subString: Proptype.string,
  properties: Proptype.shape({
    id: Proptype.string,
    value: Proptype.string,
  }),
}
