import { GET_APP_STARTUP_FAILURE, GET_APP_STARTUP_SUCCESS } from '../constants'

import initialState from '../../../initialState'

function appStartupReducer(state = initialState.appStartupComplete, action) {
  switch (action.type) {
    case GET_APP_STARTUP_SUCCESS:
      return true
    case GET_APP_STARTUP_FAILURE:
      return false
    default:
      return state
  }
}

export default appStartupReducer
