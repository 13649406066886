import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import ConfusedQuickQuoteStatement from '../../components/ConfusedQuickQuoteStatement'
import LicenceType from '../../components/LicenceType'
import { Message } from '../../components/Message'
import { MiniSummary } from '../../components/MiniSummary'
import PassportExpiryDate from '../../components/PassportExpiryDate'
import { POLICY_DURATION_HOURS } from '../../components/PolicyDurationModal/constants'
import Title from '../../components/Title'
import ValidationSummary from '../../components/ValidationSummary'
import { Button } from '../../components/common/Button'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import Email from '../../components/common/Email'
import { FieldLabel } from '../../components/common/FieldLabel'
import { Input } from '../../components/common/Input'
import { UppercaseInput } from '../../components/common/Input/UppercaseInput'
import { SectionHeading } from '../../components/common/SectionHeading'
import { LargeSeperator } from '../../components/common/Seperator'
import config from '../../config'
import useABExperiment from '../../hooks/useABExperiment/useABExperiment'
import { SYSTEM_FEATURES } from '../../services/constants'
import {
  ADDRESS_VALIDATION,
  BODY_TYPE_VALIDATION,
  DATE_OF_BIRTH_VALIDATION,
  DRIVING_LICENCE_VALIDATION,
  EMAIL_VALIDATION,
  FORENAME_VALIDATION,
  LICENCE_HELD_DURATION_VALIDATION,
  LICENCE_TYPE_VALIDATION,
  MOBILE_NUMBER_VALIDATION,
  OCCUPATION_VALIDATION,
  PASSPORT_EXPIRY_DATE_VALIDATION,
  PASSPORT_LINE_ONE_VALIDATION,
  PASSPORT_LINE_TWO_VALIDATION,
  SURNAME_VALIDATION,
  TITLE_VALIDATION,
  USE_OF_VEHICLE_VALIDATION,
  VEHICLE_VALUE_VALIDATION,
} from '../../services/validation'
import Address from '../Address'
import { updateStep, requestConfusedExternalAccount } from '../App/actions'
import { UI_ELEMENT_HOUR_DURATION } from '../App/constants'
import {
  checkMarketingOptInState,
  checkMarketingOptInStateAndForward,
  setMarketingOptInState,
} from '../MarketingPreferencesContainer/actions'
import { updateRiskData } from '../RiskDataCollectionPage/actions'
import RiskDataDiscountBannerContainer from '../RiskDataCollectionPage/components/RiskDataDiscountBannerContainer.component'
import { submitDriverDetailsForm } from './actions'
import initialState from '../../initialState'
import { ConfusedBackButton } from '../../components/common/ConfusedBackButton'
import { history } from '../..'
import VanDetails from '../VanDetails'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
} from '../Vehicle/constants'
import { ROUTES } from '../../services/constants'
import BasicDate from '../../components/common/BasicDate'
import LicenceHeldDuration from '../../components/LicenceHeldDuration'
import MarketingPreferences from '../../components/MarketingPreferences'
import { SummaryWrapper } from '../../components/MiniSummary/MiniSummary.styles'
import { useAuth } from 'react-oidc-context'
import queryString from 'query-string'

export const ContinueButton = styled(Button)`
  width: 100%;
`
export const QuestionInfo = styled.div`
  margin: 0 0 16px 0;
`

const DriverDetailsRedesigned = ({
  actions,
  address,
  hourDurationTypeElement,
  marketingPreferences: {
    loaded,
    PreviousCustomer,
    EmailAddressOptIn,
    TelephoneNumberOptIn,
  },
  myLicence,
  riskData,
  scheme,
  validation: { AllFieldsValid, IdentityCheck },
  vehicle,
  features,
  confusedQuickQuote,
  confusedExternalAccount
}) => {
  const auth = useAuth()

  const [updatingMarketingPreferences, setUpdatingMarketingPreferences] =
    useState(false)

  useEffect(() => {
    actions.updateStep(2)
  }, [])

    const [showDriverDetailsForm, setShowDriverDetailsForm] = useState(false)
    const [userHasPeople, setUserHasPeople] = useState(false)
    const [selectedPerson, setSelectedPerson] = useState()
    const [resetSelectedProfile, setResetSelectedProfile] = useState(false)
    const [showEmailAddressBox, setShowEmailAddressBox] = useState(true)
 
    const isInternalRvuUser = () => {
      const parsedQueryString = queryString.parse(window.location.search)
      return parsedQueryString.InternalRvuUserOverride === 'true'
    }

  useEffect(() => {
    setUpdatingMarketingPreferences(true)
  }, [EmailAddressOptIn, TelephoneNumberOptIn])

  useEffect(() => {
    if ((features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT] === true && features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT_PROFILE] === true) || isInternalRvuUser)
    {
      const checkIfExistingSession = async () => {    
        if (!auth.isLoading)
        {
          if (!auth.isAuthenticated) {
            await auth.signinSilent()
          }
        }

        if (auth.isAuthenticated){
          confusedExternalAccount.AuthToken = `Bearer ${auth.user.access_token}`
          actions.requestConfusedExternalAccount(`Bearer ${auth.user.access_token}`)
        }
      };

      void checkIfExistingSession();
    }
  }, [])

  useEffect(() => {
    if (!isInternalRvuUser && (features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT] === false || features[SYSTEM_FEATURES.CONFUSED_EXTERNAL_ACCOUNT_PROFILE] === false))
    {
      setUserHasPeople(false)
      setShowDriverDetailsForm(true)
      return;
    }

    const userPeopleCheck = confusedExternalAccount.Account != null && confusedExternalAccount.Account.People != null && confusedExternalAccount.Account.People.length > 0
    setShowDriverDetailsForm(!userPeopleCheck)
    setShowEmailAddressBox(!userPeopleCheck)
    setUserHasPeople(userPeopleCheck)
  }, [confusedExternalAccount])

  const currentPersonSelected = (personId) => {
      if (selectedPerson == null && riskData.Forename !== "" && riskData.Surname !== "")
      {
        const people = confusedExternalAccount.Account.People;
        const userPeople = people.filter(x => x.FirstName === riskData.Forename && x.LastName === riskData.Surname)
        if (userPeople.length > 0){
          setSelectedPerson(userPeople[0])
        }
      }

      return selectedPerson != null && selectedPerson.Id === personId
  }

  const getSelectedProfileDateOfBirth = () => {
    let dayOfBirth = new Date(selectedPerson.DateOfBirth).getDate()
    if (dayOfBirth < 10){
      dayOfBirth = `0${dayOfBirth}`
    }
    let monthOfBirth = new Date(selectedPerson.DateOfBirth).getMonth() + 1
    if (monthOfBirth < 10) {
      monthOfBirth = `0${monthOfBirth}`
    }
    
    return {
      day: `${dayOfBirth}`,
      month: `${monthOfBirth}`,
      year: `${new Date(selectedPerson.DateOfBirth).getFullYear()}`,
      date: `${new Date(selectedPerson.DateOfBirth).toISOString().split('T')[0]}T00:00:00`
    }
  }

  const checkMarketingOptInState = (payload) => {
    actions.checkMarketingOptInStateAndForward(payload)
  }

  const { variant } = useABExperiment(config.CONFUSED_CRO_EXPERIMENT_ID)
  const splitDate = (riskData.DateOfBirth || '')
    .replace(/[ :T]/g, '-')
    .split('-')
  const splitDatePassportExpiry = (riskData.PassportExpiryDate || '')
    .replace(/[ :T]/g, '-')
    .split('-')
  const isHours = riskData.DurationType === POLICY_DURATION_HOURS

  const getLicenceType = (licenceTypeInput) => {
    licenceTypeInput = licenceTypeInput.replace(/\s/g, "").toUpperCase()
    if (licenceTypeInput === "FULLUKLICENCE" || licenceTypeInput === "FULLUKMANUAL" || licenceTypeInput === "FULLUKAUTO")
    {
      return "FullUkLicence"
    }
    if (licenceTypeInput === "FULLNORTHERNIRELANDLICENCE")
    {
      return "FullNorthernIrelandLicence"
    }
    if (licenceTypeInput === "FULLEULICENCE")
    {
      return "FullEuLicence"
    }
    if (licenceTypeInput === "FULLINTERNATIONAL")
    {
      return "FullInternational"
    }
  }

  const getLicenceHeldTime = (dateObtained) => {
    const licenceCheckDateFirstHalfYear = new Date(new Date().setMonth(new Date().getMonth() - 6))
    if (dateObtained > licenceCheckDateFirstHalfYear) {
      return "ZeroToFiveMonths";
    }

    const licenceCheckDateSecondHalfYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    if (dateObtained > licenceCheckDateSecondHalfYear){
      return "SixToElevenMonths" 
    }

    const licenceCheckDateFirstYear = new Date(new Date().setFullYear(new Date().getFullYear() - 2))
    if (dateObtained > licenceCheckDateFirstYear) {
      return "OneYear" 
    }

    const licenceCheckDateSecondYear = new Date(new Date().setFullYear(new Date().getFullYear() - 3))
    if (dateObtained > licenceCheckDateSecondYear) {
      return "TwoYears" 
    }

    const licenceCheckDateThirdYear = new Date(new Date().setFullYear(new Date().getFullYear() - 4))
    if (dateObtained > licenceCheckDateThirdYear) {
      return "ThreeYears" 
    }

    const licenceCheckDateFourthYear = new Date(new Date().setFullYear(new Date().getFullYear() - 5))
    if (dateObtained > licenceCheckDateFourthYear) {
      return "FourYears" 
    }

    const licenceCheckDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10))
    return dateObtained > licenceCheckDate ? "FiveToNineYears" : "TenYearsOrMore"
  }

  const homePage = () => {
    history.push(ROUTES.HOME_PAGE)
  }

  return (
    <ConfusedWrapper>
      {!isHours && (
        //we don't have access to whether the price has a discount at this point
        <RiskDataDiscountBannerContainer></RiskDataDiscountBannerContainer>
      )}
      {myLicence.CheckFailed &&
      myLicence.FailReasons.indexOf('LicenceNumber') >= 0 ? (
        <Message
          heading='We have been unable to confirm your information'
          content='The driving licence number you have entered cannot be validated. Check and try again.'
          type='error'
        />
      ) : myLicence.CheckFailed &&
        myLicence.FailReasons.indexOf('LicenceCategory') >= 0 ? (
        <Message
          heading='Check your licence type'
          content='We have been unable to match your driving licence type with the information you have provided. Check and try again.'
          type='error'
        />
      ) : (
        (IdentityCheck === 'passport' ||
          IdentityCheck === 'drivingLicence' ||
          !AllFieldsValid) && (
          <Message
            heading='Sorry, we have been unable to confirm your information.'
            content='Double check your information and your policy start
              time is correct then try again.'
            type='error'
          />
        )
      )}

      {hourDurationTypeElement.messageIsVisible && (
        <Message
          heading='We&apos;re sorry, hourly van insurance is not quite ready.'
          content='In the meantime, we have updated your length of cover to 1 day.'
          type='info'
        />
      )}

      <Formik
        initialValues={{
          VehicleValue: vehicle.selected.vehicleValue.value,
          BodyType: riskData.BodyType,
          UseOfVehicle: riskData.UseOfVehicle,
          Occupation: riskData.Occupation,
          Title: riskData.Title,
          Forename: riskData.Forename,
          Surname: riskData.Surname,
          DateOfBirth: {
            day: splitDate[2],
            month: splitDate[1],
            year: splitDate[0],
            date: riskData.DateOfBirth,
          },
          Address: {
            Postcode: address.Postcode,
            PostcodeSearched: Boolean(riskData.AddressKey),
            AddressKey: riskData.AddressKey,
          },
          LicenceType: riskData.LicenceType,
          LicenceHeldDuration: riskData.LicenceHeldDuration,
          Email: riskData.EmailAddress,
          MobileNumber: riskData.Mobile,
          DrivingLicenceNumber: riskData.DrivingLicenceNumber,
          PassportLineOne: riskData.PassportLineOne,
          PassportLineTwo: riskData.PassportLineTwo,
          PassportExpiryDate: {
            day: splitDatePassportExpiry[2],
            month: splitDatePassportExpiry[1],
            year: splitDatePassportExpiry[0],
            date: riskData.PassportExpiryDate,
          },
          EmailAddressOptIn: EmailAddressOptIn,
          TelephoneNumberOptIn: TelephoneNumberOptIn,
          PolicyStartDate: riskData.PolicyStartDate,
          CreatedWithExistingConfusedProfile: null,          
        }}
        validationSchema={Yup.object().shape({
          VehicleValue: VEHICLE_VALUE_VALIDATION,
          BodyType: BODY_TYPE_VALIDATION(scheme),
          UseOfVehicle: USE_OF_VEHICLE_VALIDATION(scheme),
          Occupation: OCCUPATION_VALIDATION(scheme),
          Title: TITLE_VALIDATION,
          Forename: FORENAME_VALIDATION,
          Surname: SURNAME_VALIDATION,
          DateOfBirth: DATE_OF_BIRTH_VALIDATION,
          Address: ADDRESS_VALIDATION,
          LicenceType: LICENCE_TYPE_VALIDATION,
          LicenceHeldDuration: LICENCE_HELD_DURATION_VALIDATION,
          Email: EMAIL_VALIDATION,
          MobileNumber: MOBILE_NUMBER_VALIDATION,
          DrivingLicenceNumber: DRIVING_LICENCE_VALIDATION(IdentityCheck),
          PassportLineOne: PASSPORT_LINE_ONE_VALIDATION(IdentityCheck),
          PassportLineTwo: PASSPORT_LINE_TWO_VALIDATION(IdentityCheck),
          PassportExpiryDate: PASSPORT_EXPIRY_DATE_VALIDATION(IdentityCheck),
        })}
        onSubmit={(values, formActions) => {
          if (!loaded) {
            checkMarketingOptInState({
              email: values.Email,
              mobileNumber: values.MobileNumber,
            })
            formActions.setSubmitting(false)
          } else {
            actions.submitDriverDetailsForm(values)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
          submitCount,
          touched,
          values,
        }) => {
          if (resetSelectedProfile)
          {
            values.DateOfBirth = {
              day: "",
              month: "",
              year: "",
              date: initialState.DateOfBirth
            }
            values.Title = initialState.Title
            values.Forename = initialState.Forename
            values.Surname = initialState.Surname
            values.LicenceHeldDuration = initialState.LicenceHeldDuration
            values.LicenceType = initialState.LicenceType
            values.Email = ''
            setResetSelectedProfile(false)
            setShowEmailAddressBox(true)
            return;
          }

          if (selectedPerson != null) {            
            values.DateOfBirth = getSelectedProfileDateOfBirth()
            values.Title = selectedPerson.Title
            values.Forename = selectedPerson.FirstName
            values.Surname = selectedPerson.LastName
            if (selectedPerson.DrivingLicence.DateObtained != null && selectedPerson.DrivingLicence.Type != null) {
              values.LicenceHeldDuration = getLicenceHeldTime(new Date(selectedPerson.DrivingLicence.DateObtained))
              values.LicenceType = getLicenceType(selectedPerson.DrivingLicence.Type)
            }
            values.CreatedWithExistingConfusedProfile = selectedPerson.Id
          }

        if (!showEmailAddressBox && confusedExternalAccount.Account != null && confusedExternalAccount.Account.EmailAddress != null) {
            values.Email = confusedExternalAccount.Account.EmailAddress
          }

          if (updatingMarketingPreferences) {
            if (EmailAddressOptIn !== values.EmailAddressOptIn) {
              setFieldValue('EmailAddressOptIn', EmailAddressOptIn)
            }
            if (TelephoneNumberOptIn !== values.TelephoneNumberOptIn) {
              setFieldValue('TelephoneNumberOptIn', TelephoneNumberOptIn)
            }
            setUpdatingMarketingPreferences(false)
          }
          let errorCount = Object.keys(errors || {}).length
          if (
            errorCount >= 2 &&
            errors.DateOfBirth &&
            errors.LicenceHeldDuration
          ) {
            errorCount -= 1
          }
          return (
            <Form>
              <ValidationSummary
                showSummary={errorCount > 0 && submitCount > 0}
                errorCount={errorCount}
                submitCount={submitCount}
              />

              <ConfusedBackButton onClick={() => homePage()}>
                Change policy details
              </ConfusedBackButton>

              <SectionHeading>Tell us about the driver</SectionHeading>

              {
                selectedPerson && !showDriverDetailsForm && (
                  <>
                    <MiniSummary selectedPerson={selectedPerson} />
                    <ConfusedBackButton onClick={() => setSelectedPerson(null)}>
                      Change driver
                    </ConfusedBackButton>
                  </>
                )
              }

              {
                userHasPeople && !selectedPerson && !showDriverDetailsForm && confusedExternalAccount.Account.People != null && (
                  <>
                    <Button 
                      id={'AddNewDriver'}
                      tertiary 
                      includeArrow={false}
                      type={"button"}
                      onClick={() => {setShowDriverDetailsForm(!showDriverDetailsForm); setSelectedPerson(null); setResetSelectedProfile(true)}}
                      marginBottom={'5%'}
                    >
                      Add a new driver
                    </Button>

                    {
                      confusedExternalAccount.Account.People.map((res) => {
                        return(
                          <SummaryWrapper maxWidth={'31%'} display={'inline-block'} padding={'2%'} key={res.Id} marginRight={'1%'}>
                              <p>
                                <strong>
                                  { res.FirstName } { res.LastName }
                                </strong>
                                <br />

                                Date of birth:{' '}
                                <strong>
                                  { new Date(res.DateOfBirth).toLocaleDateString('en-GB') }
                                </strong>
                              </p>
                              <Button 
                                id={'ExistingPersonButton'}
                                type={'button'} 
                                onClick={() => setSelectedPerson(res)}
                                tertiary={ currentPersonSelected(res.Id) }
                                marginBottom={'5%'}
                                includeArrow={!currentPersonSelected(res.Id)}
                              >
                                { currentPersonSelected(res.Id) ? 'Selected' : 'Select'} Profile
                              </Button>
                          </SummaryWrapper>
                        )
                      })
                    }
                  </>
                )}

                {
                  userHasPeople && !showDriverDetailsForm && (
                    <Address
                      onBlur={handleBlur}
                      onChange={handleChange}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      values={values.Address}
                      touched={touched.Address || {}}
                      errors={errors.Address || {}}
                    />
                  )
                }

                {
                  selectedPerson != null && (selectedPerson.DrivingLicence.Type == null || selectedPerson.DrivingLicence.DateObtained == null) && (
                    <>
                       <LicenceType
                        id='LicenceType'
                        name='LicenceType'
                        onChange={handleChange}
                        value={values.LicenceType}
                        error={Boolean(touched.LicenceType && errors.LicenceType)}
                        errorMessage={touched.LicenceType && errors.LicenceType}
                      />
                      
                    {selectedPerson.DateOfBirth && (
                        <>
                          <FieldLabel htmlFor='LicenceHeldDuration'>
                            How long has the driver had the licence for?
                          </FieldLabel>
                          <LicenceHeldDuration
                            id='LicenceHeldDuration'
                            name='LicenceHeldDuration'
                            dateOfBirth={selectedPerson.DateOfBirth}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.LicenceHeldDuration}
                            error={Boolean(
                              touched.LicenceHeldDuration &&
                                errors.LicenceHeldDuration
                            )}
                            errorMessage={
                              touched.LicenceHeldDuration &&
                              errors.LicenceHeldDuration
                            }
                          />
                        </>
                      )}
                    </>
                  )
                }

              {
                (!userHasPeople || showDriverDetailsForm) && (
                  <>
                  <FieldLabel htmlFor='Title'>Title</FieldLabel>
                  <Title
                    id='Title'
                    name='Title'
                    onChange={handleChange}
                    error={Boolean(touched.Title && errors.Title)}
                    errorMessage={touched.Title && errors.Title}
                    value={values.Title}
                  />
                  <FieldLabel htmlFor='Forename'>First name</FieldLabel>
                  <Input
                    id='Forename'
                    name='Forename'
                    placeholder=''
                    titleCase={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.Forename && errors.Forename)}
                    errorMessage={touched.Forename && errors.Forename}
                    value={values.Forename}
                  />
                  <FieldLabel htmlFor='Surname'>Surname</FieldLabel>
                  <Input
                    id='Surname'
                    name='Surname'
                    placeholder=''
                    titleCase={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.Surname && errors.Surname)}
                    errorMessage={touched.Surname && errors.Surname}
                    value={values.Surname}
                  />
                  <FieldLabel htmlFor='DateOfBirth'>Date of birth</FieldLabel>
                  <BasicDate
                    id='DateOfBirth'
                    name='DateOfBirth'
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                    value={values.DateOfBirth}
                    touched={touched.DateOfBirth}
                    error={errors.DateOfBirth}
                  />
                  <Address
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    values={values.Address}
                    touched={touched.Address || {}}
                    errors={errors.Address || {}}
                  />
                                
                  <LicenceType
                    id='LicenceType'
                    name='LicenceType'
                    onChange={handleChange}
                    value={values.LicenceType}
                    error={Boolean(touched.LicenceType && errors.LicenceType)}
                    errorMessage={touched.LicenceType && errors.LicenceType}
                  />
                  {values.DateOfBirth &&
                    values.DateOfBirth.date &&
                    !(errors && errors.DateOfBirth && errors.DateOfBirth.date) && (
                      <>
                        <FieldLabel htmlFor='LicenceHeldDuration'>
                          How long has the driver had the licence for?
                        </FieldLabel>
                        <LicenceHeldDuration
                          id='LicenceHeldDuration'
                          name='LicenceHeldDuration'
                          dateOfBirth={values.DateOfBirth.date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.LicenceHeldDuration}
                          error={Boolean(
                            touched.LicenceHeldDuration &&
                              errors.LicenceHeldDuration
                          )}
                          errorMessage={
                            touched.LicenceHeldDuration &&
                            errors.LicenceHeldDuration
                          }
                        />
                      </>
                    )}                  
                    </>
                  )
                }

                {
                  (selectedPerson != null || showDriverDetailsForm) && (scheme === COMMERCIAL_VEHICLE_SCHEME || scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME) && (
                    <>
                      <LargeSeperator />
                      <VanDetails
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        errors={{
                          BodyType: Boolean(touched.BodyType && errors.BodyType),
                          UseOfVehicle: Boolean(
                            touched.UseOfVehicle && errors.UseOfVehicle
                          ),
                          Occupation: Boolean(
                            touched.Occupation && errors.Occupation
                          ),
                        }}
                        errorMessages={{
                          BodyType: touched.BodyType && errors.BodyType,
                          UseOfVehicle: touched.UseOfVehicle && errors.UseOfVehicle,
                          Occupation: touched.Occupation && errors.Occupation,
                        }}
                        values={{
                          BodyType: values.BodyType,
                          UseOfVehicle: values.UseOfVehicle,
                          Occupation: values.Occupation,
                        }}
                        scheme={scheme}
                      />
                    </>
                  )
                }

                <LargeSeperator />
                {variant === '1' &&
                  confusedQuickQuote.OptIn &&
                  features[SYSTEM_FEATURES.UPSELL_CONFUSED_ENABLED] && (
                    <ConfusedQuickQuoteStatement />
                  )}
                  {
                    !showEmailAddressBox && confusedExternalAccount.Account.EmailAddress != null && (
                      <>
                      <p>
                        Your policy documents will be sent to the email address linked to your Confused.com account.
                        You can also let us know if you'd like to{' '}
                        <strong
                          onClick={() => setShowEmailAddressBox(!showEmailAddressBox)} 
                          style={{'textDecoration': 'underline'}}
                        >
                          send policy document to another email address
                        </strong>
                      </p>
                      <p>
                        &nbsp;
                      </p>
                      </>
                    )
                  }
                  {
                    (showEmailAddressBox || confusedExternalAccount.Account.EmailAddress == null) && (
                      <>
                        <SectionHeading>
                          Tell us where to send your documents
                        </SectionHeading>
                        <FieldLabel htmlFor='Email'>Email</FieldLabel>
                        <Email
                          id='Email'
                          name='Email'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          error={Boolean(touched.Email && errors.Email)}
                          errorMessage={touched.Email && errors.Email}
                          value={values.Email}
                        />
                      </>
                    )                    
                  }
                <FieldLabel htmlFor='MobileNumber'>Mobile number</FieldLabel>
                <p>
                  We'll only call you if we need to get in touch about your policy or in the event of a claim
                </p>
                <Input
                  id='MobileNumber'
                  name='MobileNumber'
                  type='tel'
                  pattern='\d*'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.MobileNumber && errors.MobileNumber)}
                  errorMessage={touched.MobileNumber && errors.MobileNumber}
                  value={values.MobileNumber}
                />
                {IdentityCheck === 'drivingLicence' && (
                  <>
                    <FieldLabel htmlFor='DrivingLicenceNumber'>
                      Driving licence number
                    </FieldLabel>
                    <UppercaseInput
                      id='DrivingLicenceNumber'
                      name='DrivingLicenceNumber'
                      placeholder='My driving licence number is...'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.DrivingLicenceNumber &&
                          errors.DrivingLicenceNumber
                      )}
                      errorMessage={
                        touched.DrivingLicenceNumber &&
                        errors.DrivingLicenceNumber
                      }
                      value={values.DrivingLicenceNumber}
                    />
                    <QuestionInfo>
                      We will share this with the DVLA in order to confirm your licence status, entitlement and restriction information together with endorsement/conviction data.
                    </QuestionInfo>
                </>
              )}

              {
              IdentityCheck === 'passport' && (
                <>
                  <FieldLabel htmlFor='PassportLineOne'>
                    Passport line one
                  </FieldLabel>
                  <UppercaseInput
                    id='PassportLineOne'
                    name='PassportLineOne'
                    placeholder='The first line of my passport says...'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.PassportLineOne && errors.PassportLineOne
                    )}
                    errorMessage={
                      touched.PassportLineOne && errors.PassportLineOne
                    }
                    value={values.PassportLineOne}
                  />
                  <FieldLabel htmlFor='PassportLineTwo'>
                    Passport line two
                  </FieldLabel>
                  <UppercaseInput
                    id='PassportLineTwo'
                    name='PassportLineTwo'
                    placeholder='and the second line of my passport says...'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.PassportLineTwo && errors.PassportLineTwo
                    )}
                    errorMessage={
                      touched.PassportLineTwo && errors.PassportLineTwo
                    }
                    value={values.PassportLineTwo}
                  />

                  <PassportExpiryDate
                    id='PassportExpiryDate'
                    name='PassportExpiryDate'
                    onChange={setFieldValue}
                    handleBlurFunction={handleBlur}
                    value={values.PassportExpiryDate}
                    touched={touched.PassportExpiryDate}
                    error={errors.PassportExpiryDate}
                  />
                </>
              )}
              
              <MarketingPreferences
                loaded={loaded}
                previousCustomer={PreviousCustomer}
                emailAddressOptIn={values.EmailAddressOptIn}
                telephoneNumberOptIn={values.TelephoneNumberOptIn}
                setFieldValue={setFieldValue}
              />

              <LargeSeperator style={{ marginTop: '0' }} />
              <ContinueButton
                type='submit'
                disabled={isSubmitting}
                id='TeslaSubmitManualDetailsNextButton'
                onMouseDown={(e) => {
                  e.preventDefault()
                }}
              >
                {isSubmitting ? 'Please wait' : 'Continue'}
                <ButtonSpinner disabled={isSubmitting} />
              </ContinueButton>
            </Form>
          )
        }}
      </Formik>
    </ConfusedWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    address: state.address,
    hourDurationTypeElement: state.ui[UI_ELEMENT_HOUR_DURATION],
    location: state.router.location,
    marketingPreferences: state.marketingPreferences,
    myLicence: state.myLicence,
    riskData: state.riskData,
    scheme: state.scheme.selected,
    validation: state.validation.riskDataCollectionPage,
    vehicle: state.vehicle,
    features: state.features || {},
    confusedQuickQuote: state.confusedQuickQuote,
    confusedExternalAccount: state.confusedExternalAccount
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        checkMarketingOptInState,
        checkMarketingOptInStateAndForward,
        setMarketingOptInState,
        updateRiskData,
        updateStep,
        submitDriverDetailsForm,
        requestConfusedExternalAccount
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetailsRedesigned)
