import queryString from 'query-string'
import React from 'react'
import useChristmasCampaign from '../../../hooks/useChristmasCampaign/useChristmasCampaign'
import RiskDataDiscountBanner from './RiskDataDiscountBanner.component'

const RiskDataDiscountBannerContainer = () => {
  const parsedQueryString = queryString.parse(window.location.search)
  let christmasCampaign = useChristmasCampaign(
    parsedQueryString.utm_campaignref
  )
  if (christmasCampaign) {
    return <RiskDataDiscountBanner />
  }
  return null
}
export default RiskDataDiscountBannerContainer
