import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { DriverDetailsLabel } from '../../components/common/DriverDetailsLabel'
import { H2 } from '../../components/common/H2'
import { P } from '../../components/common/P'
import downChevron from '../../images/down-chevron.png'
import {
  Chevron,
  GridWrapper,
  MoreInfoTextWrapper,
  MoreInfoWrapper,
  ShowMoreButton,
} from './styles'

const GreyBackground = styled.div`
  background-color: #d4d4d4;
  padding: 24px;
  margin-bottom: 48px;
`
const PayableSectionText = styled(P)`
  font-weight: bold;
  font-size: 17px;
  @media (max-width: 450px) {
    font-size: 16px;
  }
`
export const NewPayableSection = ({ price }) => {
  const discount = Math.abs(price.campaign.Discount)
  const hasDiscount = !!(discount > 0)
  return (
    <PayableSectionText>
      Your total payable comprises of insurer premium £
      {price.InsurancePremium.toFixed(2)}, admin fee £
      {price.AdminFee.toFixed(2)}
      {hasDiscount && <>, discount (-£{discount.toFixed(2)})</>} and insurance
      premium tax £{price.Ipt.toFixed(2)}
    </PayableSectionText>
  )
}

export const PayableSection = ({ price }) => {
  const [showMore, setShowMore] = useState(false)
  const discount = Math.abs(price.campaign.Discount)
  const hasDiscount = !!(discount > 0)
  return (
    <GreyBackground id='PriceBreakdownContainer'>
      <GridWrapper>
        <DriverDetailsLabel style={{ marginBottom: '12px' }}>
          <H2 style={{ marginTop: '0px' }}>Total Payable Breakdown</H2>
        </DriverDetailsLabel>
      </GridWrapper>
      <ShowMoreButton
        href='#'
        onClick={() => {
          setShowMore(!showMore)
        }}
        more={showMore}
        id='ShowPriceBreakdown'
      >
        <MoreInfoTextWrapper>
          <Chevron src={downChevron} showMore={showMore} /> What's included in
          this price?
        </MoreInfoTextWrapper>
      </ShowMoreButton>
      {showMore && (
        <MoreInfoWrapper id='PriceBreakdown'>
          <P>
            Insurance Premium: <b>£{price.InsurancePremium.toFixed(2)}</b>
          </P>
          <P>
            Insurance Premium Tax: <b>£{price.Ipt.toFixed(2)}</b>
          </P>
          <P>
            Insurance Provider: <b>{price.UnderwriterDisplayName}</b>
          </P>
          <P noGap={hasDiscount ? false : true}>
            Broker Fee: <b>£{price.AdminFee.toFixed(2)}</b>
          </P>
          {hasDiscount && (
            <P noGap>
              Discount: <b>-£{discount.toFixed(2)}</b>
            </P>
          )}
        </MoreInfoWrapper>
      )}
    </GreyBackground>
  )
}

PayableSection.propTypes = {
  price: PropTypes.object.isRequired,
}
