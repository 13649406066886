import styled from 'styled-components'

export const LegalDeclarationPageWrapper = styled.div`
  ul > li {
    list-style-type: none;
  }
  ul > li > ul > li {
    list-style-type: disc;
    color: black;
    font-family: ${(props) => props.theme.fontFamily};
    font-style: ${(props) => props.theme.fontStyle};
    font-weight: ${(props) => props.theme.fontWeight};
    font-size: ${(props) => props.theme.generalFontSize};
  }
  #DisallowedOccupationsLink {
    color: ${(props) => props.theme.primaryColour};
    display: inline;
    cursor: pointer;
    text-decoration: underline;
  }
`
