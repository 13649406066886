import styled from 'styled-components'
import { Label } from '../Label'

export const FieldLabel = styled(Label)`
  color: ${(props) => props.theme.labelTextColour};
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  display: block;
  margin-top: 0;
  margin-bottom: 16px;
`
