export const store = {
  _antiForgeryToken: '',

  set antiForgeryToken(token) {
    this._antiForgeryToken = token
    localStorage.setItem('antiForgeryToken', token)
  },

  get antiForgeryToken() {
    return this._antiForgeryToken || localStorage.getItem('antiForgeryToken')
  },
}
