import React from 'react'
import { BannerText, BannerWrapper, BannerImage, BannerGrid } from './DiscountBanner.styles'
import confusedPoundDark from '../../images/confused-pound-dark.svg'

const DiscountBanner = ({ children }) => (
  <BannerWrapper>
    <BannerGrid>
      <BannerImage>
        <img src={confusedPoundDark} alt='Logo' />
      </BannerImage>
      <BannerText>{children}</BannerText>
    </BannerGrid>
  </BannerWrapper>
)

export default DiscountBanner
