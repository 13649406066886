import React from 'react'
import {
  WhiteLabelFooterNavContainer,
  WhiteLabelText,
  WhiteLabelTextSmaller,
  WhiteLabelFooterInfoWrapper,
  WhiteLabelFooterCenterWrapper,
  WhiteLabelFooterLink,
  UnstyledLink,
} from './Footer.styles'
import config from '../../config'

function CopyrightDate() {
  var date = new Date()
  return date.getFullYear()
}
const Footer = () => {
  return (
    <WhiteLabelFooterInfoWrapper className='footer'>
      <WhiteLabelFooterCenterWrapper>
        <WhiteLabelFooterNavContainer>
          <WhiteLabelText>
            Inspop.com Ltd trading as Confused.com is an intermediary who
            introduces retail customers to Tempcover Ltd. Confused.com receives
            a commission for this payable by Tempcover Ltd.
            <br />
            Inspop.com Ltd are authorised and regulated by the Financial Conduct
            Authority. Their FCA registration number is 310635. You can check
            this by visiting{' '}
            <WhiteLabelFooterLink
              href='https://www.fca.org.uk/register'
              target='_blank'
            >
              www.fca.org.uk/register
            </WhiteLabelFooterLink>{' '}
            or by contacting the FCA on 0300 500 8082.
          </WhiteLabelText>
        </WhiteLabelFooterNavContainer>
        <WhiteLabelFooterNavContainer>
          <WhiteLabelTextSmaller>
            Copyright © {CopyrightDate()}{' '}
            <UnstyledLink href={config.TEMPCOVER_SITE} target='_blank'>
              tempcover.com
            </UnstyledLink>
            . All rights reserved | Tempcover Ltd are authorised &amp; regulated
            by the Financial Conduct Authority. Our FCA registration number is
            746985 | Registered in England, Company No: 9923259
          </WhiteLabelTextSmaller>
        </WhiteLabelFooterNavContainer>
        <WhiteLabelFooterNavContainer>
          <WhiteLabelTextSmaller>
            Tempcover Ltd, 2nd Floor Admiral House, Harlington Way, Fleet,
            Hampshire, GU51 4BB
          </WhiteLabelTextSmaller>
        </WhiteLabelFooterNavContainer>
      </WhiteLabelFooterCenterWrapper>
    </WhiteLabelFooterInfoWrapper>
  )
}

export default Footer
