import React, { useState, useEffect } from 'react'
import SelectTS from '../common/SelectTS'
import { ErrorMessage } from '../common/ErrorMessage'
import { handleLicenceDurations } from '../../services/handlers'
import { FormControlWrapper } from '../FormControlWrapper'

interface Option {
  id: string
  value: string
  description: string
}

interface Props {
  id: string
  name: string
  dateOfBirth: Date
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur: (e: React.FocusEvent<HTMLSelectElement>) => void
  value: string
  error: boolean
  errorMessage: string
}

const LicenceHeldDuration: React.FC<Props> = ({
  id,
  name,
  dateOfBirth,
  onChange,
  onBlur,
  value,
  error,
  errorMessage,
}) => {
  const [filteredOptions, updateFilteredOptions] = useState<Option[]>([])

  useEffect(() => {
    updateFilteredOptions(handleLicenceDurations(dateOfBirth))
  }, [dateOfBirth])

  return (
    <FormControlWrapper>
      {error && (
        <ErrorMessage id='LicenceHeldDurationError'>
          {errorMessage}
        </ErrorMessage>
      )}
      <SelectTS
        placeholder='Please select...'
        id={id}
        options={filteredOptions}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
      />
    </FormControlWrapper>
  )
}

export default LicenceHeldDuration
