import styled from 'styled-components'

export const BaseRadioButton = styled.input`
  opacity: 0;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  left: 1em;
  top: calc(50% - 0.75em);
  line-height: normal;
  color: #1f1f1f;

  :checked + label {
    border-width: 4px;
    padding: 8px 8px 8px 52px;
  }

  :checked + label:after {
    opacity: 1;
  }

  :checked + label:before {
    border-color: #1f1f1f;
  }

  :focus {
    outline-offset: 2px;
  }

  :focus + label {
    outline: 4px solid #4c90bd;
  }
`
