import React, { useEffect } from 'react'
import { ErrorMessage } from '../ErrorMessage'
import { NumberInput } from '../Input/NumberInput'
import { SmallFieldLabel } from '../SmallFieldLabel'
import { DateWrapper, DateField } from './styles'

interface BasicDateProps {
  value: {
    day?: string
    month?: string
    year?: string
  }
  touched: {
    day?: boolean
    month?: boolean
    year?: boolean
  }
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange: (field: string, value: string | number, shouldValidate?: boolean) => void,
  error: {
    day?: string
    month?: string
    year?: string
    date?: string
  }
  name: string
  id: string
}

const BasicDate: React.FC<BasicDateProps> = ({
  value,
  touched,
  onBlur,
  onChange,
  error,
  name: nameAttribute,
  id,
}) => {
  useEffect(() => {
    const { day, month, year } = value
    onChange(`${nameAttribute}.date`, `${year}-${month}-${day}T00:00:00`)
  }, [value, onChange, nameAttribute])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target
    onChange(name, value)
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let { value, name } = event.target
    if (value) {
      if (name === `${nameAttribute}.year`) {
        if (value.length === 2) {
          value = `19${value}`
        }
      } else {
        if (value.length === 1) {
          value = `0${value}`
        }
      }
    }
    onChange(name, value)
    onBlur(event)
  }

  const errorKeys = error && Object.keys(error)
  const touchedKeys = touched && Object.keys(touched)
  const showError =
    error &&
    ((error.date && touchedKeys && touchedKeys.length === 3) ||
      (errorKeys && errorKeys.some((key) => touched && touched[key])))

  return (
    <>
      {showError && (
        <ErrorMessage id={`${nameAttribute}Error`}>
          Enter a valid date
        </ErrorMessage>
      )}
      <DateWrapper>
        <DateField
          small
          error={Boolean(
            touched &&
              error &&
              ((touched.day && error.day) ||
                (error.date && touchedKeys && touchedKeys.length === 3)),
          )}
        >
          <SmallFieldLabel htmlFor={`${nameAttribute}.day`}>
            Day
          </SmallFieldLabel>
          <div>
            <NumberInput
              type='number'
              value={value.day}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              name={`${nameAttribute}.day`}
              id={`${id}.day`}
              placeholder='DD'
              pattern='\d*'
            />
          </div>
        </DateField>
        <DateField
          small
          error={Boolean(
            touched &&
              error &&
              ((touched.month && error.month) ||
                (error.date && touchedKeys && touchedKeys.length === 3)),
          )}
        >
          <SmallFieldLabel htmlFor={`${nameAttribute}.month`}>
            Month
          </SmallFieldLabel>
          <div>
            <NumberInput
              type='number'
              value={value.month}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              name={`${nameAttribute}.month`}
              id={`${id}.month`}
              placeholder='MM'
              pattern='\d*'
            />
          </div>
        </DateField>
        <DateField
          error={Boolean(
            touched &&
              error &&
              ((touched.year && error.year) ||
                (error.date && touchedKeys && touchedKeys.length === 3)),
          )}
        >
          <SmallFieldLabel htmlFor={`${nameAttribute}.year`}>
            Year
          </SmallFieldLabel>
          <div>
            <NumberInput
              type='number'
              value={value.year}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              name={`${nameAttribute}.year`}
              id={`${id}.year`}
              placeholder='YYYY'
              pattern='\d*'
            />
          </div>
        </DateField>
      </DateWrapper>
    </>
  )
}

export default BasicDate
