export const UPDATE_CONTACT_US_DATA = 'src/ContactUsPage/UPDATE_CONTACT_US_DATA'
export const UPDATE_VALIDATION_CONTACT_US_PAGE =
  'src/ContactUsPage/UPDATE_VALIDATION_CONTACT_US_PAGE'
export const TOGGLE_FORM_IS_VALID_CONTACT_US_PAGE =
  'src/ContactUsPage/TOGGLE_FORM_IS_VALID_CONTACT_US_PAGE'
export const SUBMIT_CONTACT_US_REQUEST =
  'src/ContactUsPage/SUBMIT_CONTACT_US_REQUEST'
export const SUBMIT_CONTACT_US_REQUEST_SUCCESS =
  'src/ContactUsPage/SUBMIT_CONTACT_US_REQUEST_SUCCESS'
export const SUBMIT_CONTACT_US_REQUEST_FAILURE =
  'src/ContactUsPage/SUBMIT_CONTACT_US_REQUEST_FAILURE'

export const ENQUIRYTYPE_OPTIONS = [
  {
    id: 1,
    description: 'Quotation request',
    value: 'QuotationRequest',
  },
  {
    id: 2,
    description: 'GDPR/Unsubscribe',
    value: 'GDPRUnsubscribe',
  },
  {
    id: 3,
    description: 'Document request',
    value: 'DocumentRequest',
  },
  {
    id: 4,
    description: 'General enquiry',
    value: 'GeneralEnquiry',
  },
]
