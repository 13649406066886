export const HOURS = [
  {
    id: 0,
    value: '00',
    description: '00',
  },
  {
    id: 1,
    value: '01',
    description: '01',
  },
  {
    id: 2,
    value: '02',
    description: '02',
  },
  {
    id: 3,
    value: '03',
    description: '03',
  },
  {
    id: 4,
    value: '04',
    description: '04',
  },
  {
    id: 5,
    value: '05',
    description: '05',
  },
  {
    id: 6,
    value: '06',
    description: '06',
  },
  {
    id: 7,
    value: '07',
    description: '07',
  },
  {
    id: 8,
    value: '08',
    description: '08',
  },
  {
    id: 9,
    value: '09',
    description: '09',
  },
  {
    id: 10,
    value: '10',
    description: '10',
  },
  {
    id: 11,
    value: '11',
    description: '11',
  },
  {
    id: 12,
    value: '12',
    description: '12',
  },
  {
    id: 13,
    value: '13',
    description: '13',
  },
  {
    id: 14,
    value: '14',
    description: '14',
  },
  {
    id: 15,
    value: '15',
    description: '15',
  },
  {
    id: 16,
    value: '16',
    description: '16',
  },
  {
    id: 17,
    value: '17',
    description: '17',
  },
  {
    id: 18,
    value: '18',
    description: '18',
  },
  {
    id: 19,
    value: '19',
    description: '19',
  },
  {
    id: 20,
    value: '20',
    description: '20',
  },
  {
    id: 21,
    value: '21',
    description: '21',
  },
  {
    id: 22,
    value: '22',
    description: '22',
  },
  {
    id: 23,
    value: '23',
    description: '23',
  },
]

export const MINUTES = [
  {
    id: 0,
    value: '00',
    description: '00',
  },
  {
    id: 5,
    value: '05',
    description: '05',
  },
  {
    id: 10,
    value: '10',
    description: '10',
  },
  {
    id: 15,
    value: '15',
    description: '15',
  },
  {
    id: 20,
    value: '20',
    description: '20',
  },
  {
    id: 25,
    value: '25',
    description: '25',
  },
  {
    id: 30,
    value: '30',
    description: '30',
  },
  {
    id: 35,
    value: '35',
    description: '35',
  },
  {
    id: 40,
    value: '40',
    description: '40',
  },
  {
    id: 45,
    value: '45',
    description: '45',
  },
  {
    id: 50,
    value: '50',
    description: '50',
  },
  {
    id: 55,
    value: '55',
    description: '55',
  },
]

export const NAMED_DAYS = {
  0: 'Today',
  1: 'Tomorrow',
}
