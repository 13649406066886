import React, { useEffect } from 'react'
import { AllReasonsWrapper, TopIconWrapper } from './ReasonForCover.styles'
import { FieldLabel } from '../../components/common/FieldLabel'
import { ErrorMessage } from '../../components/common/ErrorMessage'
import { IMPOUNDED_VALUE, REASONS_FOR_COVER } from './constants'
import { RadioButton } from '../../components/common/RadioButton'
import { FieldSet } from '../../components/common/FieldSet'
import { FieldLegend } from '../../components/common/FieldLegend'

interface Props {
    id: string,
    name: string,
    value: number,
    error: boolean,
    errorMessage: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
}

const ReasonForCover: React.FC<Props> = ({
    id,
    name,
    value, 
    error, 
    errorMessage, 
    setFieldValue
 }) => {
    const setReasonForCover = (selectedValue) => {
      setFieldValue('ReasonForCover', selectedValue)
    }
        
    useEffect(() => {
    if (value === IMPOUNDED_VALUE) {
        setFieldValue('PolicyDuration', '30+days')
    } else {
        setFieldValue('PolicyDuration', '')
    }
    }, [value])
        
  return (
    <>
    <FieldSet>
      <AllReasonsWrapper>
        <FieldLegend>What's your reason for cover?</FieldLegend>
        {error && <ErrorMessage id={`${id}Error`}>{errorMessage}</ErrorMessage>}
        <TopIconWrapper>
          {REASONS_FOR_COVER.map((option) => (
            <RadioButton
              id={`${id}-${option.id}`}
              onChange={() => setReasonForCover(option.value)}
              error={error}
              value={option.description}
              name={name}
              checked={value === option.value}
              key={`radio-reasonforcover-${option.value}`}
            />
          ))}
        </TopIconWrapper>
      </AllReasonsWrapper>
      </FieldSet>
    </>
  )
}

export default ReasonForCover