import moment from 'moment'
import * as Yup from 'yup'
import {
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_SCHEME,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  PRIVATE_CAR,
} from '../containers/Vehicle/constants'
import {
  LICENCE_HELD_DURATIONS,
  TITLE_OPTIONS,
  VEHICLE_OPTIONS,
} from './constants'

export const POLICY_START_DATE_VALIDATION = Yup.object().shape({
  value: Yup.date('Select a valid policy start time')
    .nullable()
    .when('manuallyEdited', {
      is: true,
      then: Yup.date('Select a valid policy start time').required(
        'Select a valid policy start time'
      ),
    }),
  manuallyEdited: Yup.boolean(),
})

export const SPLIT_POLICY_START_DATE_VALIDATION = Yup.object()
  .shape({
    manuallyEdited: Yup.bool(),
    date: Yup.string().when('manuallyEdited', {
      is: true,
      then: Yup.string().required('Select a valid policy start time'),
    }),
    hour: Yup.string().when('manuallyEdited', {
      is: true,
      then: Yup.string().required('Select a valid policy start time'),
    }),
    minute: Yup.string().when('manuallyEdited', {
      is: true,
      then: Yup.string().required('Select a valid policy start time'),
    }),
  })
  .test(
    'PolicyStartDate.Future',
    'Select a future policy start time',
    function () {
      const { date, hour, minute, manuallyEdited } = this.parent.PolicyStartDate
      if (Boolean(date && hour && minute && manuallyEdited)) {
        const combinedDate = moment(`${date}T${hour}:${minute}`)
        return combinedDate.isValid() && combinedDate > moment()
      }
      return true
    }
  )

export const VEHICLE_VALUE_VALIDATION = Yup.number().required(
  'Select a vehicle value'
)

export const BODY_TYPE_VALIDATION = (scheme) =>
  scheme === COMMERCIAL_VEHICLE_SCHEME ||
  scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME
    ? Yup.string().nullable().required("We need to know your van's body type")
    : Yup.string()

export const USE_OF_VEHICLE_VALIDATION = (scheme) =>
  scheme === COMMERCIAL_VEHICLE_SCHEME
    ? Yup.string()
        .nullable()
        .required("We need to know why you're using your vehicle")
    : Yup.string()

export const OCCUPATION_VALIDATION = (scheme) =>
  scheme === COMMERCIAL_VEHICLE_SCHEME
    ? Yup.object().shape({
        Id: Yup.number().moreThan(0),
        OccupationCode: Yup.string().nullable().required(),
        OccupationName: Yup.string().nullable().required(),
      })
    : Yup.object()

const availableTitleOptions = TITLE_OPTIONS.map(({ value }) => value)
export const TITLE_VALIDATION = Yup.string()
  .oneOf(availableTitleOptions, 'Select a title')
  .required('Select a title')

export const FORENAME_VALIDATION = Yup.string()
  .nullable()
  .matches(
    /^[a-zA-Z]+(([’'\-\s][a-zA-Z])?[a-zA-Z]+)?(([’'\-\s][a-zA-Z])?[a-zA-Z]+)$/,
    'Enter a valid first name'
  )
  .min(2, 'Enter a valid first name')
  .max(50, 'Enter a valid first name')
  .required('Enter a valid first name')

export const SURNAME_VALIDATION = Yup.string()
  .nullable()
  .matches(
    /^[a-zA-Z]+(([’'\-\s][a-zA-Z])?[a-zA-Z]+)?(([’'\-\s][a-zA-Z])?[a-zA-Z]+)$/,
    'Enter a valid surname'
  )
  .min(2, 'Enter a valid surname')
  .max(50, 'Enter a valid surname')
  .required('Enter a valid surname')

export const DATE_OF_BIRTH_VALIDATION = Yup.object().shape({
  day: Yup.string()
    .length(2)
    .test('DateOfBirth.Day', (value) => {
      return (
        value &&
        !isNaN(parseInt(value)) &&
        parseInt(value) > 0 &&
        parseInt(value) <= 31
      )
    }),
  month: Yup.string()
    .length(2)
    .test('DateOfBirth.Month', (value) => {
      return (
        value &&
        !isNaN(parseInt(value)) &&
        parseInt(value) > 0 &&
        parseInt(value) <= 12
      )
    }),
  year: Yup.string()
    .length(4)
    .test('DateOfBirth.Year', (value) => {
      return (
        value &&
        !isNaN(parseInt(value)) &&
        parseInt(value) > 1900 &&
        parseInt(value) <= 2100
      )
    }),
  date: Yup.string()
    .length(19)
    .test(
      'DateOfBirth',
      'Sorry, we only provide insurance for customers aged between 18 and 80 years old',
      (value) => {
        let minimumDate = moment()
        let maximumDate = minimumDate.clone()
        let isValidDate = moment(value).isValid()
        maximumDate = maximumDate
          .subtract(17, 'year')
          .subtract(6, 'month')
          .format('YYYY-MM-DDTHH:mm:ss')
        minimumDate = minimumDate
          .subtract(80, 'year')
          .format('YYYY-MM-DDTHH:mm:ss')
        let selectedDate = new Date(value)
        let minimumDateAsDate = new Date(minimumDate)
        let maximumDateAsDate = new Date(maximumDate)
        return (
          isValidDate &&
          selectedDate >= minimumDateAsDate &&
          selectedDate < maximumDateAsDate
        )
      }
    ),
})

export const ADDRESS_VALIDATION = Yup.object().shape({
  PostcodeSearched: Yup.boolean().isTrue(
    'Confirm your postcode and find your address to continue'
  ),
  Postcode: Yup.string()
    .matches(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i,
      'Enter a valid postcode'
    )
    .min(5, 'Enter a valid postcode')
    .max(7, 'Enter a valid postcode')
    .required('Enter a valid postcode'),
  AddressKey: Yup.string().nullable().required('Select an address'),
})

export const LICENCE_TYPE_VALIDATION = Yup.string().required(
  'Select a licence type'
)

const availableLicenceHelds = LICENCE_HELD_DURATIONS.map(({ value }) => value)
export const LICENCE_HELD_DURATION_VALIDATION = Yup.string()
  .oneOf(
    availableLicenceHelds,
    "We need to know how long you've held your licence"
  )
  .required("We need to know how long you've held your licence")

export const EMAIL_VALIDATION = Yup.string()
  .email('Enter a valid email')
  .required('Enter a valid email')

export const MOBILE_NUMBER_VALIDATION = Yup.string()
  .matches(
    /^((07)[12345789]{1}\d{8})|((07624)\d{6})$/,
    'Enter a valid mobile number'
  )
  .min(10, 'Enter a valid mobile number')
  .max(11, 'Enter a valid mobile number')
  .required('Enter a valid mobile number')

export const DRIVING_LICENCE_VALIDATION = (IdentityCheck) =>
  IdentityCheck === 'drivingLicence'
    ? Yup.string()
        .matches(
          /^[A-Z9]{5}\d{6}[A-Z9]{2}\d[A-Z]{2}$/,
          'Enter a valid driving licence number'
        )
        .required('Enter a valid driving licence number')
    : Yup.string()

export const PASSPORT_LINE_ONE_VALIDATION = (IdentityCheck) =>
  IdentityCheck === 'passport'
    ? Yup.string()
        .matches(
          /^([P|p])(<)([G|g][B|b][R|r])([A-Za-z]{2})[(A-Za-z)<]{37}$/,
          'Ensure your passport line one is valid'
        )
        .required('Ensure your passport line one is valid')
    : Yup.string()

export const PASSPORT_LINE_TWO_VALIDATION = (IdentityCheck) =>
  IdentityCheck === 'passport'
    ? Yup.string()
        .matches(
          /^([0-9]{10})([G|g][B|b][R|r])([0-9]{7})([Mm|Ff<])([0-9]{7})(([0-9<]){16})$/,
          'Ensure your passport line two is valid'
        )
        .required('Ensure your passport line two is valid')
    : Yup.string()

export const PASSPORT_EXPIRY_DATE_VALIDATION = (IdentityCheck) =>
  IdentityCheck === 'passport'
    ? Yup.object().shape({
        day: Yup.string()
          .length(2)
          .test('PassportExpiryDate.Day', (value) => {
            return (
              value &&
              !isNaN(parseInt(value)) &&
              parseInt(value) > 0 &&
              parseInt(value) <= 31
            )
          }),
        month: Yup.string()
          .length(2)
          .test('PassportExpiryDate.Month', (value) => {
            return (
              value &&
              !isNaN(parseInt(value)) &&
              parseInt(value) > 0 &&
              parseInt(value) <= 12
            )
          }),
        year: Yup.string()
          .length(4)
          .test('PassportExpiryDate.Year', (value) => {
            return (
              value &&
              !isNaN(parseInt(value)) &&
              parseInt(value) >= 2023 &&
              parseInt(value) <= 2100
            )
          }),
        date: Yup.string()
          .length(19)
          .test(
            'PassportExpiryDate',
            'Sorry, you must use a valid passport!',
            (value) => {
              let minimumDate = moment()
              let maximumDate = minimumDate.clone()
              let isValidDate = moment(value).isValid()
              maximumDate = maximumDate
                .add(15, 'year')
                .format('YYYY-MM-DDTHH:mm:ss')
              minimumDate = minimumDate.format('YYYY-MM-DDTHH:mm:ss')
              let selectedDate = new Date(value)
              let minimumDateAsDate = new Date(minimumDate)
              let maximumDateAsDate = new Date(maximumDate)
              return (
                isValidDate &&
                selectedDate >= minimumDateAsDate &&
                selectedDate < maximumDateAsDate
              )
            }
          ),
      })
    : Yup.object().shape({
        day: Yup.string()
          .length(2)
          .test('PassportExpiryDate.Day', (value) => {
            return value === undefined
          }),
        month: Yup.string()
          .length(2)
          .test('PassportExpiryDate.Month', (value) => {
            return value === undefined
          }),
        year: Yup.string()
          .length(4)
          .test('PassportExpiryDate.Year', (value) => {
            return value === undefined
          }),
        date: Yup.string()
          .nullable()
          .length(19)
          .test(
            'PassportExpiryDate',
            'Ensure your passport expiry date is valid',
            (value) => {
              return value === undefined || value === null
            }
          ),
      })

export const POLICY_REASON_VALIDATION = Yup.string()
.nullable()
.required('Select a reason for cover')

export const POLICY_DURATION_VALIDATION = Yup.string()
  .nullable()
  .required('Select a policy duration')

export const VEHICLE_REG_VALIDATION = Yup.string()
  .nullable()
  .min(2, 'Enter a valid vehicle registration')
  .max(8, 'Enter a valid vehicle registration')
  .required('Enter a valid vehicle registration')

const availableVehilceTypes = VEHICLE_OPTIONS.map(({ value }) => value)
export const VEHICLE_TYPE_VALIDATION = Yup.string()
  .nullable()
  .oneOf(availableVehilceTypes, 'Select a vehicle type')
  .required('Select a vehicle type')

export const VEHICLE_MAKE_VALIDATION = Yup.string()
  .nullable()
  .required('Select a vehicle make')

export const VEHICLE_MODEL_VALIDATION = Yup.string()
  .nullable()
  .required('Select a vehicle model')

export const VEHICLE_VARIANT_VALIDATION = Yup.string()
  .nullable()
  .required('Select a vehicle variant')

export const VEHICLE_WEIGHT_VALIDATION = Yup.string()
  .nullable()
  .required('Enter a valid vehicle weight')

export const VEHICLE_MANUFACTURE_DATE_VALIDATION = Yup.string()
  .nullable()
  .required('Select a date of manufacture')

export const HOME_PAGE_REDESIGNED_VALIDATION = Yup.object().shape({
  ShowVehicleBuilder: Yup.boolean(),
  Registration: Yup.string().nullable().when('ShowVehicleBuilder', {
    is: false,
    then: VEHICLE_REG_VALIDATION,
  }),
  VehicleType: Yup.string().nullable().when('ShowVehicleBuilder', {
    is: true,
    then: VEHICLE_TYPE_VALIDATION,
  }),
  VehicleWeight: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleType'], {
      is: (showVehicleBuilder, type) =>
        showVehicleBuilder && type === COMMERCIAL_VEHICLE,
      then: VEHICLE_WEIGHT_VALIDATION,
    }),
  VehicleMake: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleWeight', 'VehicleType'], {
      is: (showVehicleBuilder, value, type) =>
        showVehicleBuilder &&
        ((type === COMMERCIAL_VEHICLE &&
          value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR),
      then: VEHICLE_MAKE_VALIDATION,
    }),
  VehicleModel: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleWeight', 'VehicleType'], {
      is: (showVehicleBuilder, value, type) =>
        showVehicleBuilder &&
        ((type === COMMERCIAL_VEHICLE &&
          value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR),
      then: VEHICLE_MODEL_VALIDATION,
    }),
  VehicleVariant: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleWeight', 'VehicleType'], {
      is: (showVehicleBuilder, value, type) =>
        showVehicleBuilder &&
        ((type === COMMERCIAL_VEHICLE &&
          value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR),
      then: VEHICLE_VARIANT_VALIDATION,
    }),
  VehicleDateOfManufacture: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleType'], {
      is: (showVehicleBuilder, type) =>
        showVehicleBuilder && type === COMMERCIAL_VEHICLE,
      then: VEHICLE_MANUFACTURE_DATE_VALIDATION,
    })
})

export const HOME_PAGE_VALIDATION = Yup.object().shape({
  PolicyDuration: POLICY_DURATION_VALIDATION,
  ReasonForCover: POLICY_REASON_VALIDATION,
  ShowVehicleBuilder: Yup.boolean(),
  Registration: Yup.string().nullable().when('ShowVehicleBuilder', {
    is: false,
    then: VEHICLE_REG_VALIDATION,
  }),
  VehicleType: Yup.string().nullable().when('ShowVehicleBuilder', {
    is: true,
    then: VEHICLE_TYPE_VALIDATION,
  }),
  VehicleWeight: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleType'], {
      is: (showVehicleBuilder, type) =>
        showVehicleBuilder && type === COMMERCIAL_VEHICLE,
      then: VEHICLE_WEIGHT_VALIDATION,
    }),
  VehicleMake: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleWeight', 'VehicleType'], {
      is: (showVehicleBuilder, value, type) =>
        showVehicleBuilder &&
        ((type === COMMERCIAL_VEHICLE &&
          value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR),
      then: VEHICLE_MAKE_VALIDATION,
    }),
  VehicleModel: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleWeight', 'VehicleType'], {
      is: (showVehicleBuilder, value, type) =>
        showVehicleBuilder &&
        ((type === COMMERCIAL_VEHICLE &&
          value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR),
      then: VEHICLE_MODEL_VALIDATION,
    }),
  VehicleVariant: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleWeight', 'VehicleType'], {
      is: (showVehicleBuilder, value, type) =>
        showVehicleBuilder &&
        ((type === COMMERCIAL_VEHICLE &&
          value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR),
      then: VEHICLE_VARIANT_VALIDATION,
    }),
  VehicleDateOfManufacture: Yup.string()
    .nullable()
    .when(['ShowVehicleBuilder', 'VehicleType'], {
      is: (showVehicleBuilder, type) =>
        showVehicleBuilder && type === COMMERCIAL_VEHICLE,
      then: VEHICLE_MANUFACTURE_DATE_VALIDATION,
    })
})

export const INVALID_REG_VEHICLE_VALIDATION = Yup.object().shape({
  Registration: VEHICLE_REG_VALIDATION,
  VehicleType: VEHICLE_TYPE_VALIDATION,
  VehicleWeight: Yup.string().nullable().when('VehicleType', {
    is: COMMERCIAL_VEHICLE,
    then: VEHICLE_WEIGHT_VALIDATION,
  }),
  VehicleMake: Yup.string()
    .nullable()
    .when(['VehicleWeight', 'VehicleType'], {
      is: (value, type) => {
        return (
          (type === COMMERCIAL_VEHICLE &&
            value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR
        )
      },
      then: VEHICLE_MAKE_VALIDATION,
    }),
  VehicleModel: Yup.string()
    .nullable()
    .when(['VehicleWeight', 'VehicleType'], {
      is: (value, type) => {
        return (
          (type === COMMERCIAL_VEHICLE &&
            value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR
        )
      },
      then: VEHICLE_MODEL_VALIDATION,
    }),
  VehicleVariant: Yup.string()
    .nullable()
    .when(['VehicleWeight', 'VehicleType'], {
      is: (value, type) => {
        return (
          (type === COMMERCIAL_VEHICLE &&
            value <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) ||
          type === PRIVATE_CAR
        )
      },
      then: VEHICLE_VARIANT_VALIDATION,
    }),
  VehicleDateOfManufacture: Yup.string().nullable().when('VehicleType', {
    is: COMMERCIAL_VEHICLE,
    then: VEHICLE_MANUFACTURE_DATE_VALIDATION,
  }),
})

export const ENQUIRY_TYPE_VALIDATION = Yup.string()
  .nullable()
  .required('Select an enquiry type')

export const CONTACT_US_QUESTION_VALIDATION = Yup.string()
  .nullable()
  .required('Enter either a comment or a question')

export const FULLNAME_VALIDATION = Yup.string()
  .nullable()
  .matches(
    /^[a-zA-Z]+(([’'\-\s][a-zA-Z])?[a-zA-Z]+)$/,
    'Enter a valid full name'
  )
  .min(2, 'Enter a valid full name')
  .max(50, 'Enter a valid full name')
  .required('Enter a valid full name')

export const CONTACT_US_POLICY_NUMBER_VALIDATION = Yup.string().nullable()

export const COINTACT_US_RECAPTCHA_VALIDATION = Yup.string()
  .nullable()
  .required('Invalid token')
