import styled from 'styled-components'
import { H3 } from '../../components/common/H3'
import { P } from '../../components/common/P'

export const Wrapper = styled.div`
  margin-bottom: 36px;
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
  margin-bottom: ${(props) => (props.bigGap ? '24px' : '0')};
`

export const ShowMoreButton = styled.a`
  font-weight: 600;
  cursor: pointer;
  color: #1f1f1f;

  &:hover,
  &:active,
  &:visited {
    color: #1f1f1f;
  }
`

export const Chevron = styled.img`
  transform: ${(props) => (props.showMore ? 'rotate(0deg)' : 'rotate(-90deg)')};
  transition: transform 0.5s
  width: 24px;
  height: 24px;
`

export const MoreInfoTextWrapper = styled.span`
  text-decoration: underline;
  margin-right: 8px;
  display: flex;
  align-items: center;
`

export const MoreInfoWrapper = styled.div`
  padding-left: 24px;
`

export const Bold = styled.span`
  font-weight: bold;
`

export const CRODetailContainer = styled.div`
  display: grid;
  grid-template-columns: 175px auto;
  grid-column-gap: 48px;
  grid-row-gap: 16px;

  p {
    margin-bottom: 0;
    line-height: 1.25em;
  }

  @media (max-width: 450px) {
    grid-template-columns: auto;
    grid-row-gap: 8px;

    p:nth-child(even) {
      margin-top: -8px;
      margin-bottom: 8px;
    }
  }
`

export const UnderwriterPriceBadgeContainer = styled.div`
  background-color: #d4d4d4;
  display: grid;
  grid-template-columns: 25% 75%;
  @media (max-width: 768px) {
    width: 100%;
    grid-template-columns: 40% 60%;
  }
  @media (max-width: 450px) {
    grid-template-columns: 50% 50%;
  }
  border-top: 4px solid #58aae0;
  margin-bottom: 24px;
`
export const UnderwriterPriceBadgeContainerDiscount = styled.div`
  background-color: #d4d4d4;
  display: grid;
  grid-template-columns: 25% 75%;
  @media (max-width: 768px) {
    grid-template-columns: 45% 55%;
  }
  border: 4px solid #1f1f1f;
  margin-bottom: 24px;
`

export const SpecialDiscountBadge = styled.div`
  display: grid;
  grid-template-columns: 7% 93%;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    grid-template-columns: 10% 90%;
  }
  @media (max-width: 450px) {
    grid-template-columns: 13% 87%;
  }

  background-color: #1f1f1f;
  color: #fff;
`
export const SpecialDiscountText = styled.p`
  color: #fff;
  @media (max-width: 450px) {
    font-size: 12px;
    padding-left: 4px;
  }
`

export const BannerImage = styled.img`
  height: 24px;
  width: 24px;
  @media (max-width: 450px) {
    padding: inherit;

    margin-left: 12px;
  }
`
export const BannerImageContainer = styled.div`
  padding: 8px 16px 0px 16px;
  @media (max-width: 768px) {
    padding: inherit;
    margin-left: 8px;
  }
`

export const UnderwriterPriceBadgeHeader = styled(H3)`
  margin: 12px 0px;
  font-weight: bold;
`
export const UnderwriterPriceBadgePriceSection = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  align-content: end;
`
export const PreviousPrice = styled.div`
  text-decoration: line-through;
  font-size: 20px;
`
export const CurrentPrice = styled.div`
  font-weight: 800;
  font-size: 42px;
  @media (max-width: 450px) {
    font-size: 32px;
  }
`
export const DiscountPrice = styled.div`
  display: inline-block;
`
export const PreviousPriceSection = styled.div``

export const CurrentPriceSectionDiscount = styled.div`
  margin-top: 8px;
`
export const CurrentPriceSection = styled.div`
  padding-top: 32px;
  font-weight: bold;

  @media (max-width: 450px) {
    padding-top: 32px;
  }
`
export const ImageSectionDiscount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background: #fff;
`

export const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 40px 0;
  background: #fff;
  @media (max-width: 450px) {
    padding: 40px 0;
  }
`
export const PriceSection = styled.div`
  padding-left: 16px;
`
export const DiscountSection = styled.div`
  max-width: 22%;
  background-color: #fff;
  display: inline-block;
  @media (max-width: 768px) {
    max-width: 50%;
  }
  @media (max-width: 450px) {
    max-width: 72%;
  }
`
export const DiscountSectionText = styled(P)`
  margin-top: inherit;
  margin-left: 4px;
  margin-bottom: inherit;
`
