import {
  REQUEST_VEHICLE,
  REQUEST_VEHICLE_FAILED,
  REQUEST_VEHICLE_SUCCESS,
  SET_VEHICLE,
  UPDATE_SEARCHED_VEHICLE,
  GET_MAKES,
  GET_MAKES_SUCCESS,
  GET_MODELS,
  GET_MODELS_SUCCESS,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS,
  CREATE_UNKNOWN_VEHICLE,
  CREATE_UNKNOWN_VEHICLE_SUCCESS,
  GET_MAKES_FAILURE,
  GET_MODELS_FAILURE,
  GET_VARIANTS_FAILURE,
  CREATE_UNKNOWN_VEHICLE_FAILURE,
  UNKNOWN_VEHICLE_REG,
  UNKNOWN_VEHICLE_REG_SUCCESS,
  UNKNOWN_VEHICLE_REG_FAILURE,
  SET_VEHICLE_SEARCHED_REG,
  SET_VEHICLE_ERROR,
  UPDATE_VEHICLE_VALUE,
  SET_VEHICLE_TYPE,
  UPDATE_GROSS_WEIGHT,
  UPDATE_YEAR_OF_MANUFACTURE,
  SET_VEHICLE_LISTENER,
  VEHICLE_TYPE_CHANGE,
} from './constants'

export function requestVehicle(registrationNumber) {
  return {
    type: REQUEST_VEHICLE,
    registrationNumber,
  }
}

export function updateSearchedVehicle(vehicle) {
  return {
    type: UPDATE_SEARCHED_VEHICLE,
    vehicle,
  }
}

export function requestVehicleSuccess(vehicle) {
  return {
    type: REQUEST_VEHICLE_SUCCESS,
    vehicle,
  }
}

export function requestVehicleFailed(registrationNumber, error) {
  return {
    type: REQUEST_VEHICLE_FAILED,
    registrationNumber,
    error,
  }
}

export function setVehicle(vehicle) {
  return {
    type: SET_VEHICLE,
    vehicle,
  }
}

export function setVehicleListener(vehicle) {
  return {
    type: SET_VEHICLE_LISTENER,
    vehicle,
  }
}

export function setVehicleSearchedReg(vehicleReg, vehicleType) {
  return {
    type: SET_VEHICLE_SEARCHED_REG,
    vehicleReg,
    vehicleType,
  }
}

export function setVehicleError(error) {
  return {
    type: SET_VEHICLE_ERROR,
    error,
  }
}

export function getMakes() {
  return {
    type: GET_MAKES,
  }
}

export function getMakesSuccess(makes, links) {
  return {
    type: GET_MAKES_SUCCESS,
    makes,
    links,
  }
}

export function getMakesFailure(error) {
  return {
    type: GET_MAKES_FAILURE,
    error,
  }
}

export function getModels() {
  return {
    type: GET_MODELS,
  }
}

export function getModelsSuccess(models, links) {
  return {
    type: GET_MODELS_SUCCESS,
    models,
    links,
  }
}

export function getModelsFailure(error) {
  return {
    type: GET_MODELS_FAILURE,
    error,
  }
}

export function getVariants() {
  return {
    type: GET_VARIANTS,
  }
}

export function getVariantsSuccess(variants, links) {
  return {
    type: GET_VARIANTS_SUCCESS,
    variants,
    links,
  }
}

export function getVariantsFailure(error) {
  return {
    type: GET_VARIANTS_FAILURE,
    error,
  }
}

export function createUnkownVehicle(redirect) {
  return {
    type: CREATE_UNKNOWN_VEHICLE,
    redirect,
  }
}

export function createUnkownVehicleSuccess(vehicle) {
  return {
    type: CREATE_UNKNOWN_VEHICLE_SUCCESS,
    vehicle,
  }
}

export function createUnkownVehicleFailure(error) {
  return {
    type: CREATE_UNKNOWN_VEHICLE_FAILURE,
    error,
  }
}

export function unknownReg() {
  return {
    type: UNKNOWN_VEHICLE_REG,
  }
}

export function unknownRegSuccess(builtVehicle, manualVehicle) {
  return {
    type: UNKNOWN_VEHICLE_REG_SUCCESS,
    builtVehicle,
    manualVehicle,
  }
}

export function unknownRegFailure() {
  return {
    type: UNKNOWN_VEHICLE_REG_FAILURE,
  }
}

export function updateVehicleValue(value) {
  return {
    type: UPDATE_VEHICLE_VALUE,
    value,
  }
}

export function setVehicleType(vehicleType) {
  return {
    type: SET_VEHICLE_TYPE,
    vehicleType,
  }
}

export function updateGrossWeight(grossWeight) {
  return {
    type: UPDATE_GROSS_WEIGHT,
    grossWeight,
  }
}

export function updateYearOfManufacture(yearOfManufacture) {
  return {
    type: UPDATE_YEAR_OF_MANUFACTURE,
    yearOfManufacture,
  }
}

export function vehicleTypeChange() {
  return {
    type: VEHICLE_TYPE_CHANGE,
  }
}
