export const REASONS_FOR_COVER = [
    {
      id: 1,
      description: 'Borrowing a vehicle',
      value: 30,
    },
    {
      id: 2,
      description: 'Buying or test driving a vehicle',
      value: 31,
    },
    {
      id: 3,
      description: 'Impound insurance',
      value: 32,
    },
    {
      id: 4,
      description: "My vehicle's in for MOT/service",
      value: 33,
    },
    {
      id: 5,
      description: 'Temporary business need',
      value: 34,
    },
  ]

  export const IMPOUNDED_VALUE = 32
