/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'
import { ConfusedWrapper } from '../common/ConfusedWrapper'
import PdfFile from '../../assets/Customer-Terms-of-Business-Confused-Jan-2024.pdf'

import {
  H1,
  H2,
  H3,
  P,
  A,
  UL_C,
  LI,
  StaticPageHeader,
  TermsAndConditionsWrapper,
  TermsAndConditionsHeader,
  TermsAndConditionsDownload,
} from './styles'

const TermsAndConditionsPage = () => {
  return (
    <ConfusedWrapper>
      <Grid desktop='100' mobile='100' tablet='100'>
        <TermsAndConditionsWrapper>
          <TermsAndConditionsHeader>
            <H1>Terms &amp; conditions</H1>
            <H2>Customer Terms of Business (“Terms of Business”)</H2>
          </TermsAndConditionsHeader>
          <TermsAndConditionsDownload>
            <a
              href={PdfFile}
              target='_blank'
              rel='noopener noreferrer'
              download='Customer-Terms-of-Business-Confused-Jan-2024.pdf'
            >
              Download content <i class='far fa-file-pdf'></i>
            </a>
          </TermsAndConditionsDownload>
        </TermsAndConditionsWrapper>
        <P>
          Tempcover Ltd (“Tempcover”), 2nd Floor Admiral House, Harlington Way,
          Fleet, Hampshire, GU51 4BB
        </P>
        <P>
          Tempcover and yourself agree to be bound by these Terms of Business
          when you purchase a policy from us. In addition to the contract that
          you have with the Insurer, you will have a separate contract with
          Tempcover Limited for the arrangement and administration of your
          insurance policy. This contract covers the specific insurance
          intermediary services that we provide directly to you.
        </P>
        <P>
          You should read the information contained within these Terms of
          Business before you purchase a policy from us to decide if the
          services that we provide are right for your needs.
        </P>
        <H3>
          <StaticPageHeader>
            1.&nbsp;&nbsp;&nbsp;&nbsp;Who regulates us?
          </StaticPageHeader>
        </H3>
        <P>
          Tempcover Ltd is authorised and regulated by the Financial Conduct
          Authority (FCA). The FCA is the independent watchdog that regulates
          financial services, including insurance. Our FCA number is 746985. You
          can check this at{' '}
          <A
            href='https://register.fca.org.uk/'
            target='_blank'
            rel='noopener noreferrer'
          >
            {' '}
            https://register.fca.org.uk/
          </A>{' '}
          or by contacting the FCA on 0800 111 6768. Our permitted business is
          arranging and assisting in the administration and performance of a
          contract of insurance.
        </P>
        <H3>
          <StaticPageHeader>
            2.&nbsp;&nbsp;&nbsp;&nbsp;What service will we provide you with?
          </StaticPageHeader>
        </H3>
        <P>
          We provide access to a range of short-term motor insurance policies
          under 1 month in duration. We will present you with appropriate
          products, confirm prices, set up, administer and take payment for your
          insurance.
        </P>
        <P>
          We will not provide you with advice or a personal recommendation. We
          will help you make the right choice by asking some questions to narrow
          down the selection of cover options and provide information relevant
          to your insurance demands and needs. This will involve us providing
          you with a quotation. You will then need to make your own choice about
          how to proceed. We can answer any questions you may have but we will
          not be able to advise you.
        </P>
        <P>
          By completing our on-line application, you have requested short-term
          motor insurance in accordance with the information that you have
          provided. The standard private car and commercial vehicle policies
          that we arrange and administer are designed for people who require
          short-term motor insurance cover on a comprehensive basis whilst
          driving in the United Kingdom.
        </P>
        <P>
          For more information relating to what we can and cannot cover, please
          review our Eligibility Criteria at{' '}
          <A
            href='https://temporaryinsurance.confused.com/who-we-cover-eligibility'
            target='_blank'
            rel='noopener noreferrer'
          >
            {' '}
            https://temporaryinsurance.confused.com/who-we-cover-eligibility
          </A>
          .
        </P>

        <H3>
          <StaticPageHeader>
            3.&nbsp;&nbsp;&nbsp;&nbsp;Who do we act for?
          </StaticPageHeader>
        </H3>
        <P>
          As an Insurance Intermediary we work to match customers to appropriate
          insurance products. As a result, during our work there will be times
          when we’re acting as your agent and other times when we’re acting in
          the capacity of agent of the insurer.
        </P>
        <P>
          We will act for you when we complete all the preparatory work needed
          to set up your insurance policy.
        </P>
        <P>
          We will act for the insurer when we make their product available to
          you and assisting in the administration of the policy of insurance. We
          will generally source the best price for you and present this to you
          alongside documentation for you to read to allow you to make an
          informed decision about your purchase.
        </P>
        <P>
          However, in certain circumstances we may act for and owe duties of
          care to other parties. We will inform you when these circumstances
          occur so you will be aware of any possible conflict of interest.
        </P>
        <H3>
          <StaticPageHeader>
            4.&nbsp;&nbsp;&nbsp;&nbsp;Whose products do we offer?
          </StaticPageHeader>
        </H3>
        <P>
          We offer policies representative of a wide range of insurers that
          offer the types of cover required. We compare policies from those
          insurers in terms of cover and price, to offer an appropriate
          quotation for you, which will generally be the cheapest quotation
          based on the information that you have provided about yourself and
          your needs.
        </P>
        <P>We work with the following insurers:</P>
        <UL_C>
          <LI>Ageas Insurance Limited </LI>
          <LI>Eridge Underwriting Agency Limited </LI>
          <LI>First Underwriting Limited </LI>
          <LI>Granite Underwriting </LI>
          <LI>Haven Insurance Company Limited </LI>
          <LI>KGM Underwriting Services </LI>
          <LI>LV= </LI>
          <LI>Watford Insurance Company Europe Limited </LI>
        </UL_C>
        <P>
          Whilst we take every care to check the financial stability of any
          insurer on our panel, we cannot be held responsible if that firm
          subsequently ceases to trade.
        </P>
        <H3>
          <StaticPageHeader>
            5.&nbsp;&nbsp;&nbsp;&nbsp;What will you to have to pay for our
            services?
          </StaticPageHeader>
        </H3>
        <P>
          The total price you pay will always include an insurer premium and a
          Tempcover Fee. Where relevant the total price will also reflect any
          additional extras you have selected and/or an introducer fee.
        </P>
        <P>
          <strong>Insurer Premium</strong>
        </P>
        <UL_C>
          <LI>
            The insurer premium includes the costs of underwriting your
            insurance, any insurer charges, and any commission Tempcover
            receives from the insurer for placing your business.
          </LI>
          <LI>
            Tempcover works with a panel of insurers. The individual
            arrangements we have in place with each insurer will determine how
            much commission Tempcover will receive, which for some insurers can
            be zero.
          </LI>
          <LI>
            Insurance Premium Tax (IPT.) Any IPT amount will be presented to you
            separately.
          </LI>
        </UL_C>
        <P>
          <strong>Tempcover Fee (Broker Fee)</strong>
        </P>
        <UL_C>
          <LI>
            The Tempcover fee covers our work preparatory to the conclusion of
            the policy and the provision of assistance in the arranging,
            administration and performance of the insurance contract.
          </LI>
          <LI>
            It is based on our costs for placing your business, conducting all
            required checks, policy demand at the time of application, and our
            costs for customer service activity.
          </LI>
          <LI>
            The required checks may include: identity validation check, driving
            licence check, passport check, vehicle registration look-up and
            postcode validation check.
          </LI>
          <LI>
            The amount of our Tempcover fee will always be presented to you
            before you purchase the policy.
          </LI>
        </UL_C>
        <P>
          The Tempcover fee will not exceed the following amounts for arranging
          and administering your insurance. These fees are payable directly to
          Tempcover under your separate contract.
        </P>
        <P>
          <table border='1'>
            <tbody>
              <tr>
                <td width='120'>
                  <strong>&nbsp;</strong>
                </td>
                <td width='150'>
                  <strong>Temporary Car Insurance</strong>
                </td>
                <td width='150'>
                  <strong>Temporary Van Insurance</strong>
                </td>
                <td width='150'>
                  <strong>Temporary Bike Insurance</strong>
                </td>
                <td width='150'>
                  <strong>Temporary Learner Insurance</strong>
                </td>
                <td width='150'>
                  <strong>Impounded Vehicle Insurance</strong>
                </td>
              </tr>
              <tr>
                <td width='120'>
                  <strong>Maximum Tempcover Fee Payable </strong>
                </td>
                <td width='150' align='middle'>
                  £130
                </td>
                <td width='150' align='middle'>
                  £130
                </td>
                <td width='150' align='middle'>
                  £130
                </td>
                <td width='150' align='middle'>
                  £130
                </td>
                <td width='150' align='middle'>
                  £200
                </td>
              </tr>
            </tbody>
          </table>
        </P>
        <P>
          <strong>Introducer Fee</strong>
        </P>
        <UL_C>
          <LI>
            You may pay an introducer fee where the policy is purchased through
            one of our selected partners.
          </LI>
        </UL_C>
        <P>
          <strong>Other Payments</strong>
        </P>
        <UL_C>
          <LI>
            We may receive fees from our insurer partners when we introduce
            customers to them for the provision of certain accident management
            services.
          </LI>
          <LI>
            From time to time, we may also participate in performance-based
            profit share agreements with insurers.
          </LI>
        </UL_C>
        <P>
          The quotation we offer is based on the best price available at the
          time of quotation for the cover you have requested. The quotation
          price will be correct at the time of issuing but is not guaranteed if
          it is not purchased at the time the quotation is provided and may
          change. Quotation prices may change for a variety of reasons,
          including, but not limited to, the insurer changing the premium rate
          it will offer the cover for or their acceptance criteria and/or
          changes to the Tempcover fee.
        </P>
        <P>
          We earn the entirety of our Tempcover fee when your risk is
          successfully placed and take our fee immediately upon receipt of
          payment unless agreed otherwise with your insurer. You are entitled at
          any time to request information about earnings that we receive as a
          result of placing your business. Remuneration is only earned on the
          basis that it does not detract from our obligation to act in your best
          interests at all times.
        </P>
        <P>
          We currently accept payment by Debit or Credit card. We require full
          payment before your policy can be completed and documents issued to
          you.
        </P>
        <H3>
          <StaticPageHeader>
            6.&nbsp;&nbsp;&nbsp;&nbsp;How we will handle your money?
          </StaticPageHeader>
        </H3>
        <P>
          We act as agent of the insurer for the collection of premium and where
          applicable any refunds. This means that premiums are treated as being
          received by the insurer when received in our separate insurer trust
          account and the insurer will bear the risk for any losses that may
          arise from the failure of our firm. This means that the policy will
          start on the agreed start date irrespective of whether the insurer
          received payment of the premium. Any interest, or investment returns,
          earned on monies whilst in our possession will be retained by
          ourselves.
        </P>
        <H3>
          <StaticPageHeader>
            7.&nbsp;&nbsp;&nbsp;&nbsp;Your duty to disclose information
          </StaticPageHeader>
        </H3>
        <P>
          When you enter into a contract of insurance wholly for non-business
          purposes you are under a legal duty to take reasonable care not to
          misrepresent information to insurers. If you misrepresent information
          deliberately, recklessly or carelessly respond to insurer questions
          then a claim under the policy may not be paid.
        </P>
        <H3>
          <StaticPageHeader>
            8.&nbsp;&nbsp;&nbsp;&nbsp;Changes, Cancellations and Refunds
          </StaticPageHeader>
        </H3>
        <P>
          If your policy is for 30 days or less in duration, once purchased you
          will not be able to make any changes or additions to the policy and
          due to the short-term nature of the policy, you will not be entitled
          to a refund of any monies paid if you cancel your policy. This is in
          line with the rules set by the Financial Conduct Authority.
        </P>
        <H3>
          <StaticPageHeader>9.&nbsp;&nbsp;&nbsp;&nbsp;Claims</StaticPageHeader>
        </H3>
        <P>
          To provide the best possible service all claims are handled directly
          by insurers. In the unfortunate event of an accident or incident
          involving your vehicle you should contact your insurer using the
          telephone number displayed on your certificate of motor insurance or
          policy wording. If you are ever in any doubt as to what action to take
          in the event of a claim, please contact us on the number provided or
          email us at{' '}
          <A href='mailto:contactus@tempcover.com'>contactus@tempcover.com</A>.
        </P>
        <H3>
          <StaticPageHeader>
            10.&nbsp;&nbsp;&nbsp;&nbsp;What to do if you are unhappy about our
            service?
          </StaticPageHeader>
        </H3>
        <P>
          Every effort is made to ensure that we provide you with a high level
          of customer service at all times. However, if you need to bring any
          matter to our attention or wish to register a complaint about
          Tempcover, please contact us:{' '}
          <UL_C>
            <LI>
              {' '}
              in writing: to Head of Operations, Tempcover Limited, 2nd Floor
              Admiral House, Harrington Way, Fleet, Hampshire, GU51 4BB;
            </LI>
            <LI>
              by email:{' '}
              <A href='mailto:complaints@tempcover.com'>
                complaints@tempcover.com
              </A>{' '}
              for the attention of the Director of Operations & Compliance; or
            </LI>
            <LI>
              by telephone: 0330 460 9633. This line is open 9am to 5.30pm
              Monday to Friday, excluding Bank Holidays and is used solely for
              the purpose of registering a complaint with Tempcover. Calls to
              our offices may be recorded for training and monitoring purposes.
            </LI>
          </UL_C>
        </P>
        <P>
          To help us continually improve our service, and in the interests of
          security, your communications may be monitored and/or recorded.
          Following the complaints procedure does not affect your right to take
          legal action. If you cannot settle your complaint with us, you may be
          entitled to refer it to the Financial Ombudsman Service (“FOS”).
          Further information is available by contacting them on 0800 023 4567
          or by visiting their website on:
          <A
            href='https://www.financial-ombudsman.org.uk/'
            target='_blank'
            rel='noopener noreferrer'
          >
            www.financial-ombudsman.org.uk/
          </A>
        </P>
        <H3>
          <StaticPageHeader>
            11.&nbsp;&nbsp;&nbsp;&nbsp;Are we covered by the Financial Services
            Compensation Scheme (FSCS)?
          </StaticPageHeader>
        </H3>
        <P>
          Our obligations are covered by the Financial Services Compensation
          Scheme (FSCS). If we were unable to meet our obligations, you could be
          entitled to compensation from this scheme, depending on the type of
          insurance and the circumstances at the time. Further information about
          compensation scheme arrangements is available on the FSCS website:{' '}
          <A
            href='https://www.fscs.org.uk/'
            target='_blank'
            rel='noopener noreferrer'
          >
            www.fscs.org.uk/
          </A>{' '}
          or telephone 0800 678 1100.
        </P>
        <H3>
          <StaticPageHeader>
            12.&nbsp;&nbsp;&nbsp;&nbsp;Privacy & Confidentiality
          </StaticPageHeader>
        </H3>
        <P>
          When you contact us for an insurance quotation we collect the relevant
          personal information needed by an insurer to understand your insurance
          needs and calculate the premium. All personal information about you
          will be treated as private and confidential. We are registered under
          the Data Protection Act 2018 and we undertake to comply with the Act
          in all our dealings with your personal data. Your personal information
          will be kept secure.
        </P>
        <P>
          For further information relating to your rights as a data subject
          please view our Privacy Notice at:{' '}
          <A
            href='https://temporaryinsurance.confused.com/privacy-cookies?referrer=confused'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://temporaryinsurance.confused.com/privacy-cookies?referrer=confused
          </A>
          .
        </P>
        <H3>
          <StaticPageHeader>
            13.&nbsp;&nbsp;&nbsp;&nbsp;Conflicts of Interest
          </StaticPageHeader>
        </H3>
        <P>
          We have no close links with our insurers. We manage any conflicts of
          interest by monitoring the outcomes of our customers. There may be
          occasions when a potential conflict of interest arises. If this
          happens, we will inform you and obtain your consent before we carry
          out your instructions.
        </P>
        <H3>
          <StaticPageHeader>
            14.&nbsp;&nbsp;&nbsp;&nbsp;Your Insurer Schedule/Insurer Certificate
            of Insurance
          </StaticPageHeader>
        </H3>
        <P>
          Your insurer policy schedule and insurer certificate of insurance,
          which form part of your contract with the insurer, will not be issued
          until we are in receipt of full payment of the policy premium. These
          will be issued to you via the policy confirmation email sent to the
          email address that you provide. You may also request a postal copy,
          free of charge. To request this please contact us on{' '}
          <A href='mailto:contactus@tempcover.com'>contactus@tempcover.com</A>.
        </P>

        <H3>
          <StaticPageHeader>
            15.&nbsp;&nbsp;&nbsp;&nbsp;Claims and Underwriting Exchange Register
            and Motor Insurance Anti-Fraud Register
          </StaticPageHeader>
        </H3>
        <P>
          Insurers pass information to the Claims and Underwriting Exchange
          Register operated by Insurance Database Services Limited and the Motor
          Insurance Anti-Fraud and Theft Register compiled by the Association of
          British Insurers. The objective is to check information provided and
          to prevent fraudulent claims. Motor insurance details are also added
          to the Motor Insurance Database operated by the Motor Insurers’
          Information Centre (MIIC), which has been formed to help identify
          uninsured drivers and may be accessed by the police to help confirm
          who is insured to drive. In the event of an accident, this database
          may be used by insurers, MIIC and your motor insurer to identify
          relevant policy information. Other insurance related databases may
          also be added in the future.
        </P>
        <H3>
          <StaticPageHeader>
            16.&nbsp;&nbsp;&nbsp;&nbsp;Applicable law
          </StaticPageHeader>
        </H3>
        <P>
          This Terms of Business document is subject to English Law and the
          jurisdiction of English Courts.
        </P>
        <H3>
          <StaticPageHeader>
            17.&nbsp;&nbsp;&nbsp;&nbsp;Version Control
          </StaticPageHeader>
        </H3>
        <P>Last updated: 06th February 2024</P>
        <P>Version: 1.2.</P>
      </Grid>
    </ConfusedWrapper>
  )
}

export default TermsAndConditionsPage
