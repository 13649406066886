import styled from 'styled-components'

export const ConfusedWording = styled.a`
  color: ${(props) => props.theme.primaryTextColour};
  cursor: pointer;
  text-decoration: none;
  font-size: ${(props) => props.theme.linkFontSize};
  border-bottom: 1px solid ${(props) => props.theme.primaryTextColour};
  text-align: left;
`
