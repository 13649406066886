import { RENDER_READY } from '../constants'

import initialState from '../../../initialState'

function renderReadyReducer(state = initialState.renderReady, action) {
  switch (action.type) {
    case RENDER_READY:
      return true
    default:
      return state
  }
}

export default renderReadyReducer
