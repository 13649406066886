import initialState from '../../initialState'
import {
  SEND_3D_SECURE_DATA_SUCCESS,
  CREATE_JUDO_PAYMENT_FAILURE,
  SEND_3D_SECURE_DATA,
} from '../JudoPay/constants'
import {
  SEND_EMAIL_QUOTE_SUCCESS,
  UPDATE_EMAIL_RISK_DATA_SUCCESS,
} from '../EmailQuoteModal/constants'
import {
  FRAUD_FAILURE,
  SET_PAYMENT_IN_PROGRESS,
  CLEAR_PAYMENT_ERROR,
  PRICE_MATCH,
} from './constants'
import {
  PAYPAL_PAYMENT_ERROR_SUCCESS,
  CREATE_PAYPAL_PAYMENT,
} from '../PayPal/constants'
import { RESET_PAYMENT_OPTIONS } from './constants'
import { CREATE_APPLE_PAYMENT_FAILURE } from '../ApplePay/constants'
import { REQUEST_QUOTE_SUCCESS, CREATE_QUOTE_SUCCESS } from '../App/constants'
import { CREATE_STRIPE_PAYMENT_FAILURE } from '../Stripe/constants'

function paymentPageReducer(state = initialState.payment, action) {
  switch (action.type) {
    case UPDATE_EMAIL_RISK_DATA_SUCCESS:
    case SEND_EMAIL_QUOTE_SUCCESS:
      return {
        ...state,
        optionsVisible: true,
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        optionsVisible: action.emailAddress ? true : false,
      }
    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        optionsVisible: action.quote.EmailAddress ? true : false,
      }
    case SEND_3D_SECURE_DATA_SUCCESS:
      return {
        ...state,
        Secure: {
          ...state.Secure,
          ...action.response,
        },
      }
    case PAYPAL_PAYMENT_ERROR_SUCCESS:
    case CREATE_JUDO_PAYMENT_FAILURE:
    case CREATE_APPLE_PAYMENT_FAILURE:
    case CREATE_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        paymentError: action.errorMessage,
        paymentInProgress: false,
        priceMatch: true,
      }
    case CLEAR_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: '',
      }
    case FRAUD_FAILURE:
      return {
        ...state,
        paymentInProgress: false,
      }
    case CREATE_PAYPAL_PAYMENT:
    case SET_PAYMENT_IN_PROGRESS:
      return {
        ...state,
        paymentInProgress: true,
      }
    case RESET_PAYMENT_OPTIONS:
      return {
        ...state,
        paymentInProgress: false,
        optionsVisible: false,
      }
    case SEND_3D_SECURE_DATA:
      return {
        ...state,
        paymentInProgress: false,
      }
    case PRICE_MATCH:
      return {
        ...state,
        priceMatch: action.isPriceMatching,
      }
    default:
      return state
  }
}

export default paymentPageReducer
