import React from 'react'
import { FieldLabel } from '../../components/common/FieldLabel'
import BasicDate from '../common/BasicDate'
import { ErrorMessage } from '../common/ErrorMessage'

interface Props {
  id: string
  name: string
  handleBlurFunction: React.FocusEventHandler<HTMLInputElement>
  onChange: (field: string, value: string | number, shouldValidate?: boolean) => void,
  error: {
    day?: string
    month?: string
    year?: string
    date?: string
  }
  value: {
    day: string
    month: string
    year: string
  }
  touched: {
    day?: boolean
    month?: boolean
    year?: boolean
  }
  errorMessage: string
}

const PassportExpiryDate: React.FC<Props> = ({
  id,
  name,
  handleBlurFunction,
  onChange,
  value,
  touched,
  error,
  errorMessage,
}) => {
  return (
    <>
      <FieldLabel htmlFor={name}>Passport Expiry Date</FieldLabel>
      <BasicDate
        id={id}
        name={name}
        onBlur={handleBlurFunction}
        onChange={onChange}
        value={value}
        touched={touched}
        error={error}
      />
      {error && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
    </>
  )
}
export default PassportExpiryDate
