import {
  UPDATE_QUOTE,
  UPDATE_RISK_DATA,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_FAILURE,
  UPDATE_VALIDATION_RISK_DATA_COLLECTION,
  TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION,
  INIT_RISK_DATA_COLLECTION_PAGE,
  REQUEST_IDENTITY_CHECK,
  REQUEST_IDENTITY_CHECK_SUCCESS,
  FORM_MANUALLY_EDITED,
  REQUEST_IDENTITY_CHECK_FAILURE,
  UPDATE_UNDERWRITER,
  UPDATE_UNDERWRITER_SUCCESS,
  CONFIRM_RISK_DATA_COLLECTION_PAGE,
  REQUEST_IDENTITY_CHECK_MORE_INFO,
  DRIVING_LICENCE_CHECK,
  PASSPORT_CHECK,
  CLEAR_IDENTITY_CHECK,
  IS_BUTTON_DISABLED,
  ENABLE_ALL_BUTTONS,
  UNDERWRITER_CHANGED_BY_CUE,
} from './constants'

export function initRiskDataCollectionPage() {
  return {
    type: INIT_RISK_DATA_COLLECTION_PAGE,
  }
}

export function confirmRiskCollectionPage(callback) {
  return {
    type: CONFIRM_RISK_DATA_COLLECTION_PAGE,
    callback,
  }
}

export function updateQuote(payload) {
  return {
    type: UPDATE_QUOTE,
    payload,
  }
}

export function updateQuoteSuccess(quote) {
  return {
    type: UPDATE_QUOTE_SUCCESS,
    quote,
  }
}

export function updateQuoteFailure(message) {
  return {
    type: UPDATE_QUOTE_FAILURE,
    message,
  }
}

export function updateRiskData(riskData) {
  return {
    type: UPDATE_RISK_DATA,
    riskData,
  }
}

export function updateValidation(fields) {
  return {
    type: UPDATE_VALIDATION_RISK_DATA_COLLECTION,
    fields,
  }
}

export function toggleFormIsValid(form) {
  return {
    type: TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION,
    form,
  }
}

export function updateUnderwriter() {
  return {
    type: UPDATE_UNDERWRITER,
  }
}

export function updateUnderwriterSuccess(response) {
  return {
    type: UPDATE_UNDERWRITER_SUCCESS,
    response,
  }
}

export function requestIdentityCheck() {
  return {
    type: REQUEST_IDENTITY_CHECK,
  }
}

export function requestIdentityCheckMoreInfo(links, check) {
  return {
    type: REQUEST_IDENTITY_CHECK_MORE_INFO,
    links,
    check,
  }
}

export function requestIdentityCheckSuccess(response) {
  return {
    type: REQUEST_IDENTITY_CHECK_SUCCESS,
    response,
  }
}

export function requestIdentityCheckFailure() {
  return {
    type: REQUEST_IDENTITY_CHECK_FAILURE,
  }
}

export function formManuallyEdited() {
  return {
    type: FORM_MANUALLY_EDITED,
  }
}

export function drivingLicenceCheck() {
  return {
    type: DRIVING_LICENCE_CHECK,
  }
}

export function passportCheck() {
  return {
    type: PASSPORT_CHECK,
  }
}

export function clearIdentityCheck() {
  return {
    type: CLEAR_IDENTITY_CHECK,
  }
}

export function isButtonDisabled(payload) {
  return {
    type: IS_BUTTON_DISABLED,
    payload,
  }
}

export function enableAllButtons() {
  return {
    type: ENABLE_ALL_BUTTONS,
  }
}

export function underwriterChangedByCue(underwriterId) {
  return {
    type: UNDERWRITER_CHANGED_BY_CUE,
    underwriterId,
  }
}
