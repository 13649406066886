import {
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE,
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD,
  SAVE_MARKETING_PREFERENCES_OPT_IN_STATE,
  RESET_MARKETING_PREFERENCES_OPT_IN_STATE,
} from './constants'

import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  getMarketingPreferenceLink,
  saveMarketingPreferenceLink,
} from './selector'
import marketingPreferencesApi from '../../api/marketingPreferencesApi'
import {
  checkMarketingOptInStateSuccess,
  checkMarketingOptInStateFailure,
  saveMarketingPreferencesSuccess,
  saveMarketingPreferencesFailure,
  resetMarketingPreferences,
} from './actions'
import { enableAllButtons } from '../RiskDataCollectionPage/actions'

function* checkMarketingOptInState(action) {
  const {
    payload: { email, mobileNumber },
  } = action

  if (email && mobileNumber) {
    var url = (yield select(getMarketingPreferenceLink)).Href.replace(
      /{emailAddress}/,
      email
    ).replace(/{telephoneNumber}/, mobileNumber)

    try {
      const response = yield call(marketingPreferencesApi.getState, url)

      yield put(checkMarketingOptInStateSuccess({ ...response.data }))
      yield put(enableAllButtons())
    } catch (e) {
      yield put(checkMarketingOptInStateFailure(e.message))
    }
  }
}

function* checkMarketingOptInStateAndForward(action) {
  yield checkMarketingOptInState(action)
}

function* resetMarketingPreferencesState() {
  yield put(resetMarketingPreferences)
}

export function* saveMarketingPreferences(marketingPreferences) {
  const { mobile, mobileOptIn, email, emailOptIn } = marketingPreferences

  const url = (yield select(saveMarketingPreferenceLink)).Href

  const data = {
    TelephoneNumber: mobile,
    TelephoneNumberOptIn: mobileOptIn,
    EmailAddress: email,
    EmailAddressOptIn: emailOptIn,
  }

  try {
    yield call(marketingPreferencesApi.update, url, data)

    yield put(saveMarketingPreferencesSuccess(data))
  } catch (e) {
    yield put(saveMarketingPreferencesFailure(e.message))
  }
}

export default function* marketingPreferencesSaga() {
  yield takeLatest(
    CHECK_MARKETING_PREFERENCES_OPT_IN_STATE,
    checkMarketingOptInState
  )
  yield takeLatest(
    CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD,
    checkMarketingOptInStateAndForward
  )
  yield takeLatest(
    RESET_MARKETING_PREFERENCES_OPT_IN_STATE,
    resetMarketingPreferencesState
  )
  yield takeLatest(
    SAVE_MARKETING_PREFERENCES_OPT_IN_STATE,
    saveMarketingPreferences
  )
}
