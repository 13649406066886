import React from 'react'
import { QuestionInfo } from '../../../containers/DriverDetails'
import { ErrorMessage } from '../ErrorMessage'
import { FieldLabel } from '../FieldLabel'
import { RadioButton } from '../RadioButton'

import {PreferenceWrapper} from './ToggleInput.style'

interface Props {
    id: string,
    label: string,
    value: boolean,
    onChange: (e: boolean) => void,
    name: string,
    questionInfo?: string,
    error?: boolean,
    errorMessage?: string,
}

const ToggleInput:React.FC<Props> = ({
  id,
  label,
  value,
  onChange,
  name,
  questionInfo,
  error,
  errorMessage,
}) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      {questionInfo && <QuestionInfo>{questionInfo}</QuestionInfo>}
      {error && (
        <ErrorMessage id={`${name || id}Error`}>{errorMessage}</ErrorMessage>
      )}
      <PreferenceWrapper>
        <RadioButton
          id={`${id}-true`}
          onChange={() => onChange(true)}
          value='true'
          name={name || id}
          label='Yes'
          checked={!!value}
          error={error}
        />
        <RadioButton
          id={`${id}-false`}
          onChange={() => onChange(false)}
          value='false'
          name={name || id}
          label='No'
          checked={value === false}
          error={error}
        />
      </PreferenceWrapper>
    </>
  )
}

export default ToggleInput
