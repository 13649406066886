import React from 'react'
import PropTypes from 'prop-types'
import { P, Span } from './styles.js'
import { Grid, GridContainer } from 'unsemantic'
import styled from 'styled-components'

const PriceSpan = styled(Span)`
  color: #1f1f1f;
  @media (max-width: 768px) {
    font-size: 22px;
    font-weight: bold;
  }
`

const SummaryFooter = ({ price, impounded }) => {
  return (
    <GridContainer>
      <Grid desktop='40' tablet='20' mobile='30'>
        <P>Policy cost and cover</P>
      </Grid>
      <Grid desktop='30' tablet='40' mobile='35'>
        <P>
          <PriceSpan>£{price}</PriceSpan>
        </P>
      </Grid>
      {impounded && (
        <Grid desktop='30' tablet='40' mobile='35'>
          <P>
            <strong>Third-Party Only</strong> cover
          </P>
        </Grid>
      )}
      {!impounded && (
        <Grid desktop='30' tablet='40' mobile='35'>
          <P>Fully comprehensive</P>
        </Grid>
      )}
    </GridContainer>
  )
}

SummaryFooter.propTypes = {
  price: PropTypes.string,
}

export default SummaryFooter
