import styled from 'styled-components'

export const ErrorMessage = styled.div`
  &:not(:empty) {
    display: block;
    color: #e61414;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    padding-left: 40px;
    padding-top: 5px;
  }

  &:not(:empty):before {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background-color: #e61414;
    position: absolute;
    top: 3.6px;
    left: 0;
  }

  &:not(:empty):after {
    content: '!';
    color: #fff;
    position: absolute;
    top: 3px;
    left: 9px;
  }
`
