import {
  REQUEST_AFFILIATE_REL,
  REQUEST_OPEN_SCHEMES_REL,
  GET_QUOTE_SUMMARY_REL,
  CREATE_QUOTE_REL,
  GET_PRICE_REL,
  GET_MOTOR_QUOTE_REL,
  REQUEST_CONFUSED_QUICK_QUOTE_REL,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_REL,
  CREATE_QUOTE_WITH_START_DATE_REL,
  REQUEST_CONFUSED_EXTERNAL_ACCOUNT_REL,
  UPDATE_CONFUSED_EXTERNAL_ACCOUNT_REL
  } from './constants'

export const requestAffiliateLink = (state) =>
  state.links.filter((link) => link.Rel === REQUEST_AFFILIATE_REL)[0]

export const getOpenSchemesSelector = (state) =>
  state.links.filter((link) => link.Rel === REQUEST_OPEN_SCHEMES_REL)[0]

export const getQuoteSummaryLink = (state) =>
  state.links.filter((link) => link.Rel === GET_QUOTE_SUMMARY_REL)[0]

export const createQuoteLink = (state) => {
  const scheme = state.scheme.selected
  const { Links } = state.scheme.open.filter((s) => s.Scheme === scheme)[0]
  return Links.filter((l) => l.Rel === CREATE_QUOTE_REL)[0]
}

export const createQuoteWithStartDateLink = (state) => {
  const scheme = state.scheme.selected
  const { Links } = state.scheme.open.filter((s) => s.Scheme === scheme)[0]
  return Links.filter((l) => l.Rel === CREATE_QUOTE_WITH_START_DATE_REL)[0]
}

export const getPriceLink = (state) =>
  state.links.filter((link) => link.Rel === GET_PRICE_REL)[0]

export const getVehicleInfo = (state) => state.vehicle

export const getRiskData = (state) => state.riskData

export const getQuoteLink = (state) =>
  state.links.filter((link) => link.Rel === GET_MOTOR_QUOTE_REL)[0]

export const getSelectedScheme = (state) => state.scheme.selected

export const getCampaignData = (state) => state.price.campaign
export const getAffiliateFromState = (state) => state.affiliate

export const getSessionData = (state) => state.session
export const getQuoteState = (state) => state.quote

export const getConfusedQuickQuote = (state) => state.confusedQuickQuote
export const getRequestConfusedQuickQuoteLink = (state) =>
  state.links.filter((link) => link.Rel === REQUEST_CONFUSED_QUICK_QUOTE_REL)[0]
export const getRequestConfusedQuickQuoteResponseLink = (state) =>
  state.links.filter(
    (link) => link.Rel === REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_REL
  )[0]
  
export const getConfusedExternalAccount = (state) =>
    state.links.filter((link) => link.Rel === REQUEST_CONFUSED_EXTERNAL_ACCOUNT_REL)[0]
export const updateConfusedExternalAccountLink = (state) =>
    state.links.filter((link) => link.Rel === UPDATE_CONFUSED_EXTERNAL_ACCOUNT_REL)[0]
export const getConfusedExternalAccountState = (state) => state.confusedExternalAccount