import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from 'unsemantic'
import underwriterApi from '../../api/underwriterApi'
import { DeclarationModal } from '../../components/DeclarationModal'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { ConfusedBackButton } from '../../components/common/ConfusedBackButton/ConfusedBackButton.component'
import { ConfusedPageEnd } from '../../components/common/ConfusedPageEnd'
import { ConfusedWording } from '../../components/common/ConfusedWording'
import { ConfusedWrapper } from '../../components/common/ConfusedWrapper'
import { H2 } from '../../components/common/H2'
import { H3 } from '../../components/common/H3'
import config from '../../config'
import { ROUTES } from '../../services/constants'
import { updateStep } from '../App/actions'
import { requestLegalDeclarationSuccess } from '../LegalDeclarationModal/actions'
import { LegalDeclarationButton } from './Button'
import { LegalDeclarationPageWrapper } from './LegalDeclarationPageWrapper'
import {
  DocumentLinksWrapper,
  LegalDeclarationAgreeAndContinueText,
  PolicyConfirmationButtonWrapper,
  ShortDeclarationsText,
} from './styles'

const LegalDeclarationPage = (props) => {
  // eslint-disable-next-line no-unused-vars
  let legalDeclarationModalRef = useRef()
  // eslint-disable-next-line no-unused-vars
  let disallowedOccupationsModalRef = useRef()
  const [modalOpen, setModalOpen] = useState('')

  useEffect(() => {
    props.actions.updateStep(2)

    underwriterApi
      .getLegalDeclaration(
        `/api/affiliates/CF103AE310635/quotes/${props.quoteId}/schemes/${props.scheme}/underwriters/${props.price.UnderwriterId}/allDeclarations`
      )
      .then((result) => {
        props.actions.requestLegalDeclarationSuccess(result.data)
      })

    if (document.getElementById('DisallowedOccupationListItem')) {
      const disallowedOccupationsList = document.getElementById(
        'DisallowedOccupationListItem'
      )
      const disallowedOccupationsText = document.createTextNode(
        'Not be in one of the following occupations: '
      )
      const disallowedOccupationsLink = document.createElement('p')
      disallowedOccupationsLink.innerHTML = 'View'
      disallowedOccupationsLink.setAttribute('id', 'DisallowedOccupationsLink')
      disallowedOccupationsLink.onclick = () => {
        handleModalOpen('DisallowedOccupations')
      }
      disallowedOccupationsList.appendChild(disallowedOccupationsText)
      disallowedOccupationsList.appendChild(disallowedOccupationsLink)
    }
  }, [])

  const handleModalOpen = (modalName) => {
    setModalOpen(modalName)
  }

  const handleModalClose = () => {
    setModalOpen('')
  }

  const agreeClick = () => {
    props.history.push(`${ROUTES.QUOTE_PAYMENT}${props.queryString}`)
  }

  const yourPolicyButton = () => {
    props.history.push(`${ROUTES.QUOTE_MORE_INFO}${props.queryString}`)
  }

  return (
    <LegalDeclarationPageWrapper>
      <ConfusedWrapper>
        <DeclarationModal
          id='LegalDeclarationsModal'
          text={props.legalDeclaration.FullText}
          title='Full Declaration'
          hideFullDeclaration={() => handleModalClose()}
          myRef={(el) => (legalDeclarationModalRef = el)}
          isVisible={modalOpen === 'Declarations'}
        />
        <DeclarationModal
          text={props.legalDeclaration.DisallowedOccupations}
          title='Unacceptable Occupations'
          hideFullDeclaration={() => handleModalClose()}
          myRef={(el) => (disallowedOccupationsModalRef = el)}
          isVisible={modalOpen === 'DisallowedOccupations'}
        />
        <ConfusedBackButton onClick={yourPolicyButton}>
          Your quote
        </ConfusedBackButton>
        <H2 gap='medium'>Your quote is:</H2>
        {props.riskData.Impounded ? (
          <>
            <H3>
              <strong>Third-Party Only</strong> cover
            </H3>
            <H3>
              The policyholder <strong>MUST</strong> be the owner and registered
              keeper of the vehicle being insured.
            </H3>
          </>
        ) : (
          <H3>
            Fully comprehensive with &pound;
            {props.price.CompulsoryExcess.toFixed(2)} excess
          </H3>
        )}

        <H2>Just to check...</H2>
        <ShortDeclarationsText
          id='ShortDeclaration'
          dangerouslySetInnerHTML={{
            __html: props.legalDeclaration.ShortText,
          }}
        />
        <DocumentLinksWrapper>
          <div className='declaration-link'>
            <ConfusedWording
              id='FullDeclaration'
              onClick={() => handleModalOpen('Declarations')}
            >
              Read full declaration here
            </ConfusedWording>
          </div>
          <div className='declaration-link'>
            <ConfusedWording
              id='PolicyWording'
              href={
                config.WEB_URL + '/' + props.legalDeclaration.PolicyWordingUrl
              }
              target='_blank'
            >
              Download policy wording here
            </ConfusedWording>
          </div>
          <div className='declaration-link'>
            <ConfusedWording
              id='ProductInformation'
              href={
                config.WEB_URL +
                '/' +
                props.legalDeclaration.InsuranceProductInformationUrl
              }
              target='_blank'
            >
              Download insurance product information here
            </ConfusedWording>
          </div>
          <div className='declaration-link'>
            <ConfusedWording
              id='TempcoverContract'
              href={
                config.WEB_URL +
                '/' +
                props.legalDeclaration.TempcoverContractUrl
              }
              target='_blank'
            >
              Download brokerage contract here
            </ConfusedWording>
          </div>
        </DocumentLinksWrapper>
        <ConfusedPageEnd bigPadding />
        <PolicyConfirmationButtonWrapper>
          <Grid desktop='100' parent>
            <LegalDeclarationAgreeAndContinueText id='AgreementStatement'>
              By proceeding, you confirm that you have read, can meet, and have
              understood the terms and conditions set by the Broker, as laid out
              in the Broker contract, and {props.price.UnderwriterDisplayName}{' '}
              as laid out in the full declaration.
            </LegalDeclarationAgreeAndContinueText>
          </Grid>
          <LegalDeclarationButton
            id='TeslaSubmitDeclarationNextButton'
            onClick={agreeClick}
            data-email={props.riskData.EmailAddress}
            className={
              props.myLicenceStatus.DisabledBySetting
                ? `uw_${props.price.Underwriter}`
                : props.myLicenceStatus.Enabled
                ? `ab_mylicence_on uw_${props.price.Underwriter}`
                : `ab_mylicence_off uw_${props.price.Underwriter}`
            }
          >
            {props.legalDeclarationButtonDisabled
              ? 'Please wait'
              : 'Agree and Continue'}
            <ButtonSpinner disabled={props.legalDeclarationButtonDisabled} />
          </LegalDeclarationButton>
        </PolicyConfirmationButtonWrapper>
        <ConfusedBackButton onClick={yourPolicyButton}>
          Your quote
        </ConfusedBackButton>
      </ConfusedWrapper>
    </LegalDeclarationPageWrapper>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    price: state.price,
    legalDeclaration: state.legalDeclaration,
    quoteId: state.riskData.QuoteId,
    legalDeclarationButtonDisabled: state.formButtons.legalDeclaration.disabled,
    queryString: state.queryString,
    riskData: state.riskData,
    scheme: state.scheme.selected,
    myLicenceStatus: state.myLicence,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateStep,
        requestLegalDeclarationSuccess,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalDeclarationPage)
