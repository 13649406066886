import styled from 'styled-components'

export const P = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: ${(props) => props.theme.labelFontSize};
  line-height: 1rem;
  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.mobileLabelFontSize};
  }
`

export const Span = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: #1f1f1f;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
