import styled from 'styled-components'
import { SummaryBorderMobile } from '../QuoteSummary/SummarySectionStyles'
import { Button } from '../../components/common/Button'
import { Select } from '../../components/common/Select'
import { H1 } from '../../components/common/H1'
import { H3 } from '../../components/common/H3'

export const MainHeading = styled(H1)`
  color: ${(props) => props.theme.primaryColour};
`

export const I = styled.i`
  color: ${(props) => props.color != null ? props.color : props.theme.successColour};
  font-size: 55px;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
`
export const IconWrapper = styled.div`
  text-align: center;
`

export const P = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: ${(props) => props.theme.labelFontSize};
  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.mobileLabelFontSize};
  }
`

export const A = styled.a`
  text-decoration: underline;
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.primaryTextColour};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const DocumentsWrapper = styled(SummaryBorderMobile)`
  background-color: #efefef;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
`

export const SendReasonForCoverButton = styled(Button)`
  width: 124px;
  @media (max-width: 768px) {
    width: 75px;
  }
`

export const SendReasonForCoverButtonWrapper = styled.div`
  margin-top: 10px;
`

export const SendReasonForCoverSelect = styled(Select)`
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const SuccessfulReasonForCover = styled(H3)`
  color: ;
`

export const DocumentPostText = styled.p`
  font-size: 12px;
`
export const DocumentsList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
`

export const SendReasonWrapper = styled.div`
  margin-bottom: 50px;
`
