import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  requestConfusedExternalAccountSuccess,
  requestConfusedExternalAccountFailure
} from '../actions'
import {
  REQUEST_CONFUSED_EXTERNAL_ACCOUNT,
  UPDATE_CONFUSED_EXTERNAL_ACCOUNT
} from '../constants'
import {
  getConfusedExternalAccount,
  getConfusedExternalAccountState,
  updateConfusedExternalAccountLink,
} from '../selector'
import confusedExternalAccountApi from '../../../api/confusedExternalAccountApi'
import queryString from 'query-string'

export function* requestConfusedExternalAccount(data) {
  try {
    let { Href } = yield select(getConfusedExternalAccount)
    
    const parsedQueryString = queryString.parse(window.location.search)
    if (Href) {
      const response = yield call(
        confusedExternalAccountApi.getConfusedExternalAccount,
        Href,
        data.authToken,
        parsedQueryString.InternalRvuUserOverride === 'true'
      )
      yield put(
        requestConfusedExternalAccountSuccess({ ...response.data.Account})
      )
    }
  } catch (e) {
    yield put(requestConfusedExternalAccountFailure())
  }
}

export function* requestConfusedExternalAccountResponse() {
  try {
    let { Href } = yield select(requestConfusedExternalAccountResponse)

    if (Href) {
      const response = yield call(
        confusedExternalAccountApi.getConfusedExternalAccountResponse,
        Href
      )
      if (response && response.data) {
        yield put(requestConfusedExternalAccountSuccess({ ...response.data}))
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export function* updateConfusedExternalAccount(action) {
  try {
    let { Href } = yield select(updateConfusedExternalAccountLink)
    let {AuthToken} = yield select(getConfusedExternalAccountState)
    
    if (Href) {
      yield call(
        confusedExternalAccountApi.updateConfusedExternalAccount,
        Href,
        AuthToken,
        JSON.stringify(action.data)
      )
    }
  } catch (e) {
    // This is a fire and forget request so catch is intentionally blank
  }
}

export default function* confusedExternalAccountSaga() {
  yield takeLatest(REQUEST_CONFUSED_EXTERNAL_ACCOUNT, requestConfusedExternalAccount)
  yield takeLatest(UPDATE_CONFUSED_EXTERNAL_ACCOUNT, updateConfusedExternalAccount)
}