import styled from 'styled-components'

export const DateWrapper = styled('div')<{ error?: boolean }>`
  display: flex;
  justify-content: flex-start;
  max-width: 300px;
  margin-bottom: 48px;
`

export const DateField = styled('div')<{ error?: boolean; small?: boolean }>`
  max-width: 5rem;

  & input {
    border: ${(props) => (props.error ? '4px' : '2px')} solid
      ${(props) =>
        props.error
          ? props.theme.inputBorderColourError
          : props.theme.inputBorderColour};
    width: ${(props) => (props.small ? '68px' : '80px')};
    margin-right: 10px;

    ::placeholder {
      font-weight: 400;
    }

    :focus {
      outline: 4px solid #4c90bd;
    }
  }

  & label {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 18px;
  }
`
