import styled from 'styled-components'
import { BaseSelect } from '../common/Select/BaseSelect'
import React from 'react'
import { SelectWrapper } from '../common/Select/SelectWrapper'

const VehicleBuilderSelectStyle = styled(BaseSelect)`
  font-size: 16px;
  margin-bottom: 8px;
  &.noMargin {
    margin-bottom: 0px;
  }
`

export const VehicleBuilderSelect = ({
  id,
  className,
  value,
  options,
  error,
  onBlur,
  onChange,
  placeholder,
  name,
  style,
  myRef,
}) => {
  return (
    <SelectWrapper error={error} style={style} tinyGap>
      <VehicleBuilderSelectStyle
        ref={myRef}
        id={id}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        key={value}
        name={name}
        style={style}
        className={className}
      >
        {placeholder && <option value=''>{placeholder}</option>}
        {options.map((option) => (
          <option
            key={option.id}
            value={option.value}
            selected={option.selected}
          >
            {option.description}
          </option>
        ))}
      </VehicleBuilderSelectStyle>
    </SelectWrapper>
  )
}

export default VehicleBuilderSelect
