import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showExperiment } from '../../store/experiment/experiment.actions'

const handleABExperiment = async (experimentID, setVariant) => {
  if (window.dataLayer) {
    // @ts-ignore
    await window.dataLayer.push({ event: 'optimize.activate' })
  }
  let index = 0
  const intervalId = setInterval(() => {
    // @ts-ignore
    if (window.google_optimize !== undefined && index < 10) {
      // @ts-ignore
      const variantResult = window.google_optimize.get(experimentID)
      if (variantResult !== undefined) {
        setVariant(variantResult)
        clearInterval(intervalId)
      } else {
        index += 1
      }
    } else {
      // In this case google optimize is down / blocked
      // So we set variant to be the default
      setVariant('0')
      clearInterval(intervalId)
    }
  }, 100)
}

const useABExperiment = (experimentID) => {
  const dispatch = useDispatch()

  const experimentsStore = useSelector((state) => state.experiments)

  const experimentInState = experimentsStore[experimentID]

  const [variant, setVariant] = useState('')

  if (typeof experimentInState !== 'number') {
    // @ts-ignore
    handleABExperiment(experimentID, setVariant)
  }

  useEffect(() => {
    if (variant !== '' && typeof experimentInState !== 'number') {
      let variantNumber = null
      if (!Number.isNaN(parseInt(variant, 10))) {
        variantNumber = +variant
      }

      if (variantNumber !== null) {
        // store experiment in store so we dont re-call the AB logic
        dispatch(showExperiment({ [experimentID]: variantNumber }))
      }
    }
  }, [variant, experimentInState, experimentID, dispatch])

  useEffect(() => {
    if (typeof experimentInState === 'number') {
      setVariant(experimentInState.toString())
    }
  }, [experimentInState])

  return {
    variant,
  }
}

export default useABExperiment
