import styled from 'styled-components'

export const MessageHeader = styled.h2`
  font-size: 26px;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  text-align: left;
  line-height: 36px;
  margin-bottom: 5px;
  margin: 0px;
  padding-left: 14px;
`
