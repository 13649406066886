import React from 'react'
import PropTypes from 'prop-types'
import { P, Span } from './styles.js'
import { Grid, GridContainer, GridClear } from 'unsemantic'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-bottom: 10px;
`

const VehicleRegSection = ({ reg }) => {
  return (
    <GridContainer>
      <Wrapper>
        <Grid desktop='40' tablet='20' mobile='30'>
          <P>Vehicle registration</P>
        </Grid>
        <Grid desktop='60' mobile='70' tablet='80'>
          <P>
            <Span>{reg}</Span>
          </P>
        </Grid>
        <GridClear />
      </Wrapper>
    </GridContainer>
  )
}

VehicleRegSection.propTypes = {
  reg: PropTypes.string,
}

export default VehicleRegSection
