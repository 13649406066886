import styled from 'styled-components'

export const HeaderWrapper = styled.header` 
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto;
    background-color: ${(props) => props.theme.header.background};
    border-bottom: ${(props) => props.theme.header.borderBottom};
    background-image: ${(props) => props.theme.header.backgroundImage};
    background-repeat: no-repeat;
    background-size: cover;
    &:hover: ${(props) => props.theme.header.hover};
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    min-height: 60px;

    @media (max-width: 768px) {
        height: 50px;
        min-height: 50px;
    }
`
export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 900px;
  padding: 0 58px;
  background: ${(props) => props.theme.header.background};
  z-index: 3;
  height: 60px;

  @media (max-width: 768px) {
    height: 50px;
  }

  @media (max-width: 1024px) {
    padding: 0 70px 0 20px;
  }
`

export const TeslaBrand = styled.div`
  &:after {
    content: ' ';
    clear: both;
  }
`

export const HeaderLinkStyled = styled.a`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  font-family: ${(props) => props.theme.labelFontFamily};
  text-decoration: none;
  padding: 0 10px;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
export const HeaderLinkWrapper = styled.div`
  float: right;
  display: flex;
  height: 48px;
  align-items: center;
`
