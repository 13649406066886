import moment from 'moment'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  INIT_RISK_DATA_COLLECTION_PAGE,
  CONFIRM_RISK_DATA_COLLECTION_PAGE,
  REQUEST_IDENTITY_CHECK_SUCCESS,
  DRIVING_LICENCE_CHECK,
  PASSPORT_CHECK,
} from '../constants'
import { handleQueryString } from '../../../services/handlers'
import { updateRiskData } from '../actions'
import fetchVehicleSaga, { setVehicleSaga } from '../../Vehicle/saga'
import { underwriterSaga } from './underwriter'
import {
  identitySaga,
  drivingLicenceCheckSaga,
  passportCheckSaga,
} from './identity'
import legalDeclarationSaga from '../../LegalDeclarationModal/saga'
import {
  getVehicle,
  getQuoteId,
  getQueryString,
  getQuoteStatus,
} from '../selector'
import { history } from '../../../'
import {
  createQuoteSaga,
  fetchQuoteSummary,
  fetchQuote,
} from '../../App/sagas/quoteSaga'
import {
  isLoading,
  setQueryString,
  setTempUnderwriter,
  updateCampaignData,
  setQuoteJourneyPosition,
} from '../../App/actions'
import queryString from 'query-string'
import {
  setUiElementVisibility,
  setUiElementMessageVisibility,
} from '../../App/actions'
import { getAffiliate } from '../../App/sagas/affiliateSaga'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
} from '../../Vehicle/constants'
import { getSelectedScheme } from '../../App/selector'
import { UI_ELEMENT_HOUR_DURATION } from '../../App/constants'
import {
  POLICY_DURATION_DAYS,
  POLICY_DURATION_HOURS,
} from '../../../components/PolicyDurationModal/constants'
import { getPostcode } from '../../Address/selector'
import { fetchAddresses } from '../../Address/saga'
import { updateRiskDataSaga } from './riskData'
import { formValidation } from './validation'
import { requestMyLicenceStatus } from '../../MyLicence/actions'
import { ROUTES, QUOTE_STATUSES } from '../../../services/constants'
import { IMPOUNDED_VALUE } from '../../HomePage/constants'
import { QUOTE_JOURNEY_POSITION } from '../../../constants'

function* initRiskDataCollection() {
  try {
    const parsedQueryString = queryString.parse(window.location.search)

    if (parsedQueryString.u) {
      yield put(setTempUnderwriter(parsedQueryString.u))
      var x =
        '?q=' +
        parsedQueryString.q +
        (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : '') + 
        (parsedQueryString.experiment ? '&experiment=' + parsedQueryString.experiment : '')
      history.replace(`/quote/driving-licence${x}`)
    }

    if (parsedQueryString.ref) {
      yield* getAffiliate(parsedQueryString.ref)
    }

    let quoteId
    if (parsedQueryString.q) {
      quoteId = parsedQueryString.q
      yield put(
        setQueryString(
          '?q=' +
            quoteId +
            (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : '') + 
            (parsedQueryString.experiment ? '&experiment=' + parsedQueryString.experiment : '')
        )
      )
      yield put(
        setQuoteJourneyPosition(QUOTE_JOURNEY_POSITION.CustomerDirectAccess)
      )
      yield* fetchQuoteSummary(quoteId)
      yield put(requestMyLicenceStatus())
      yield* fetchQuote()
      const quoteStatus = yield select(getQuoteStatus)
      const queryString = yield select(getQueryString)
      if (quoteStatus === QUOTE_STATUSES.COMPLETE) {
        history.replace(`/policy/confirmation${queryString}`)
        return
      }
      if (quoteStatus === QUOTE_STATUSES.ANONYMISED) {
        history.replace({
          pathname: ROUTES.ANONYMISED_QUOTE,
        })
        return
      }
      const postcode = yield select(getPostcode)
      if (postcode) {
        yield* fetchAddresses({ postcode })
        yield* updateRiskDataSaga({
          payload: { partialValidation: true, getPrice: true },
        }) //bit hacky, need to only call if i have a address
      }
      yield* formValidation()
      yield put(isLoading(false))
      return
    }
    let params = {
      duration: handleQueryString().Duration,
      durationType: handleQueryString().DurationType,
      campaignRef: handleQueryString().CampaignRef,
      reasonForCover: handleQueryString().ReasonForCover,
      policyStartDate: handleQueryString().PolicyStartDateTime,
      confusedProfileVehicleSelected: handleQueryString().ConfusedProfileVehicleSelected,
    }

    yield* setStateFromParams(params)
    if (
      handleQueryString().UnknownVehicle.Make ||
      handleQueryString().UnknownVehicle.GrossWeight
    ) {
      yield* createNewQuoteFromUnknownVehicle(params)
      yield put(requestMyLicenceStatus())
    } else {
      const vehicleReg = handleQueryString().RegistrationNumber
      const requestStatus = yield* fetchVehicleSaga({
        registrationNumber: vehicleReg,
      })
      const vehicle = yield select(getVehicle)
      if (requestStatus === 200) {
        if (vehicle.searched.VehicleType === 'Motorbike') {
          history.replace({
            pathname: `/vehicle/invalid-reg`,
            search:
              window.location.search +
              '&VehicleType=' +
              vehicle.searched.VehicleType,
            state: { error: 'VehicleNotAllowed' },
          })
          return
        }

        yield* createNewQuoteFromReg(params)
        yield put(requestMyLicenceStatus())
      } else {
        let error
        if (requestStatus === 400) {
          error = 'InvalidReg'
        } else {
          error = 'VehicleNotFound'
        }
        history.replace({
          pathname: `/vehicle/invalid-reg`,
          search: window.location.search,
          state: { error: error },
        })
        return
      }
    }

    quoteId = yield select(getQuoteId)
    yield put(
      setQueryString(
        '?q=' +
          quoteId +
          (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : '') + 
          (parsedQueryString.experiment ? '&experiment=' + parsedQueryString.experiment : '') +
          (parsedQueryString.InternalRvuUserOverride ? '&InternalRvuUserOverride=' + parsedQueryString.InternalRvuUserOverride : '')
      )
    )
    const redirectQueryString = yield select(getQueryString)
    // if(!approvedList) {
    history.replace(`/quote/driver-details${redirectQueryString}`)
    // } else {
    //   yield put(updateStep(1));
    //   history.push(`/quote/select-path?q=${quoteId}`);
    // }

    yield put(isLoading(false))
  } catch (e) {
    yield put(isLoading(false))
  }
}
function* confirmRiskDataCollectionPage() {
  try {
    yield call(underwriterSaga)
    yield call(identitySaga)
  } catch (e) {}
}

export default function* riskDataCollectionSaga() {
  yield takeLatest(INIT_RISK_DATA_COLLECTION_PAGE, initRiskDataCollection)
  yield takeLatest(
    CONFIRM_RISK_DATA_COLLECTION_PAGE,
    confirmRiskDataCollectionPage
  )
  yield takeLatest(REQUEST_IDENTITY_CHECK_SUCCESS, legalDeclarationSaga)
  yield takeLatest(DRIVING_LICENCE_CHECK, drivingLicenceCheckSaga)
  yield takeLatest(PASSPORT_CHECK, passportCheckSaga)
}

function* setStateFromParams({
  duration,
  durationType,
  campaignRef,
  reasonForCover,
  policyStartDate,
  confusedProfileVehicleSelected
}) {
  let policyStartDateJson = (policyStartDate == null || policyStartDate === "Immediately") ?
    {
      value: moment().format('YYYY-MM-DDTHH:mm'),
      manuallyEdited: false
    } :
    { 
      value: moment(policyStartDate.replace('_', ':')).format('YYYY-MM-DDTHH:mm'),
      manuallyEdited: true 
    }

  yield put(
    updateRiskData({
      Duration: duration,
      DurationType: durationType,
      PolicyStartDate: policyStartDateJson,
      ReasonForCover: reasonForCover,
      Impounded: reasonForCover === IMPOUNDED_VALUE,
      confusedProfileVehicleSelected: confusedProfileVehicleSelected
    })
  )
  yield put(
    updateCampaignData({
      CampaignRef: campaignRef,
    })
  )
}

function* createNewQuoteFromReg(params) {
  yield* setStateFromParams(params)
  const vehicle = yield select(getVehicle)
  yield* setVehicleSaga(vehicle.searched)
  yield* createQuoteSaga()
}

function* createNewQuoteFromUnknownVehicle(params) {
  const { RegistrationNumber } = handleQueryString()

  if (RegistrationNumber) {
    yield* createNewQuoteFromReg(params)
  } else {
    yield* setStateFromParams(params)
    yield* createQuoteSaga()
  }
}

export function* setHourDurationTypeVisibilityForCVSaga(durationType) {
  const scheme = yield select(getSelectedScheme)
  const isCV =
    scheme === COMMERCIAL_VEHICLE_SCHEME ||
    scheme === IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME
  const isHours = durationType === POLICY_DURATION_HOURS
  yield put(setUiElementVisibility(UI_ELEMENT_HOUR_DURATION, !isCV))
  yield put(
    setUiElementMessageVisibility(UI_ELEMENT_HOUR_DURATION, isCV && isHours)
  )
  if (isCV && isHours) {
    yield put(
      updateRiskData({
        Duration: 1,
        DurationType: POLICY_DURATION_DAYS,
      })
    )
  }
}
