import styled from 'styled-components'

export const VehicleButtonWrapper = styled.div`
  padding: 20px 0;
  text-align: left;
`

export const VehicleParagraph = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: ${(props) => props.theme.labelFontSize};
  text-align: left;
`

export const VehicleBlockWrapper = styled.div`
  & button {
    margin-top: 16px;
    margin-bottom: 48px;
  }
`
