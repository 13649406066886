import React from 'react'
import { BaseButton } from '../common/Button/BaseButton'
import { H2 } from '../common/H2'
import { ButtonSpacer } from '../../containers/HomePage/styles'
import { ROUTES } from '../../services/constants'

export const AnonymisedQuotePage = () => {
  const startNewQuote = () => {
    window.location.href = ROUTES.HOME_PAGE
  }
  return (
    <div>
      <H2>
        This quote has now been anonymised in accordance with GDPR{' '}
        <ButtonSpacer></ButtonSpacer>
        <BaseButton onClick={startNewQuote}>Start a new quote</BaseButton>
      </H2>
    </div>
  )
}
