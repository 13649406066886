import { ROUTES } from '../../services/constants'

let Steps = {
  0: {
    Route: ROUTES.HOME_PAGE,
    Display: 'Policy details',
  },
}

const isUsingExperment = window.location.search.indexOf('experiment') > 0;

if (isUsingExperment){
  Steps = {
    0: {
      Route: ROUTES.HOME_PAGE,
      Display: 'Policy details',
    },
    1: {
      Route: ROUTES.COVER_DETAILS,
      Display: 'Cover details',
    },
    2: {
      Route: ROUTES.QUOTE_DRIVER_DETAILS,
      Display: 'Driver details',
    },
    3: {
      Route: ROUTES.QUOTE_DRIVING_LICENCE,
      Display: 'Your quote',
    },
    4: {
      Route: ROUTES.QUOTE_PAYMENT,
      Display: 'Payment',
    },
  }
} else {
  Steps = {
    0: {
      Route: ROUTES.HOME_PAGE,
      Display: 'Policy details',
    },
    1: {
      Route: ROUTES.QUOTE_DRIVER_DETAILS,
      Display: 'Driver details',
    },
    2: {
      Route: ROUTES.QUOTE_DRIVING_LICENCE,
      Display: 'Your quote',
    },
    3: {
      Route: ROUTES.QUOTE_PAYMENT,
      Display: 'Payment',
    },
  }
}

export default Steps;