import fetchAntiForgeryTokenSaga from '../App/sagas/antiForgerySaga'
import fetchLinksSaga from '../App/sagas/linksSaga'
import { setVehicleSearchedReg, setVehicleError } from '../Vehicle/actions'
import { put, takeLatest } from 'redux-saga/effects'
import { INIT_INVALID_REG_PAGE } from './constants'

import { handleQueryString } from '../../services/handlers'
import { updateRiskData } from '../RiskDataCollectionPage/actions'
import { initInvalidRegPageSuccess, initInvalidRegPageFailure } from './actions'
import { isLoading } from '../App/actions'
import { history } from '../../'

function* initInvalidRegPage() {
  try {
    yield put(isLoading(true))
    yield* fetchAntiForgeryTokenSaga()
    yield* fetchLinksSaga()

    const duration = handleQueryString().Duration
    const durationType = handleQueryString().DurationType
    const vehicleReg = handleQueryString().RegistrationNumber
    const vehicleType = handleQueryString().UnknownVehicle.VehicleType

    if (history.location.state.error === 'VehicleNotFound') {
      yield put(setVehicleError('NotFound'))
    } else {
      yield put(setVehicleError(history.location.state.error))
    }

    yield* setStateFromParams(duration, durationType, vehicleReg, vehicleType)

    yield put(initInvalidRegPageSuccess())
    yield put(isLoading(false))
  } catch (e) {
    yield put(initInvalidRegPageFailure(e))
    yield put(isLoading(false))
  }
}

export default function* invalidRegPageSaga() {
  yield takeLatest(INIT_INVALID_REG_PAGE, initInvalidRegPage)
}

function* setStateFromParams(duration, durationType, vehicleReg, vehicleType) {
  yield put(
    updateRiskData({
      Duration: duration,
      DurationType: durationType,
    })
  )

  yield put(setVehicleSearchedReg(vehicleReg, vehicleType))
}
